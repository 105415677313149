/*
Intro component for homepage
Display Intro slider with banners on right side
*/
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import greenwells from "../../../static/greenwells.jpg";
import modro from "../../../static/modroizeleno_banner.jpg";
import LoaderBox from "../../helpers/LoaderBox";
import { homeUrl } from "../../../functions/pageUrls";
import axios from "axios";
// import omnium from "../../../static/baneri-omnium-bih.jpg";
import omnium from "../../../static/Omnium.jpg";
import winkStores from "../../../static/desktop-slider-klompe.jpg";
import LazyLoad from "react-lazyload";

class Intro extends React.Component {
  constructor() {
    super();
    this.unsubscribe = null;

    this.state = {
      sliders: [],
      loading: false,
    };
  }
  getSliderList() {
    axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL + "api/sliders")
      .then((response) => {
        this.setState({
          sliders: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount = () => {
    this.getSliderList();
  };
  render() {
    //Slider attributes

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      lazyLoad: true,
    };

    return (
      <section id="s_intro" className="section intro">
        <div className="container">
          {/******************* Loader box *******************/}
          {this.state.loading && <LoaderBox />}

          <div className="columns my-tiles">
            <div className="column is-8 is-left">
              <div className="big-tile">
                <Slider {...settings}>
                  {this.state.sliders.map((slider) => (
                    <>
                      {slider.published === 1 && (
                        <Link
                          to={slider.link}
                          className="image"
                          area-label="Slider image"
                        >
                          {slider.image && (
                            <img
                              src={
                                process.env.REACT_APP_FILES_DESTINATION +
                                slider.image
                              }
                              alt="Slider"
                            />
                          )}
                        </Link> /* AREA LEFT END */
                      )}
                    </>
                  ))}
                  {/* <>
                                <a target="_blank" href="https://bih.winkstores.com/collections/klompe" className="image"  area-label="Slider image">
                                    <img src="/desktop-slider-1.jpg" alt="Slider" />
                                </a>
                            </> */}
                </Slider>
              </div>
            </div>
            <div className="column is-4 is-right">
              <div className="first-tile single-tile">
                <a href="https://zdravo.ba/proizvod/zdravlje/vitacare/neubria-charge-energy-cps-a60">
                  <LazyLoad height={455} once="true">
                    <img
                      style={{ width: "100%" }}
                      src={omnium}
                      alt="Banner"
                      area-label="Banner Moonamar"
                    />
                  </LazyLoad>
                </a>
              </div>
              <div className="second-tile single-tile">
                <a
                  href="https://bih.winkstores.com/collections/klompe"
                  target="_blank"
                >
                  <LazyLoad height={170} once="true">
                    <img
                      style={{ width: "100%" }}
                      src={winkStores}
                      alt="Banner"
                      area-label="Banner wink"
                    />
                  </LazyLoad>
                </a>
              </div>
              {/* <div className="second-tile single-tile">
                            <a href="https://modroizeleno.com" target="_blank">
                            <LazyLoad height={170} once="true">
                                <img src={modro}  alt="Banner" area-label="Banner Modro i Zeleno"/>
                            </LazyLoad>
                            </a>
                        </div> */}
            </div>
          </div>
        </div>
        {/*  CONTAINER END */}
      </section> /* INTRO SECTION END */
    );
  }
}

export default Intro;
