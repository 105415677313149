/*
Default variables for whole shop
This is client side config only - don't put anything in here that shouldn't be public!
*/

export const perPage = 4;
export const adminPerPage = 10;
export const sliderPerPage = 2;
export const siteTitle = "Zdravo.ba Online Shop";
export const timeoutDelay = 3000;
export const homeCategory1 = "1";
export const homeCategory2 = "5";
export const homeCategory3 = "31";