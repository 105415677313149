/*
Footer component
Display footer on each page
*/
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../static/zdravo-logo1.png'
import {aboutUsUrl, contactUrl, homeUrl, privacyPolicyUrl, categoryUrl, faqUrl, paymentOptionsUrl, shippingUrl, termsOfUseUrl} from '../../functions/pageUrls'
import axios from 'axios';

class Footer extends React.Component{

    constructor () {
        super()
        this.unsubscribe = null;
        
        this.state = {
            menuCategories: [],
            }
      }


      
getCategoryList() {  
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories')
.then((response) => {
        this.setState({
    menuCategories: response.data
    });
})
.catch((error) => {
    console.log(error);
})
}

componentDidMount = () => {
    this.getCategoryList();
}

//Function: check if is homepage
 checkHomepage() {
    if (window.location.pathname == '/'){
        return true;
      }
 }

    render(){
        return(
            <section id="footer" className="section footer">
                <div className="main">
                    <div className="container">
                        <div className="columns is-multiline">
                        {/******************* Logo and info *******************/}
                            <div className="column is-4 area-left">
                                <Link to="/" className="logo"><img src={logo} alt="Logo" /></Link>
                                <div className="support-box">
                                    <span className="icon help"></span>
                                    <div className="content-box">
                                        <p>Kontakt podrška</p>
                                        <a href="tel:+387 61 93 93 48">+387 61 93 93 48</a>
                                        <a href="tel:+387 33 50 08 18">+387 33 50 08 18</a>
                                        <a href="mailto:info@zdravo.ba">info@zdravo.ba</a>
                                    </div>
                                </div>
                            </div>{/* AREA LEFT END */}
                            <div className="column is-6 is-mobile">
                                <div className="social-box">
                                    <a href="https://www.facebook.com/Zdravoba-1248724705303553/" target="_blank" className="icon facebook" area-label="facebook"></a>
                                    <a href="/" className="icon instagram" area-label="instagram"></a>
                                </div>
                            </div>
                            {/******************* Contact menus *******************/}
                            <div className="column is-2 footer-menu is-right">
                                <h6 className="title">Informacije</h6>
                                <ul>
                                    <li><Link to={"/"+ aboutUsUrl}>O nama</Link></li>
                                    <li><Link to={"/"+ contactUrl}>Kontaktirajte nas</Link></li>
                                    <li><Link to={"/"+ privacyPolicyUrl}>Zaštita privatnosti</Link></li>
                                    <li><Link to={"/"+ termsOfUseUrl}>Uslovi korištenja</Link></li>
                                </ul>
                            </div>{/* FOOTER MENU END */}
                           
                                    <div className="column is-2 footer-menu is-right">
                                        <h6 className="title">Kategorije</h6>
                                        <ul>
                                        {this.state.menuCategories.map(category =>
                                         category.parentCategory === null && category.showInMenu === 1 && (
                                            <li><Link to={`/${categoryUrl}/${category.slug}`} onClick={() => this.props.headerCategoryData(category.slug)}>{category.name}</Link></li>
                                            )
                                        )}
                                        </ul>
                                    </div>{/* FOOTER MENU END */}

                           

                            <div className="column is-2 footer-menu is-right">
                                <h6 className="title">Pomoć</h6>
                                <ul>
                                    <li><Link to={"/"+faqUrl}>FAQ</Link></li>
                                    <li><Link to={"/"+paymentOptionsUrl}>Način plaćanja</Link></li>
                                    <li><Link to={"/"+shippingUrl}>Dostava</Link></li>
                                </ul>
                            </div>{/* FOOTER MENU END */}
                        </div>{/*COLUMNS END */}
                    </div>{/* CONTAINER END*/}
                </div>{/* MAIN END */}

                {/******************* Call SEO footer component *******************/}
                {this.checkHomepage() &&  <div className="seo-section">
            <div className="container">
            {/******************* Content/Text *******************/}
            <p className="text">Dobrodošli na online trgovinu ZDRAVO.BA na kojoj možete poručiti širok 
            asortiman biljnih preparata, dodataka prehrani, kozmetike, preparata za sportiste kao i aparata i pomagala. </p>
            <p className="text">Naša online trgovina sadrži preko 3000 proizvoda koje smo svrstali u kategorije 
            <Link to={`/${categoryUrl}/zdravlje`}> ZDRAVLJE</Link>, 
            <Link to={`/${categoryUrl}/ljepota-i-njega`}> LJEPOTA & NJEGA</Link>, 
            <Link to={`/${categoryUrl}/sport-i-rekreacija`}> SPORT/SPA</Link>, 
            <Link to={`/${categoryUrl}/aparati-i-pomagala`}> APARATI & POMAGALA</Link>, 
            <Link to={`/${categoryUrl}/drogerija`}> DROGERIJA</Link>.</p>
            
            <p className="text">Nakon što uđete u neku od spomenutih kategorija, imate mogućnost da prema Vašim željama i 
            potrebama sortirate proizvode za sve starosne grupe prema njihovoj namjeni, sastavu, primjenjivosti za 
            starosnu dob ili spol i drugim karakteristikama. 
            <b>Ovi filteri Vam olakšavaju pretragu i štede dragocjeno vrijeme.</b> </p>
            
            <h6 className="title">ZDRAVLJE</h6>
            <p className="text"><b>U ovoj kategoriji proizvoda Vam nudimo namjenske preparate i dodatke prehrani koje 
                smo svrstali prema njihovoj specifičnoj primjeni u sljedeće grupe:</b></p>
            <ul className="columns is-multiline">
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/infekcije-bakterijske-gljivicne-parazitne`}>INFEKCIJE (BAKTERIJSKE, GLJIVIČNE, PARAZITNE)</Link></li>
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/hormonalni-sistem-dijabetes-stitnjaca`}>HORMONALNI SISTEM / DIJABETES / ŠTITNJAČA</Link></li>
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/metabolizam-holesterol-masnoce-u-krvi`}>METABOLIZAM / HOLESTEROL / MASNOĆE U KRVI</Link></li>
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/spolnost-afrodizijaci-potencija`}>VITAMINI I MINERALI</Link></li>
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/oci-usi-usta`}>OČI / UŠI / USTA</Link></li>
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/probavni-i-disajni-sistem`}>PROBAVNI I DISAJNI SISTEM</Link></li>
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/misici-zglobovi-kosti`}>MIŠIĆI / ZGLOBOVI / KOSTI</Link></li>
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/vitalnost`}>VITALNOST</Link></li>
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/imunitet-alergije-intolerancije`}>IMUNITET / ALERGIJE / INTOLERANCIJE</Link></li>
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/koza-kosa-nokti`}>KOŽA / KOSA / NOKTI</Link></li>
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/urogenitalni-sistem`}>UROGENITALNI SISTEM</Link></li>
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/nervni-sistem-pamcenje-psiha`}>NERVNI SISTEM / PAMĆENJE / PSIHA</Link></li>
            <li className="column is-6"><Link to={`/${categoryUrl}/zdravlje/ostalo-zdravlje`}>OSTALO</Link></li>
            </ul>
            <p className="text">Svrha proizvoda u našem šopu nije da zamijene ljekare i farmaceute. Ne prodajemo proizvode koji su 
            registrovani kao lijekovi niti bilo šta za što je nužno imati recept. Dakle radi se o klasičnim dodacima ishrani, 
            dijetetskim proizvodima i proizvodima za njegu koji mogu pomoći organizmu pri prevazilaženju određenih zdravstvenih 
            tegoba. Svi opisi proizvoda su urađeni kao orjentacija za naše kupce i ne mogu zamijeniti analizu i preporuku liječnika i
             farmaceuta. </p>
             <p className="text">Shop smo snabdjeli preparatima najvećih svjetskih i domaćih brendova koje lako možete pronaći 
             putem tražilice ili filtera.</p>
            <h6 className="title">LJEPOTA I NJEGA</h6>
            <p className="text">U ovoj kategoriji Vam nudimo proizvode najvećih svjestkih brendova kao što su 
            <a target="_blank" href="https://www.vichy.hr/"> „Vichy“</a>, 
            <a target="_blank" href="http://beurer.ba/"> „Beurer“</a>, 
            <a target="_blank" href="http://dr-theiss.hr/"> „Dr Theiss“</a>, 
            <a target="_blank" href="https://www.weleda.ba/"> „Weleda“</a>, 
            <a target="_blank" href="https://parisienneitalia.com/"> „Parisienne“</a>, 
            <a target="_blank" href="https://www.pierre-fabre.com/en"> „Pierre Fabre“</a> i mnogi drugi. 
            Proizvodi su kategorisani prema svrsi i vrsti upotrebe u grupe:</p>
            <ul className="columns is-multiline">
                <li className="column is-6"><Link to={`/${categoryUrl}/ljepota-i-njega/parfemi`}>PARFEMI</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/ljepota-i-njega/sunce-i-more`}>SUNCE I MORE</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/ljepota-i-njega/kupke`}>KUPKE</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/ljepota-i-njega/njega-tijela`}>NJEGA TIJELA</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/ljepota-i-njega/njega-kose-i-noktiju`}>NJEGA KOSE I NOKTIJU</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/ljepota-i-njega/njega-lica`}>NJEGA LICA</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/ljepota-i-njega/sminka`}>ŠMINKA</Link></li>
            </ul>

            <h6 className="title">SPORT I REKREACIJA</h6>
            <p className="text">Osobe koje vole sport, kretanje, meditaciju i relax će u ovoj kategoriji naći mnoštvo 
            vrhunskih proizvoda sa pristupačnim cijenama. Kategorija je zbog lakše orijentacije podijeljena u sljedeće 
            grupe:</p>
            <ul className="columns is-multiline">
                <li className="column is-6"><Link to={`/${categoryUrl}/sport-i-rekreacija/energy-booster`}>ENERGY BOOSTER</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/sport-i-rekreacija/proteini`}>PROTEINI</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/sport-i-rekreacija/slim-fit`}>SLIM / FIT</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/sport-i-rekreacija/ostalo-sport-i-spa`}>OSTALO</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/sport-i-rekreacija/preparati-za-lokalnu-upotrebu`}>PREPARATI ZA LOKALNU UPOTREBU</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/sport-i-rekreacija/dodatna-oprema`}>DODATNA OPREMA</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/sport-i-rekreacija/vitamini-i-minerali`}>VITAMINI I MINERALI</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/sport-i-rekreacija/pica`}>PIĆA</Link></li>
            </ul>

           {/* <h6 className="title">DROGERIJA</h6>
            <p className="text">U drogeriji naše online trgovine ćete naći mnoštvo korisnih proizvoda koje možete naći u 
            najvećim drogerijama. Za mnoštvo sredstava za čišćenje i osvježivača vazduha, lične higijene i raznoraznih 
            sredstava za pranje Vam je omogućena laka narudžba i besplatna dostava u Sarajevu i Tuzli.Kategorija je podijeljena u 
            sljedeće podkategorija: </p>
            <ul className="columns is-multiline">
                <li className="column is-6"><Link to={`/${categoryUrl}/drogerija/sredstva-za-ciscenje`}>SREDSTVA ZA ČIŠĆENJE</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/drogerija/osvjezivaci-vazduha`}>OSVJEŽIVAČI VAZDUHA</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/drogerija/higijena`}>HIGIJENA</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/drogerija/sredstva-za-pranje`}>SREDSTVA ZA PRANJE</Link></li>
                                         </ul>*/}

            <h6 className="title">APARATI I POMAGALA</h6>
            <p className="text">Pronađite razne aparate i pomagala koji Vam olakšavaju svakodnevnicu. Od aparata i pomagala 
            sa specijalnom namjenom poput onih za stare i iznemogle osobe,  dijabetičare, osobe sa specijalnim potrebama i 
            dr. pa do  aparata i pomagala za svakodnevnu upotrebu poput četkica za zube, aparata za kozmetičke svrhe i mnogih
             drugih, naći ćete u online trgovini <Link to={homeUrl}>ZDRAVO.BA</Link>. </p>
             <p className="text">Kategorija je u cilju lakših pretraga podijeljena u sljedeće funkcionalne grupe proizvoda:</p>
             <ul className="columns is-multiline">
                <li className="column is-6"><Link to={`/${categoryUrl}/aparati-i-pomagala/njega-zuba`}>NJEGA ZUBA</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/aparati-i-pomagala/djeciji-program`}>DJEČIJI PROGRAM</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/aparati-i-pomagala/naocale`}>NAOČALE</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/aparati-i-pomagala/inhalacija`}>INHALACIJA</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/aparati-i-pomagala/dijabetes`}>DIJABETES</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/aparati-i-pomagala/termometri`}>TERMOMETRI</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/aparati-i-pomagala/ostali-aparati-i-pomagala`}>OSTALI APARATI I POMAGALA</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/aparati-i-pomagala/tlakomjeri`}>TLAKOMJERI</Link></li>
                <li className="column is-6"><Link to={`/${categoryUrl}/aparati-i-pomagala/toplomjeri`}>TOPLOMJERI</Link></li>
            </ul>

            <p className="text"><b>Naši stručni saradnici Vam svakodnevno  stoje na raspolaganju za sva pitanja i nedoumice 
                kod izbora proizvoda ili obavljanja narudžbe. Možete nas kontaktirati putem email-a 
                <a href="mailto:info@zdravo.ba">info@zdravo.ba</a>,
                 telefona / Viber-a / WhatsApp-a <a href="tel:+38733500818">+38733500818</a> ili putem live chat-a integrisanog na našoj stranici. </b></p>
            <p className="text"><b>Ukoliko među proizvodima ne uspijete pronaći određeni preparat, kontaktirajte nas i pokušat
                 ćemo ga nabaviti u najkraćem roku. </b></p>
            </div>
            </div>}
                {/******************* Copyright *******************/}
                <div className="bottom">
                    <div className="container">
                        <p className="is-left">Zdravo.ba &copy; {new Date().getFullYear()}. Sva prava pridržana.</p>
                        <p className="is-right">Dizajn i programiranje: <a href="https://wellpromotion.ba"><b>WELL BUSINESS PROMOTION</b></a>.</p>
                    </div>{/* CONTAINER END */}
                </div>{/* BOTTOM END */}
            </section>/* SECTION FOOTER END */
        );
    }
}

export default Footer