import React from 'react'
import '../../styles/admin/_index.scss';
import Footer from './dashboard/Footer'
import Header from './Header';
import Sidebar from './sidebar/Sidebar'

//Admin wrapper komponenta: Poziva se na većini ostalih komponenti kao wrapper imena "admin", 
//u koji je smješten sav ostali sadržaj
class AdminWrapper extends React.Component{
    render(){
        return(
            <div className="admin">
                <Header />
                <Sidebar />
                <div className="page-content">
                    {this.props.children}
                </div>
                <Footer />
            </div>
        )
    }
}

export default AdminWrapper