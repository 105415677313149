export const months = [
    {value: "01", name: "Januar"},
    {value: "02", name: "Februar"},
    {value: "03", name: "Mart"},
    {value: "04", name: "April"},
    {value: "05", name: "Maj"},
    {value: "06", name: "Juni"},
    {value: "07", name: "Juli"},
    {value: "08", name: "August"},
    {value: "09", name: "Septembar"},
    {value: "10", name: "Oktobar"},
    {value: "11", name: "Novembar"},
    {value: "12", name: "Decembar"}
   ]