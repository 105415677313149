/*
Thank you order page
Display thank you message after successfully finishing order
*/
import React from 'react'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet";
import ShopWrapper from '../ShopWrapper'
import { siteTitle } from '../../../functions/config'
import LoaderBox from '../../helpers/LoaderBox';
import {generatedOrderUrl, homeUrl} from '../../../functions/pageUrls'
import axios from 'axios';

var cartId = "empty";
var pageTitle = "Hvala vam na narudžbi"
class ThankYou extends React.Component{
    constructor(props) {
        super(props);

        this.redirectToHome = this.redirectToHome.bind(this);
        this.state = {
            orderName: '',
            orderKey: '',
            orderExist: false,
            loading: true,
        }    
    }

getCartData(){
    if(localStorage.cartId)
    {  
        cartId = localStorage.cartId;
        var orderNameInner;  
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/get-cart/' + cartId)
        .then((response) => {
        var orderName; 
        orderName = response.data.orderName; 
        orderNameInner = orderName
        this.setState({
            loading:false,
            orderExist:true,
            orderName: orderNameInner,
        }) 
    });

    var orderKeyInner;  
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders/single-order-cart-id/' + cartId)
        .then((response) => {
    orderKeyInner = response.data.generatedOrderId; 
    this.setState({
        loading:false,
        orderExist:true,
        orderKey: orderKeyInner,
    }) 
});
}
else{
    this.setState({
        orderExist:false,
        loading:false,
    })
}
}


//Function: redirect to home after opening generated order
redirectToHome(){
    this.setState({
        orderKey: '',
    })
    localStorage.removeItem('cartId');
    this.props.history.push(homeUrl);
}

componentDidMount() {
    this.getCartData();
    window.scrollTo(0, 0);
}

    render(){
        return(
            <ShopWrapper>
                <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
              </Helmet>
                {this.state.loading && (<LoaderBox /> )}
             {this.state.orderExist &&  ( 
             <section id="p_thank_you" className="section thank-you">

                     {/******************* Thank you content if order exist *******************/}
                    <div className="container thank-you-content">
                        <h1 className="title">Hvala vam.</h1>
                        <h4 className="subtitle">Vaša narudžba je zaprimljena. Na vašu e-mail adresu smo vam poslali detalje
                        narudžbe, kao i ostale informacije o preuzimanju pošiljke.</h4>
                        <div className="button-area">
                        <Link to={`/${generatedOrderUrl}/${this.state.orderKey}`} onClick={this.redirectToHome.bind(this)} className="button checkout" target="_blank"><span className="icon"></span>Pogledajte narudžbu</Link>
                    </div>
                    <div className="button-area">
                        <Link to={homeUrl} className="continue">Nazad u trgovinu <span className="icon"></span></Link>
                    </div>
                    </div>{/* CONTAINER ENDS */}
                </section>/* SECTION CHECKOUT END */
                 )}

            {/******************* If order is not completed *******************/}
            {!this.state.orderExist && (
            <section id="p_thank_you" className="section thank-you">
             
            <div className="container thank-you-content">
                <h1 className="title">Niste izvršili narudžbu.</h1>
            </div>{/* CONTAINER ENDS */}
            </section>/* SECTION CHECKOUT END */
                )}
                </ShopWrapper>
        );
    }
}

export default ThankYou