/*
Actions page
Display all products which is on base action
*/
import React from 'react'
import Product from '../product/Product'
import ProductPopup from '../product/ProductPopup'
import {Helmet} from "react-helmet";
import Pagination from '../Pagination';
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import LoaderBox from '../../helpers/LoaderBox';
import {siteTitle} from '../../../functions/config'
import  {createFilter} from 'react-search-input'
import axios from 'axios';
import { withGlobalState } from 'react-globally'

var latinize = require('latinize');
const KEYS_TO_FILTERS = ['title', 'title_latinize', 'productBrandName'];

var pageTitle = "Pretraga proizvoda";
var togglePopupBox, productDataForPopup;
class SearchPage extends React.Component{

    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            showPopupClass: '',
            popupTitle: '',
            popupPrice: '',
            popupOldPrice: '',
            popupImage: '',
            popupSlug: '',
            pageName: 'Pretraga proizvoda',
            pageSize: 1,
            popupCategory: 'empty',
            productId: 'empty',
            products: [],
            pageOfProducts: [],
            loading:true,
            searchTermFromURL: this.props.match.params.pojam.replace("-", " "),
        }
        this.searchUpdated = this.searchUpdated.bind(this);
        togglePopupBox	= this.togglePopupBox.bind(this);
        productDataForPopup = this.productDataForPopup.bind(this);
        this.hidePopupBox = this.hidePopupBox.bind(this);     
        this.onChangePage = this.onChangePage.bind(this);
    }

//Function: show popup box after adding new product in cart
togglePopupBox (arg) {
    if (this.state.showPopupClass === '') {
        this.setState({
            showPopupClass: arg
        })
     //   setTimeout(function(){
     //       this.setState({
     //           showPopupClass: ''
     //       })
     //  }.bind(this),7500);
    } 
  }

//Function: hide popup box after adding new product in cart
hidePopupBox (arg){
    this.setState({
        showPopupClass: arg
    })
  }


//Function: pass product data to popup
productDataForPopup (title,slug, category, price,oldPrice,image,productId, quantity, prAvailable ){
    this.setState({
        popupImage: image,
        popupPrice: price,
        popupOldPrice: oldPrice,
        popupSlug: slug,
        popupTitle: title,
        popupCategory: category,
        productId: productId,
        popupQuantity: 1,
        prAvailable: prAvailable,
    })
}

getSettings() {
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/settings/get-settings')
    .then((response) => {
    this.setState({
        pageSize: response.data.pageSize,
    });
    })
    .catch((error) => {
    console.log(error);
    })
  }


//Function: Splice products for pagination
onChangePage(pageOfProducts) {
    this.setState({ 
        pageOfProducts: pageOfProducts 
    });
}

//Function: update single product data when product is opened
singleProductData(productSlug){}

getProductsList() {  
    const productsArray = [];
    
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products')
        .then((response) => {
        response.data.forEach((doc) => {
            const { title, slug, price, image_thumb, priceAction, category, productOffer, productAction, actionPercent,
                deliverer, productVariety, brand, vele_price, markup, available,purpose } = doc;
                productsArray.push({
                    key: doc._id,
                    doc, 
                    title,
                    title_latinize: latinize(title),
                    slug, 
                    price,
                    image_thumb,
                    priceAction,
                    actionPercent,
                    category,
                    productOffer,
                    productAction,
                    deliverer,
                    productVariety,
                    purpose,
                    brand,
                    vele_price,
                    markup,
                    available,
                    productCategorySlug: doc.categoryData.slug,
                    brandDiscount: doc.brandData.discount,
                    productBrandName: doc.brandData.slug,
                    productBrandFullName: doc.brandData.name,
                    productBrandLogo: doc.brandData.image,
                });
        });
       
        this.setState({
            products: productsArray,
            loading: false,
        })
    }).catch((error) => {
        console.log(error);
    })
}

componentDidUpdate(oldProps) {
    const newProps = this.props
    if(oldProps.match.params.pojam !== newProps.match.params.pojam) {
        this.setState({
            searchTermFromURL:this.props.match.params.pojam
        })
        this.componentDidMount();  
    }
}

componentDidMount = () => {
    
    this.searchUpdated();
    this.getProductsList();
    this.getSettings();
    if(this.state.searchTermFromURL === undefined){
        this.setState({
            searchTermFromURL: "empty"
        })
    }
}

    render(){
     
        const filteredProducts = this.state.products.filter(createFilter(this.state.searchTermFromURL, KEYS_TO_FILTERS))

        return(
            <ShopWrapper>
                <section id="p_checkout" className="section search-page actions-page">
               <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
              </Helmet> 
                    {/******************* Loader box *******************/}
                    {this.state.loading && (<LoaderBox /> )}

                    <div className="container">   
                    {/******************* Main title *******************/}
                    <MainTitle title={pageTitle} />

                    <div className="search-results-key">
                        <h4>Rezultati pretrage za: <b>{this.props.match.params.pojam.replace("-", " ")}</b></h4>

                        <p className="total-products">Ukupan broj prikazanih proizvoda: 
                                <b>
                               {filteredProducts.length}
                                </b>
                            </p>
                    </div>
                    <div className="columns inner products-slider is-multiline">
                    {this.state.showPopupClass === 'show' && (
                           <ProductPopup
                           title={this.state.popupTitle}
                           slug={this.state.popupSlug}
                           category={this.state.popupCategory}
                           price={this.state.popupPrice}
                           oldPrice={this.state.popupOldPrice}
                           image={this.state.popupImage}
                           productId={this.state.productId}
                           popupQuantity={this.state.popupQuantity}
                           prAvailable={this.state.prAvailable}
                           showClass={this.state.showPopupClass}
                           hidePopupBox = {this.hidePopupBox.bind(this)} />
                            )}

                        {filteredProducts.map(product =>
                            <div className="column is-one-fifth single">
                            {/******************* Product popup *******************/}
                           
                          
                          {/******************* Call product component *******************/}
                            <Product product={product} 
                            key={product.key} 
                            togglePopupBox = {togglePopupBox.bind(this)} 
                            productDataForPopup={productDataForPopup.bind(this)}
                            singleProductData={(productSlug) => this.singleProductData(productSlug)}
                            />
                            </div>
                            )}
                            </div>
                            {filteredProducts.length === 0 && (
                                <p className="no-products"><span className="icon"></span>Nema proizvoda koji zadovoljavaju vašu pretragu.</p>
                            )}

                              <div className="pagination-wrapper pagination-bottom">
                                <Pagination items={this.state.actionProducts} onChangePage={this.onChangePage} pageName={this.state.pageName} pageSize={16} /> 
                            </div> 
                            
                    </div>
                </section>
            </ShopWrapper>
        );
    }
    searchUpdated (term) {
        this.setState({
            searchTerm: this.state.searchTermFromURL,
        }) 
      }
}

export default withGlobalState(SearchPage)