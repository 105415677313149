import React, { Component } from "react";
import {Link, withRouter} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Logout extends Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }
  handleLogout() {
    localStorage.removeItem("usertoken");
    this.props.history.push("/administracija/prijava");
  }
  render() {
    return (
        <span onClick={this.handleLogout} className="header-link logout">Odjava <FontAwesomeIcon icon="sign-out-alt" /></span>
    );
  }
}
export default withRouter(Logout);