/*
Order page
Display form for entering personal informations required for completing order, and products in cart
*/
import React from 'react'
import { Link } from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import {Helmet} from "react-helmet";
import ShopWrapper from '../ShopWrapper'
import Sticker from '../../helpers/Sticker'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { siteTitle, timeoutDelay } from '../../../functions/config'
import LoaderBox from '../../helpers/LoaderBox';
import MainTitle from '../../helpers/MainTitle'
import {cartUrl, privacyPolicyUrl, productUrl} from '../../../functions/pageUrls'
import Header from '../Header'
import axios from 'axios';
import jwt_decode from 'jwt-decode'
import { addOrder, register } from '../../helpers/UserFunctions'
const clientPaypalKeys = {
    sandbox:    'AXR4rVBY68kBC84VOPoX0D6n0Px_pJ2TzUw91jjYJCh1aNenKetxxOhdJY4bdACNOPJxCpzfODX83Alo',
    production: 'AVOktjGhcmxyZehZUib7gbWG8sbfpI8AOIXe2XVY18zMvSMTVBkXPdsiHV_NyNNFMtqdWILeADxhnLy6',
}

let paypal_env = 'sandbox';
let paypal_currency = 'EUR';
const stylePaypalButton = {
    label: 'paypal',
    tagline: false,
    height: 55,
    shape: 'pill',
    color: 'blue',
}

var cartId = "empty", productsInCart = [];
var pageTitle = "Narudžba";

class Order extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
        showStickerClass: '',
        orderId: '',
        orderName: '',
        userExist: false,
        userOrderId: '',
        name: '',
        surname: '',
        email: '',
        address: '',
        phone: '',
        postalCode: '',
        city: '',
        cityOther: '',
        country: 'Bosna i Hercegovina',
        notes: '',
        nameError: '',
        surnameError: '',
        emailError: '',
        addressError: '',
        phoneError: '',
        postalCodeError: '',
        cityError: '',
        cityOtherError: '',
        minimumOrderErrorOthers: '',
        privacyPolicyError: '',
        chargeError: '',
        freePickupError: '',
        shippingMethodError: '',
        homeDeliveryError: '',
        shippingMethod: '',
        totalPriceNoShipping: '',
        shipping: 'Odaberite tip dostave',
        freeShipping: '',
        totalPrice: '',
        totalPoints: '',
        charge: '',
        orderCreatedAt: '',
        products: [],
        privacyPolicy: '',
        totalPriceForFreeShipping: '',
        loading:true,
        totalPriceEuro: '',
        euroValue: 1.95583,
        onSubmitAction: '',
        firstPaymentStep: true,
        secondPaymentStep: false,
        createAccount: false,
        freePickupSelected: false,
        homeDeliverySelected: false,
        freePickup: '',
        userId: 1,
        passwordOne: '',
        passwordTwo: '',
        userTotalPoints: '',
        couponCode: '',
        userCoupon: '',
        userCouponValue: '',
        couponValidity: '',
        couponActivated: false,
        errorCouponBigger: false,
        toggleHomeDeliveryClass: '',
        toggleFreePickupClass: '',
        }   
        this.validateRequiredFields = this.validateRequiredFields.bind(this);
        this.backToStepOne = this.backToStepOne.bind(this); 
        this.couponValidity = this.couponValidity.bind(this);
        this.removeCoupon = this.removeCoupon.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.EnterHomeDelivery = this.EnterHomeDelivery.bind(this);
        this.LeaveHomeDelivery = this.LeaveHomeDelivery.bind(this);
        this.EnterFreePickup = this.EnterFreePickup.bind(this);
        this.LeaveFreePickup = this.LeaveFreePickup.bind(this);
    }

    EnterHomeDelivery(){
        if (this.state.toggleHomeDeliveryClass === '') {
            this.setState({
                toggleHomeDeliveryClass: "active"
            })
        } 
      }
    
      LeaveHomeDelivery(){
        if (this.state.toggleHomeDeliveryClass === 'active') {
            this.setState({
                toggleHomeDeliveryClass: ""
            })
        } 
      }
    
      EnterFreePickup(){
        if (this.state.toggleFreePickupClass === '') {
            this.setState({
                toggleFreePickupClass: "active"
            })
        } 
      }
    
      LeaveFreePickup(){
        if (this.state.toggleFreePickupClass === 'active') {
            this.setState({
                toggleFreePickupClass: ""
            })
        } 
      }

onSuccessPaypal = (payment) => {
            console.log("The payment was succeeded!", payment);
            this.props.history.push("/hvala-vam")
}

onCancelPaypal = (data) => {
    console.log('The payment was cancelled!', data);
}

onErrorPaypal = (err) => {
    console.log("Error!", err);
}

getSettingsData(){
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/settings/get-settings')
    .then((response) => {
      this.setState({
        freeShipping: response.data.freeShipping,
      });
      })
      .catch((error) => {
      console.log(error);
      })
}

getCartData(){
    //Collect cart data
    if(localStorage.cartId)
    {  
        cartId = localStorage.cartId;
        var totalPriceInner, totalPointsInner, productsInner, productsQuantityInner;  
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/get-cart/' + cartId)
        .then((response) => {
        var totalPrice,totalPoints, products; 
        totalPrice = response.data.totalPrice;  
        totalPoints = response.data.totalPoints;
        products = JSON.parse(response.data.products);
        productsInCart = []; 
        totalPriceInner = totalPrice;
        totalPointsInner = totalPoints;
        productsInner = products; 
        productsQuantityInner = products.length
        Object.keys(productsInner).forEach(function(id) {
            productsInCart.push(productsInner[id]);
            });
        this.setState({
            totalPoints: totalPointsInner,
            totalPrice: totalPriceInner,
            totalPriceNoShipping: totalPriceInner,
            productsQuantity: productsQuantityInner,
            loading:false,
        }) 
    });
    }
    if(cartId === 'empty'){
        totalPriceInner = 0;
        totalPointsInner = 0;
        productsQuantityInner = 0;
    } 

    this.setState({
        orderId: 1,
    }) 
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders')
    .then((response) => {
        response.data.forEach((doc) => {
      if(doc.order_id >= this.state.orderId)
      {
        this.setState({
          orderId: doc.order_id+1,
      }) 
      }  
    });
})
.catch((error) => {
    console.log("problem", error);
});
}


toggleCreateAccountChange = () => {
    this.setState({
      createAccount: !this.state.createAccount,
    });
  }

//Function: handle order form change
handleChange(e) {     

    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
    this.state.orderCreatedAt = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
    this.state.privacyPolicy = e.target.checked;
    if(this.state.shippingMethod === "Gratis PICK-UP"){
        this.setState({
            freePickupSelected: true,
        })
    }
    else{
        this.setState({
            freePickupSelected: false,
        })
    }
    if(this.state.shippingMethod === "Dostava na kućnu adresu"){
        this.setState({
            homeDeliverySelected: true,
        })
    }
    else{
        this.setState({
            homeDeliverySelected: false,
        })
    }
    var { name, surname, email, phone, address, postalCode, city, cityOther,charge, shippingMethod,
         privacyPolicy, nameError, surnameError,emailError, phoneError, addressError, postalCodeError,
        cityError, cityOtherError, privacyPolicyError,freePickup, chargeError,freePickupError, shippingMethodError, homeDeliveryError} = this.state;
       if(name !== "" && name !== undefined){
          nameError = "";
       }
       if(surname !== "" && surname !== undefined){
           surnameError = "";
       }
       if(email !== "" && email !== undefined){
           emailError = "";
       }
       if(phone !== "" && phone !== undefined){
           phoneError = "";
       }
       if(address !== "" && address !== undefined){
           addressError = "";
       }
       if(postalCode !== "" && postalCode !== undefined){
           postalCodeError = "";
       }
       if(city !== "" && city !== undefined){
           cityError = "";
       }
       if(city === "Drugi grad" && cityOther !== ""  && cityOther !== undefined){
        cityOtherError = "";
    }
       if(privacyPolicy === true){
           privacyPolicyError = ""
       }
       if(charge !== "" && charge !== undefined){
        chargeError = "";
    }
    if(this.state.freePickupSelected && ( freePickup !== "" && freePickup !== undefined)){
        freePickupError = "";
    }
    if(shippingMethod !== "" && shippingMethod !== undefined){
        shippingMethodError = "";
    }
    // __CHANGE
    if(this.state.homeDeliverySelected && this.state.totalPriceNoShipping >= 0){
        homeDeliveryError = "";
    }
       this.setState({
           nameError,
           surnameError,
           emailError,
           phoneError,
           addressError, 
           postalCodeError,
           cityError,
           cityOtherError,
           privacyPolicyError,
           chargeError,
           freePickupError,
           homeDeliveryError,
           shippingMethodError
       })
    
   
}

couponValidity(){
    if(this.state.couponCode === this.state.userCoupon){
        if((this.state.userCouponValue*10) > this.state.totalPrice){
            this.setState({
                errorCouponBigger: true,
            })
        }
        else{
            this.setState({
                couponValidity: 'valid',
                couponActivated: true,
            })
        }
       
    }
    else{
        this.setState({
            couponValidity: 'invalid',
            couponActivated: false,
        })
    }
}

removeCoupon(){
    this.setState({
        couponActivated:false,
        couponValidity: '',
        couponCode: '',
    })
}

getRandom(length) {

    return Math.floor(Math.pow(10, length-1) + Math.random() * 9 * Math.pow(10, length-1));
    
    }

insertData(){
    var products = [];
    products = JSON.stringify(productsInCart); 
    var { shippingMethod, shipping }= this.state;
    const {userId, orderId,orderName, orderCreatedAt, name, surname, email, phone, address, postalCode,
        country, charge, totalPrice,totalPoints,totalPriceNoShipping, notes, freePickupSelected, freePickup, createAccount, passwordOne, userCoupon, userCouponValue, userOrderId } = this.state;
        var {city,cityOther} = this.state;
        if(city === "Drugi grad"){
            city = cityOther;
        }
        if(shipping === "Besplatna"){
            shipping = 0;
        }
        if(freePickupSelected){
            shippingMethod = shippingMethod + " (" + freePickup + ")";
        }
        const newOrder = {
        generatedOrderId: this.getRandom(9),
        orderName,
        name,
        surname,
        email,
        phone,
        address,
        notes,
        postalCode,
        city,
        country,
        totalPriceNoShipping,
        shipping,
        shippingMethod,
        totalPrice,
        totalPoints,
        charge,
        orderCreatedAt,
        cartId,
        products,
        userOrderId,
        readedByAdmin: 0,
        couponName: userCoupon,
        couponValue: userCouponValue*10,
        totalPriceNoShippingFormatted: formatMoney(totalPriceNoShipping),
        shippingFormatted: formatMoney(shipping),
        totalPriceFormatted: formatMoney(totalPrice),
            }
        addOrder(newOrder).then(res => {
        this.setState({
            name: '',
            surname: '',
            email: '',
            charge: '',
            notes: '',
            phone: '',
            address: '',
            postalCode: '',
            city: '',
            shippingMethod: '',
            cityOther: '',
            country: '',
        });
     
        var totalPrice = 0;
        var totalPoints = 0;
        products = []; 
        products = JSON.stringify(products);  
        axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/update-cart/' + cartId, {
            cartId,
            products,
            totalPrice,
            totalPoints,
            orderName,
            }).then((response) => {
            })
            .catch((error) => {
                console.log("problem", error);
            });
     
    
        this.props.history.push("/hvala-vam")
        })
        .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Došlo je do problema prilikom izvršavanja vaše narudžbe: ' + error,
        stickerType: 'danger',
        })
    });
}
//Function: insert order in databse on pressing form button
onSubmit(e) {
    if(this.state.charge === "Plaćanje prilikom preuzimanja proizvoda"){
        e.preventDefault();
    }
   
    const {userId, orderId,orderName, orderCreatedAt, name, surname, email, phone, address, postalCode,
         city,cityOther, country, charge, totalPrice, shipping,totalPriceNoShipping, notes, userOrderId, createAccount, passwordOne } = this.state;
    var totalPointsCurrent = this.state.totalPoints;
    const userCreatedAt = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();

if(createAccount === true){

    const newUser = {
        name,
        surname,
        email,
        phone,
        address,
        postalCode,
        city,
        cityOther,
        totalPoints: totalPointsCurrent,
        newsletter: 0,
        password: passwordOne,
        isAdmin: 0,
        }

        
    register(newUser).then(res => {
        this.insertData();
        })
        .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Došlo je do problema prilikom izrade vašeg računa: ' + error,
        stickerType: 'danger',
        })
        });

}
else{
    this.insertData();
}
/*
if(localStorage.getItem('usertoken')){
    const token = localStorage.getItem('usertoken');
    const decoded = jwt_decode(token)
    if(token){
        axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/users/profile-update-total-points/' + decoded._id, {
            totalPoints: this.state.userTotalPoints + totalPointsCurrent,
          })
          if(this.state.couponActivated === true){
            axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/users/profile-update-coupon-data/' + decoded._id, {
                couponExist: 0,
                couponName: '',
                couponValue: '',
              }) 
        }
    }
}
  */ 
}

componentDidMount = () => {
    this.getCartData();
    window.scrollTo(0, 0);

    if(localStorage.getItem('usertoken')){
        const token = localStorage.getItem('usertoken');
        const decoded = jwt_decode(token)
        if(token){
            this.setState({
                userExist: true,
                userName: decoded.name,
                userSurname: decoded.surname,
                email: decoded.email
              })
              axios
              .get(process.env.REACT_APP_SERVER_PROXY_URL+'api/users/profile/'+decoded._id, {
              })
              .then(response => {
                const { _id, name, surname, email, phone, address, postalCode, city,cityOther, country, totalPoints, couponName, couponValue } = response.data;
                this.setState({
                    userOrderId: _id,
                    name: name,
                    surname: surname,
                    email: email,
                    phone: phone,
                    address: address,
                    postalCode: postalCode,
                    city: city,
                    cityOther: cityOther,
                    userTotalPoints: totalPoints,
                    userCoupon: couponName,
                    userCouponValue: couponValue,
                })
            });
        }
    }
}

validateRequiredFields(){
    var { name, surname, email, phone, address, postalCode, city,cityOther,cityOtherError, charge, shippingMethod, privacyPolicy, nameError, surnameError,emailError, phoneError, addressError, postalCodeError,
        cityError, privacyPolicyError,totalPrice,totalPriceNoShipping, chargeError, freePickup, freePickupError, shippingMethodError,homeDeliveryError, minimumOrderErrorOthers, shipping} = this.state;
    var validated = true; 
       if(name === "" || name === undefined){
          nameError = "Molimo vas da unesete vaše ime.";
          validated = false;
       }
       if(surname === "" || surname === undefined){
           surnameError = "Molimo vas da unesete vaše prezime.";
           validated = false;
       }
       if(email === "" || email === undefined){
           emailError = "Molimo vas da unesete vašu email adresu";
           validated = false;
       }
       if(phone === "" || phone === undefined){
           phoneError = "Molimo vas da unesete vaš broj telefona.";
           validated = false;
       }
       if(address === "" || address === undefined){
           addressError = "Molimo vas da unesete vašu adresu stanovanja.";
           validated = false;
       }
       if(postalCode === "" || postalCode === undefined){
           postalCodeError = "Molimo vas da unesete poštanski broj.";
           validated = false;
       }
       if(city === "" || city === undefined){
           cityError = "Molimo vas da unesete vaš grad.";
           validated = false;
       }
       if(city === "Drugi grad" && (cityOther === "" || cityOther === undefined)){
        cityOtherError = "Molimo vas da unesete vaš grad.";
        validated = false;

        }
       {/*if(privacyPolicy === "" || privacyPolicy === undefined){
           privacyPolicyError = "Niste prihvatili uslove korištenja."
           validated = false;
           console.log(validated) 
       }
    */}
       if(charge === "" || charge === undefined){
        chargeError = "Molimo vas da odabere jednu od metoda plaćanja."
        validated = false;

    }
    if(this.state.freePickupSelected && (freePickup === "" || freePickup === undefined)){
        freePickupError = "Molimo vas da odaberete mjesto preuzimanja pošiljke."
        validated = false;

    }
    if(shippingMethod === "" || shippingMethod === undefined){
        shippingMethodError = "Molimo vas da odaberete jednu od opcija dostave."
        validated = false;
    }
    // __CHANGED
    // if(this.state.homeDeliverySelected && this.state.totalPriceNoShipping < 4000){
        // homeDeliveryError = "Minimalan iznos narudžbe za kućnu dostavu je 40 KM";
        // console.log(homeDeliveryError)
        // validated = false;
    // }
    if(shippingMethod === "Dostava na kućnu adresu"){
        // __CHANGE
        if(this.state.totalPriceNoShipping <=10000){
            shipping = 900;
            totalPrice = totalPriceNoShipping + 900;
        }
        else{
            shipping = 0;
        }
    }
    else if(shippingMethod === "" || shippingMethod === undefined){
        shipping = "Odaberite tip dostave";
    }
    else{
        shipping = 0;
    }

       this.setState({
        totalPrice,
           shipping,
           nameError,
           surnameError,
           emailError,
           phoneError,
           addressError, 
           postalCodeError,
           cityError,
           cityOtherError,
           privacyPolicyError,
           chargeError,
           homeDeliveryError,
           shippingMethodError,
           freePickupError,
           minimumOrderErrorOthers,
           firstPaymentStep: !validated,
           secondPaymentStep: validated,
       })
       window.scrollTo(0,0);
       return validated;

}

backToStepOne(){
    this.setState({
        secondPaymentStep: false,
        firstPaymentStep: true,
    })
    window.scrollTo(0,0); 
}

//Function: pass data about category from header
headerCategoryData(categorySlug){} 

render(){  

    //Calculate total price and free shipping
   
    this.state.totalPriceEuro = (this.state.totalPrice/(100*this.state.euroValue)).toFixed(2);
    if(this.state.couponActivated === true){
        this.state.totalPrice = parseFloat(this.state.totalPrice) - (this.state.userCouponValue*10);
    }
    //Generate order name
    var MyDate = new Date();
    var MyDateString;
    var randomNumber = Math.floor(Math.random() * 1000) + 100;
    MyDate.setDate(MyDate.getDate());
    MyDateString = ('0' + MyDate.getDate()).slice(-2) + '.'  + ('0' + (MyDate.getMonth()+1)).slice(-2) + '.' + MyDate.getFullYear();
    this.state.orderName = this.state.orderId + '.' + MyDateString + '.' + randomNumber 

    const { orderId,orderName, orderCreatedAt, name, surname, email, phone, address, postalCode, freePickup, city,cityOther, country, charge, privacyPolicy, shippingMethod,
        homeDeliveryError, shippingMethodError,nameError, surnameError, emailError, phoneError, addressError,freePickupError, postalCodeError, cityError, cityOtherError,
         minimumOrderErrorOthers, privacyPolicyError, chargeError, notes, couponCode} = this.state;
        return(
        <div className={"shop"}>
            <Header headerCategoryData={(categorySlug) => this.headerCategoryData(categorySlug)}/>
            <Helmet>
                <title>{pageTitle} &rsaquo; {siteTitle}</title>
           </Helmet>
                <section id="p_order" className="section order order-default without-footer">

                {/******************* Loader box *******************/}
                {this.state.loading && (<LoaderBox /> )}

                {this.state.showStickerClass=== 'show' && (  
                    <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                    <div className="container">
                   {/******************* Main title *******************/}
                   <MainTitle title={pageTitle} />

                    {/******************* Order form *******************/}
            <form name="add-category" onSubmit={this.onSubmit} className="form default-form">
                <fieldset>
                    <div className="columns">
                        <div className="column is-7 is-left">
                           {this.state.firstPaymentStep && (
                               <>
                            <h1 className="part-title">Kontakt informacije</h1>
                            <div className="form">
                                <div className="columns is-multiline">
                                    <label htmlFor="name" className="column is-6">
                                        <input type="text" id="name" name="name" placeholder="Ime"
                                        value={name} onChange={ this.handleChange }  />
                                        {nameError !== "" && (<span className="error-message">{nameError}</span>)}
                                       
                                    </label>
                                    <label htmlFor="surname" className="column is-6">
                                        <input type="text" id="surname" name="surname" placeholder="Prezime"
                                        value={surname} onChange={ this.handleChange }  />
                                        {surnameError !== "" && (<span className="error-message">{surnameError}</span>)}
                                    </label>
                                    <label htmlFor="email" className="column is-12">
                                        <input type="email" id="email" name="email" placeholder="E-mail adresa"
                                        value={email} onChange={ this.handleChange }  />
                                        {emailError !== "" && (<span className="error-message">{emailError}</span>)}
                                    </label>
                                    <label htmlFor="phone" className="column is-12">
                                        <input type="text" id="phone" name="phone" placeholder="Broj telefona"
                                        value={phone} onChange={ this.handleChange }  />
                                        {phoneError !== "" && (<span className="error-message">{phoneError}</span>)}
                                    </label>
                                    <span className="break"></span>
                                    <label htmlFor="address" className="column is-6">
                                        <input type="text" id="address" name="address" placeholder="Adresa stanovanja"
                                        value={address} onChange={ this.handleChange }  />
                                        {addressError !== "" && (<span className="error-message">{addressError}</span>)}
                                    </label>
                                    <label htmlFor="postalCode" className="column is-6">
                                        <input type="text" id="postalCode" name="postalCode" placeholder="ZIP kod"
                                        value={postalCode} onChange={ this.handleChange }  />
                                        {postalCodeError !== "" && (<span className="error-message">{postalCodeError}</span>)}
                                    </label>
                                    <label htmlFor="city" className="column is-6">
                                        <input type="text" id="city" name="city" placeholder="Grad"
                                        value={city} onChange={ this.handleChange }  />
                                        
                                        {cityError !== "" && (<span className="error-message">{cityError}</span>)}
                                    </label>
                                    {city === "Drugi grad" && (
                                    <label htmlFor="cityOther" className="column is-6">
                                        <input type="text" id="cityOther" name="cityOther" placeholder="Unesite ime grada"
                                        value={cityOther} onChange={ this.handleChange }  />
                                        {cityOtherError !== "" && (<span className="error-message">{cityOtherError}</span>)}
                                    </label>
                                    )}
                                   {/* <label htmlFor="country" className="column is-6">
                                        <select id="country" name="country" value={country} onChange={this.handleChange} required>
                                            <option value="">Izaberite državu</option>
                                            <option value="Bosna i Hercegovina">Bosna i Hercegovina</option>
                                        </select>
                                    </label> */}
                                    
                                 {/*   <label htmlFor="privacyPolicy" className="column is-12 privacy-policy-label">
                                        <span className="checkbox">
                                            <input type="checkbox" id="privacyPolicy" className="checkbox" defaultChecked={privacyPolicy} name="privacyPolicy" onChange={this.handleChange}/>
                                            <p className="text">Slažem se sa uslovima korištenja.</p>
                                        </span>
                                        {privacyPolicyError !== "" && ( <span className="error-message">{privacyPolicyError}</span>)}
                                    </label>
                                */}
                                    

                                    <div className="column is-12 payment-options shipping-options">
                                        <p className="subtitle">Tip dostave</p>
                                        <label className="column is-12 radio">
                                            <input type="radio" id="shippingMethod" name="shippingMethod" className="radio" value="Dostava na kućnu adresu"
                                             checked={shippingMethod == "Dostava na kućnu adresu"} onChange={this.handleChange}
                                            required/>
                                            <span class="text">Dostava na kućnu adresu</span>
                                            <span class="checkmark"></span>
                                            <span className="info" onMouseOver={this.EnterHomeDelivery.bind(this)}  
                                            onMouseLeave={this.LeaveHomeDelivery.bind(this)} ></span>
                                            <div className={"home-delivery-table shipping-table " + this.state.toggleHomeDeliveryClass}>
                                            <div className="columns titles">
                                                <p className="column is-6">Vrsta narudžbe</p>
                                                <p className="column is-3">Cijena dostave</p>
                                                <p className="column is-3">Minimalna narudžba</p>
                                            </div>
                                            <div className="columns results">
                                                <p className="column is-6 is-block"><b>GRATIS PICK-UP</b></p>
                                                <p className="column is-3">GRATIS</p>
                                                <p className="column is-3">25KM</p>
                                            </div>
                                            <div className="columns results">
                                                <p className="column is-6">KUĆNA DOSTAVA 9KM</p>
                                                <p className="column is-3">9KM</p>
                                                <p className="column is-3">0KM</p>
                                            </div>
                                            <div className="columns results">
                                                <p className="column is-6"><b>GRATIS KUĆNA DOSTAVA</b></p>
                                                <p className="column is-3">GRATIS</p>
                                                <p className="column is-3">100KM</p>
                                            </div>
                                              {/*  <div className="columns results">
                                                    <p className="column is-12">Pri finalnom koraku kupovine ćete imati opciju odabira 
                                                    <br />- mjesta i <br />- vrste dostave</p>
                                                </div> */}
                                                <div className="columns results">
                                                    <p className="column is-12 is-block">Sve narudžbe možete obaviti i putem telefona 
                                                    <b> 061 93 93 48</b> ili email-a <a href="mailto:narudzba@zdravo.ba"><b>narudzba@zdravo.ba</b></a> </p>
                                                </div>
                                            </div>
                                        </label>

                                        <label className="column is-12 radio">
                                            <input type="radio" id="shippingMethod" name="shippingMethod" className="radio" value="Gratis PICK-UP"
                                             checked={shippingMethod == "Gratis PICK-UP"} onChange={this.handleChange} required/>
                                            <span class="text">Gratis PICK-UP</span>
                                            <span class="checkmark"></span>
                                            <span className="info" onMouseOver={this.EnterFreePickup.bind(this)} onMouseLeave={this.LeaveFreePickup.bind(this)}></span>
                                            <div className={"free-pickup-table shipping-table " + this.state.toggleFreePickupClass}>
                                <div className="columns titles">
                                    <p className="column is-12"><b>Iskoristite mogućnost besplatnog preuzimanja</b> već u 
                                    vrijednosti narudžbe od 25 KM:</p>
                                </div>
                                <div className="columns results list-places is-multiline">
                                    <p className="column is-12"><b>“Apoteka Valor”</b> Rogačići broj 1, Sarajevo, Ilidža 71215, radno vrijeme Pon - Sri; 08.00h - 18.00h, Cet; 10.00h - 20.00h, Pet; 08.00 - 18.00h, Sub; 08.00h - 14.00h</p>
                                    <p className="column is-12"><b>1. "WINK SPORT"</b> D.O.O.-Prodavnica br. 17.- adresa: Koševo 3, Opština Centar, Sarajevo, tel: 033/216-204, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00</p>
                                    <p className="column is-12"><b>2. "WINK SPORT"</b> D.O.O.-Prodavnica br. 7.- adresa: Trg međunarodnog prijateljstva 1.Opština Novi grad, Sarajevo,  tel: 033/465-622, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00 (Alipašino polje)</p>
                                </div>
                                
                                <div className="columns results">
                                    <p className="column is-12 is-block">Sve narudžbe možete obaviti i putem telefona 
                                    <b> 033 500-818</b> ili email-a <a href="mailto:narudzba@zdravo.ba"><b>narudzba@zdravo.ba</b></a> </p>
                                </div>
                            </div>
                                        </label>
                                    
                                    {shippingMethodError !== "" && (<span className="error-message">{shippingMethodError}</span>)}
                                    </div>
                                    {this.state.freePickupSelected && this.state.totalPriceNoShipping >= 2500 && (
                                        
                                        <label className="column is-12"><span className="description">Odaberite lokaciju preuzimanja vašeg proizvoda</span>
                                             <select name="freePickup" id="freePickup" value={freePickup} onChange={this.handleChange} required>
                                                 <option value="">Izaberite mjesto</option>
                                                 <option value="Apoteka Valor” Rogačići broj 1, Sarajevo, Ilidža">Apoteka Valor” Rogačići broj 1, Sarajevo, Ilidža</option>
                                                 <option value="1. WINK SPORT - Koševo 3, Opština Centar, Sarajevo">1. "WINK SPORT" D.O.O.-Prodavnica br. 17.- adresa: Koševo 3, Opština Centar, Sarajevo</option>
                                                 <option value="2. WINK SPORT - Trg međunarodnog prijateljstva 1.Opština Novi grad, Sarajevo">2. "WINK SPORT" Trg međunarodnog prijateljstva 1.Opština Novi grad, Sarajevo</option>
                                                 
                                             </select>
                                             {freePickupError !== "" && (<span className="error-message">{freePickupError}</span>)}
                                        </label>
                                     )}
                                    {this.state.freePickupSelected && this.state.totalPriceNoShipping < 2500 && ( 
                                        <label className="column is-12">
                                        <h4 className="error-message-big">Molimo vas da upotpunite vašu narudžbu.</h4>
                                        <span className="error-message">Minimalan iznos narudžbe za Gratis Pick-UP(besplatno preuzimanje) je 25KM.</span>
                                        </label> 
                                    )}

                                    {/* __CHANGE */}

                                    {/* {this.state.homeDeliverySelected && this.state.totalPriceNoShipping < 4000 && ( 
                                        <label className="column is-12">
                                        <h4 className="error-message-big">Molimo vas da upotpunite vašu narudžbu.</h4>
                                        <span className="error-message">Minimalan iznos narudžbe za kućnu dostavu je 40KM.</span>
                                        </label> 
                                    )} */}
                                    <div className="column is-12 payment-options">
                                        <p className="subtitle">Opcije plaćanja</p>
                                        <label className="column is-12 radio">
                                            <input type="radio" id="charge" name="charge" className="radio" value="Plaćanje prilikom preuzimanja proizvoda"
                                             checked={charge == "Plaćanje prilikom preuzimanja proizvoda"} onChange={this.handleChange} required/>
                                            <span class="text">Plaćanje prilikom preuzimanja proizvoda</span>
                                            <span class="checkmark"></span>
                                        </label>

                                   {/*   <label className="column is-12 radio">
                                            <input type="radio" id="charge" name="charge" className="radio" value="Plaćanje putem Paypala"
                                            checked={charge == "Plaćanje putem Paypala"} onChange={this.handleChange} required/>
                                            <span class="text">Plaćanje putem Paypala</span>
                                            <span class="checkmark"></span>
                                    </label>  */}
                                    
                                    {chargeError !== "" && (<span className="error-message">{chargeError}</span>)}
                                    </div>

                                    </div>
                                    <div className="button-area next-step">
                                        <span className="button default" onClick={this.validateRequiredFields.bind(this)}>Sljedeći korak <i className="icon"></i></span>
                                    </div>
                              </div>
                              </>
                           )} 
                           {this.state.secondPaymentStep && (
                               <>
                               <h1 className="part-title">Plaćanje</h1>
                               <div className="form">
                                    <div className="contact-info">
                                        
                                        <div className="inner">
                                        <p className="subtitle">Informacije o naručiocu</p>
                                            <div className="single-row"><p>Ime: <b>{name}</b></p></div>
                                            <div className="single-row"><p>Prezime: <b>{surname}</b></p></div>
                                            <div className="single-row"><p>Email adresa: <b>{email}</b></p></div>
                                            <div className="single-row"><p>Broj telefona: <b>{phone}</b></p></div>
                                            <div className="single-row"><p>Adresa stanovanja: <b>{address}</b></p></div>
                                            <div className="single-row"><p>ZIP kod: <b>{postalCode}</b></p></div>
                                            {city !== "Drugi grad" && (<div className="single-row"><p>Grad: <b>{city}</b></p></div>)}
                                            {city === "Drugi grad" && (<div className="single-row"><p>Grad: <b>{cityOther}</b></p></div>)}
                                            
                                                 <div className="single-row"><p>Tip dostave: <b>{shippingMethod} 
                                                 {this.state.freePickupSelected && (
                                                    <>
                                                      ({freePickup})
                                                     </>  
                                                     )}
                                                     </b></p></div>
                                        
                                            <div className="single-row"><p>Metoda plaćanja: <b>{charge}</b></p></div>
                                            <div className="button-area">
                                                <span className="button default" onClick={this.backToStepOne.bind(this)}>Izmijenite podatke</span>
                                            </div>
                                        </div>
                                    </div>
                                   <div className="columns is-multiline">
                                        <label htmlFor="notes" className="column is-12 notes">
                                            <textarea id="surname" name="notes" placeholder="Dodatne napomene u vezi plaćanja(opcionalno)"
                                            value={notes} onChange={ this.handleChange }  />
                                        </label>

                                      {/*  {this.state.userExist && (
                                          <div className="coupon-box columns">
                                            <label htmlFor="couponCode" className="column is-6 coupon-code"><span>Unesite vaš kupon te ostvarite dodatni popust</span>
                                                <input type="text" id="couponCode" name="couponCode" value={couponCode} onChange={this.handleChange} placeholder="Kod kupona" />
                                            </label>
                                            <div className="button-area column is-12">
                                                <span className="button default activate-coupon" onClick={this.couponValidity.bind(this)}>Aktiviraj kupon</span>
                                            </div>
                                        </div>
                                        
                                        )} 
                                         {this.state.couponValidity === 'valid' && (
                                            <p className="coupon-validity valid">Čestitamo, vaš kupon je ispravan. Ostvarili ste popust u iznosu od  {formatMoney(this.state.userCouponValue*10)}.</p>
                                        )}
                                         {this.state.couponValidity === 'invalid' && (
                                            <p className="coupon-validity invalid">Vaš kupon nije validan. Molimo vas da provjerite joše jednom vaš kupon u postavkama vašeg računa.</p>
                                        )}
                                        {this.state.errorCouponBigger && (
                                             <p className="coupon-validity invalid">Vrijednost vašeg kupona je veća od ukupne vrijednosti narudžbe. Molimo vas da poništite vaš kupon u vašem računu, te generišete kupon manje vrijednosti.</p>
                                        )}
                                        */}
                                       {!this.state.userExist && (
                                        <label htmlFor="createAccount" className="checkbox column is-12">Kreirajte račun koristeći gore navedene podatke?
                                            <input type="checkbox" id="createAccount" name="createAccount" checked={this.state.createAccount} onChange={this.toggleCreateAccountChange}/><span className="checkmark"></span>
                                        </label>
                                       )} 
                                        {this.state.createAccount && (
                                            <label htmlFor="passwordOne" className="password-create-account column is-12" >
                                            <input type="password" name="passwordOne" id="passwordOne"placeholder="Unesite lozinku za vaš račun" onChange={this.handleChange}/>
                                        </label>
                                        )}
                                        <div className="button-area">
                                        <p className="notice">NAPOMENA: Izvršavanjem vaše narudžbe, pristajete na naše uslove korištenja.</p>
                                           <Link to={privacyPolicyUrl} className="privacy-policy" target="_blank">Pogledajte uslove korištenja</Link>
                                        </div>
                                        <div className="button-area">

                                       {charge === "Plaćanje prilikom preuzimanja proizvoda" && (
                                            <button type="submit" className="button default"><span className="icon"></span>Završite narudžbu</button>
                                       )}
                                       {charge === "Plaćanje putem Paypala" && (
                                            <PaypalExpressBtn client={clientPaypalKeys} env={paypal_env} currency={paypal_currency} style={stylePaypalButton} total={this.state.totalPriceEuro} 
                                            onError={this.onErrorPaypal} onSuccess={this.onSubmit} onCancel={this.onCancelPaypal}/> 
                                       )}
                                    </div>
                                   </div>
                                </div>
                                </>
                           )}
                        </div>

                        {/******************* Cart products informations *******************/}
                        <div className="column is-5 is-right">
                            <div className="inner">
                            <h1 className="part-title">Vaša narudžba</h1>
                          {/*  <div> {this.displayItems()}</div> */}
                            {cartId !== 'empty' && (
                                <div>
                                    {productsInCart.map(product => 
                                        /******************* Product single row *******************/
                                        <div className="columns single-row margin-0">
                                        <div className="column is-2 image">
                                        <Link to={`/${productUrl}/${product.categorySlug}/${product.brandSlug}/${product.slug}`}>
                                            <img src={process.env.REACT_APP_FILES_DESTINATION +product.image}  alt="Product"/>
                                            <span className="quantity">{product.quantity}</span>
                                            </Link> 
                                           
                                        </div>
                                        <div className="column is-7 product-info">
                                        <Link to={`/${productUrl}/${product.categorySlug}/${product.brandSlug}/${product.slug}`}>
                                            <h1 className="title">{product.title}</h1></Link>
                                            {(product.productAction !== 1 && product.productOffer !== 1) && (
                                            <p className="single-price">Cijena: <span>{formatMoney(product.price)}</span>
                                             {/* {(product.points >=5 || product.points === 0) && ( <span className="points"> ({product.points} bodova vjernosti)</span>)}
                                            {product.points < 5 && product.points > 1 && ( <span className="points"> ({product.points} boda vjernosti)</span>)}
                                            {product.points === 1 && ( <span className="points"> ({product.points} bod vjernosti)</span> )} */}
                                            </p>
                                            )}
                                            {(product.productAction === 1 || product.productOffer === 1) && (
                                            <p className="single-price">Cijena: <span>{formatMoney(product.price)}</span>
                                          {/*   {(product.points >=5 || product.points === 0) && ( <span className="points"> ({product.points} bodova vjernosti)</span>)}
                                            {product.points < 5 && product.points > 1 && ( <span className="points"> ({product.points} boda vjernosti)</span>)}
                                            {product.points === 1 && ( <span className="points"> ({product.points} bod vjernosti)</span> )} */}
                                            {(Number(product.oldPrice)-product.price !== 0) && (  <span class="old-price">{formatMoney(product.oldPrice)}</span>)}
                                            </p>
                                            )}
                                            {product.productVariety === 1 && (
                                                <p className="variation">{product.productVariationType}: <span>{product.productVariationName}</span></p>
                                            )}
                                        </div>
                                        <div className="column is-3 price">
                                            <h4>{formatMoney(product.price*product.quantity)}</h4>
                                        </div>
                                    </div>/* COLUMNS SINGLE ROW END */
                                    )}
                                   <div className="button-area">
                                    <Link to={cartUrl} className="button default">Ažurirajte proizvode</Link>
                                   </div>
                                </div>
                            )}
                            {/******************* Order total price *******************/}
                            <div className="sum">
                                <h4 className="subtotal">Cijena bez dostave: <span><b>{formatMoney(this.state.totalPriceNoShipping)}</b></span></h4>
                                <h4 className="subtotal">Dostava: <span><b>
                                {(this.state.shipping !== "Odaberite tip dostave" && this.state.shipping !== "Besplatna") && (
                                <>
                                {formatMoney(this.state.shipping)}
                                </>
                                )}
                                {/* __NOTE */}
                                {(this.state.shipping === "Odaberite tip dostave" && (<>Odaberite tip dostave</>))}
                                {(this.state.shipping === "Besplatna") && ( <>{this.state.shipping}</> )}
                                </b></span></h4>
                               {/* {this.state.couponActivated && (
                                <>
                                    <h4 className="subtotal">Kupon(<b>{this.state.userCoupon}</b>): <span><b>{formatMoney(this.state.userCouponValue*10)}</b></span></h4>
                                    <span className="remove-coupon" onClick={this.removeCoupon.bind(this)}>Poništite kupon</span>
                                </>
                                )}
                               */}
                                <span className="break"></span>
                                <h1 className="total">Ukupno: <span><b>{formatMoney(this.state.totalPrice)}</b></span></h1>
                                <p className="free-order-info">Narudžbom od 100 KM i više ostvarujete <b>besplatnu dostavu</b> u cijeloj BiH.</p>
                               {/* <h4 className="total-points">Ukupan broj bodova vjernosti: <span><b>{this.state.totalPoints}</b></span></h4> */}
                            </div>
                            </div>
                            
                        </div>
                    </div>
                </fieldset>
            </form>        
            <div className="support-box">
                <div className="inner">
                    <span className="icon help"></span>
                    <div className="content-box">
                        <p>Kontakt podrška</p>
                        <a href="tel:+387 61 93 93 48">+387 61 93 93 48</a>
                        <a href="mailto:info@zdravo.ba">info@zdravo.ba</a>
                    </div>
                </div>
            </div>   
         </div>{/* CONTAINER ENDS */}
    </section>{/* SECTION CHECKOUT END */}
</div>
        );
    }
}

export default Order