import React from 'react'
import { Link } from 'react-router-dom'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import {Helmet} from "react-helmet";
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { upload } from '../../helpers/UserFunctions'


class EditPost extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;
        
        this.state = {
            showPopupClass: '',
            id: '',
            title: '',
            image: null,
            link: '',
            published: '',
            imageFile: null,
            showStickerClass: '',
            stickerMessage: '',
            description: '',
            stickerType: '',
            imageChanged: false,
        }   

        this.onChangeImage = this.onChangeImage.bind(this);
        this.resetImage = this.resetImage.bind(this);
    }

//ISTAKNUTA SLIKA
onChangeImage(event) {
    this.setState({
        imageChanged: true,
        image: URL.createObjectURL(event.target.files[0]),
        imageFile: event.target.files[0]
    });
}

resetImage(event) {
    event.preventDefault();
    this.setState({ image: null });
}

handleChange = (e) => {     
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
        }
    
componentDidMount = () => {
    this.getPostById();
}

getPostById() {
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/posts/edit-post/' + this.props.match.params.id)
    .then((response) => {
    this.setState({
    title: response.data.title,
    description: response.data.description,
    link: response.data.link,
    published: response.data.published,
    image: response.data.image,
    });
    })
    .catch((error) => {
    console.log(error);
    })
}

onSubmit = (e) => {
    e.preventDefault();
    const { title, description, link, published, image } = this.state;
    var newImage;
    var imageFile = this.state.imageFile;
    if(imageFile !== null){
        newImage = imageFile.name
    }
    else{
        newImage = image;
    }
    axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/posts/update-post/' + this.props.match.params.id, {
    title,
    description,
    link,
    published,
    image: newImage,
    })
    .then((response) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Uspješno ste uredili članak "<b>' + this.state.name + '"</b>',
        stickerType: 'success',
    })
    
    this.props.history.push('/administracija/clanci');
    })
    .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Došlo je do problema prilikom uređivanja članka: ' + error,
        stickerType: 'danger',
    })
    });

    const addImage = new FormData();
    addImage.append(
    'image',this.state.imageFile);        
    const config = {       
    headers: {'content-type': 'multipart/form-data'}
    };

    upload(addImage, config) .then((response) => {
        }).catch((error) => {
    });
}        

    render(){
        var description = this.state.description;
        const { id, title, link, published, image, imageChanged} = this.state;
        return(
            <AdminWrapper>
            <section id="posts" className="section default-section content-page-section add-post default-form">
               {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> {title}  &rsaquo; Uređivanje članka  &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="content">
                        <div className="page-header">
                            <h2 className="page-title">Uređivanje članka</h2>
                        </div>
                        <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <Link to="/administracija/clanci">Članci</Link>
                            <span>/</span>
                            <span>Uređivanje članka</span>
                        </div>
                        <div className="columns">
                            <div className="column is-8">
                                <form name="edit-post" onSubmit={this.onSubmit} className="form">
                                 <fieldset className="">
                                    <label htmlFor="title" className="column is-12 title">
                                        Ime članka
                                        <input type="text" id="title" name="title" placeholder="Puno ime posta"
                                        value={title} onChange={ this.handleChange} required/>
                                    </label> 
                                
                                    <label htmlFor="link" className="column is-12">
                                        Link članka
                                        <input type="text" id="link" name="link" placeholder="Link koji će se otvarati putem posta"
                                        value={link} onChange={ this.handleChange} required/>
                                    </label>

                                    <label htmlFor="published" className="column is-12">
                                    Prikazati post na početnoj strani?
                                        <select id="published" name="published" value={published} onChange={ this.handleChange }>
                                            <option value="">Izaberi</option>
                                            <option value="1">Da</option>
                                            <option value="0">Ne</option>
                                        </select>   
                                    </label>
                                                            
                                    <label htmlFor="description" className="column is-12 description">
                                    Skraćeni tekst članka                            
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data= {description}
                                            onInit={ editor => {
                                            } }
                                            onChange={ ( event, editor ) => {
                                                this.setState({
                                                    description: editor.getData(),
                                                })
                                            } }
                                            onBlur={ editor => {
                                            } }
                                            onFocus={ editor => {
                                            } }
                                        />
                                    </label>
                                    <span className="is-hidden">
                                        {description = this.state.description}
                                    </span>

                                    <div id="upload_button" className="">
                                    <label htmlFor="image" className="label-image">
                                        Istaknuta slika
                                    <input type="file" id="image" name="image" onChange={ this.onChangeImage } />
                                    <span className="add-image">Dodaj istaknutu sliku</span>
                                    </label>
                                    <div className="image-preview">
                                        {image && !imageChanged && <img src={process.env.REACT_APP_FILES_DESTINATION + image} alt="" />}
                                        {image && imageChanged && <img src={image} alt="" />}
                                        {image && (                                 
                                                <button className="remove-button" onClick={this.resetImage}><i className="icon"></i></button>
                                                )}
                                    </div>
                                       
                                    </div>       
                                    <button type="submit" className="button default add default-no-icon"><span className="item">Spremi izmjene <FontAwesomeIcon icon="pencil-alt" /></span></button>

                            </fieldset>
                        </form>
                            </div>
                            
                         
                        </div>
                        
                    </div>
                </div>
            </section>
            </AdminWrapper>
        )
    }
}

export default EditPost