import React from 'react'
import logo from '../../static/zdravo-logo-white.png'
import {Link, withRouter} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logout from './profile/Logout'
var togglePopupMenu;
class Header extends React.Component{

    constructor () {
        super()
        this.state = {
            showPopupMenuClass: '',
        }   
        togglePopupMenu = this.togglePopupMenu.bind(this);
        this.handleLogout = this.handleLogout.bind(this);

    }

    togglePopupMenu () {
        if (this.state.showPopupMenuClass === '') {
            this.setState({
                showPopupMenuClass: 'show'
            })
        }
        else{
            this.setState({
                showPopupMenuClass: ''
            })
        }     
      }

    handleLogout(){
        localStorage.removeItem("usertoken");
        this.props.history.push('/administracija')
    }

    render(){

        return(
            <section id="header" className="seaction main-header">
                <div className="container is-fluid">
                    <div className="logo">
                        <Link to="/administracija"><img src={logo} /></Link>
                    </div>

                    <div className="header-menu is-left">
                        <span className="close-sidebar"><FontAwesomeIcon icon="bars" /></span>
                            <Link to="/administracija/novi-proizvod" className="header-link">Dodaj novi proizvod <FontAwesomeIcon icon="plus" /></Link>
                            <Link to="/administracija/postavke" className="header-link">Postavke trgovine <FontAwesomeIcon icon="wrench" /></Link>
                    </div>

                    <div className="header-menu is-right">
                            <Link to="/" className="header-link">Posjeti trgovinu <FontAwesomeIcon icon={['fab', 'telegram-plane']} aria-hidden="true" /></Link>
                            <Logout />
                            <Link to="/administracija/racun" className="header-link my-account"><span>Moj račun</span> <span className="avatar"></span></Link>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(Header)