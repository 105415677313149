import React from "react";
import Slider from "react-slick";
import Magnifier from "react-magnifier";
import formatMoney from '../../../functions/formatMoney'
import no_image from '../../../static/no_image.png'
class SingleProductSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const sliderArr = this.props.sliderArr, 
    flagAction = this.props.flagAction, 
    percent_discount = this.props.percent_discount, 
    save_money = this.props.save_money,
    prPurpose = this.props.prPurpose;
    return (

        <>
        <div className="column is-2 column-thumbs ">
            <Slider asNavFor={this.state.nav1} 
            ref={slider => (this.slider2 = slider)}
            slidesToShow={5}
            vertical={true}
            arrows={false}
            swipeToSlide={true}
            focusOnSelect={true}
            responsive={
                [
                    {
                      breakpoint: 768,
                      settings: {
                        vertical: false,
                      }
                    },
                  ]
                }
              >
                {sliderArr.map(slider => 
                slider !== 'empty' && (
                    <div><img src={process.env.REACT_APP_FILES_DESTINATION+slider} alt={prPurpose} /></div>)
                )}
            </Slider>
        </div>

        {/******************* Product Big gallery image *******************/}
        <div className="column is-10 column-gallery">
          {(this.props.image === "empty" || this.props.image === undefined || this.props.image === null) && (
            <img src={no_image} />
          )}
           {this.props.image !== "empty" && this.props.image !== undefined && this.props.image !== null && (
            <Slider
            asNavFor={this.state.nav2}
            arrows={false}
            autoplay={true}
            autoplaySpeed={3500}
            ref={slider => (this.slider1 = slider)}
            >
                {sliderArr.map(slider => 
                slider !== 'empty' && (
                <div>
                        {flagAction && percent_discount > 0 && (
                        <div className="action-label">
                            <span className="percent-discount">-{percent_discount}%</span>
                            <span className="save-calculator">Uštedi* <b>{formatMoney(save_money)}</b></span>
                        </div>
                        )}
                        {flagAction && (percent_discount === "0" || percent_discount === "0 " || percent_discount === "") && (
                        <div className="action-label gratis">
                          <span className="percent-discount">+ GRATIS</span>
                        </div>
                        )}  
                    <Magnifier src={process.env.REACT_APP_FILES_DESTINATION+slider} mgWidth={250} mgHeight={250} mgShape={'square'} 
                    mgBorderWidth={1} mgShowOverflow={false} zoomFactor={0.8}  alt={prPurpose} />
                    </div>
                )
                )}
            </Slider>
           )}
        </div>
        </>
    );
  }
}

export default SingleProductSlider