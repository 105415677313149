import React, { Component } from 'react'
import { login } from '../../helpers/UserFunctions'
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom'
import logo from '../../../static/zdravo-logo.png'

class Login extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      password: '',
      errors: {}
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  onSubmit(e) {
    e.preventDefault()

    const user = {
      email: this.state.email,
      password: this.state.password
    }

    login(user).then(res => {
      if (res) {
        this.props.history.push(`/administracija`)
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  render() {
    const { email, password, error } = this.state;
        return(
            <div className="admin">
            <section id="login" className="hero is-fullheight login">
                <Helmet>
                    <title> Prijava  &rsaquo; Administracija  &rsaquo; Zdravo.ba </title>
                </Helmet>
                <div className="container">
                  <div className="inner">
                    <div className="logo">
                        <Link to="/administracija"><img src={logo} /></Link>
                    </div>
                        <form name="login" method="POST" onSubmit={this.onSubmit} className="login-form">
                            <fieldset>
                                <label htmlFor="email" className="email">
                                    <input type="email" name="email" id="email" value={email} onChange={this.onChange} placeholder="Email adresa"/>
                                </label>
                                <label htmlFor="password" className="password" >
                                    <input type="password" name="password" id="password" value={password} onChange={this.onChange} placeholder="Lozinka"/>
                                </label>

                                <div className="button-area">
                                    <Link to="/administracija/" className="forgot-password">Zaboravili ste lozinku?</Link>
                                    <button type="submit" className="button default is-right"><span className="item">Prijava</span></button>
                                </div>
                            </fieldset>
                        </form>

                        <div className="divider">
                            <p>ili</p>
                        </div>

                    {/*    <div className="social-login">
                            <a href="" className="google"><span className="icon"><i></i></span><span className="item">Prijavi se putem Google-a</span></a>
                            <a href="" className="facebook"><span className="icon"><i></i></span><span className="item">Prijavi se putem Facebook-a</span></a>
                        </div>
                    */}
                  </div>
                </div>
            </section>
            </div>
        );
  }
}

export default Login