/*
My settings page
Display account information and data
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'
import {siteTitle, timeoutDelay} from '../../../functions/config'
import Sticker from '../../helpers/Sticker'
import LoaderBox from '../../helpers/LoaderBox';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import Logout from './Logout'
import jwt_decode from 'jwt-decode'
import axios from 'axios'

var pageTitle = "Moji podaci", curUserId = "";
class MyySettings extends React.Component{
    constructor () {

        super()
        this.unsubscribe = null;
        
        this.state = {
            userId: '',
            name: '',
            showStickerClass: '',
            surname: '',
            address: '',
            phone: '',
            email: '',
            city: '',
            id: '',
            newsletter: '',
            userCreatedAt: '',
            postalCode: '',
            loading:false,
            changeData: false,
        }
        
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.changeDataTriger = this.changeDataTriger.bind(this); 
      }

getUserData(){
    axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL+'api/users/profile/'+curUserId, {
      })
      .then(response => {
        const {name, surname, address, city, email, phone, postalCode, newsletter, userCreatedAt } = response.data;
       this.setState({
        id: response.data._id,
        newsletter, 
        userCreatedAt,
        name,
        surname,
        address,
        city,
        email,
        phone,
        postalCode,
       })
      })
      .catch(err => {
        console.log(err)
      })
}
changeDataTriger(){
    this.setState({
        changeData: !this.state.changeData
    })
}

onSubmit(e){
    e.preventDefault();
    
    const {name, surname, email, phone, address, postalCode,
        city } = this.state;
    axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/users/profile-update/' + curUserId, {
        name,
        surname,
        email,
        phone,
        address,
        postalCode,
        city,
        })
        .then((response) => {
            this.setState({
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
            }.bind(this),timeoutDelay);
            this.setState({
            stickerMessage: 'Uspješno ste izmijenili vaše postavke računa.',
            stickerType: 'success',
            }) 
        })
        .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Došlo je do problema prilikom izrade vaših podataka: ' + error,
        stickerType: 'danger',
        })
    });

    }
        
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    
componentDidMount = () => {
    const token = localStorage.getItem('usertoken');
    const decoded = jwt_decode(token)
    if(token){
        curUserId = decoded._id
    } 
    this.getUserData(); 
  }

    render(){

        const {name, surname, email, address, phone, city, postalCode } = this.state;
        return(
            <ShopWrapper>
               {/* <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
               </Helmet>*/}
                <section id="p_about_us" className="section my-settings-page default-form user-page">
                    {this.state.showStickerClass=== 'show' && (  
                        <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                    )}
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={pageTitle} />

                         {/******************* Loader box *******************/}
                         {this.state.loading && (<LoaderBox /> )}

                        {/******************* Content *******************/}
                        <div className="content">
                            <div className="columns is-multiline">
                                <div className="column is-12 user-menu">
                                    <ul>
                                        <li><Link to="/moj-racun">Naslovnica</Link></li>
                                        <li><Link to="/moj-racun/narudzbe">Moje narudžbe</Link></li>
                                        <li><Link to="/moj-racun/korisnicki-podaci" className="current">Moji podaci</Link></li>
                                     {/*   <li><Link to="/moj-racun/bodovi-vjernosti">Bodovi vjernosti</Link></li> */}
                                        <Logout />
                                    </ul>
                                </div>

                                <div className="column main">
                                    <div className="current-data">
                                        <div className="single-line">Ime i prezime: <b>{this.state.name} {this.state.surname}</b></div>
                                        <div className="single-line">Email adresa: <b>{this.state.email}</b></div>
                                        <div className="single-line">Broj telefona: <b>{this.state.phone}</b></div>
                                        <div className="single-line">Adresa: <b>{this.state.address}, {this.state.city}, {this.state.postalCode}</b></div>
                                        <div className="button-area">
                                            <span className="button default" onClick={this.changeDataTriger.bind(this)}>Izmijenite podatke</span>
                                        </div>
                                    </div>
                                    {this.state.changeData && (
                                            <form name="login" method="POST" onSubmit={this.onSubmit} className="user-data-form">
                                            <fieldset>
                                                <div className="columns is-multiline">
                                                    <label htmlFor="name" className="name column is-6">Vaše ime
                                                        <input type="text" name="name" id="name" placeholder="Vaše ime" value={name} onChange={this.onChange}/>
                                                    </label>
            
                                                    <label htmlFor="surname" className="surname column is-6">Vaše prezime
                                                        <input type="text" name="surname" id="surname" placeholder="Vaše prezime" value={surname} onChange={this.onChange}/>
                                                    </label>
                                                    
                                                    <label htmlFor="phone" className="phone column is-6">Broj telefona
                                                        <input type="text" name="phone" id="phone" placeholder="Broj telefona" value={phone} onChange={this.onChange}/>
                                                    </label>
            
                                                    <label htmlFor="address" className="address column is-6">Adresa stanovanja
                                                        <input type="text" name="address" id="address" placeholder="Adresa stanovanja" value={address} onChange={this.onChange}/>
                                                    </label>
            
                                                    <label htmlFor="postalCode" className="postalCode column is-6">ZIP kod
                                                        <input type="text" name="postalCode" id="postalCode" placeholder="ZIP kod" value={postalCode} onChange={this.onChange}/>
                                                    </label>
            
                                                    <label htmlFor="city" className="city column is-6">Grad
                                                        <input type="text" name="city" id="city" placeholder="Grad" value={city} onChange={this.onChange}/>
                                                    </label>
                                                    <span className="break"></span>
            
                                                   </div>
                                                    <div className="button-area">
                                                        <button type="submit" className="button default is-right"><span className="item">Spremite izmjene</span></button>
                                                    </div>
                                            </fieldset>
                                        </form>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}
            </ShopWrapper>
        )
    }
}

export default MyySettings