import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Favicon from 'react-favicon';
import favicon from './static/favicon.png'
import jwt_decode from 'jwt-decode'


import Dashboard from './components/admin/dashboard/Dashboard'
import Login from "./components/admin/profile/Login";
import Register from "./components/admin/Register";
import Brands from './components/admin/brand/Brands'
import EditBrand from './components/admin/brand/EditBrand'
import Sliders from './components/admin/slider/Sliders'
import EditSlider from './components/admin/slider/EditSlider'
import Posts from './components/admin/post/Posts'
import EditPost from './components/admin/post/EditPost'
import FiltersCategories from './components/admin/filters/FiltersCategories'
import EditFiltersCategory from "./components/admin/filters/Filters";
import Categories from './components/admin/category/Categories'
import EditCategory from './components/admin/category/EditCategory'
import AddProduct from './components/admin/product/AddProduct'
import Products from './components/admin/product/Products'
import EditProduct from './components/admin/product/EditProduct'
import Settings from './components/admin/settings/Settings'
import Orders from './components/admin/order/Orders'
import Page404 from './components/shop/pages/404'
import PageMaintance from './components/shop/pages/Maintance'
import BestSellers from './components/admin/product/BestSellers'
import Checkout from './components/shop/cart/Checkout'
import Uploader from './components/Uploader'
import Category from './components/shop/category/Category'
import SingleProduct from './components/shop/product/SingleProduct'
import Homepage from './components/shop/homepage/Homepage'
import AboutUs from './components/shop/pages/AboutUs'
import PrivacyPolicy from './components/shop/pages/PrivacyPolicy'
import FAQ from './components/shop/pages/FAQ'
import PaymentOptions from './components/shop/pages/PaymentOptions'
import Shipping from './components/shop/pages/Shipping'
import TermsOfUse from './components/shop/pages/TermsOfUse'
import Contact from './components/shop/pages/Contact'
import SignUp from './components/shop/user/SignUp'
import Order from './components/shop/cart/Order'
import GeneratedOrderAdmin from './components/admin/order/GeneratedOrderAdmin'
import GeneratedOrder from './components/shop/cart/GeneratedOrder'
import Actions from './components/shop/pages/Actions'
import SearchPage from './components/shop/pages/SearchPage'
import WeekOffer from './components/shop/pages/WeekOffer'
import {Helmet} from "react-helmet";
import Brand from './components/shop/pages/Brand';
import Markups from './components/admin/markup/Markups'
import EditMarkup from './components/admin/markup/EditMarkup'
import MyAccount from './components/shop/user/MyAccount'
import MySettings from './components/shop/user/MySettings'
import { LoginRouteUser } from "./LoginRouteUser";
import { PrivateRouteUser } from "./PrivateRouteUser";
import SignIn from './components/shop/user/SignIn'
import MyPoints from './components/shop/user/MyPoints'
import ThankYou from './components/shop/cart/ThankYou'
import BrandsList from "./components/shop/pages/BrandsList";
import {PrivateRouteAdmin} from './PrivateRouteAdmin'
import {LoginRouteAdmin} from "./LoginRouteAdmin";
import Users from './components/admin/users/Users'
import Pages from './components/admin/page/Pages'
import AddPage from './components/admin/page/AddPage'
import EditPage from "./components/admin/page/EditPage";
import LandingPage from "./components/shop/landing-page/LandingPage";
import ReactPixel from 'react-facebook-pixel';
import offline from './static/offline.png'

//GOOGLE ANALITYCS TRACKING CODE
import ReactGA from 'react-ga';
/////////////////////////////////////

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faBars, faWrench, faSignOutAlt, faBoxOpen, faInbox, faLayerGroup, faSlidersH, faTags, faChevronRight, faChevronDown,
faEye, faEdit,faTrashAlt, faImage, faPlusCircle, faPencilAlt, faTimesCircle,faDollarSign, faUser, faNewspaper, faFileAlt  } from '@fortawesome/free-solid-svg-icons'
import { faAdn, faTelegramPlane  } from '@fortawesome/free-brands-svg-icons'


library.add(faPlus, faBars, faWrench, faSignOutAlt, faBoxOpen, faInbox, faLayerGroup, faSlidersH, faTags, faAdn, faChevronRight, faChevronDown, faEye,
  faEdit, faTrashAlt, faImage, faPlusCircle, faPencilAlt, faTimesCircle, faTelegramPlane, faDollarSign, faUser, faNewspaper, faFileAlt)
  
//GOOGLE ANALITYCS TRACKING CODE
ReactGA.initialize('UA-102855107-22');
ReactGA.pageview(window.location.pathname + window.location.search);
/////////////////////////////////////

const advancedMatching = { em: 'armin.f@wellpromotion.ba' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: true, 		// enable logs
};
ReactPixel.init('1213708225438041', advancedMatching, options);
 
ReactPixel.pageView(); 					// For tracking page view

class App extends Component {

  state = { 
   authenticatedAdmin: false,
   authenticatedUser: false, 
   user: null };

  componentWillMount() {

    if(localStorage.getItem('usertoken')){
      const token = localStorage.getItem('usertoken');
      const decoded = jwt_decode(token)
      if(decoded.isAdmin === 1){
        this.setState({
          authenticatedAdmin: true,
          authenticatedUser: true,
        });
      }
      else{
        this.setState({
        authenticatedUser: true,
        })
      } 
    }
   
  }

componentDidMount(){
}

  render() {
    const { authenticatedAdmin, authenticatedUser } = this.state;
    return (
      // <>
      // <div className="offline-wrap">
      //   <div>
      //     <h1 className="page-offline">Poštovani korisnici!</h1>
      //     <h1 className="page-offline">Obavejštavamo Vas da zbog izmjena u strukturi poslovanja Onlineshop neće raditi do <br/> <b>1. februara 2021.</b></h1>
      //     <h2 className="page-offline">Do tada SVAKO DOBRO <br/> Vaš PharmShop ZDRAVO.BA</h2>
      //     <img src={offline} />
      //   </div>
        
      // </div>
      
      <Router>
        <Favicon url={favicon} />
          <Switch>
              <PrivateRouteAdmin exact path="/administracija" component={Dashboard} authenticated={authenticatedAdmin}/>
              <LoginRouteAdmin exact path="/administracija/prijava" component={Login} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/brendovi" component={Brands} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/uredjivanje-brenda/:slug" component={EditBrand} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/baneri" component={Sliders} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/uredjivanje-banera/:id" component={EditSlider} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/clanci" component={Posts} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/uredjivanje-clanka/:id" component={EditPost} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/filteri" component={FiltersCategories} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/uredjivanje-filtera/:id" component={EditFiltersCategory} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/kategorije" component={Categories} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/uredjivanje-kategorije/:slug" component={EditCategory} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/novi-proizvod" component={AddProduct} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/proizvodi/:type?" component={Products} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/uredjivanje-proizvoda/:slug" component={EditProduct} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/postavke" component={Settings} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/narudzbe" component={Orders} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/generisana-narudzba/:id" component={GeneratedOrderAdmin} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/marze" component={Markups} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/uredjivanje-marze/:id" component={EditMarkup} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/najprodavaniji-proizvodi" component={BestSellers} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/korisnici" component={Users} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/stranice" component={Pages} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/nova-stranica" component={AddPage} authenticated={authenticatedAdmin}/>
              <PrivateRouteAdmin exact path="/administracija/uredjivanje-stranice/:slug" component={EditPage} authenticated={authenticatedAdmin}/>
            <Route exact path="/" component={Homepage} />



            {/*<Route exact path="/uploader" component={Uploader} />*/}



            <Route path="/kategorija/:slug?/:subCategory?" component={Category} />
            <Route path="/proizvod/:category/:brand/:slug/" component={SingleProduct} />
            <Route path="/o-nama" component={AboutUs} />
            <Route path="/zastita-privatnosti" component={PrivacyPolicy} />
            <Route path="/cesto-postavljena-pitanja" component={FAQ} />
            <Route path="/nacini-placanja" component={PaymentOptions} />
            <Route path="/dostava" component={Shipping} />
            <Route path="/uslovi-koristenja" component={TermsOfUse} />
            <Route path="/kontakt" component={Contact} />
            <Route path="/hvala-vam" component={ThankYou} />
            <Route path="/generisana-narudzba/:id" component={GeneratedOrder} />
            <Route path="/korpa" component={Checkout} />
            <Route path="/narudzba" component={Order} />
            <Route path="/ponuda-sedmice" component={WeekOffer} />
            <Route path="/special/:slug" component={LandingPage} />


             {/*<Route path="/akcije" component={Actions} />*/}

             
            <Route path="/brend/:slug" component={Brand} />
            <Route path="/pretraga/:pojam?" component={SearchPage} />
            <Route path="/brendovi" component={BrandsList} />
            <LoginRouteUser exact path="/prijava" component={SignIn} authenticated={authenticatedUser}/>
            <PrivateRouteUser exact path="/moj-racun" component={MyAccount} authenticated={authenticatedUser}/>
            <PrivateRouteUser exact path="/moj-racun/korisnicki-podaci" component={MySettings} authenticated={authenticatedUser}/>


           <PrivateRouteUser exact path="/moj-racun/bodovi-vjernosti" component={MyPoints} authenticated={authenticatedUser}/>


            <Route path="/registracija" component={SignUp} />
            {/* <Route component={Page404} /> */}


           {/*   <Route exact path="/" component={PageMaintance} />
            <Route component={PageMaintance} /> */}


        </Switch>

      </Router>
    );
  }
}
export default App;