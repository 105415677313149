/*
Checkout page
Display cart with products, available options for changing product quantity and removing products
*/
import React from 'react'
import { Link } from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import {Helmet} from "react-helmet";
import ShopWrapper from '../ShopWrapper'
import Sticker from '../../helpers/Sticker'
import { siteTitle, timeoutDelay } from '../../../functions/config'
import LoaderBox from '../../helpers/LoaderBox';
import Header from '../Header'
import MainTitle from '../../helpers/MainTitle'
import {orderUrl, homeUrl} from '../../../functions/pageUrls'
import axios from 'axios';
import { withGlobalState } from 'react-globally'

var cartId = "empty", productsInCart = [];
var pageTitle = "Korpa";

class Checkout extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
        newQuantity:0,
        showPopupDeleteClass: '',
        showStickerClass: '',
        totalPrice: 0,
        productToRemoveTitle: '',
        productToUpdateQuantityTitle: '',
        productsQuantity: 0,
        localStorageCartState: '',
        productToRemoveId: '',
        productToRemoveVariationName: '',
        productToRemoveProductVariety: '',
        productToRemovePoints: '',
        productToRemovePrice: '',
        productToRemoveQuantity: '',
        loading: true,
        totalPoints: 0,
        } 
        this.togglePopupDelete = this.togglePopupDelete.bind(this);
        this.updateProductInCart = this.updateProductInCart.bind(this);
    }



getCartData(){
    if(localStorage.cartId)
    {  
        cartId = localStorage.cartId;
        var totalPriceInner,totalPointsInner, productsInner, productsQuantityInner;  
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/get-cart/' + cartId)
        .then((response) => {
         if(response.data !== null){
            var totalPrice, products, totalPoints; 
            totalPrice = response.data.totalPrice;  
            totalPoints = response.data.totalPoints
            products = JSON.parse(response.data.products);
            productsInCart = []; 
            totalPriceInner = totalPrice;
            totalPointsInner = totalPoints;
            productsInner = products; 
            productsQuantityInner = products.length;
    
    
            if(productsInner === undefined){
                localStorage.removeItem('cartId');
            }
            else{
            Object.keys(productsInner).forEach(function(id) {
                productsInCart.push(productsInner[id]);
                });
            }
    
            this.setState({
                totalPrice: totalPriceInner,
                totalPoints: totalPointsInner,
                productsQuantity: productsQuantityInner
            }) 
         }
         this.setState({
            loading: false
         })
       
    });
}

    if(cartId === 'empty'){
        this.setState({
            loading: false,
        })
        totalPriceInner = 0;
        totalPointsInner = 0;
        productsQuantityInner = 0;
    }   
}

//Function: show popup for deleting products
togglePopupDelete (id, productVariety, variationName, name, price, quantity, points) {
    if (this.state.showPopupDeleteClass === '') {
        this.setState({
            showPopupDeleteClass: 'show',
            productToRemoveTitle: name,
            productToRemoveProductVariety: productVariety,
            productToRemoveId: id,
            productToRemoveVariationName: variationName,
            productToRemovePrice: price,
            productToRemoveQuantity: quantity,
            productToRemovePoints: points,
        })
    }
    else{
        this.setState({
            showPopupDeleteClass: ''
        })
    } 
     
  }

//Function: Removing product from cart
removeProductFromCart(id, productVariety, variationName, price, quantity, points){
var products = [];
var totalPrice = 0;
var totalPoints = 0;
this.setState({
    loading:true,
})
for (var i in productsInCart) {
    if(productVariety === 1){
        if(productsInCart[i].id == id && productsInCart[i].productVariationName == variationName){
            productsInCart.splice(i,1);
        }
    }
    else{
        if(productsInCart[i].id == id){
            productsInCart.splice(i,1);
            }
        }
    }
    setTimeout(function(){
        this.setState({
            loading:false
        })
   }.bind(this),1000);
    totalPrice = this.state.totalPrice - price*quantity;
    totalPoints = this.state.totalPoints - points*quantity;
    cartId = localStorage.cartId;
       
            products = JSON.stringify(productsInCart);  
            axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/update-cart/' + cartId, {
                cartId,
                products,
                totalPrice,
                totalPoints,
              }).then((docRef) => {
                  this.setState({
                    productsQuantity: this.state.productsQuantity-1,
                      totalPrice: totalPrice,
                      totalPoints: totalPoints,
                      showPopupDeleteClass: '',
                      showStickerClass: 'show',
                  })
                  setTimeout(function(){
                    this.setState({
                        showStickerClass: ''
                    })
               }.bind(this),timeoutDelay);
               this.setState({
                stickerMessage: 'Proizvod "<b>' + this.state.productToRemoveTitle + '"</b> je uspješno uklonjen iz vaše korpe.',
                stickerType: 'success',
            })
            this.props.setGlobalState({
                changeHeaderCart: true,
              })
               //   this.componentDidMount();
              })
              .catch((error) => {
                this.setState({
                  showStickerClass: 'show',
              })
              setTimeout(function(){
                  this.setState({
                      showStickerClass: ''
                  })
             }.bind(this),timeoutDelay);
             this.setState({
              stickerMessage: 'Došlo je do problema prilikom brisanja proizvoda: ' + error,
              stickerType: 'danger',
          })
              });
          }
          

handleChangeQuantity = (e) => {  
    const { name, type, value } = e.target;
    const val = type === 'number' ? parseFloat(value) : value; 
   this.state.quantityTestNew = val;
}
//Function: change quantity of products in cart by 1 more or 1 less
 updateProductInCart(id, productVariety, variationName, price, points, productName){
    const val = this.state.quantityTestNew;  
    if(val > 0){
        this.setState({
            loading:true,
            productToUpdateQuantityTitle: productName,
            quantityTestNew: ''
        })
        setTimeout(function(){
            this.setState({
                loading:false
            })
       }.bind(this),1000);
        var currQuantity;
        var products = [];
        var totalPrice = 0;
        var totalPoints = 0;
    
        for (var i in productsInCart) {
          if(productVariety === 1){
                if(productsInCart[i].id == id && productsInCart[i].productVariationName == variationName)
                {
                    currQuantity = productsInCart[i].quantity;
                    productsInCart[i].quantity = val;
                }
            }
            else{
                if(productsInCart[i].id == id)
                {
                    currQuantity = productsInCart[i].quantity;
                    productsInCart[i].quantity = val;
                }
            }
        }
        if(currQuantity !== val){
            if(val > currQuantity)
            {
                totalPrice = parseFloat(this.state.totalPrice) + (parseFloat(price)*(val-currQuantity));
                totalPoints = this.state.totalPoints + (points*(val-currQuantity));
            }
    
            else if(currQuantity>1 && val < currQuantity){
                totalPrice = parseFloat(this.state.totalPrice) - (parseFloat(price)*(currQuantity-val));
                totalPoints = this.state.totalPoints - (points*(currQuantity-val));
            }
            else if(currQuantity <= 1){
                totalPrice = this.state.totalPrice;
                totalPoints = this.state.totalPoints;
            }
        
            cartId = localStorage.cartId;

                    products = JSON.stringify(productsInCart);  
                    axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/update-cart/' + cartId, {
                        cartId,
                        products,
                        totalPrice,
                        totalPoints,
                      }).then((docRef) => {
                          this.setState({
                              totalPrice: totalPrice,
                              totalPoints: totalPoints,
                              showStickerClass: 'show',
                            })
                            setTimeout(function(){
                              this.setState({
                                  showStickerClass: ''
                              })
                         }.bind(this),timeoutDelay);
                         this.setState({
                          stickerMessage: 'Količina proizvoda "<b>' + this.state.productToUpdateQuantityTitle + '"</b> je uspješno promjenjena.',
                          stickerType: 'success',
                      })
                      this.props.setGlobalState({
                        changeHeaderCart: true,
                      })
                  
                      })
                      .catch((error) => {
                        this.setState({
                          showStickerClass: 'show',
                      })
                      setTimeout(function(){
                          this.setState({
                              showStickerClass: ''
                          })
                     }.bind(this),timeoutDelay);
                     this.setState({
                      stickerMessage: 'Došlo je do problema prilikom brisanja proizvoda: ' + error,
                      stickerType: 'danger',
                  })
                      });
                
        }
      
    
    }
   
 }

 
 componentDidMount = () => { 
   this.getCartData();

  }

//Function: pass data about category from header
headerCategoryData(categorySlug){} 

    render(){
        return(
            <div className={"shop"}>
                <Header headerCategoryData={(categorySlug) => this.headerCategoryData(categorySlug)}/>
                <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
                </Helmet>
                <section id="p_checkout" className="section checkout without-footer">
                
                 {/******************* Loader box *******************/}
                 {this.state.loading && (<LoaderBox /> )}
                 
                    <div className="container">
                    {this.state.showStickerClass=== 'show' && (  
                        <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                    )}
                     {/******************* Popup delete product *******************/}
                        <div className={"popup-wrapper " + this.state.showPopupDeleteClass}>
                            <div className="overlay-back"></div>
                            <div className="popup-box popup-delete">
                                <h6 className="text">Jeste li sigurni da želite ukloniti <br /><b>"{this.state.productToRemoveTitle}"</b> iz vaše korpe?</h6>
                                <div className="button-area">
                                    <button className="button default confirm"  onClick={() => this.removeProductFromCart(this.state.productToRemoveId, this.state.productToRemoveProductVariety, this.state.productToRemoveVariationName, this.state.productToRemovePrice, this.state.productToRemoveQuantity, this.state.productToRemovePoints)}>Želim</button>
                                    <button className="button red" onClick={this.togglePopupDelete.bind(this)}>Ne, hvala</button>
                                </div>
                            </div>
                        </div>
                    
                     {/******************* Main title *******************/}
                    <MainTitle title={pageTitle} />

                    {cartId !== 'empty' && (
                    <>
                    {productsInCart.map(product =>
                     /******************* Single product row *******************/
                        <div className="columns single-row margin-0">
                         {/******************* Product image *******************/}
                        <div className="column is-2 image">
                            <Link to={`/proizvod/${product.categorySlug}/${product.brandSlug}/${product.slug}`}>
                                <img src={process.env.REACT_APP_FILES_DESTINATION+product.image}  alt="Product"/>
                            </Link> 
                        </div>

                         {/******************* Product title, price, variation *******************/}
                        <div className="column is-5 product-info">
                            <Link to={`/proizvod/${product.categorySlug}/${product.brandSlug}/${product.slug}`}>
                                <h1 className="title">{product.title}</h1>
                            </Link>
                            {console.log(product)}
                            {(product.productAction !== 1 && product.productOffer !== 1) && (
                                <p className="single-price">Cijena: <span>{formatMoney(product.price)}</span>
                             {/*  {(product.points >=5 || product.points === 0) && ( <span className="points"> ({product.points} bodova vjernosti)</span>)}
                               {product.points < 5 && product.points > 1 && ( <span className="points"> ({product.points} boda vjernosti)</span>)}
                            {product.points === 1 && ( <span className="points"> ({product.points} bod vjernosti)</span> )} */}
                                </p>
                            )}
                             {(product.productAction === 1 || product.productOffer === 1)  && (
                                <p className="single-price">Cijena: <span>{formatMoney(product.price)}</span>
                               {/*  {(product.points >=5 || product.points === 0) && ( <span className="points"> ({product.points} bodova vjernosti)</span>)}
                                {product.points < 5 && product.points > 1 && ( <span className="points"> ({product.points} boda vjernosti)</span>)}
                                  
                                {product.points === 1 && ( <span className="points"> ({product.points} bod vjernosti)</span> )} */}
                                {(Number(product.oldPrice)-product.price !== 0) && ( <span class="old-price">{formatMoney(product.oldPrice)}</span>)} 
                                </p>
                            )}
                            {product.productVariety === 1 && (
                                <p className="variation">{product.productVariationType}: <span>{product.productVariationName}</span></p>
                            )}
                        </div>

                        {/******************* Product quantity *******************/}
                        <div className="column is-2 quantity">
                            <label className="field">
                                <span>Količina:</span> 
                                <input type="number" name={"quantity_"+product.id} min="1" max="100"
                                defaultValue={product.quantity} onChange={this.handleChangeQuantity} 
                                />
                            </label>
                           
                        </div>

                         {/******************* Product total price *******************/}
                        <div className="column is-2 price">
                            <h4>{formatMoney(product.price*product.quantity)}</h4>
                            <span onClick={this.updateProductInCart.bind(this, product.id, product.productVariety, product.productVariationName, product.price,product.points, product.title)} className="update-quantity">Ažurirajte</span>
                        </div>

                         {/******************* Product delete box *******************/}
                        <div className="column is-1 delete-box">
                            <div className="inner">
                               
                                <button type="submit" className="delete" onClick={this.togglePopupDelete.bind(this, product.id, product.productVariety, product.productVariationName, product.title, product.price, product.quantity, product.points)}></button> 
                            </div> 
                        </div>
                    </div>/* COLUMNS SINGLE ROW END */
                    )} 
                    </>
                    )}

                    {/******************* If cart is empty *******************/}
                    {this.state.productsQuantity === 0 && (
                    <>
                        <p className="empty-cart">Vaša korpa je trenutno prazna.</p>
                        <div className="button-area">
                            <Link to={homeUrl} className="continue">Krenite u kupovinu <span className="icon"></span></Link>
                        </div>
                    </>
                     )}

                    {/******************* If cart has products *******************/}
                    {this.state.productsQuantity !== 0 && (
                    <>
                        <div className="sum">
                            <h1>Ukupno: <span><b>{formatMoney(this.state.totalPrice)}</b></span></h1>
                        {/*  <h4>Ukupan broj bodova vjernosti: <span><b>{this.state.totalPoints}</b></span></h4>*/}
                        </div>

                        <div className="button-area">
                            <Link to={orderUrl} className="button checkout"><span className="icon"></span>Plaćanje</Link>
                        </div>
                        <div className="button-area">
                            <Link to={homeUrl} className="continue">Nastavite sa kupovinom <span className="icon"></span></Link>
                        </div>
                    </>
                    )} 
                    <div className="support-box">
                        <div className="inner">
                            <span className="icon help"></span>
                            <div className="content-box">
                                <p>Kontakt podrška</p>
                                <a href="tel:+387 61 93 93 48">+387 61 93 93 48</a>
                                <a href="mailto:info@zdravo.ba">info@zdravo.ba</a>
                            </div>
                        </div>
                    </div>
                    </div>{/* CONTAINER ENDS */}
                </section>{/* SECTION CHECKOUT END */}
                </div>
        );
    }
}

export default withGlobalState(Checkout)