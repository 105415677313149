/*
About Us page
Display static page with informations about us
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import {siteTitle} from '../../../functions/config'
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import Product from '../product/Product'
import ProductPopup from '../product/ProductPopup'
var togglePopupBox, productDataForPopup;

class LandingPage extends React.Component{

    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            title: '',
            description: '',
            showPopupClass: '',
            popupTitle: '',
            popupPrice: '',
            popupOldPrice: '',
            popupImage: '',
            popupSlug: '',
            popupCategory: 'empty',
            productId: 'empty',
            products: [],
            loading:false,
        }

        togglePopupBox	= this.togglePopupBox.bind(this);
        productDataForPopup = this.productDataForPopup.bind(this);
        this.hidePopupBox = this.hidePopupBox.bind(this);     
    }

togglePopupBox (arg) {
    if (this.state.showPopupClass === '') {
        this.setState({
            showPopupClass: arg
        })
        //   setTimeout(function(){
        //       this.setState({
        //           showPopupClass: ''
        //       })
        //  }.bind(this),7500);
    } 
    }

//Function: hide popup box after adding new product in cart
hidePopupBox (arg){
    this.setState({
        showPopupClass: arg
    })
    }


//Function: pass product data to popup
productDataForPopup (title,slug, category, price,oldPrice,image,productId, quantity ){
    this.setState({
        popupImage: image,
        popupPrice: price,
        popupOldPrice: oldPrice,
        popupSlug: slug,
        popupTitle: title,
        popupCategory: category,
        productId: productId,
        popupQuantity: 1,
    })
}

//Function: show popup box after adding new product in cart
togglePopupBox (arg) {
    if (this.state.showPopupClass === '') {
        this.setState({
            showPopupClass: arg
        })
     //   setTimeout(function(){
     //       this.setState({
     //           showPopupClass: ''
     //       })
     //  }.bind(this),7500);
    } 
  }

//Function: hide popup box after adding new product in cart
hidePopupBox (arg){
    this.setState({
        showPopupClass: arg
    })
  }


//Function: pass product data to popup
productDataForPopup (title,slug, category, price,oldPrice,image,productId, quantity, prAvailable ){
    this.setState({
        popupImage: image,
        popupPrice: price,
        popupOldPrice: oldPrice,
        popupSlug: slug,
        popupTitle: title,
        popupCategory: category,
        productId: productId,
        popupQuantity: 1,
        prAvailable,
    })
}


getPage(){
    var productsArray = [];
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/pages/edit-page/'+this.props.match.params.slug)
        .then((response) => {
            console.log(JSON.parse(response.data.products))
            const {title, description, image, products, status} = response.data;
            if(status === "Objavljeno"){
                this.setState({
                    title,
                    description,
                    image
                })
                JSON.parse(products).forEach(product => {
                    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/edit-product/'+product.slug)
                    .then((response) => {
                        const { title, slug, price, image_thumb, priceAction, category, productOffer, productAction, actionPercent,
                            deliverer, productVariety, brand, vele_price, markup, available,purpose } = response.data[0];
                        productsArray.push({
                            key: response.data._id,
                            title,
                            slug, 
                            price,
                            image_thumb,
                            priceAction,
                            actionPercent,
                            category,
                            productOffer,
                            productAction,
                            deliverer,
                            productVariety,
                            brand,
                            markup,
                            vele_price,
                            available,
                            purpose,
                            productCategorySlug: response.data[0].categoryData[0].slug,
                            productBrandName: response.data[0].brandData[0].slug,
                            brandDiscount: response.data[0].brandData[0].discount,
                            productBrandFullName: response.data[0].brandData[0].name,
                            productBrandLogo: response.data[0].brandData[0].image,
                        });
                        this.setState({
                            products: productsArray,
                        })
                });
            })
            
        }
        })
}
  
//Function: update single product data when product is opened
singleProductData(productSlug){}


componentDidMount() {
    this.getPage()
}

    render(){
        const {title, description, products, image} = this.state;
        window.scrollTo(0,0); 
        return(
            <ShopWrapper>
               <Helmet>
                    <title>{title} &rsaquo; {siteTitle}</title>
              </Helmet>
                <section id="p_page" className="section landing-page static-page">
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={title} />

                        {/******************* Content *******************/}
                        <div className="content">
                        {image !== null && image !== "empty" && image !== "" && (
                            <div className="image">
                                <img src={process.env.REACT_APP_FILES_DESTINATION+image} />
                            </div>
                            )}
                           <div className="text-content">
                            {ReactHtmlParser(description)}
                           </div>
                        </div>
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}

                <section className="section">
                    <div className="container">
                        <div className="columns inner products-slider is-multiline">
                            {this.state.showPopupClass === 'show' && (
                                <ProductPopup
                                title={this.state.popupTitle}
                                slug={this.state.popupSlug}
                                category={this.state.popupCategory}
                                price={this.state.popupPrice}
                                oldPrice={this.state.popupOldPrice}
                                image={this.state.popupImage}
                                productId={this.state.productId}
                                popupQuantity={this.state.popupQuantity}
                                prAvailable={this.state.prAvailable}
                                showClass={this.state.showPopupClass}
                                hidePopupBox = {this.hidePopupBox.bind(this)} />
                                    )}

                                {products.map(product =>
                                    <div className="column is-one-fifth single">
                                    {/******************* Product popup *******************/}
                                
                                
                                {/******************* Call product component *******************/}
                                    <Product product={product} 
                                    key={product.key} 
                                    togglePopupBox = {togglePopupBox.bind(this)} 
                                    productDataForPopup={productDataForPopup.bind(this)}
                                    singleProductData={(productSlug) => this.singleProductData(productSlug)}
                                    />
                                    </div>
                                    )}
                                </div>
                    </div>
                </section>
            </ShopWrapper>
        )
    }
}

export default LandingPage