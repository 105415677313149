/*
Header component
Display header on each page
*/
import React from 'react'
import { BrowserRouter, Link, withRouter } from 'react-router-dom'
import logo from '../../static/zdravo-logo.png'
import formatMoney from '../../functions/formatMoney'
import Sticky from 'react-stickynode';
import SearchInput, {createFilter} from 'react-search-input'
import { homeUrl, privacyPolicyUrl, cartUrl, weekOfferUrl, actionUrl, contactUrl, productUrl, categoryUrl, loginUrl, registerUrl } from '../../functions/pageUrls';
import axios from 'axios';
import jwt_decode from 'jwt-decode'
import { withGlobalState } from 'react-globally'

var slugify = require('slugify')
var latinize = require('latinize');
const KEYS_TO_FILTERS = ['title', 'title_latinize', 'brand'];
var cartId = "empty", productsInCart = []; 
class Header extends React.Component{
    state = {
        items: [],
        loading: false,
        
    };
constructor () {

    super()
    this.unsubscribe = null;
    
    this.state = {
        menuCategories: [],
        cartBoxClass: '',
        categorySlug: '',
        counter: 1,
        price: 0,
        totalPrice: 0,
        productsQuantity: 0,
        productsOnActions: [],
        products: [],
        searchTerm: '',
        displayResults: false,
        showBigMenuClass: '',
        userExist: false,
        userId: '',
        userName: '',
        userSurname: '',
    }
  this.searchUpdated = this.searchUpdated.bind(this);
  this.enterPressed = this.enterPressed.bind(this);
  this.backToTop = this.backToTop.bind(this);
  this.toggleCartBox = this.toggleCartBox.bind(this);
  this.toggleBigMenu = this.toggleBigMenu.bind(this);
  this.setWrapperRef = this.setWrapperRef.bind(this);
  this.handleClickOutside = this.handleClickOutside.bind(this);
  this.updateGlobalProductSlug = this.updateGlobalProductSlug.bind(this);
  this.updateGlobalSearchSlug = this.updateGlobalSearchSlug.bind(this);
  }


//Function: Show and hide mini cart in header
  toggleCartBox() {
    if (this.state.cartBoxClass === '') {
        this.setState({
            cartBoxClass: 'show'
        })
       }
      else {
       this.setState({
        cartBoxClass: '',
       })
      } 
  }

//Function: Show and hide main menu on mobile devices
  toggleBigMenu () {
    if (this.state.showBigMenuClass === '') {
        this.setState({
            showBigMenuClass: 'show'
        })
        window.scrollTo(0, 0);
    }
    else{
        this.setState({
            showBigMenuClass: ''
        })
    }     
  }

getTotalPrice(){
  //Get total price and cart products quantity for mini cart from carts table in database
  if(localStorage.cartId)
  {
      cartId = localStorage.cartId;
      var totalPriceInner, productsQuantityInner;  
      axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/get-cart/' + cartId)
        .then((response) => {
    if(response.data !== null){
      var totalPrice, products;   
      totalPrice = response.data.totalPrice;
      products = JSON.parse(response.data.products);
      totalPriceInner = totalPrice;
      productsQuantityInner = products.length
      this.setState({
          totalPrice: totalPriceInner,
          productsQuantity: productsQuantityInner
      })
    }
  });
  if(cartId === 'empty'){
      this.setState({
          totalPrice: 0,
          productsQuantity: 0
      })
      productsInCart = [];
  } 
}  

}

updateGlobalProductSlug(){
    if(this.props.globalState.changeProductSlug === true){
        this.state.productSlug = this.props.globalState.singleProductSlug;
        this.props.setGlobalState({
            changeProductSlug: false,
          })
    }
}
//Function: collect cart data for mini cart
getCartData() {
    if(localStorage.cartId)
    {
        cartId = localStorage.cartId;
        var productsInner;  
        const productsArray = [];  
            axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/action')
        .then((response) => {
        response.data.forEach((doc) => {
            productsArray.push({
            key: doc._id,
            });          
        });
        this.setState({
            productsOnActions: productsArray,
        })
    });

        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/get-cart/' + cartId)
        .then((response) => {
            if(response.data !== null){ 
        var products;   
    
        products = JSON.parse(response.data.products);
        productsInCart = []; 
        productsInner = products 
        if(productsInner === undefined){
            localStorage.removeItem('cartId');
        }
        else{
            Object.keys(productsInner).forEach(function(id) {
                productsInCart.push(productsInner[id]);
            });   
            console.log(productsInCart)
        }
        Object.keys(productsInCart).forEach(function(id) {
            if(productsInCart[id].productOffer === 'Da'){
               
            }
         }); 

         productsInCart.map(product => {
            axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/single-category-id/'+product.category)
            .then((response) => {
                product.category = response.data.slug;       
            });
        })

        productsInCart.map(product => {
            axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/brands/single-brand-id/'+product.brand)
            .then((response) => {
                product.brand = response.data.slug;       
            });
        })
    }
    else{
        localStorage.removeItem('cartId');
    }
    });
}


}

//Function: update single product data
singleProductData(productSlug, title){
    this.props.setGlobalState({
        singleProductSlug: productSlug,
        changeProductSlug: true,

      })
    this.setState({
         productSlug: productSlug,
         searchTerm: "",
         displayResults: false,
        }); 
    
    this.componentDidMount(); 
    }  

setWrapperRef(node) {
    this.wrapperRef = node;
    }

    handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        setTimeout(function(){
            this.setState({
                displayResults: false,
            }) 
        }.bind(this),200);
        
    }
}

handleLogout(){
}

backToTop() {
    window.scrollTo(0,0); 
}

enterPressed(event) {
    var code = event.keyCode || event.which;
    if(code === 13) { //13 is the enter keycode
        this.props.history.push(`/pretraga/${slugify(this.state.searchTerm, {
            replacement: '-',
            remove: null,
            lower: true
          })}`)
    } 
    this.setState({
        displayResults: false,
    })
}

updateGlobalSearchSlug(){
    this.setState({
        displayResults: false,
    })
    this.props.setGlobalState({
        changeSearchTerm: true,
      })
}



getCategoryList() {  
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories')
.then((response) => {
        this.setState({
    menuCategories: response.data
    });
})
.catch((error) => {
    console.log(error);
})
}

getProductList(){
    const products = [] ;
    //Collect products
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products')
    .then((response) => {
        response.data.forEach((doc) => {
            const { id, title, brand, slug, category, price, image_thumb } = doc;
            products.push({
            key: doc._id,
            title: title,
            title_latinize: latinize(title),
            slug,
            brand: doc.brandData[0].slug,
            category: doc.categoryData[0].slug,
            price,
            image_thumb,
            });
        });
        this.setState({
            products,
            
        });
    });
}

componentDidMount = () => {
    this.getCategoryList();
    this.getProductList();
    this.getCartData();
    this.getTotalPrice();
    document.addEventListener('mousedown', this.handleClickOutside);
    if(localStorage.getItem('usertoken')){
        const token = localStorage.getItem('usertoken');
        const decoded = jwt_decode(token)
        console.log(decoded)
        if(token){
            this.setState({
                userExist: true,
                userName: decoded.name,
                userSurname: decoded.surname,
                email: decoded.email
              })
        }
    }
}

componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

    render(){
        if(this.props.globalState.changeHeaderCart === true){
            setTimeout(function(){
                this.getTotalPrice();
                this.getCartData();
                this.props.setGlobalState({
                    changeHeaderCart: false,
                  })
            }.bind(this),800);
          
        }
    const filteredProducts = this.state.products.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))

   
    return(
    <>

    <Sticky enabled={true} top={0} bottomBoundary={0} innerZ={999}>
    <section id="header" className="section header desktop padding-0">
 
    {/******************* Top header *******************/}
        <div className="top">
            <div className="container">
            <div className="columns margin-0">

            {/******************* Logo *******************/}
                <div className="column is-2 padding-0 logo">
                    <Link to={homeUrl}  onClick={this.backToTop.bind(this)}><img src={logo} /></Link>
                </div>{/* LOGO END */}
                <div className="viber-call" title="Kontaktirajte nas putem Vibera">
                <i></i><a href="tel:+38761939348" >061 93 93 48</a>
                </div>
                <div className="padding-0 cart-box is-right">

                {/******************* Informations menu *******************/}
                {!this.state.userExist && (<Link to={"/"+loginUrl} className="user-button login">Prijava<span className="icon"></span></Link>)}
                {(this.state.userExist && this.state.userName !== "") && (<Link to={"/moj-racun"} className="user-button my-account">{this.state.userName}<span className="icon"></span></Link>)}
              

                {/******************* Cart button and mini cart *******************/}
                    <div className="inner" onClick={this.toggleCartBox}>
                        <p className="quantity"><span>{this.state.productsQuantity}</span></p>
                         <p className="price">{formatMoney(this.state.totalPrice)}</p>   
                    </div> 
                </div>{/* CART BOX END */}
                {localStorage.cartId && cartId !== 'empty' && (
                <div className={"cart-header " + this.state.cartBoxClass}>
                    <div className="cart-top">
                        <p className="added-successfully is-left">Vaša korpa</p>
                        <span className="close-cart is-right"  onClick={this.toggleCartBox}>Zatvori <i></i></span>
                    </div>
                    <div className="inner">
                    {productsInCart.map(product => 
                    <div className="single-row">
                    <div className="image">
                    <img src={process.env.REACT_APP_FILES_DESTINATION+product.image} alt="Product" />
                    </div>
                    <div className="content">
                    <Link to={`/${productUrl}/${product.category}/${product.brand}/${product.slug}`}>
                    <h4 className="title" onClick={() => this.singleProductData(product.slug, product.title)}>{product.title}</h4></Link>
                        {(product.productAction !== 1 && product.productOffer !== 1) && (
                            <p className="price">{formatMoney(product.price)}</p>
                        )}
                        {(product.productAction === 1 || product.productOffer === 1) && (
                             <>
                                <p className="price">{formatMoney(product.price)}</p>
                                <p className="old-price">{formatMoney(product.oldPrice)}</p>
                             </>
                        )}
                    </div>
                    <span className="quantity">{product.quantity}</span>
                </div>
                    )}
                    </div>
                <div className="button-area">
                            <div className="total-price">
                                <span>Ukupno za platiti</span>
                                <h4>{formatMoney(this.state.totalPrice)}</h4>
                            </div>
                            <Link to={"/"+cartUrl} className="button default">Posjetite korpu</Link>
                        </div>
                </div>
                )}
                {this.state.productsQuantity === 0 && (
                <div className={"cart-header " + this.state.cartBoxClass}>
                    <div className="cart-top">
                        <p className="added-successfully is-left">Vaša korpa</p>
                        <span className="close-cart is-right"  onClick={this.toggleCartBox}>Zatvori <i></i></span>
                    </div>
                            <p className="no-products">Trenutno nemate nijedan proizvod u vašoj korpi.</p>
                <div className="button-area">
                        <div className="total-price">
                            <span>Ukupno za platiti</span>
                            <h4>0 KM</h4>
                        </div>
                        <Link to={"/"+cartUrl} className="button checkout">Posjetite korpu</Link>
                    </div>
                </div>
                )}
            </div>  {/*COLUMNS END */} 
                {/* <Cart /> */}
            </div>  {/* CONTAINER END */}
        </div>{/* TOP HEADER END */}

        {/******************* Main Header *******************/}
        <div className="main">
        <div className="container">
            <div className="columns">

            {/******************* Category button and menu *******************/}
            <ul className="column is-2 category-menu">
            <li><p className="category-icon"><span>Kategorije proizvoda</span></p>
                <ul className="first-child">
                    {this.state.menuCategories.map(category =>
                    <>
                    {category.parentCategory === null && category.showInMenu === 1 && (
                    <li>
                        <Link to={`/${categoryUrl}/${category.slug}`} onClick={() => this.props.headerCategoryData(category.slug, undefined, category.category_id, undefined)} className={category.color + " " + category.slug + " link-first-child"}>
                    {category.name}
                        </Link>
                        <ul className="second-child">
                            {this.state.menuCategories.map(subCategory =>
                            <>
                            {subCategory.parentCategory === category.category_id && (
                            <li>
                                <Link to={`/${categoryUrl}/${category.slug}/${subCategory.slug}`} onClick={() => this.props.headerCategoryData(category.slug, subCategory.slug, category.category_id, subCategory.category_id)} className={subCategory.color + " " + subCategory.slug}>
                            {subCategory.name}
                                </Link>
                                
                            </li>
                            )}
                            </>
                            )}
                        </ul>
                    </li>
                    )}
                    </>
                    )}
                </ul>
            </li>
        </ul>

        {/******************* Search bar *******************/}
        <div className="column is-5 padding-0 search-bar">
                <div className="ref">
                    <div className="inner">
                        <label>                    
                            <SearchInput className="search-input" onChange={this.searchUpdated} value={this.state.searchTerm} onKeyPress={this.enterPressed.bind(this)} placeholder="Unesite pojam za pretraživanje "/>
                        </label>
                    <Link to={`/pretraga/${slugify(this.state.searchTerm, {
        replacement: '-',
        remove: null,
        lower: true
      })}`} onClick={this.updateGlobalSearchSlug.bind(this)} className="search-button"  area-label="Search"></Link>
                    </div>
                    {this.state.displayResults && (
                    <section className="section search-results padding-0">
                        <div className="container">
                            <div className="columns">
                                <div className="column is-2 padding-0"></div>
                                <div className="column is-5 content padding-0" ref={this.setWrapperRef}>
                                <div className="inner-content">
                                {filteredProducts.slice(0,5).map(product =>
                                    <Link to={`/${productUrl}/${product.category}/${product.brand}/${product.slug}`} className="single-row" onClick={() => this.singleProductData(product.slug, product.title)}>
                                    <img src={process.env.REACT_APP_FILES_DESTINATION+product.image_thumb} alt="Product" />
                                    <div className="content">
                                        <h2 className="title">{product.title}</h2>
                                        <span className="price">{formatMoney(product.price)}</span>
                                    </div>
                                    </Link>
                                )}
                                {filteredProducts.length === 0 &&(
                                    <p className="no-results">Nema pronađenih rezultata.</p>
                                )}
                                <div className="advanced-search">
                                    <Link to={`/pretraga/${slugify(this.state.searchTerm, {
            replacement: '-',
            remove: null,
            lower: true
          })}`} onClick={this.updateGlobalSearchSlug.bind(this)}>Pogledajte sve rezultate ({filteredProducts.length})</Link>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    )}
                    </div>
                </div>{/* SEARCH BAR END */}

    {/******************* Main navigation *******************/}                 
     <ul className="column is-5 navbar">
            <li><Link to="/brendovi">Brendovi</Link></li>
            <li><Link to={"/"+weekOfferUrl} className="actions">Top ponude</Link>
              {/*  <ul>
                    <li><Link to={"/"+weekOfferUrl} className="week-offer">Ponuda sedmice</Link></li>
                    <li><Link to={"/"+actionUrl} className="other-actions">Ostale akcije</Link></li>
                </ul>
              */}
            </li>
           {/* <li><Link to="/brendovi">Brendovi</Link></li>*/}
            <li><a href="https://modroizeleno.com" target="_blank">Modro & Zeleno</a></li>
            <li><Link to={"/"+contactUrl} className="contact">Kontakt</Link></li>
            </ul>{/* NAVBAR END */}
        </div>
        </div>
        </div>{/* MAIN HEADER END */}
    </section>{/* HEADER SECTION DESKTOP END */}
    </Sticky>


{/******************* Mobile navigation(cloned) *******************/}
 <section id="header_mobile" className="section header mobile padding-0">
 {/******************* Top header *******************/}
 <div className="top-header">
 <div className="container">

 {/******************* Logo *******************/}
     <div className="logo">
         <Link to={homeUrl} onClick={this.backToTop.bind(this)}><img src={logo} /></Link>
     </div>

{/******************* Car button and mini cart *******************/}
     <div className="menu is-right">
     <div className="cart-box is-right">
         <div className="inner" onClick={this.toggleCartBox}>
             <p className="quantity"><span>{this.state.productsQuantity}</span></p>
                 <p className="price">{formatMoney(this.state.totalPrice)}</p>   
         </div> 
     </div>{/* CART BOX END */}
     {localStorage.cartId && cartId !== 'empty' && (
                <div className={"cart-header " + this.state.cartBoxClass}>
                    <div className="cart-top">
                        <p className="added-successfully is-left">Vaša korpa</p>
                        <span className="close-cart is-right"  onClick={this.toggleCartBox}>Zatvori <i></i></span>
                    </div>
                    <div className="inner">
                    {productsInCart.map(product => 
                    <div className="single-row">
                    <div className="image">
                    <img src={process.env.REACT_APP_FILES_DESTINATION+product.image} alt="Product" />
                    </div>
                    <div className="content">
                    <Link to={`/${productUrl}/${product.category}/${product.brand}/${product.slug}`}>
                    <h4 className="title" onClick={() => this.singleProductData(product.slug, product.title)}>{product.title}</h4></Link>
                        {(product.productAction !== 1 && product.productOffer !== 1) && (
                            <p className="price">{formatMoney(product.price)}</p>
                        )}
                        {(product.productAction === 1 || product.productOffer === 1) && (
                             <>
                                <p className="price">{formatMoney(product.price)}</p>
                                <p className="old-price">{formatMoney(product.oldPrice)}</p>
                             </>
                        )}
                    </div>
                    <span className="quantity">{product.quantity}</span>
                </div>
                    )}
                    </div>
                <div className="button-area">
                            <div className="total-price">
                                <span>Ukupno za platiti</span>
                                <h4>{formatMoney(this.state.totalPrice)}</h4>
                            </div>
                            <Link to={"/"+cartUrl} className="button checkout">Posjetite korpu</Link>
                        </div>
                </div>
                )}
                {this.state.productsQuantity === 0 && (
                <div className={"cart-header " + this.state.cartBoxClass}>
                    <div className="cart-top">
                        <p className="added-successfully is-left">Vaša korpa</p>
                        <span className="close-cart is-right"  onClick={this.toggleCartBox}>Zatvori <i></i></span>
                    </div>
                            <p className="no-products">Trenutno nemate nijedan proizvod u vašoj korpi.</p>
                <div className="button-area">
                        <div className="total-price">
                            <span>Ukupno za platiti</span>
                            <h4>0 KM</h4>
                        </div>
                        <Link to={"/"+cartUrl} className="button checkout">Posjetite korpu</Link>
                    </div>
                </div>
                )}
         <button className="button nav-button-open"  onClick={this.toggleBigMenu.bind(this)}></button>
     </div>
 </div>

 {/******************* Main menu with categories and pages *******************/}
 <div className={"big-mobile-menu " + this.state.showBigMenuClass}>
        <button className="button nav-button-close"  onClick={this.toggleBigMenu.bind(this)}></button>

        <ul className="category-menu">
            <li><p className="category-icon"><span>Kategorije proizvoda</span></p>
                <ul className="first-child">
                    {this.state.menuCategories.map(category =>
                    <>
                    {category.parentCategory === null && category.showInMenu === 1 && (
                    <li>
                        <Link to={`/${categoryUrl}/${category.slug}`} onClick={() => {this.props.headerCategoryData(category.slug, undefined); this.state.showBigMenuClass=""}} className={category.color + " " + category.slug}>
                    {category.name}
                        </Link>
                     {/*   <ul className="second-child">
                            {this.state.menuCategories.map(subCategory =>
                            <>
                            {subCategory.parentCategory === category.slug && (
                            <li>
                                <Link to={`/${categoryUrl}/${subCategory.parentCategory}/${subCategory.slug}`} onClick={() => this.props.headerCategoryData(category.slug, subCategory.slug)} className={subCategory.color + " " + subCategory.slug}>
                            {subCategory.name}
                                </Link>
                                
                            </li>
                            )}
                            </>
                            )}
                            </ul> */}
                    </li>
                    )}
                    </>
                    )}
                </ul>
            </li>
        </ul>

        <ul className="navbar">
            <li><Link to={"/brendovi"}>Brendovi</Link></li>
            <li><Link to={"/"+weekOfferUrl}>Top ponude</Link></li>
            <li><a href="https://modroizeleno.com" target="_blank">Modro & Zeleno</a></li>
            <li><Link to={"/"+contactUrl}>Kontakt</Link></li>
        </ul>{/* NAVBAR END */}

    {!this.state.userExist && (<Link to={"/"+loginUrl} className="user-button login">Prijava<span className="icon"></span></Link>)}
    {this.state.userExist && (<Link to={"/moj-racun"} className="user-button my-account">{this.state.userName}<span className="icon"></span></Link>)}
    
 </div>
 </div>
 <div className="viber-call">
<i></i><a href="tel:+38761939348" >061 93 93 48</a> 
</div>

 {/******************* Search bar *******************/}
 <div className="search-bar">
    <div className="container">
        <div ref={this.setWrapperRef}>
            <div className="inner">
            <label>  
                <SearchInput className="search-input" onChange={this.searchUpdated} value={this.state.searchTerm} placeholder="Unesite pojam za pretraživanje"/>
            </label>
            <Link  to={`/pretraga/${slugify(this.state.searchTerm, {
                        replacement: '-',
                        remove: null,
                        lower: true
                    })}`}  onClick={this.updateGlobalSearchSlug.bind(this)} className="search-button" area-label="Search"></Link>
            </div>
            {this.state.displayResults && (
            <section className="section search-results padding-0">
                <div className="container">
                    <div className="columns">
                        <div className="column is-2 padding-0"></div>
                        <div className="column is-5 content padding-0">
                        <div className="inner-content">
                        {filteredProducts.slice(0,5).map(product =>
                            <Link to={`/${productUrl}/${product.category}/${product.brand}/${product.slug}`} className="single-row" onClick={() => this.singleProductData(product.slug, product.title)}>
                            <img src={process.env.REACT_APP_FILES_DESTINATION+product.image_thumb} alt="Product" />
                            <div className="content">
                                <h2 className="title">{product.title}</h2>
                                <span className="price">{formatMoney(product.price)}</span>
                            </div>
                            </Link>
                        )}
                        {filteredProducts.length === 0 &&(
                            <p className="no-results">Nema pronađenih rezultata.</p>
                        )}
                        <div className="advanced-search">
                        <Link to={`/pretraga/${slugify(this.state.searchTerm, {
                        replacement: '-',
                        remove: null,
                        lower: true
                    })}`} onClick={this.updateGlobalSearchSlug.bind(this)}>Pogledajte sve rezultate ({filteredProducts.length})</Link>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            )}
        </div>
    </div>
 </div>
</section>{/* HEADER SECTION MOBILE END */}

</>
    )
    }

    searchUpdated (term) {
        this.setState({
            searchTerm: term,
        })
        if(term.length > 2){
            this.setState({
                displayResults: true,
            })
        }
        else{
            this.setState({
                displayResults: false,
            }) 
        }
      }
}


export default withRouter(withGlobalState(Header));