import React from 'react'

class Sticker extends React.Component{
    render(){
        return(
           <div className={"sticker "+ this.props.stickerType}>
            <div className="inner"><div className="text-box"><p dangerouslySetInnerHTML={{__html: this.props.stickerMessage}} /></div>
            <div className="icon-box is-right"><i className="icon"></i></div></div>
        </div>
        );
    }
}

export default Sticker