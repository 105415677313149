import React from 'react'

class MainTitle extends React.Component{
    render(){
        return(
            <div className="title-box-main">
                <h4 className="title">{this.props.title}</h4>
            </div>/* TITLE BOX MAIN END */
        )
    }
}

export default MainTitle