/*/
Const: define urls for all pages
*/

const 
cartUrl = "korpa", 
orderUrl = "narudzba", 
productUrl = "proizvod",
privacyPolicyUrl = "zastita-privatnosti",
homeUrl = "/",
generatedOrderUrl = "generisana-narudzba",
categoryUrl = "kategorija",
weekOfferUrl = "ponuda-sedmice",
actionUrl = "akcije",
aboutUsUrl = "o-nama",
contactUrl = "kontakt",
shippingUrl = "dostava",
paymentOptionsUrl = "nacini-placanja",
faqUrl = "cesto-postavljena-pitanja",
termsOfUseUrl = "uslovi-koristenja",
registerUrl = "registracija",
loginUrl = "prijava"

export {cartUrl, orderUrl, productUrl, privacyPolicyUrl, homeUrl, generatedOrderUrl, 
    categoryUrl, contactUrl, weekOfferUrl, actionUrl, aboutUsUrl, shippingUrl, faqUrl, paymentOptionsUrl, termsOfUseUrl,
registerUrl, loginUrl};