/*
Category list component for homepage
Display products from selected category on homepage (3 times)
*/
import React from 'react'
import Product from '../product/Product'
import ProductPopup from '../product/ProductPopup'
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import {categoryUrl} from '../../../functions/pageUrls'
import axios from 'axios';

var togglePopupBox, productDataForPopup;
class CategoryList extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            catImage: '',
            catColor: '',
            catName: '',
            catSlug: '',
            showPopupClass: '',
            popupTitle: '',
            popupPrice: '',
            popupOldPrice: '',
            popupImage: '',
            popupSlug: '',
            popupCategory: 'empty',
            productId: 'empty',
            weekOffer: 30,
            products: [],
            categoryDisplayed: parseInt(this.props.categoryDisplayed),
        }
        togglePopupBox	= this.togglePopupBox.bind(this);
        productDataForPopup = this.productDataForPopup.bind(this);
        this.hidePopupBox = this.hidePopupBox.bind(this);       
    }

//Function: show popup box after adding new product in cart
togglePopupBox (arg) {
    if (this.state.showPopupClass === '') {
        this.setState({
            showPopupClass: arg
        })
     //   setTimeout(function(){
     //       this.setState({
     //           showPopupClass: ''
     //       })
     //  }.bind(this),7500);
    } 
  }

//Function: hide popup box after adding new product in cart
hidePopupBox (arg){
    this.setState({
        showPopupClass: arg
    })
  }

//Function: pass product data to popup
productDataForPopup (title,slug, category, price,oldPrice,image,productId, quantity, prAvailable ){
    this.setState({
        popupImage: image,
        popupPrice: price,
        popupOldPrice: oldPrice,
        popupSlug: slug,
        popupTitle: title,
        popupCategory: category,
        productId: productId,
        popupQuantity: 1,
        prAvailable,
    })
}


singleProductData(productSlug){}

getCategoryData(){
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/single-category-id/'+this.state.categoryDisplayed)
.then((response) => { 
    const { color, image, name, slug } = response.data;
    console.log(response.data)
        this.setState({
            catColor: color,
            catImage: image,
            catName: name,
            catSlug: slug,
        })  
    this.setState({
        currentSubCategory: response.data.name,
    })
}).catch((error) => {
    console.log(error);
})
}

getProductsList() {  
    const productsArray = [];
    
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/category-homepage/'+this.state.categoryDisplayed)
        .then((response) => {
        response.data.forEach((doc) => {
            const { title, slug, price, image_thumb, priceAction, category, productOffer, productAction, actionPercent, 
                deliverer, productVariety, productVariations, brand, vele_price, markup, available,purpose } = doc;
                productsArray.push({
                key: doc._id,
                title,
                slug,
                price,
                image_thumb,
                priceAction,
                category,
                productOffer,
                actionPercent,
                productAction,
                deliverer,
                productVariety,
                productVariations,
                purpose,
                brand,
                markup,
                vele_price,
                available,
                productCategorySlug: doc.categoryData[0].slug,
                productBrandName: doc.brandData[0].slug,
                brandDiscount: doc.brandData[0].discount,
                productBrandFullName: doc.brandData[0].name,
                productBrandLogo: doc.brandData[0].image,
                });
        });
        this.setState({
            products: productsArray,
        })
    }).catch((error) => {
        console.log(error);
    })
}
    
componentDidMount = () => {
    this.getProductsList();
    this.getCategoryData();
}

    render(){
        //Attributes for slider
        var sliderSettings = {
            dots: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3500,
            pauseOnHover: true,
            lazyLoad: true,
            responsive: [
                {
                  breakpoint: 1023,
                  settings: {
                    slidesToShow: 3,
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 479,
                  settings: {
                    slidesToShow: 1,
                  }
                },
              ]
          };

const {catImage, catSlug, catName, catColor} = this.state;

        return(
            <>
          {this.state.products.length > 0 && (
            <section id={"s_category_1_"+this.state.categoryDisplayed} className="section category-main category-1 is-first">
                <div className="container">
                    <div className="columns margin-0">

                    {/******************* Area left with category informations *******************/}
                        <Link to={`/${categoryUrl}/${catSlug}`} className="column is-3 area-left">
                            <div className="inner-wrapper">
                                <div className="overlay"><div className={"inner " + catColor}></div></div>
                                <div className="title-box">
                                    <h1 className="title">{catName}</h1>
                                </div>
                                <span className="icon-category" style={{ backgroundImage: `url(${process.env.REACT_APP_FILES_DESTINATION+catImage})` }}></span>
                                <Link to={`/${categoryUrl}/${catSlug}`} className={"button load-category " + catColor}>Više</Link>
                            </div>
                        </Link>{/* AREA LEFT END */}

                        {/******************* Area right with products slider *******************/}
                        <div className="column is-9 area-right default-pagination">
                        
                        {/******************* Product popup *******************/}
                        {this.state.showPopupClass === 'show' && (
                           <ProductPopup
                           title={this.state.popupTitle}
                           slug={this.state.popupSlug}
                           category={this.state.popupCategory}
                           price={this.state.popupPrice}
                           oldPrice={this.state.popupOldPrice}
                           image={this.state.popupImage}
                           productId={this.state.productId}
                           popupQuantity={this.state.popupQuantity}
                           prAvailable={this.state.prAvailable}
                           showClass={this.state.showPopupClass}
                           hidePopupBox = {this.hidePopupBox.bind(this)} />
                        )}
                         

                            {/******************* Products slider *******************/}  
                            <div className="inner products-slider">
                                <Slider {...sliderSettings}>
                                {this.state.products.map(product =>
                                    <div className="is-3 single">

                                    {/******************* Call product component *******************/}
                                     <Product product={product} 
                                        key={product.key} 
                                        togglePopupBox = {togglePopupBox.bind(this)} 
                                        productDataForPopup={productDataForPopup.bind(this)}
                                        singleProductData={(productSlug) => this.singleProductData(productSlug)}
                                        />
                                    </div>
                                    )} 
                                </Slider>
                            </div>
                            
                        </div>{/* AREA RIGHT END */}
                    </div>{/* COLUMNS END */}
                </div>{/*CONTAINER END */}
            </section>/*SECTION COSMETICS END */
          )}
          </>
        );
    }
}

export default CategoryList