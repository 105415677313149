import React from 'react'
import {Link} from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import Pagination from '../Pagination';
import {Helmet} from "react-helmet";
import Reactable from 'reactable';
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { months}  from '../../helpers/months'
var Table = Reactable.Table,
    Thead = Reactable.Thead,
    Th = Reactable.Th,
    Tr = Reactable.Tr;

var togglePopupDelete;
class Orders extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
          orders: [],
          filteredOrders: [],
          currentPage: 0,
          showPopupDeleteClass: '',
          showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
            singleOrderKey: '',
            singleOrderName: '',
            readedOrders: [],
            unreadedOrders: [],
            loading:false,
        };

        togglePopupDelete = this.togglePopupDelete.bind(this);
        
      }
    
    getOrderList(){
        const orders = [], readedOrders = [], unreadedOrders = [];
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders')
        .then((response) => {
            console.log(response.data)
        response.data.forEach((doc) => {
            const { order_id,orderName, name, surname, phone, totalPrice, charge, readedByAdmin, orderCreatedAt } = doc;
             orders.push({
            generatedOrderId: doc.generatedOrderId,
            key: doc._id,
            order_id,
            orderName,
            orderCreatedAt: orderCreatedAt.split('T')[0],
            name,
            surname,
            phone,
            totalPrice,
            charge,
            readedByAdmin,
          }); 
        })  
        this.setState({
            orders,
           
         }); 
        })
        .catch((error) => {
            console.log(error);
        })

        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders/readed-by-admin/'+1)
        .then((response) => {
        response.data.forEach((doc) => {
            readedOrders.push({
            id: doc.generatedOrderId,
            });
            });
            this.setState({
            readedOrders,
        });
        });

        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders/readed-by-admin/'+0)
        .then((response) => {
        response.data.forEach((doc) => {
            unreadedOrders.push({
            id: doc.generatedOrderId,
            });
            });
            this.setState({
                unreadedOrders,
        });
        });

    }
    
      componentDidMount = () => {
      this.getOrderList();
      }

      togglePopupDelete (id, name) {
        if (this.state.showPopupDeleteClass === '') {
            this.setState({
                showPopupDeleteClass: 'show',
                singleOrderKey: id,
                singleOrderName: name,
            })
        }
        else{
            this.setState({
                showPopupDeleteClass: ''
            })
        }     
      }


      deleteOrder(id){
        console.log("heheheh", id)
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders/delete-order/' + id)
        .then(() => {
            
            this.getOrderList();
          this.setState({
            showPopupDeleteClass: '',
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Uspješno ste izbrisali narudžbu "<b>' + this.state.singleOrderName + '"</b>',
        stickerType: 'success',
    })
        }).catch((error) => {
          this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Došlo je do problema prilikom brisanja proizvoda: ' + error,
        stickerType: 'danger',
    })
        });
    }

    orderOptions(key,generatedOrderId, orderName){
        return(
            <div className={"options-wrapper"}>
                <div className="inner">
                    <Link to={`/administracija/generisana-narudzba/${generatedOrderId}`} className="link" target="_blank"><FontAwesomeIcon icon="eye" /></Link>
                    <button onClick={this.togglePopupDelete.bind(this, key, orderName)} className="link"><FontAwesomeIcon icon="trash-alt" /></button>
                </div>
            </div>
        )
    }    

    statusColor(status){
        if(status === "Pogledano") 
        status = "readed";
        else
        status = "unreaded";
        return(
            <span className={status}></span>
        )
    }

    render(){
        var sortableOrders = [];
        {this.state.orders.map(order =>
            {
                console.log(order.readedByAdmin)
                if(order.readedByAdmin === 1){
                    order.readedByAdmin = "Pogledano"
                }
                if(order.readedByAdmin === 0){
                    order.readedByAdmin = "Nova narudžba"
                }
                var unformatedDate = order.orderCreatedAt.split('-');
                var formatedMonth ,formatedDate;
                months.forEach(month => {
                    if(month.value == unformatedDate[1])  {
                        formatedMonth = month.name;
                    }
                });
                formatedDate = unformatedDate[2] + " " + formatedMonth + ", " + unformatedDate[0];
                sortableOrders.push({
                    orderId: order.order_id,
                    orderName: order.orderName,
                    name: order.name + " " + order.surname, 
                    date: formatedDate,
                    totalPrice: formatMoney(order.totalPrice), 
                    charge: order.charge,
                    status: order.readedByAdmin,
                    options: this.orderOptions(order.key,order.generatedOrderId, order.orderName)
              })
            }
      )}
        return(
            <AdminWrapper>
            <section id="orders" className="section orders content-page-section default-table">
            {this.state.loading && (
                <div className="loader-box">
                    <span className="loader"></span>
                </div>
            )}

             <div className={"popup-wrapper " + this.state.showPopupDeleteClass}>
                    <div className="overlay-back"></div>
                    <div className="popup-box popup-delete">
                        <h6 className="text">Jesi li siguran da želiš izbrisati naružbu <br /><b>"{this.state.singleOrderName}"</b>?</h6>
                        <div className="button-area">
                            <button className="button confirm" onClick={this.deleteOrder.bind(this, this.state.singleOrderKey)}>Želim <FontAwesomeIcon icon="trash-alt" /></button>
                            <button className="button decline" onClick={this.togglePopupDelete.bind(this)}>Ne, hvala <FontAwesomeIcon icon="times-circle" /></button>
                        </div>
                    </div>
                </div>
                {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> Administracija  &rsaquo; Narudžbe  &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="page-header">
                        <h2 className="page-title">Narudžbe</h2>
                    </div>
                    <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <span>Narudžbe</span>
                        </div>
                    <div className="meta-info">
                        <p className="products-number default-text">Sve narudžbe <b>({this.state.orders.length})</b></p>
                        <p className="products-number default-text">Nepogledane narudžbe <b>({this.state.unreadedOrders.length})</b></p>
                    </div>
                  
                    <Table className="default-table order-table"
                    filterable={['orderName', 'name', 'date', 'totalPrice', 'charge', 'status']}
                    noDataText="Nema pronađenih proizvoda."
                    itemsPerPage={12}
                    pageButtonLimit={5}
                    currentPage={this.state.currentPage}
                    sortable={[
                        'orderId',
                        'orderName',
                        'name',
                        'totalPrice',
                        'charge',
                        'status'
                    ]}
                    previousPageLabel="Nazad"
                    nextPageLabel="Naprijed"
                    data={sortableOrders} 
                    defaultSort={{column: 'orderId', direction: 'desc'}}
                    >
                    <Thead>
                        <Th column="orderId">ID</Th>
                        <Th column="orderName">Broj narudžbe</Th>
                        <Th column="name">Ime i prezime</Th>
                        <Th column="date">Datum</Th>
                        <Th column="totalPrice">Cijena</Th>
                        <Th column="charge">Način plaćanja</Th>
                        <Th column="status">Status</Th>
                        <Th column="options">Opcije</Th>
                    </Thead>
                    </Table>
                </div>
            </section>
            </AdminWrapper>
        );
    }
}

export default Orders