import React from 'react'
import Footer from './Footer'
import AdminWrapper from '../AdminWrapper';
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Sidebar extends React.Component{
    constructor () {
        super()
        this.state = {
            showSubMenuClass: '',
            active: '',
            iconArrow: 'chevron-right',
        }
      this.toggleSubMenu = this.toggleSubMenu.bind(this);
      }

  toggleSubMenu () {
    if (this.state.showSubMenuClass === '') {
        this.setState({
            showSubMenuClass: 'show',
            active: 'active',
            iconArrow: 'chevron-down',
        })
    }
    else{
        this.setState({
            showSubMenuClass: '',
            active: '',
            iconArrow: 'chevron-right'
        })
    }     
  }

    render(){
        return(
        
            <div className="sidebar">
                <div className="menu">
                    <nav className="navbar">
                        <ul>
                            <span className="subtitle">Glavni meni</span>
                            <li className=""><span className={"link " + this.state.active} onClick={this.toggleSubMenu.bind(this)}><FontAwesomeIcon icon="box-open" />Proizvodi<span className="is-right"><FontAwesomeIcon icon={this.state.iconArrow} className="is-right" /></span></span>
                               {this.state.showSubMenuClass === 'show' && (
                                <ul className={"submenu " + this.state.showSubMenuClass}>
                                    <li><Link to="/administracija/proizvodi">Svi proizvodi</Link></li>
                                    <li><Link to="/administracija/proizvodi/akcija">Proizvodi na akciji</Link></li>
                                    <li><Link to="/administracija/proizvodi/ponuda-sedmice"><span className="item">Proizvodi u ponudi sedmice</span></Link></li>
                                </ul>
                               )}
                               
                            </li>
                            <li className=""><Link to="/administracija/narudzbe"><FontAwesomeIcon icon="inbox" />Narudžbe</Link></li>
                            <li className=""><Link to="/administracija/kategorije"><FontAwesomeIcon icon="layer-group" />Kategorije</Link></li>
                            <li className=""><Link to="/administracija/stranice"><FontAwesomeIcon icon="file-alt" />Stranice</Link></li>
                            <li className=""><Link to="/administracija/baneri"><FontAwesomeIcon icon="sliders-h" />Slideri</Link></li>
                            <li className=""><Link to="/administracija/filteri"><FontAwesomeIcon icon="tags" />Filteri</Link></li>
                            <li className=""><Link to="/administracija/brendovi"><FontAwesomeIcon icon={['fab', 'adn']} aria-hidden="true" />Brendovi</Link></li>
                            <li className=""><Link to="/administracija/marze"><FontAwesomeIcon icon={['fas', 'dollar-sign']} aria-hidden="true" />Marže</Link></li>
                            <li className=""><Link to="/administracija/korisnici"><FontAwesomeIcon icon={['fas', 'user']} aria-hidden="true" />Korisnici</Link></li>
                            <li className=""><Link to="/administracija/clanci"><FontAwesomeIcon icon="newspaper" />Članci(Modro i zeleno)</Link></li>
                            <span className="subtitle">Postavke</span>
                            <li className=""><Link to="/administracija/postavke"><FontAwesomeIcon icon="wrench" />Postavke</Link></li>
                            <li className=""><Link to="/administracija/najprodavaniji-proizvodi"><FontAwesomeIcon icon="box-open" />Najprodavaniji proizvodi</Link></li>
                            
                        </ul>
                    </nav>
                </div>
            </div>
           
        )
    }
}

export default Sidebar