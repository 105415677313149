import React from 'react'
import AdminWrapper from '../AdminWrapper';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'
import Reactable from 'reactable';
import formatMoney from '../../../functions/formatMoney'
import { productUrl } from '../../../functions/pageUrls'
import axios from 'axios';
import { months}  from '../../helpers/months'
import CsvParse from '@vtex/react-csv-parse'
import { addProduct } from '../../helpers/UserFunctions'
import { calcNormalPrice } from '../../../functions/calcNormalPrice'
import { timeoutDelay } from '../../../functions/config'

var Table = Reactable.Table,
    Thead = Reactable.Thead,
    Th = Reactable.Th,
    Tr = Reactable.Tr;

var slugify = require('slugify')

class Dashboard extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            products: [],
            publishedProducts: [],
            categories: [],
            mainCategories: [],
            brands: [],
            orders:[],
            newOrders: [],
            tableOrders: [],
            lastAdded: 400,
            lastPrName: '',
            lastPrSlug: '',
            lastPrCat: '',
            lastPrBrand: '',
            lastPrDate: '',
            lastPrKey: '',
            documentFile: null,
            document: null,
            importFinished: false,
            successimported: 0,
            totalforimport: 0,
            allMarkups: [],
            allBrands: [],
            deliverer: '',
            brand: 15,
        };
        this.addProducts = this.addProducts.bind(this);
        this.onChangeDocument = this.onChangeDocument.bind(this);
        this.resetDocument = this.resetDocument.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

handleChange = (e) => {    
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
}

getData(){  
    var products = [], publishedProducts = [], categories= [],mainCategories= [], brands= [], orders= [], newOrders= [], tableOrders = [];
    
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/administration')
    .then((response) => {
        response.data.forEach((doc) => {
            products.push({
            key: doc._id,
            });
            });
            this.setState({
                products,
        });
        });
    
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/administration/count/published')
        .then((response) => {
        response.data.forEach((doc) => {
            publishedProducts.push({
            key: doc._id,
            });
            });
            this.setState({
            publishedProducts,
        });
        });
    
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories')
.then((response) => {
        response.data.forEach((doc) => {
            categories.push({
            key: doc._id,
            });
            });
            this.setState({
            categories,
        });
        });
     
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/parent')
    .then((response) => {
        response.data.forEach((doc) => {
            mainCategories.push({
            key: doc._id,
            });
            });
            this.setState({
                mainCategories,
        });
        });
     
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/brands')
        .then((response) => {
        response.data.forEach((doc) => {
            brands.push({
            key: doc._id,
            });
            });
            this.setState({
                brands,
        });
        });
    
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders')
        .then((response) => {
        response.data.forEach((doc) => {
            orders.push({
            key: doc.generatedOrderId,
            });
            });
            this.setState({
                orders,
        });
        });
    
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders/readed-by-admin/'+0)
        .then((response) => {
        response.data.forEach((doc) => {
            newOrders.push({
            key: doc.generatedOrderId,
            });
            });
            this.setState({
                newOrders,
        });
        });
    
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders/administration')
        .then((response) => {
        response.data.forEach((doc) => {
          const { order_id,orderName, name, surname, phone, totalPrice, charge, readedByAdmin, orderCreatedAt } = doc;
          tableOrders.push({
            key: doc._id,
            generatedOrderId: doc.generatedOrderId,
            order_id,
            orderName, // DocumentSnapshot
            name,
            surname,
            phone,
            totalPrice,
            charge,
            orderCreatedAt: orderCreatedAt.split('T')[0],
            readedByAdmin,
          });
        });
        this.setState({
        tableOrders,
          loading:false,
         
       });
    });
    var lastAdded = this.state.lastAdded;

    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products')
    .then((response) => {
        response.data.forEach((doc) => {
            if(doc.product_id > lastAdded)
            {
              lastAdded = doc.product_id;
              this.setState({
                  lastPrName: doc.title,
                  lastPrSlug: doc.slug,
                  lastPrKey: doc._id,
                  lastAdded: doc.id,
                  lastPrKey: doc.productCreatedAt,
                  lastPrCat: doc.categoryData.slug,
                  lastPrBrand: doc.brandData.slug,
              }) 
            }
        });
    });   
}

componentDidMount = () => {
    this.getData();
    this.getMarkupList();
    this.getBrandList();
}

orderOptions(key,generatedOrderId, orderName){
    return(
        <div className={"options-wrapper"}>
            <div className="inner">
                <Link to={`/administracija/generisana-narudzba/${generatedOrderId}`} className="button blue" target="_blank">Pogledaj</Link>
            </div>
        </div>
    )
}    

statusColor(status){
    if(status === "Pogledano") 
    status = "readed";
    else
    status = "unreaded";
    return(
        <span className={status}></span>
    )
}

onChangeDocument = (event) => {
    this.setState({
        documentFile:event.target.files[0],
        document: URL.createObjectURL(event.target.files[0]),
  });
 
}

resetDocument(event) {
    event.preventDefault();
    this.setState({ image: null });
}

getMarkupList() {  
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/markups')
.then((response) => {
this.setState({
allMarkups: response.data
});
})
.catch((error) => {
console.log(error);
})
}

getBrandList() {  
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/brands')
.then((response) => {
this.setState({
allBrands: response.data
});
})
.catch((error) => {
console.log(error);
})
}


addProducts(){
    var data = this.state.data;
    this.state.totalforimport = data.length;
    var date = new Date();
    date = date.getDay() + date.getDate() + date.getFullYear();
   var localProductKey= "GWS" + (Math.floor(Math.random() * 100) + 10) + date + (Math.random().toString(36).substring(7)).toUpperCase();
    data.forEach(singleData => {
        var formatedPrice = calcNormalPrice(singleData.price*100);
       var slug = slugify(singleData.title, {
        replacement: '-',
        remove: null,
        lower: true
      }); 
      var newFilter = new Object();
      newFilter.filter_id = "0LWBTD3xL4";
      newFilter.cat_id = "1";
      var filters=[];
      filters.push(newFilter);
      const newProduct = {
          categories: [],
          subcategories: [],
          title: singleData.title,
          seoTitle: singleData.title,
          slug,
          description: "",
          seoDescription: "",
          shortDescription: "",
          vele_price: "",
          price: formatedPrice.toString(),
          markup: null,
          priceAction: formatedPrice.toString(),
          actionPercent: "",
          available: 1,
          brand: this.state.brand,
          category: 53,
          subCategory: null,
          deliverer: this.state.deliverer,
          delivererBarCode: "",
          delivererProductKey: "",
          deliveryTime: "",
          localProductKey,
          productAction:0,
          productOffer:0,
          productType: 1,
          productVariations: "[]",
          productVariety: 0,
          status: "Objavljeno",
          filters: JSON.stringify(filters),
          displayOnHomepage: 0,
          image: "empty",
          image_thumb: "empty",
          sliderImage1: "empty",
          sliderImage2: "empty",
          sliderImage3: "empty",
          sliderImage4: "empty",
          } 
          addProduct(newProduct).then(res => {
           this.state.successimported = this.state.successimported+1;
            })
            .catch((error) => {
                console.log("error", error);
            })    
    });
    this.setState({
        importFinished: true,
    })
    setTimeout(function(){
        this.setState({
            importFinished: false,
        })
    }.bind(this),5000);
  
}

handleData = data => {
    this.setState({ data })
    console.log(this.state.data)
  }

    render(){
      
    const keys = [
    "title",
    "price",
  ]

        var sortableOrders = [];
        {this.state.tableOrders.map(order =>
            {
                console.log(order.readedByAdmin)
                if(order.readedByAdmin === 1){
                    order.readedByAdmin = "Pogledano"
                }
                if(order.readedByAdmin === 0){
                    order.readedByAdmin = "Nova narudžba"
                }
                var unformatedDate = order.orderCreatedAt.split('-');
                var formatedMonth ,formatedDate;
                months.forEach(month => {
                    if(month.value == unformatedDate[1])  {
                        formatedMonth = month.name;
                    }
                });
                formatedDate = unformatedDate[2] + " " + formatedMonth + ", " + unformatedDate[0];
                sortableOrders.push({
                    orderId: order.order_id,
                    orderName: order.orderName,
                    name: order.name + " " + order.surname, 
                    date: formatedDate,
                    totalPrice: formatMoney(order.totalPrice), 
                    charge: order.charge,
                    status: order.readedByAdmin,
                    options: this.orderOptions(order.key,order.generatedOrderId, order.orderName)
              })
            }
      )}
      
            const{brand, deliverer} = this.state;
        return(
           <AdminWrapper>
                 <Helmet>
                    <title> Administracija  &rsaquo; Zdravo.ba </title>
                </Helmet>
                <section id="dashboard" className="hero dashboard default-form">
                    <div className="container">
                    <h6 className="subtitle with-padding">Zadnje dodano</h6>
                    <div className="last-added-product">
                        <div className="inner">
                            <Link to={`/${productUrl}/${this.state.lastPrCat}/${this.state.lastPrBrand}/${this.state.lastPrSlug}`} className="product-name">
                            {this.state.lastPrName}<span className="date">{this.state.lastPrKey}</span></Link>
                            <Link to={`/administracija/uredjivanje-proizvoda/${this.state.lastPrKey}`} className="edit-product">Uredi proizvod</Link>
                        </div>
                    </div>
                   
                   
                    <h6 className="subtitle with-padding">Stanje trgovine</h6>
                        <div className="top-infos columns">                   
                            <div className="column is-3">
                                <div className="inner first">
                                    <h4 className="title">Broj proizvoda</h4>
                                    <h1 className="result">{this.state.products.length}</h1>
                                    <h6 className="subtitle">Objavljeni proizvodi: <b>{this.state.publishedProducts.length}</b></h6>
                                </div>
                            </div>

                            <div className="column is-3">
                                <div className="inner second">
                                    <h4 className="title">Broj kategorija</h4>
                                    <h1 className="result">{this.state.categories.length}</h1>
                                    <h6 className="subtitle">Broj glavnih kategorija: <b>{this.state.mainCategories.length}</b></h6>
                                </div>
                            </div>

                            <div className="column is-3">
                                <div className="inner third">
                                    <h4 className="title">Broj brandova</h4>
                                    <h1 className="result">{this.state.brands.length}</h1>
                                    <h6 className="subtitle">-</h6>
                                </div>
                            </div>

                            <div className="column is-3">
                                <div className="inner fourth">
                                    <h4 className="title">Broj narudžbi</h4>
                                    <h1 className="result">{this.state.orders.length}</h1>
                                    <h6 className="subtitle">Neotvorenih narudžbi: <b>{this.state.newOrders.length}</b></h6>
                                </div>
                            </div>
                        </div>{/* TOP INFOS END */}

                        <div className="main-dashboard columns">
                            <div className="column is-12 order-table">
                            <h6 className="subtitle">Posljednje narudžbe</h6>
                                <Table className="default-table order-table"
                                    filterable={['orderName', 'name', 'totalPrice', 'charge', 'date', 'status']}
                                    noDataText="Nema novih narudžbi."
                                    itemsPerPage={12}
                                    pageButtonLimit={5}
                                    currentPage={this.state.currentPage}
                                    sortable={[
                                        'orderId',
                                        'orderName',
                                        'name',
                                        'totalPrice',
                                        'charge',
                                        'date',
                                        'status'
                                    ]}
                                    previousPageLabel="Nazad"
                                    nextPageLabel="Naprijed"
                                    data={sortableOrders} 
                                    defaultSort={{column: 'orderId', direction: 'desc'}}
                                    >
                                    <Thead>
                                        <Th column="orderId">ID</Th>
                                        <Th column="orderName">Broj narudžbe</Th>
                                        <Th column="name">Ime i prezime</Th>
                                        <Th column="date">Datum</Th>
                                        <Th column="totalPrice">Cijena</Th>
                                        <Th column="charge">Način plaćanja</Th>
                                        <Th column="status">Status</Th>
                                        <Th column="options">Opcije</Th>
                                    </Thead>
                                </Table>
                            </div>

                            <div className="column is-4">
                            
                            </div>
                        </div>{/*MAIN DASHBOARD END */}

                        <div className="bulk-add">
                            <CsvParse
                            keys={keys}
                            onDataUploaded={this.handleData}
                            onError={this.handleError}
                            render={onChange => <label htmlFor="file"><input type="file" name="file" onChange={onChange} /></label>}
                            />
                             <button onClick={this.addProducts.bind(this)} className="button default">Importuj proizvode</button>
                        </div>
                        {this.state.importFinished && (
                        <div className="import-finished">
                            <p className="">Proizvodi su importovani ({this.state.successimported}/{this.state.totalforimport}).</p>
                        </div>
                         )}

                        <form name="add-product" onSubmit={this.onSubmit} className="form">
                            <fieldset className="">
                                <div className="columns is-multiline main">
                                    <label htmlFor="deliverer" className="column is-6">
                                        Dostavljač
                                        <select id="deliverer" name="deliverer"
                                        value={deliverer} onChange={ this.handleChange } >
                                            <option value="null">Izaberi dostavljača</option>
                                         {this.state.allMarkups.map(markup =>(
                                            <option value={markup.markup_id}>{markup.name}</option>
                                        ) )}
                                        </select>
                                    </label>
                                    <label htmlFor="brand" className="column is-6">
                                        Proizvođač
                                        <select id="brand" name="brand" onChange={this.handleChange} value={brand} required>
                                            <option value="null">Izaberi proizvođača</option>
                                            {this.state.allBrands.map(brand =>
                                            <option value={brand.brand_id}>{brand.name}</option>
                                            )}
                                        </select>
                                    </label>
                                </div>
                            </fieldset>
                        </form>
                      
                    </div>{/*CONTAINER END */}
                </section>{/*DASHBOARD SECTION END*/}   
            </AdminWrapper>
        );
    }
}

export default Dashboard