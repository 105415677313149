import React from 'react'
import {Link} from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import {Helmet} from "react-helmet";
import Reactable from 'reactable';
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { pageUrl } from '../../../functions/pageUrls'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { months}  from '../../helpers/months'
import no_image from '../../../static/no_image.png'
var Table = Reactable.Table,
    Thead = Reactable.Thead,
    Th = Reactable.Th;

var togglePopupDelete;
class Pages extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            pages: [],
            totalNumberPages: [],
            filteredPages: [],
            currentPage: 0,
            showPopupDeleteClass: '',
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
            singlePageKey: '',
            singlePageName: '',
            loading:false,
        };

        togglePopupDelete = this.togglePopupDelete.bind(this);
      }
    
    

togglePopupDelete (id, name) {
if (this.state.showPopupDeleteClass === '') {
    this.setState({
        showPopupDeleteClass: 'show',
        singlePageKey: id,
        singlePageName: name,
    })
}
else{
    this.setState({
        showPopupDeleteClass: ''
    })
}     
}

deletePage(id){
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/pages/delete-page/' + id)
    .then(() => {
        this.getPagesList();
        this.setState({
            showPopupDeleteClass: '',
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Uspješno ste izbrisali stranicu "<b>' + this.state.singlePageName + '"</b>',
        stickerType: 'success',
        })
    })
    .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Došlo je do problema prilikom brisanja stranice: ' + error,
        stickerType: 'danger',
    })
    });
    
    }


pageOptions(key, name, slug){
    return(
        <div className={"options-wrapper"}>
            <div className="inner">
                <Link to={`/special/${slug}`} className="button orange">Pogledaj</Link>
                <Link to={`/administracija/uredjivanje-stranice/${slug}`} className="button blue">Uredi</Link>
                <button onClick={this.togglePopupDelete.bind(this, key, name)} className="button red">Izbriši</button>
            </div>
        </div>
    )
}

getPagesList() {  
    const pages = [];    
     //Collect pages from selected category
     axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/pages')
    .then((response) => {
    response.data.forEach((doc) => {
        const { page_id, title, slug } = doc;

            pages.push({
                _id: doc._id,
                page_id: page_id,
                slug,
                title,
            })
        })
        this.setState({
            pages: pages,
            filteredPages: pages,
            totalNumberPages: pages,
        })


    }).catch((error) => {
        console.log(error);
    })
}

componentDidMount = () => {
    this.getPagesList();
}

    render(){
        var sortablePages = [];
        
          {this.state.pages.map(page =>
            {
               
                sortablePages.push({
                    id: page.page_id,
                    page_title: page.title, 
                    options_extend: this.pageOptions(page._id, page.title, page.slug)
                })
            }
           
        )}
        return(
            <AdminWrapper>
            <section id="pages" className="section pages content-page-section default-table pages-table">
            {this.state.loading && (
                <div className="loader-box">
                    <span className="loader"></span>
                </div>
            )}
             <div className={"popup-wrapper " + this.state.showPopupDeleteClass}>
                    <div className="overlay-back"></div>
                    <div className="popup-box popup-delete">
                        <h6 className="text">Jesi li siguran da želiš izbrisati stranicu <br /><b>"{this.state.singlePageName}"</b>?</h6>
                        <div className="button-area">
                            <button className="button confirm" onClick={this.deletePage.bind(this, this.state.singlePageKey)}>Želim <FontAwesomeIcon icon="trash-alt" /></button>
                            <button className="button decline" onClick={this.togglePopupDelete.bind(this)}>Ne, hvala <FontAwesomeIcon icon="times-circle" /></button>
                        </div>
                    </div>
                </div>
                {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> Administracija  &rsaquo; Stranice  &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="page-header">
                    <h2 className="page-title">Sve stranice</h2>
                    <Link to="/administracija/nova-stranica" className="button default default-no-icon is-right"><span className="item">Dodaj stranicu <FontAwesomeIcon icon="plus" /></span></Link>
                    </div>
                  
                    <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <span>Stranice</span>
                        </div>

                    <div className="meta-info">
                        <p className="pages-number default-text">Sve stranice <b>({this.state.totalNumberPages.length})</b></p>
                    </div>
                    
                    <Table className="default-table"
                    filterable={['page_title']}
                    noDataText="Nema pronađenih stranica."
                    itemsPerPage={12}
                    pageButtonLimit={5}
                    currentPage={this.state.currentPage}
                    sortable={[
                        'id',
                        'page_title',
                    ]}
                    previousPageLabel="Nazad"
                    nextPageLabel="Naprijed"
                    data={sortablePages} 
                    defaultSort={{column: 'id', direction: 'desc'}}
                    >
                    <Thead>
                        <Th column="id">ID</Th>
                        <Th column="page_title">Naziv stranice</Th>
                        <Th column="options_extend">Opcije stranice</Th>
                    </Thead> 
                    </Table>
                </div>
            </section>
            </AdminWrapper>
        );
    }

}

export default Pages