/*
About Us page
Display static page with informations about us
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import {siteTitle} from '../../../functions/config'
import image from '../../../static/about_img.png'

var pageTitle = "O nama";
class AboutUs extends React.Component{

    componentDidMount() {
    }

    render(){
        window.scrollTo(0,0); 
        return(
            <ShopWrapper>
               <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
              </Helmet>
                <section id="p_about_us" className="section static-page about-us">
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={pageTitle} />

                        {/******************* Content *******************/}
                        <div className="content">
                            <div className="text-content">
                            <p>Naša online trgovina Vam omogućava lako pronalaženje proizvoda koji će vam na raznorazne 
                                načine pomoći u Vašoj svakodnevnici, radilo se o očuvanju zdravlja, njezi kože i kose, 
                                zdravoj ishrani, preparatima za sportiste ili dr.</p>
                            
                            <p>Cilj nam je koristeći nove tehnološke mogućnosti putem internet prodaje pružiti Vam ugođaj 
                                prijatne, jednostavne i brze kupovine. Prednost online prodaje je u uštedi standardnih 
                                izdataka koje imaju klasične trgovine, apoteke, supermarket…. Iz tog razloga smo u 
                                mogućnosti pružiti Vam proizvode sa povoljnim cijenama ali i besplatnom dostavom u 
                                navedenim mjestima. <b>Naše usluge Vam štede novac i vrijeme a onima koji nisu u stanju 
                                da napuste svoje domove olakšavaju svakodnevnicu.</b> </p>
                            <p>Trudimo se da visokim nivoom poslovanja i zadovoljstvom naših kupaca postavimo nove standarde
                                 u sredini u kojoj radimo i živimo. Dugi niz godina pratimo najnovija kretanja u oblasti 
                                  prodaje dodataka ishrani, dijetetskih proizvoda, kozmetike, sporta i drogerije, koje 
                                  primjenjujemo u našoj online trgovini praveći poseban ugođaj kupovine za naše klijente.</p>
                            <p>Dodatni projekti poput redovnog savjetovanja naših kupaca putem live chat-a ili telefona funkcioniraju 
                                kao još jedna od olakšica za naše kupce. <b>Naši stručni saradnici vam stoje na raspolaganju za sva pitanja 
                                i nedoumice kod izbora preparata.</b></p>
                            <p>Možete nas kontaktirati mailom na <a href="mailto: info@zdravo.ba">info@zdravo.ba</a>, putem telefona / Whatsapp-a / Viber-a 
                                na <a href="tel:+387 61 93 93 48">+387 61 93 93 48</a> ili live chat-a svakim danom od 
                                07:00-20:00. </p>
                            <p><b>Ukoliko na našim stranicama ne možete pronaći određeni preparat, kontaktirajte nas i pokušat ćemo ga nabaviti.</b></p>

                            <p className="small-text">Ovim putem naglašavamo da Svrha proizvoda u našem šopu nije da zamijene 
                            ljekare i farmaceute. Ne prodajemo proizvode koji su registrovani kao lijekovi niti bilo šta za što 
                            je nužno imati recept. Dakle radi se o klasičnim dodacima ishrani, dijetetskim proizvodima i
                             proizvodima za njegu koji mogu pomoći organizmu pri prevazilaženju određenih zdravstvenih tegoba.
                              Svi opisi proizvoda su urađeni kao orjentacija za naše kupce i ne mogu zamijeniti analizu i
                               preporuku liječnika i farmaceuta. </p>
                            
                            <p>Online trgovina ZDRAVO.BA je u vlasništvu kompanije PA "VALOR" sa sjedištem u Sarajevu u ulici Rogačići br 1.</p>
                            </div> 
                            <div className="back-image" style={{ backgroundImage: `url(${image})` }}></div>

                        </div>
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}
            </ShopWrapper>
        )
    }
}

export default AboutUs