/*
Offer component for homepage
Display Products which is on week offer
*/
import React from 'react'
import Slider from "react-slick";
import Countdown from 'react-countdown-now';
import Product from '../product/Product'
import ProductPopup from '../product/ProductPopup'
import { Link } from 'react-router-dom'
import {weekOfferUrl} from '../../../functions/pageUrls'
import axios from 'axios';

var  togglePopupBox, productDataForPopup;

//Const: render date for week offer
const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <p>Ponuda je istekla.</p>;
    } else {
      // Render a countdown
      return (
      <div className="counter">
          <div className="single">
              <span>{days}</span>
              <p>Dana</p>
          </div>
          <div className="single">
              <span>{hours}</span>
              <p>Sati</p>
          </div>
          <div className="single">
              <span>{minutes}</span>
              <p>Minuta</p>
          </div>
          <div className="single">
              <span>{seconds}</span>
              <p>Sekundi</p>
          </div>
      </div>
      );
    }
  };

//calculate miliseconds
var monday, sunday;
    var curr = new Date;
    curr.setHours(0,0,0,0);
    var first = curr.getDate() - curr.getDay();
    var first = first + 1;
    var last = first + 6;
    
    monday = new Date(curr.setDate(first)).toISOString();
    sunday = new Date(curr.setDate(last)).toISOString();
    var milliseconds = Date.parse(monday);
        
class Offer extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            showPopupClass: '',
            popupTitle: '',
            popupPrice: '',
            popupOldPrice: '',
            popupImage: '',
            popupSlug: '',
            productId: 'empty',
            popupCategory: 'empty',
            weekOffer: '',
            products: [],
        }
        togglePopupBox	= this.togglePopupBox.bind(this);
        productDataForPopup = this.productDataForPopup.bind(this);
        this.hidePopupBox = this.hidePopupBox.bind(this); 
    }

//Function: show popup box after adding new product in cart
togglePopupBox (arg) {
    if (this.state.showPopupClass === '') {
        this.setState({
            showPopupClass: arg
        })
      //   setTimeout(function(){
      //       this.setState({
      //           showPopupClass: ''
      //       })
      //  }.bind(this),7500);
    }     
}

//Function: hide popup box after adding new product in cart
hidePopupBox (arg){
    this.setState({
        showPopupClass: arg
    })
  }

//Function: pass product data to popup
productDataForPopup (title,slug, category, price,oldPrice,image,productId, quantity, prAvailable ){
  this.setState({
      popupImage: image,
      popupPrice: price,
      popupOldPrice: oldPrice,
      popupSlug: slug,
      popupTitle: title,
      popupCategory: category,
      productId: productId,
      popupQuantity: 1,
      prAvailable,
  })
}
 
singleProductData(productSlug){}
  
getSettings() {
  axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/settings/get-settings')
  .then((response) => {
  this.setState({
      weekOffer: response.data.weekOffer,
  });
  })
  .catch((error) => {
  console.log(error);
  })
}

getProductsList() {  
  const productsArray = [];
  
      axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/week-offer-homepage')
      .then((response) => {
      response.data.forEach((doc) => {
          const { title, slug, price, image_thumb, priceAction, category, productOffer, productAction, actionPercent, 
              deliverer, productVariety, productVariations, brand, vele_price, markup, available,purpose } = doc;
              productsArray.push({
              key: doc._id,
              title,
              slug,
              price,
              image_thumb,
              priceAction,
              category,
              productOffer,
              actionPercent,
              productAction,
              purpose,
              deliverer,
              productVariety,
              productVariations,
              brand,
              vele_price,
              markup,
              available,
              productCategorySlug: doc.categoryData[0].slug,
              productBrandName: doc.brandData[0].slug,
              brandDiscount: doc.brandData[0].discount,
              productBrandFullName: doc.brandData[0].name,
              productBrandLogo: doc.brandData[0].image,
              });
      });
      this.setState({
          products: productsArray,
      })
  }).catch((error) => {
      console.log(error);
  })
}
  
componentDidMount = () => {
  this.getProductsList();
  this.getSettings();
}

  
    render(){
      //Attributes for slider
      var sliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 479,
            settings: {
              slidesToShow: 1,
            }
          },
        ]
      };
        
        return(
          <>
          {this.state.products.length > 0 && (
            <section id="s_offer" className="section offer padding-default">
                <div className="container">
                    <div className="columns margin-0">

                    {/******************* Area left with week offer informations *******************/}
                        <div className="column is-3 area-left ">
                        <div className="title-box-action">
                          <h1 className="title"><b>Top</b> <br />ponude</h1>{/*<Link to={weekOfferUrl} className="icon"></Link>*/}
                        </div>              
                                       {/* <h4 className="discount">-{this.state.weekOffer} <span>%</span></h4>*/}
                            <Countdown date={milliseconds + 604800000} renderer={renderer} />
                           <div className="button-area">
                           <Link to={weekOfferUrl} className="button default">Pogledajte sve ponude</Link>
                           </div>
                        </div>{/* AREA LEFT END */}
                      
                      {/******************* Area right with products slider *******************/}
                        <div className="column is-9 area-right default-pagination">

                        {/******************* Product popup *******************/}
                        {this.state.showPopupClass === 'show' && (
                       <ProductPopup
                       title={this.state.popupTitle}
                       slug={this.state.popupSlug}
                       category={this.state.popupCategory}
                       price={this.state.popupPrice}
                       oldPrice={this.state.popupOldPrice}
                       image={this.state.popupImage}
                       productId={this.state.productId}
                       popupQuantity={this.state.popupQuantity}
                       prAvailable={this.state.prAvailable}
                       showClass={this.state.showPopupClass}
                       hidePopupBox = {this.hidePopupBox.bind(this)} />
                        )}
                        
                        <div className="inner products-slider">
                        {/******************* Products slider *******************/} 
                        <Slider {...sliderSettings}>
                        {this.state.products.map(product =>
                            <div className="is-4 single">
                            {/******************* Call product component *******************/}
                           <Product product={product} 
                            key={product.key} 
                            togglePopupBox = {togglePopupBox.bind(this)} 
                            productDataForPopup={productDataForPopup.bind(this)}
                            singleProductData={(productSlug) => this.singleProductData(productSlug)}
                            />
                            </div>
                            )}
                        </Slider> 
                        </div>  
                        </div>{/* AREA RIGHT END */}
                    </div>{/* COLUMNS END */}
                </div>{/* CONTAINER END */}
            </section>/* SECTION OFFER END */
             )}
            </>
        );
    }
}

export default Offer;