import React from 'react'
import { Link } from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import {Helmet} from "react-helmet";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { upload } from '../../helpers/UserFunctions'
import Reactable from 'reactable';
import no_image from '../../../static/no_image.png'

var Table = Reactable.Table,
    Thead = Reactable.Thead,
    Th = Reactable.Th;

var slugify = require('slugify')
var exist = false;
class EditPage extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;  
        this.state = {
            status: '',
            metaDesc: '',
            key: '',
            id: '',
            title: '',
            slug: '',
            description: '',
            products: [],
            allProducts: [],
            image: null,
            imageFile: null,
            imageChanged: false,
            facebookImage: null,
            facebookImageFile: null,
            facebookImageChanged: null,
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
        }   

        this.onChangeImage = this.onChangeImage.bind(this);
        this.resetImage = this.resetImage.bind(this);

        
        this.onChangeFacebookImage = this.onChangeFacebookImage.bind(this);
        this.resetFacebookImage = this.resetFacebookImage.bind(this);

        this.addToList = this.addToList.bind(this);
    }

handleChangeSlug = (e) => {    
    this.state.slug = slugify(this.state.title, {
        replacement: '-',
        remove: null,
        lower: true
      });
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({page:state});
}
  
handleChange = (e) => {     
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({page:state});
}


//ISTAKNUTA SLIKA
onChangeImage(event) {
    this.setState({
        imageChanged: true,
        imageFile:event.target.files[0],
        image: URL.createObjectURL(event.target.files[0]),
    });
}
    
resetImage(event) {
    event.preventDefault();
    this.setState({ image: null });
}

//ISTAKNUTA SLIKA
onChangeFacebookImage(event) {
    this.setState({
        facebookImageChanged: true,
        facebookImageFile:event.target.files[0],
        facebookImage: URL.createObjectURL(event.target.files[0]),
    });
}
    
resetFacebookImage(event) {
    event.preventDefault();
    this.setState({ facebookImage: null });
}


getPageById() {
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/pages/edit-page/' + this.props.match.params.slug)
    .then((response) => {
       const {title,  slug, description, status,metaDesc, image, facebookImage, products} = response.data;
    this.setState({
        title,
        slug,
        description,
        status,
        metaDesc,
        products: JSON.parse(products),
        image,
        facebookImage,
    })
    }).catch((error) => {
        console.log(error);
    })
}

getProducts(){
    var allProducts = [];
    //Collect products from selected category
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/administration')
    .then((response) => {
    response.data.forEach((doc) => {
        const { product_id, title, slug, image_thumb } = doc;
        allProducts.push({
                _id: doc._id,
                id: product_id,
                title,
                image: image_thumb,
                slug,
            })
        })
        this.setState({
            allProducts,
        })
    }).catch((error) => {
        console.log(error);
    })
}

onSubmit = (e) => {
    e.preventDefault();

    const {id, title, slug,description, image,facebookImage, status,metaDesc, products } = this.state;

    var newImage, newFacebookImage;
    var {imageFile} = this.state;
    if(imageFile !== null){
        newImage = imageFile.name
    }
    else{
        newImage = image;
    }

    var {facebookImageFile} = this.state;
    if(facebookImageFile !== null){
        newFacebookImage = facebookImageFile.name
    }
    else{
        newFacebookImage = facebookImage;
    }

    axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/pages/update-page/' + this.props.match.params.slug, {
        title,
        slug,
        description,
        image: newImage,
        facebookImage: newFacebookImage,
        status,
        metaDesc,
        products: JSON.stringify(products)
        })
        .then((response) => {
            this.setState({
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
           }.bind(this),timeoutDelay);
           this.setState({
            stickerMessage: 'Uspješno ste uredili stranicu "<b>' + this.state.title + '"</b>',
            stickerType: 'success',
        })

        
        })
        .catch((error) => {
            this.setState({
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
            }.bind(this),timeoutDelay);
            this.setState({
            stickerMessage: 'Došlo je do problema prilikom uređivanja stranice: ' + error,
            stickerType: 'danger',
        })
        });

        if(imageFile !== null){
            const addImage = new FormData();
            addImage.append(
            'image',this.state.imageFile);        
            const config = {       
            headers: {'content-type': 'multipart/form-data'}
            };
        
            upload(addImage, config) .then((response) => {
                }).catch((error) => {
            });
        }  

        if(facebookImageFile !== null){
            const addFacebookImage = new FormData();
            addFacebookImage.append(
            'image',this.state.facebookImageFile);        
            const config = {       
            headers: {'content-type': 'multipart/form-data'}
            };
        
            upload(addFacebookImage, config) .then((response) => {
                }).catch((error) => {
            });
        }  


}



addToList(id, title, image, slug){

    // FIX POSITION DELETING

    function findIndexNested(data, id) {
        if (data.id === id) return [];
        let result;
        const i = (data.children || []).findIndex(child => {
            return result = findIndexNested(child, id)
        });
        if (result) return [i, ...result];
    }
    function findByPath(data, path) {
        for (let i of path) data = data.children[i];
        return data
    }
    
    var products = this.state.products;
    // var productsAdded = sortableProductsAdded
    // console.log("PRODUCTS", products)
    // console.log("PRODUCTS ADDED", sortableProductsAdded)
    var product = new Object();
    product.id = id;
    product.title = title;
    product.image = image;
    product.slug = slug;
    if(products.length > 0){
        if (products.some(e => e.id === id)) {
            // console.log("attempting delete", title)
            // if(products.some(e => e.id === id) === true){
            //     console.log("index of", products.findIndex(x => x.id === id))
            // } else {
            //     console.log("IF FALSE some function", products.some(e => e.id === id))
            // }
            products.splice(products.findIndex(x => x.id === id),1)
        }
        else{
            console.log("attempting PUSH", title)
            products.push(product);
        }

    }
    else{
        products.push(product);
    }
    this.setState({
        products
    })
}


productOptions(key,title, image,slug, prAdded){

    return(
        <div className={"options-wrapper"}>
            <div className="inner">
                {prAdded  && (
                    <span onClick={this.addToList.bind(this, key, title, image, slug)} className="button green" title="Isključi best seler opciju">Ukloni iz liste</span>
               )}
               {!prAdded  && (
                    <span onClick={this.addToList.bind(this, key, title, image, slug)} className="button red" title="Uključi best seler opciju">Dodaj u listu</span>
               )}
            </div>
        </div>
    )
}

productImage(image){
    if(image === "empty" || image === undefined || image === null){
        return(
            <img src={no_image} />
        )
    }
    else{
        return(
            <img src={process.env.REACT_APP_FILES_DESTINATION+image} />
        )
    }    
}

componentDidMount = () => {
    this.getPageById();
    this.getProducts();
}

    render(){
        var sortableProducts = [], sortableProductsAdded = [];
        var prAdded = false;
        var {description } = this.state;
        const { title, slug, image, facebookImage, allProducts, products, status,metaDesc, imageChanged, facebookImageChanged } = this.state;

        allProducts.map(product =>
            {   
                prAdded = false;
                if(products.some(e => e.id === product.id)){
                    prAdded = true;
                }
                sortableProducts.push({
                    id: product.id,
                    image: this.productImage(product.image), 
                    title: product.title, 
                    options: this.productOptions(product.id, product.title, product.image,product.slug, prAdded)
                })
            }
        )

        products.map(product =>
            {   
                sortableProductsAdded.push({
                    id: product.id,
                    image: this.productImage(product.image), 
                    title: product.title, 
                    options: this.productOptions(product.id, product.title, product.image,product.slug, true)
                })
            }
        )

        return(
            <AdminWrapper>
            <section id="addPage" className="section default-section add-page content-page-section default-form">
              {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> {title}  &rsaquo; Uredi stranicu  &rsaquo; Zdravo.ba </title>
                </Helmet>
                <div className="is-hidden"><canvas ref="canvas" width={640} height={425} /></div>
                    <div className="content">
                        <div className="page-header">
                           <h2 className="page-title">Uređivanje stranice</h2>
                        </div>
                        <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <Link to="/administracija/stranice">Stranice</Link>
                            <span>/</span>
                            <span>Uređivanje stranice</span>
                        </div>
                        <form name="add-page" onSubmit={this.onSubmit} className="form">
                            <fieldset className=""> 
                                <div className="columns is-multiline main"> 
                                 
                                    <label htmlFor="title" className="column is-12 title">
                                        Ime stranice
                                        <input type="text" id="title" name="title" placeholder="Puno ime stranice koje će biti prikazano"
                                        value={title} onChange={ this.handleChangeSlug} required/>
                                    </label>
                                    <label htmlFor="slug" className="column is-12 slug">
                                    <span>Generisani url</span>
                                        <input type="text" id="slug" name="slug" placeholder="Slug stranice"
                                        value={slug} onChange={ this.handleChange} required/>
                                    </label>
                                    <label htmlFor="metaDesc" className="column is-12 title">
                                        Meta deskripcija stranice
                                        <input type="text" id="metaDesc" name="metaDesc" placeholder="Meta deskripcija stranice"
                                        value={metaDesc} onChange={ this.handleChangeSlug} required/>
                                    </label>
                                   
                                    <label htmlFor="description" className="column is-12 description">
                                        Detaljni Opis Stranice                            
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data= {description}
                                            onInit={ editor => {
                                            } }
                                            onChange={ ( event, editor ) => {
                                                this.setState({
                                                    description: editor.getData(),
                                                })
                                            } }
                                            onBlur={ editor => {
                                            } }
                                            onFocus={ editor => {
                                            } }
                                        />
                                    </label>
                                    <span className="is-hidden">
                                        {description = this.state.description}
                                    </span>
                                </div>

                                <div className="sidebar">
                                        <label htmlFor="status" className="">
                                            Status stranice
                                            <select id="status" name="status" value={status} onChange={ this.handleChange } required>
                                                <option value="">Izaberi</option>
                                                <option value="Objavljeno">Objavljeno</option>
                                                <option value="U pripremi">U pripremi</option>
                                                <option value="Neobjavljeno">Neobjavljeno</option>
                                            </select>   
                                        </label>
                                    
                                    <div className="button-area">
                                        <button type="submit" className="button default add"><span className="item">Spremi izmjene <FontAwesomeIcon icon="pencil-alt" /></span></button>
                                    </div>

                                    {this.state.imageFile !== null && this.state.imageFile.name}
                                    <div id="upload_button" className=""> 
                                    <label htmlFor="image" className="label-image">
                                        Istaknuta slika
                                    <input type="file" id="image" name="image" onChange={ this.onChangeImage }/>
                                    <span className="add-image">Dodaj istaknutu sliku <FontAwesomeIcon icon="image" /></span>
                                    </label>
                                    <div className="image-preview">
                                        {image && !imageChanged && <img src={process.env.REACT_APP_FILES_DESTINATION + image} alt="" />}
                                        {image && imageChanged && <img src={image} alt="" />}
                                        {image !== 'empty'  && (                                 
                                                <button className="remove-button" onClick={this.resetImage}><i className="icon"></i></button>
                                                )}
                                    </div>
                                    </div>

                                    {this.state.facebookImageFile!== null && this.state.facebookImageFile.name}
                                    <div id="upload_button" className=""> 
                                    <label htmlFor="facebookImage" className="label-image">
                                        Facebook slika
                                    <input type="file" id="facebookImage" name="image" onChange={ this.onChangeFacebookImage }/>
                                    <span className="add-image">Dodaj facebook sliku <FontAwesomeIcon icon="image" /></span>
                                    </label>
                                    <div className="image-preview">
                                        {facebookImage && !facebookImageChanged && <img src={process.env.REACT_APP_FILES_DESTINATION + facebookImage} alt="" />}
                                        {facebookImage && facebookImageChanged && <img src={facebookImage} alt="" />}
                                        {facebookImage !== 'empty'  && (                                 
                                                <button className="remove-button" onClick={this.resetFacebookImage}><i className="icon"></i></button>
                                                )}
                                    </div>
                                    </div>


                                </div>

                               <div className="columns is-multiline">
                               <div className="column is-12">
                                  <div className="page-header">
                                      <h2 className="page-title">Trenutno odabrani proizvodi</h2>
                                  </div>
                                   <Table className="default-table"
                                    filterable={['title']}
                                    noDataText="Nema pronađenih proizvoda."
                                    itemsPerPage={12}
                                    pageButtonLimit={5}
                                    currentPage={this.state.currentPage}
                                    sortable={[
                                        'id',
                                        'title',
                                    ]}
                                    previousPageLabel="Nazad"
                                    nextPageLabel="Naprijed"
                                    data={sortableProductsAdded} 
                                    defaultSort={{column: 'id', direction: 'desc'}}
                                    >
                                        <Thead>
                                            <Th column="id">ID</Th>
                                            <Th column="image">Slika</Th>
                                            <Th column="title">Naziv</Th>
                                            <Th column="options">Opcije</Th>
                                        </Thead> 
                                    </Table>
                                   </div>

                                   <div className="column is-12">
                                  <div className="page-header">
                                      <h2 className="page-title">Dodaj nove proizvode</h2>
                                  </div>
                                   <Table className="default-table"
                                    filterable={['title']}
                                    noDataText="Nema pronađenih proizvoda."
                                    itemsPerPage={12}
                                    pageButtonLimit={5}
                                    currentPage={this.state.currentPage}
                                    sortable={[
                                        'id',
                                        'title',
                                    ]}
                                    previousPageLabel="Nazad"
                                    nextPageLabel="Naprijed"
                                    data={sortableProducts} 
                                    defaultSort={{column: 'id', direction: 'desc'}}
                                    >
                                        <Thead>
                                            <Th column="id">ID</Th>
                                            <Th column="image">Slika</Th>
                                            <Th column="title">Naziv</Th>
                                            <Th column="options">Opcije</Th>
                                        </Thead> 
                                    </Table>
                                   </div>
                               </div>

                            </fieldset>
                        </form>
                    </div>
                </div>
            </section>
            </AdminWrapper>
        )
    }
}

export default EditPage