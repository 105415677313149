/*
Actions page
Display all products which is on base action
*/
import React from 'react'
import Product from '../product/Product'
import ProductPopup from '../product/ProductPopup'
import {Helmet} from "react-helmet";
import Pagination from '../Pagination';
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import LoaderBox from '../../helpers/LoaderBox';
import {siteTitle} from '../../../functions/config'
import axios from 'axios';

var pageTitle = "Proizvodi brenda";
var togglePopupBox, productDataForPopup;
class Actions extends React.Component{

    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            showPopupClass: '',
            popupTitle: '',
            popupPrice: '',
            popupOldPrice: '',
            popupImage: '',
            popupSlug: '',
            pageName: 'Akcije',
            pageSize: 1,
            popupCategory: 'empty',
            productId: 'empty',
            actionProducts: [],
            pageOfProducts: [],
            loading:true,
            brandName: '',
        }

        togglePopupBox	= this.togglePopupBox.bind(this);
        productDataForPopup = this.productDataForPopup.bind(this);
        this.hidePopupBox = this.hidePopupBox.bind(this);     
        this.onChangePage = this.onChangePage.bind(this);
    }

//Function: show popup box after adding new product in cart
togglePopupBox (arg) {
    if (this.state.showPopupClass === '') {
        this.setState({
            showPopupClass: arg
        })
     //   setTimeout(function(){
     //       this.setState({
     //           showPopupClass: ''
     //       })
     //  }.bind(this),7500);
    } 
  }

//Function: hide popup box after adding new product in cart
hidePopupBox (arg){
    this.setState({
        showPopupClass: arg
    })
  }


//Function: pass product data to popup
productDataForPopup (title,slug, category, price,oldPrice,image,productId, quantity, prAvailable ){
    this.setState({
        popupImage: image,
        popupPrice: price,
        popupOldPrice: oldPrice,
        popupSlug: slug,
        popupTitle: title,
        popupCategory: category,
        productId: productId,
        popupQuantity: 1,
        prAvailable: prAvailable,
    })
}

//Function: Splice products for pagination
onChangePage(pageOfProducts) {
    this.setState({ 
        pageOfProducts: pageOfProducts 
    });
}

//Function: update single product data when product is opened
singleProductData(productSlug){}

getSettings() {
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/settings/get-settings')
    .then((response) => {
    this.setState({
        pageSize: response.data.pageSize,
    });
    })
    .catch((error) => {
    console.log(error);
    })
  }

  
getProductsList() {  
    const actionProductsArray = [];
    
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/brands/edit-brand/' + this.props.match.params.slug)
    .then((response) => {
        this.setState({
        brandName: response.data.name,
        });
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/brand/'+response.data.brand_id)
        .then((response) => {
        response.data.forEach((doc) => {
            const { title, slug, price, image_thumb, priceAction, category, productOffer, productAction, actionPercent,
                deliverer, productVariety, brand, vele_price, markup,available,purpose } = doc;
                actionProductsArray.push({
                    key: doc._id,
                    doc, 
                    title,
                    slug, 
                    price,
                    image_thumb,
                    priceAction,
                    actionPercent,
                    category,
                    productOffer,
                    productAction,
                    purpose,
                    deliverer,
                    productVariety,
                    brand,
                    markup,
                    vele_price,
                    available,
                    productCategorySlug: doc.categoryData[0].slug,
                    brandDiscount: doc.brandData[0].discount,
                    productBrandName: doc.brandData[0].slug,
                    productBrandFullName: doc.brandData[0].name,
                    productBrandLogo: doc.brandData[0].image,
                });
        });
        this.setState({
            actionProducts: actionProductsArray,
            loading: false,
        })
    }).catch((error) => {
        console.log(error);
    })
        })
        .catch((error) => {
        console.log(error);
    })
}
    
componentDidMount = () => {
    this.getProductsList();
    this.getSettings();
}

    render(){
        return(
            <ShopWrapper pageName="brand"  subPageName="">
                <section id="p_brand" className="section actions-page brand-page">
                <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
              </Helmet>
                    {/******************* Loader box *******************/}
                    {this.state.loading && (<LoaderBox /> )}

                    <div className="container">   
                    {/******************* Main title *******************/}
                    <MainTitle title={pageTitle+": " + this.state.brandName} />

                    <div className="columns inner products-slider is-multiline">
                    {this.state.showPopupClass === 'show' && (
                           <ProductPopup
                           title={this.state.popupTitle}
                           slug={this.state.popupSlug}
                           category={this.state.popupCategory}
                           price={this.state.popupPrice}
                           oldPrice={this.state.popupOldPrice}
                           image={this.state.popupImage}
                           productId={this.state.productId}
                           popupQuantity={this.state.popupQuantity}
                           prAvailable={this.state.prAvailable}
                           showClass={this.state.showPopupClass}
                           hidePopupBox = {this.hidePopupBox.bind(this)} />
                            )}
                            
                        {this.state.pageOfProducts.map(product =>
                            <div className="column is-one-fifth single">
                            {/******************* Product popup *******************/}
                         
                          
                          {/******************* Call product component *******************/}
                            <Product product={product} 
                            key={product.key} 
                            togglePopupBox = {togglePopupBox.bind(this)} 
                            productDataForPopup={productDataForPopup.bind(this)}
                            singleProductData={(productSlug) => this.singleProductData(productSlug)}
                            />
                            </div>
                            )}
                            </div>
                            {this.state.pageOfProducts.length === 0 && (
                                <p className="no-products"><span className="icon"></span>Trenutno nemamo proizvoda u ovome brendu.</p>
                            )}

                              <div className="pagination-wrapper pagination-bottom">
                                <Pagination items={this.state.actionProducts} onChangePage={this.onChangePage} pageName={this.state.pageName} pageSize={15} /> 
                            </div> 
                            
                    </div>
                </section>
            </ShopWrapper>
        );
    }
}

export default Actions