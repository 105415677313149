import React from 'react'
import { Link } from 'react-router-dom'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import Pagination from '../Pagination';
import {Helmet} from "react-helmet";
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addCategory, upload } from '../../helpers/UserFunctions'
import axios from 'axios';
var slugify = require('slugify')
var togglePopupDelete;

//Komponenta Kategorije: Prikazuje listu svih trenutnih kategorija, sa opcijama uređivanja, pregledanja i brisanja.
//Sadrži formu za dodavanje nove kategorije


class Categories extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;
        this.state = {
            categories: [],
            pageOfCategories: [],
            allCategories: [],
            filtersCategories: [],
            allFiltersCategories: [],
            catFiltersCategories: [],
            checkedFiltersCategories: [],
            id: '',
            showPopupDeleteClass: '',
            name: '',
            image: null,
            slug: '',
            parentCategory: '',
            color: '',
            showInMenu: '',
            imageFile: null,
            singleCategoryKey: '',
            singleCategoryName: '',
            addedCategoryName: '',
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
            loading:false,
            categoryOrder: "",
        }   
        this.onChangePage = this.onChangePage.bind(this);
        this.onChangeImage = this.onChangeImage.bind(this);
        this.resetImage = this.resetImage.bind(this);

        togglePopupDelete = this.togglePopupDelete.bind(this);
    }

//Prikaz popupa za brisanje kategorije
togglePopupDelete (id, name) {
    if (this.state.showPopupDeleteClass === '') {
        this.setState({
            showPopupDeleteClass: 'show',
            singleCategoryKey: id,
            singleCategoryName: name,
        })
    }
    else{
        this.setState({
            showPopupDeleteClass: ''
        })
    }     
  }

//Metoda za brisanje kategorije
deleteCategory(id){
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/delete-category/' + id)
    .then(() => {
        this.getCategoryList();
        this.setState({
            showPopupDeleteClass: '',
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Uspješno ste izbrisali kategoriju "<b>' + this.state.singleCategoryName + '"</b>',
        stickerType: 'success',
        })
    })
    .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Došlo je do problema prilikom brisanja kategorije: ' + error,
        stickerType: 'danger',
    })
    });
}

onChangeImage = (event) => {
    this.setState({
      imageFile:event.target.files[0],
      image: URL.createObjectURL(event.target.files[0]),
  });
 
}

resetImage(event) {
    event.preventDefault();
    this.setState({ image: null });
}
  

//Metoda potrebna za paginaciju, prelaz između stranica
onChangePage(pageOfCategories) {
    this.setState({ 
        pageOfCategories: pageOfCategories 
    });
}


//Spremanje podataka sa inputa u state
handleChange = (e) => { 
    this.state.slug = slugify(this.state.name, {
        replacement: '-',
        remove: null,
        lower: true
      });    
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
}

handleChangeFiltersCategories = (e) =>  {
    if(this.state.catFiltersCategories.length !== 0){
    }
      var allFiltersCategories = this.state.allFiltersCategories;
      if(e.target.checked === true){
          this.state.catFiltersCategories.push( parseInt(e.target.value) )
      }
      else{
          for(var i in this.state.catFiltersCategories){
              if(this.state.catFiltersCategories[i] == e.target.value){
                  this.state.catFiltersCategories.splice(i,1);
              }
          }
      }
      allFiltersCategories.forEach(filtersCategory => {
         if (filtersCategory.filter_id == e.target.value){
          filtersCategory.isChecked =  e.target.checked
         }          
      }) 
      this.state.catFiltersCategories = this.state.catFiltersCategories
    this.setState({
      allFiltersCategories: allFiltersCategories,
    })
  
}

//Spremanje podataka u kolekciju
onSubmit = (e) => {
    const {name, slug, parentCategory, showInMenu, color, catFiltersCategories, categoryOrder} = this.state;
    var imageFile = this.state.imageFile;
e.preventDefault();
if(imageFile === null){
    imageFile = new Object();
    imageFile.name = "empty"
}
const newCategory = {
    name: name,
    slug: slug,
    parentCategory: parseInt(parentCategory),
    showInMenu: showInMenu,
    color: color,
    categoryOrder,
    filtersCategories: JSON.stringify(catFiltersCategories),
    image: imageFile.name
    }

    addCategory(newCategory).then(res => {
        this.getCategoryList();
    this.setState({
        addedCategoryName: name,
        showStickerClass: 'show',
    })
    setTimeout(function(){
        this.setState({
            showStickerClass: ''
        })
    }.bind(this),timeoutDelay);
    this.setState({
        stickerMessage: 'Uspješno ste dodali kategoriju <b>"' + this.state.addedCategoryName + '"</b>',
        stickerType: 'success',
        id: '',
        name: '',
        slug: '',
        parentCategory: '',
        showInMenu: '',
        categoryOrder: '',
        image: '',
        });

    this.props.history.push("/administracija/kategorije")
          })
          .catch((error) => {
            this.setState({
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
           }.bind(this),timeoutDelay);
           this.setState({
            stickerMessage: 'Došlo je do problema prilikom dodavanja kategorije: ' + error,
            stickerType: 'danger',
        })
        });
   
    if(imageFile !== null){
        const addImage = new FormData();
        addImage.append(
        'image',this.state.imageFile);        
        const config = {       
        headers: {'content-type': 'multipart/form-data'}
        };
    
        upload(addImage, config) .then((response) => {
            }).catch((error) => {
        });
    }
}

getCategoryList() {  
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories')
.then((response) => {
        this.setState({
    allCategories: response.data
    });
})
.catch((error) => {
    console.log(error);
})
}


getFiltersCategoriesList() {  
    var allFiltersCategoriesArray = [];
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters')
.then((response) => {

    response.data.forEach((doc) => {
        const { filter_id, name } = doc;
        allFiltersCategoriesArray.push({
          filter_id,
          isChecked: false,
          name,
        });          
      });
    this.setState({
        allFiltersCategories: allFiltersCategoriesArray,
    })
})
.catch((error) => {
    console.log(error);
})
}

componentDidMount = () => {
    this.getCategoryList();
    this.getFiltersCategoriesList();
}

    render(){
    console.log(this.state.allFiltersCategories)
        const { id, name, slug, parentCategory, showInMenu, image, color, categoryOrder} = this.state;
        return(
            <AdminWrapper>
            <section id="categories" className="section default-section content-page-section add-category default-form">
            {this.state.loading && (
                <div className="loader-box">
                    <span className="loader"></span>
                </div>
            )}

                <div className={"popup-wrapper " + this.state.showPopupDeleteClass}>
                    <div className="overlay-back"></div>
                    <div className="popup-box popup-delete">
                        <h6 className="text">Jesi li siguran da želiš izbrisati kategoriju <br /><b>"{this.state.singleCategoryName}"</b>?</h6>
                        <div className="button-area">
                            <button className="button confirm" onClick={this.deleteCategory.bind(this, this.state.singleCategoryKey)}>Želim <FontAwesomeIcon icon="trash-alt" /></button>
                            <button className="button decline" onClick={this.togglePopupDelete.bind(this)}>Ne, hvala <FontAwesomeIcon icon="times-circle" /></button>
                        </div>
                    </div>
                </div>
                {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
              
                <div className="container">
                <Helmet>
                    <title> Stranica 1  &rsaquo; Kategorije  &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="content">
                        <div className="page-header">
                           <h2 className="page-title">Nova kategorija</h2>
                        </div>
                        <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <span>Kategorije</span>
                        </div>
                        <div className="columns">
                            <div className="column is-5">
                                <form name="add-category" onSubmit={this.onSubmit} className="form border-form">
                                 <fieldset className="">
                                    <label htmlFor="name" className="column is-12 title">
                                        Ime kategorije
                                        <input type="text" id="name" name="name" placeholder="Puno ime kategorije"
                                        value={name} onChange={ this.handleChange} required/>
                                    </label> 

                                    <label htmlFor="slug" className="column is-12 slug">
                                        <span>Generisani URL</span>
                                        <input type="text" id="slug" name="slug" placeholder="Slug kategorije"
                                        value={slug} onChange={ this.handleChange} required/>
                                    </label> 

                                    <label htmlFor="parentCategory" className="column is-12">
                                        Glavna kategorija
                                        <select id="parentCategory" name="parentCategory" onChange={this.handleChange} required>
                                            <option value="null">Glavna kategorija</option>
                                            {this.state.allCategories.map(category =>
                                            category.parentCategory === null && (
                                            <option value={category.category_id}>{category.name}</option>
                                            )
                                            )}
                                        </select>
                                    </label>
                                                            
                                        <label htmlFor="showInMenu" className="column is-12">
                                        Prikazati kategoriju u glavnom meniju?
                                            <select id="showInMenu" name="showInMenu" value={showInMenu} onChange={ this.handleChange }>
                                                <option value="">Izaberi</option>
                                                <option value="0">Ne</option>
                                                <option value="1">Da</option>
                                            </select>   
                                        </label>

                                        <label htmlFor="filtersCategories" className="column is-12 filters-categories">
                                        Izaberi kategorije filtera
                                        <div className="cat-filters-list columns is-multiline">
                                        {this.state.allFiltersCategories.map((filtersCategory) => {
                                        return (
                                        <div className="single-line column is-6">
                                        <input key={filtersCategory.filter_id} 
                                        onClick={this.handleChangeFiltersCategories} 
                                        type="checkbox" 
                                        value={filtersCategory.filter_id} 
                                        checked={filtersCategory.isChecked}
                                        /> 
                                        {filtersCategory.name}
                                        </div>
                                        )
                                        })}
                                        </div>
                                        </label>

                                    <label htmlFor="color" className="column is-12">
                                    Boja kategorije
                                        <select id="color" className="select-color" name="color" value={color} onChange={ this.handleChange }>
                                            <option value="">Izaberi</option>
                                            <option value="zdravlje">Zdravlje</option>
                                            <option value="aparati-i-pomagala">Aparati i pomagala</option>
                                            <option value="drogerija">Drogerija</option>
                                            <option value="ljepota-i-njega">Ljepota i njega</option>
                                            <option value="sport-i-rekreacija">Sport i rekreacija</option>
                                        </select>   
                                    </label>

                                    <label htmlFor="slug" className="column is-12">
                                        <span>Pozicija kategorije</span>
                                        <input type="text" id="categoryOrder" name="categoryOrder" placeholder="Pozicija kategorije"
                                        value={categoryOrder} onChange={ this.handleChange}/>
                                    </label> 

                                    <div id="upload_button" className="">
                                    <label htmlFor="image" className="label-image">
                                        Istaknuta slika
                                    <input type="file" id="image" name="image" onChange={ this.onChangeImage }/>
                                    <span className="add-image">Dodaj istaknutu sliku <FontAwesomeIcon icon="image" /></span>
                                    </label>
                                    {image && (
                                    <div className="image-preview">
                                         <img src={image} alt="" />
                                        {image && (                                 
                                                <button className="remove-button" onClick={this.resetImage}><i className="icon"></i></button>
                                                )}
                                    </div>
                                    )}
                                       
                                    </div>       
                                    <button type="submit" className="button default default-no-icon add"><span className="item">Dodaj kategoriju <FontAwesomeIcon icon="plus-circle" /></span></button>

                            </fieldset>
                        </form>
                            </div>
                            <div className="column is-7 default-list category-list is-right">
                            {this.state.allCategories.map(singleCategory =>
                             singleCategory.parentCategory === null && (
                               <div className="inner-box">

                               <div className="single-row">
                                    <div className="image"><img src={process.env.REACT_APP_FILES_DESTINATION + singleCategory.image} /></div>
                                    <h1 className="title">{singleCategory.name}</h1>
                                    <div className="options is-right">
                                        <Link to={`/administracija/uredjivanje-kategorije/${singleCategory.slug}`} className="link"><FontAwesomeIcon icon="edit" /></Link>
                                        <button onClick={this.togglePopupDelete.bind(this, singleCategory._id, singleCategory.name)} className="link"><FontAwesomeIcon icon="trash-alt" /></button>
                                    </div>
                                </div>
                               
                                {this.state.allCategories.map(singleSubCategory => 
                                    <>
                                    {singleSubCategory.parentCategory === singleCategory.category_id && (
                                    <div className="single-row subcategory">
                                            <h1 className="title">{singleSubCategory.name}</h1>
                                            <div className="options is-right">
                                                <Link to={`/administracija/uredjivanje-kategorije/${singleSubCategory.slug}`} className="link"><FontAwesomeIcon icon="edit" /></Link>
                                                <button onClick={this.togglePopupDelete.bind(this, singleSubCategory._id, singleSubCategory.name)} className="link"><FontAwesomeIcon icon="trash-alt" /></button>
                                            </div>
                                        </div>
                                        )} 
                                    </>
                                    )}
                                </div>
                            )
                            )} 
                           
                        {/*       <div className="pagination-wrapper pagination-bottom">
                                <Pagination items={this.state.allCategories} onChangePage={this.onChangePage} pageName="Kategorije" /> 
                                    </div>  */}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            </AdminWrapper>
        )
    }
}

export default Categories