/*
Category page (Dynamic)
Display single category, with category information, subcategories, filters and products from selected category. Main category/subcategory page
*/
import React from 'react'
import { Link } from 'react-router-dom'
import Product from '../product/Product'
import ProductPopup from '../product/ProductPopup'
import WideBanner from '../banners/WideBanner'
import {Helmet} from "react-helmet";
import Header from '../Header'
import Footer from '../Footer'
import Pagination from '../Pagination';
import slugify from 'react-slugify';
import { siteTitle } from '../../../functions/config'
import LoaderBox from '../../helpers/LoaderBox';
import {categoryUrl} from '../../../functions/pageUrls'
import axios from 'axios';
import Popup from '../homepage/Popup'

var pageTitle = "Kategorije";
var togglePopupBox, productDataForPopup, changeCategoryData, changeCategoryDataMain, addFilters, removeFilters, sortProducts;

var sortingOptions = [
    {
      name: "Najnovije",
      slug: "newest",
    },
    {
        name: "Najstarije",
        slug: "oldest",
    },
    {
        name: "Cijena - najniža",
        slug: "price-low-to-high",
    },
    {
        name: "Cijena - najviša",
        slug: "price-high-to-low",
    },
    {
        name: "Abecedno",
        slug: "name",
    }
  ];

class Category extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
    
        this.state = {
            showPopupClass: '',
            name: '',
            image: '',
            slug: '',
            color: '',
            popupTitle: '',
            popupPrice: '',
            popupOldPrice: '',
            popupImage: '',
            popupSlug: '',
            popupCategory: 'empty',
            productId: 'empty',
            popupQuantity: '',
            categoryName: '',
            subCategories: [],
            categoryProducts: [],
            categoryProductsForRemove: [],
            subCategoryProductsForRemove: [],
            pageOfProducts: [],
            subCategoryProducts: [],
            categorySlug: this.props.match.params.slug,
            subCategorySlug: this.props.match.params.subCategory,
            pageName: '',
            categoryColor: '',
            pageSize: 1,
            catFiltersCategories: [],
            catFiltersCategoriesIds: [],
            productFilters: [],
            addedFilters: [],
            loading: false,
            showFilterBoxClass: '',
            subCategoryName: '',
            categoryName: '',
            selectedOption: null,
            filtersCategoriesFiltersIds: [],
            subFiltersCategoriesFiltersIds: [],
            sortBy: '',
            showMobileFiltersCats: '',
            categoryId: '',
            subCategoryId: '',
            loadingSubCategory: false,
            sortActive: '',
            showSortList: '',
            currentSortingOption: 'newest'
        }

        changeCategoryData = this.changeCategoryData.bind(this);
        addFilters = this.addFilters.bind(this);
        removeFilters = this.removeFilters.bind(this);
        changeCategoryDataMain = this.changeCategoryDataMain.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        togglePopupBox	= this.togglePopupBox.bind(this);
        productDataForPopup = this.productDataForPopup.bind(this);
        this.hidePopupBox = this.hidePopupBox.bind(this);  
        sortProducts = this.sortProducts.bind(this);
        this.openSortList = this.openSortList.bind(this);
    }

//Function: show popup box after adding new product in cart
togglePopupBox (arg) {
    if (this.state.showPopupClass === '') {
        this.setState({
            showPopupClass: arg
        })
        //   setTimeout(function(){
        //       this.setState({
        //           showPopupClass: ''
        //       })
        //  }.bind(this),7500);
    }     
}

//Function: hide popup box after adding new product in cart
hidePopupBox (arg){
    this.setState({
        showPopupClass: arg
    })
}
      
//Function: pass product data to popup
productDataForPopup (title,slug, category, price,oldPrice,image,productId, quantity, prAvailable ){
    this.setState({
        popupImage: image,
        popupPrice: price,
        popupOldPrice: oldPrice,
        popupSlug: slug,
        popupTitle: title,
        popupCategory: category,
        productId: productId,
        popupQuantity: 1,
        prAvailable,
    })
}

//Function: show and hide filters and subcategories on mobile version
toggleMobileFiltersCats () {
    if (this.state.showMobileFiltersCats === '') {
        this.setState({
            showMobileFiltersCats: 'show'
        })
    }
    else{
        this.setState({
            showMobileFiltersCats: ''
        })
    }     
}


//Function: Splice products for pagination
onChangePage(pageOfProducts) {
    this.setState({ 
        pageOfProducts: pageOfProducts 
    });
        //console.log(pageOfProducts)

}

//Function: update informations about category from menu in header
headerCategoryData(categorySlug, subCategorySlug, categoryId, subCategoryId){ //Fuction
    this.setState({ 
        categorySlug: categorySlug,
        subCategorySlug: subCategorySlug,    
        categoryId: categoryId,
        subCategoryId: subCategoryId,
        loading:false
    }); 
    window.scrollTo(0, 0);
    this.changeCategoryData (categorySlug, subCategorySlug, subCategoryId, this.state.subCategoryName)
    this.getMainCategory(categorySlug);   
    this.getSettings();
    this.getSubCategory(subCategorySlug); 
 } 

 //Function: update single product data when product is opened
 singleProductData(productSlug){}


//Collect settings data
getSettings(){
    //Get settings data
  axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/settings/get-settings')
  .then((response) => {
    this.setState({
        pageSize: response.data.productsInCategory,
    });
    })
    .catch((error) => {
    console.log(error);
    })
  }

  openSortList () {
    if (this.state.showSortList === '') {
        this.setState({
            showSortList: 'show',
        })
    } 
    else{
        this.setState({
            showSortList: '',
        })
    }    
}


//Collect main category and filters from selected category
getMainCategory(catSlug){
    this.setState({
        addedFilters: []
    })
    var subcategoriesArray = [],categoryProductsArray = [], newFiltersGroup, newFilter, productFilters = [],
    filtersCategoriesIds = [], filtersCategoriesFiltersIds = [];
    this.setState({
        loadingSubCategory: true,
    })
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/edit-category/' + catSlug)
    .then((response) => {
        const { category_id, name, image,slug , color} = response.data;
        this.setState({
            id:category_id,
            name,
            image,
            slug,
            color,
            categoryName: name,
            categorySlug: slug,
            categoryId: category_id,
        }) 

        //Collect subcategories from selected category
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/subcategories/'+category_id)
        .then((response) => {
            response.data.forEach((doc) => {
                const { _id, category_id, name, image,slug, parentCategory } = doc;
            subcategoriesArray.push({
                key: _id,
                id: category_id,
                name,
                image,
                slug,
                parentCategory,
                });
            });
            this.setState({
                subCategories: subcategoriesArray,
            })
        })
        .catch((error) => {
        console.log(error);
        })

         //Collect products from selected category
         axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/categories/'+category_id)
        .then((response) => {
        response.data.forEach((doc) => {
            const { title,product_id, slug, price, image_thumb, priceAction, category,categories, productOffer, productAction, actionPercent, 
                deliverer,productVariety, filters, brand, vele_price, markup, available,purpose } = doc;
                categoryProductsArray.push({
                    key: doc._id,
                    product_id,
                    title,
                    slug, 
                    price,
                    image_thumb,
                    priceAction,
                    actionPercent,
                    category,
                    productOffer,
                    productAction,
                    deliverer,
                    productVariety,
                    filters,
                    brand,
                    vele_price,
                    markup,
                    categories,
                    purpose,
                    available,
                    productCategorySlug: doc.categoryData.slug,
                    productBrandName: doc.brandData.slug,
                    brandDiscount: doc.brandData.discount,
                    productBrandFullName: doc.brandData.name,
                    productBrandLogo: doc.brandData.image,
                    });  

                    var filtersCategories = doc.filters;
                    if(filtersCategories !== undefined && filtersCategories.length > 0)
                    {
                        filtersCategories =  JSON.parse(filtersCategories);
                   /* filtersCategories.forEach(filter => {
                        if(!filtersCategoriesIds.includes(filter.cat_id))
                        filtersCategoriesIds.push(filter.cat_id);  
                    })
                   */
                  filtersCategories.forEach(filter => {
                    filtersCategoriesFiltersIds.push(filter.filter_id);   
                    })
                } 
            })
            this.setState({
                loadingSubCategory: false,
                categoryProducts: categoryProductsArray,
                filtersCategoriesFiltersIds: filtersCategoriesFiltersIds,
                categoryProductsForRemove: categoryProductsArray,
            })
        }).catch((error) => {
            console.log(error);
        })

        //Collect filters
        filtersCategoriesIds = JSON.parse(response.data.filtersCategories);

        for(var i in filtersCategoriesIds) {
            axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters/product-filters/' + parseInt(filtersCategoriesIds[i]))
            .then((response) => {
                if(response.data !== null){
                        const { name,filter_id, filters  } = response.data;
                        var usedFilters = [];
                     
                            newFiltersGroup = new Object();
                            newFiltersGroup.groupName = name;
                            newFiltersGroup.id = filter_id;
                            JSON.parse(filters).forEach(filter => {
                                    newFilter = new Object();
                                    newFilter.value = filter.id;
                                    newFilter.label = filter.name;
                                    newFilter.status = "inactive";
                                    usedFilters.push(newFilter);
                            })
                            newFiltersGroup.filters = usedFilters;
                            productFilters.push(newFiltersGroup);
                    this.setState({
                    productFilters: productFilters,
                    })
                }
            });       
        } 
    })
    .catch((error) => {
    console.log(error);
    })
}

//Collect products from selected subcategory
getSubCategory(subCatSlug){
    var subCategoryProductsArray = [], subFiltersCategoriesFiltersIds = [];
    this.setState({
        loadingSubCategory: true,
    })
    if(subCatSlug){
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/edit-category/' + subCatSlug)
        .then((response) => {
            const { category_id, name, slug} = response.data;
            this.setState({
                subCategoryName: name,
                subCategorySlug: slug,
                subCategoryId: category_id,
            })
            
        //Collect products from selected subcategory 
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/subcategories/'+category_id)
        .then((response) => {
        response.data.forEach((doc) => {
            const { title,product_id, slug, price, image_thumb, priceAction, category,categories, productOffer, productAction, actionPercent, 
                deliverer,productVariety, filters, brand, vele_price, markup, available,purpose } = doc;
                subCategoryProductsArray.push({
                    key: doc._id,
                    product_id,
                    title,
                    slug,
                    price,
                    image_thumb,
                    priceAction,
                    actionPercent,
                    category,
                    productOffer,
                    productAction,
                    deliverer,
                    productVariety,
                    filters,
                    brand,
                    vele_price,
                    markup,
                    purpose,
                    available,
                    categories,
                    productCategorySlug: doc.categoryData.slug,
                    productBrandName: doc.brandData.slug,
                    brandDiscount: doc.brandData.discount,
                    productBrandFullName: doc.brandData.name,
                    productBrandLogo: doc.brandData.image,
                });

                var filtersCategories = doc.filters;
                if(filtersCategories !== undefined && filtersCategories.length > 0)
                {
                    filtersCategories =  JSON.parse(filtersCategories);
                /* filtersCategories.forEach(filter => {
                        if(!filtersCategoriesIds.includes(filter.cat_id))
                        filtersCategoriesIds.push(filter.cat_id);  
                    })
                    */
                    filtersCategories.forEach(filter => {
                        subFiltersCategoriesFiltersIds.push(filter.filter_id);   
                    })
                }
             
            })
             //  this.onChangePage(subCategoryProductsArray); 
             this.setState({
                loadingSubCategory: false,
                subCategoryProducts: subCategoryProductsArray,
                subFiltersCategoriesFiltersIds: subFiltersCategoriesFiltersIds,
                subCategoryProductsForRemove: subCategoryProductsArray,
            })

        })
        .catch((error) => {
        console.log(error);
        })
    })
    .catch((error) => {
    console.log(error);
    })
    }
}

//Function: add filters to array
addFilters(value, label){
    window.scrollTo(0,0); 
    this.setState({
        showMobileFiltersCats: '',
    })
    var categoryProducts;
    var checkSubCat = this.state.subCategorySlug;
    if(checkSubCat !== undefined){
        categoryProducts = this.state.subCategoryProducts;
    }
    else{
        categoryProducts = this.state.categoryProducts;
    }
    var addedFilters = this.state.addedFilters;
    let addedFiltersIds = [], filters = [];
    var newCategoryProducts = [];
    var newFilter = new Object();
    newFilter.value = value;
    newFilter.label = label;

    let checkIfFilterExist = addedFilters.some( addedFilter => addedFilter['value'] === value );
        if(!checkIfFilterExist){
            addedFilters.push(newFilter);
            addedFilters.forEach(addedFiltersId => {
                addedFiltersIds.push(addedFiltersId.value);
                
                this.state.productFilters.forEach(filterGroup => {
                  filterGroup.filters.forEach(filter => {
                    if(filter.value === value){
                        filter.status = "active";
                    }
                  })
                })
            })
           
    for(var i in categoryProducts){
        var productFilterId = [];
        if(categoryProducts[i].filters !== undefined && categoryProducts[i].filters.length > 0)
        { 
            filters =  JSON.parse(categoryProducts[i].filters);
            filters.forEach(filter => {
                if(!productFilterId.includes(filter.filter_id))
                productFilterId.push(filter.filter_id);  
            })  
        }
        var intersectRes = productFilterId.filter(value => addedFiltersIds.includes(value))
      
        if(addedFiltersIds.length === intersectRes.length){
            for(var j in intersectRes){
                if(addedFiltersIds.indexOf(intersectRes[j]) !== -1 && !newCategoryProducts.includes(categoryProducts[i])){
                    newCategoryProducts.push(categoryProducts[i]);
                }
            }
        } 
    }
    
    if(checkSubCat !== undefined){
        var subFiltersCategoriesFiltersIds = [];
        this.state.subCategoryProducts = newCategoryProducts;
        newCategoryProducts.map(product => {
            JSON.parse(product.filters).forEach(filter => {
                subFiltersCategoriesFiltersIds.push(filter.filter_id);   
            })
        })
        this.setState({
            subFiltersCategoriesFiltersIds
        })
    }
    else{
        this.state.categoryProducts = newCategoryProducts;
        var filtersCategoriesFiltersIds = [];
        newCategoryProducts.map(product => {
            JSON.parse(product.filters).forEach(filter => {
                filtersCategoriesFiltersIds.push(filter.filter_id);   
            })
        })
        this.setState({
            filtersCategoriesFiltersIds
        })
    }
    this.onChangePage(this.state.categoryProducts); 
    }
}

//Function: remove filters from array
removeFilters(value){
    var categoryProducts;
    var checkSubCat = this.state.subCategorySlug;
    if(checkSubCat !== undefined){
        categoryProducts = this.state.subCategoryProductsForRemove;
    }
    else{
        categoryProducts = this.state.categoryProductsForRemove;
    }

    var addedFilters = this.state.addedFilters;
    let addedFiltersIds = [], filters = [];
    var newCategoryProducts = [];
    for(var i in addedFilters){  
        if(addedFilters[i].value == value){
            addedFilters.splice(i,1)
        }
    }

    this.state.productFilters.forEach(filterGroup => {
        filterGroup.filters.forEach(filter => {
          if(filter.value === value){
              filter.status = "inactive";
          }
        })
      })

    addedFilters.forEach(addedFiltersId => {
        addedFiltersIds.push(addedFiltersId.value);
    })

    for(var i in categoryProducts){
        var productFilterId = [];
        if(categoryProducts[i].filters !== undefined && categoryProducts[i].filters.length > 0)
        {
            filters =  JSON.parse(categoryProducts[i].filters);
            filters.forEach(filter => {
                if(!productFilterId.includes(filter.filter_id))
                productFilterId.push(filter.filter_id);  
            })  
        }
        var intersectRes = productFilterId.filter(value => addedFiltersIds.includes(value))
        if(addedFiltersIds.length !== 0){
            if(addedFiltersIds.length === intersectRes.length){
        
                for(var j in intersectRes){
                    if(addedFiltersIds.indexOf(intersectRes[j]) !== -1 && !newCategoryProducts.includes(categoryProducts[i])){
                        newCategoryProducts.push(categoryProducts[i]);
                    }
                }
            } 
        }
        else {
            newCategoryProducts.push(categoryProducts[i]);
        }
    }
 
  if(checkSubCat !== undefined){
    var subFiltersCategoriesFiltersIds = [];
    this.state.subCategoryProducts = newCategoryProducts;
    newCategoryProducts.map(product => {
        JSON.parse(product.filters).forEach(filter => {
            subFiltersCategoriesFiltersIds.push(filter.filter_id);   
        })
    })
    this.setState({
        subFiltersCategoriesFiltersIds
    })
    }
    else{
        this.state.categoryProducts = newCategoryProducts;
        var filtersCategoriesFiltersIds = [];
        newCategoryProducts.map(product => {
            JSON.parse(product.filters).forEach(filter => {
                filtersCategoriesFiltersIds.push(filter.filter_id);   
            })
        })
        this.setState({
            filtersCategoriesFiltersIds
        })
    }
    this.onChangePage(newCategoryProducts); 
}


sortProducts(type){
    var categoryProducts;
    var checkSubCat = this.state.subCategorySlug;
    if(checkSubCat !== undefined){
        categoryProducts = [...this.state.subCategoryProducts];
    }
    else{
        categoryProducts = [...this.state.categoryProducts];
    }
    this.setState({
        showSortList: '',
        currentSortingOption: type,
    })
   
    if(type === "newest"){
        categoryProducts.sort((a,b) => (b.product_id > a.product_id) ? 1 : ((a.product_id > b.product_id) ? -1 : 0));
    }
    if(type === "oldest"){
        categoryProducts.sort((a,b) => (a.product_id > b.product_id) ? 1 : ((b.product_id > a.product_id) ? -1 : 0));
    }
    if(type === "price-low-to-high"){
        categoryProducts.sort((a,b) => (parseInt(a.price) > parseInt(b.price)) ? 1 : ((parseInt(b.price) > parseInt(a.price)) ? -1 : 0));
    }
    if(type === "price-high-to-low"){
        categoryProducts.sort((a,b) => (parseInt(b.price) > parseInt(a.price)) ? 1 : ((parseInt(a.price) > parseInt(b.price)) ? -1 : 0));
    }
    if(type === "name"){
        categoryProducts.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
    } 
    if(checkSubCat !== undefined){
        this.setState({
            subCategoryProducts: categoryProducts,
        })
    }
    else{
        this.setState({
            categoryProducts: categoryProducts,
        })
    }
}


//Function: update products when main category is selected from subactegories menu in sidebar
changeCategoryDataMain (categoryId, categorySlug) {
    window.scrollTo(0,0); 
    const categoryProductsArray = [];
    this.setState({
        categorySlug: categorySlug,
        subCategorySlug: undefined,
        showMobileFiltersCats: '',
        loadingSubCategory: true,
    })

    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/categories/'+categoryId)
    .then((response) => { 
        response.data.forEach((doc) => { 
        const { _id,title,product_id, slug, price, image_thumb, priceAction, category,categories, productOffer, productAction, actionPercent,
            deliverer,productVariety, filters, brand, vele_price, markup, available,purpose } = doc;
            categoryProductsArray.push({
                key: _id,
                title,
                product_id,
                slug,
                price,
                image_thumb,
                priceAction,
                actionPercent,
                category,
                productOffer,
                productAction,
                deliverer,
                productVariety,
                filters,
                brand,
                purpose,
                vele_price,
                markup,
                available,
                categories,
                productCategorySlug: doc.categoryData.slug,
                productBrandName: doc.brandData.slug,
                brandDiscount: doc.brandData.discount,
                productBrandFullName: doc.brandData.name,
                productBrandLogo: doc.brandData.image,
            }); 
        });
            this.onChangePage(categoryProductsArray);
            this.setState({
                categoryProducts: categoryProductsArray,
                loadingSubCategory: false,
            })
    })
    .catch((error) => {
        console.log(error);
    })
        
}

//Function: update products when subacategory is selected from subcategory menu in sidebar
changeCategoryData (categorySlug, subCategorySlug, subCategoryId, subCategoryName) {
    window.scrollTo(0,0); 
    var filtersCategoriesIds = [], subFiltersCategoriesFiltersIds = [];
    const subCategoryProductsArray = [];
    this.setState({
        categorySlug: categorySlug,
        subCategorySlug: subCategorySlug,
        subCategoryName: subCategoryName,
        showMobileFiltersCats: '',
        loadingSubCategory:true,
    })
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/subcategories/'+subCategoryId)
    .then((response) => {
        response.data.forEach((doc) => {   
        const { _id, title, product_id, slug, price, image_thumb, priceAction, category, categories, productOffer, productAction, 
            actionPercent, deliverer, productVariety, filters, brand, vele_price, markup, available,purpose } = doc;
            subCategoryProductsArray.push({
                key: _id,
                title,
                product_id,
                slug,
                price,
                image_thumb,
                priceAction,
                actionPercent,
                category,
                productOffer,
                productAction,
                deliverer,
                productVariety,
                filters,
                brand,
                vele_price,
                purpose,
                available,
                markup,
                categories,
                productCategorySlug: doc.categoryData.slug,
                productBrandName: doc.brandData.slug,
                brandDiscount: doc.brandData.discount,
                productBrandFullName: doc.brandData.name,
                productBrandLogo: doc.brandData.image,
            }); 

            var filtersCategories = doc.filters;
            if(filtersCategories !== undefined && filtersCategories.length > 0)
            {
                filtersCategories =  JSON.parse(filtersCategories);
                filtersCategories.forEach(filter => {
                    if(!filtersCategoriesIds.includes(filter.cat_id))
                    filtersCategoriesIds.push(filter.cat_id);  
                })

                filtersCategories.forEach(filter => {
                    subFiltersCategoriesFiltersIds.push(filter.filter_id);   
                })
            } 
        })
            this.onChangePage(subCategoryProductsArray); 
            this.setState({
                subCategoryProducts: subCategoryProductsArray,
                subFiltersCategoriesFiltersIds: subFiltersCategoriesFiltersIds,
                subCategoryProductsForRemove: subCategoryProductsArray,
                loadingSubCategory: false,
            })
            
    })
    .catch((error) => {
        console.log(error);
    })
}
componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getMainCategory(this.state.categorySlug);   
    this.getSettings();
    this.getSubCategory(this.state.subCategorySlug); 

      //Tawk chat API
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/5cb03c36d6e05b735b423133/default';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s1.setAttribute('rel','preconnect');
      s0.parentNode.insertBefore(s1,s0);
      })();
}


render(){
    var {checkSubCat, categorySlug, subCategorySlug, categoryName, categoryId, subCategoryId} = this.state;
//Check if subcategory is active
checkSubCat = (subCategorySlug !== undefined) ? true : false;

//Check which filters(category or subcategory) append to filtersIds
var filtersIds = (subCategorySlug !== undefined) ? this.state.subFiltersCategoriesFiltersIds : this.state.filtersCategoriesFiltersIds;
const { name, image, slug, color} = this.state;
//Check if product filters is equal to 0
this.state.productFilters.forEach(productFilter => {
    productFilter.isEmpty = true;   
    productFilter.filters.forEach(filter => {
        var quantity = 0;
        for(var j in filtersIds){
            if(filtersIds[j] === filter.value){
                quantity++;
            }  
        }
        filter.quantity = quantity;
        if(quantity > 0)
        productFilter.isEmpty = false;
    })     
})
 
    if(categorySlug){  
        return(
            <>  
           {/* <Popup />*/}
            <div className={"shop " + color}>
           
            {/******************* Header component *******************/}
            <Header headerCategoryData={(categorySlug, subCategorySlug, categoryId, subCategoryId) => this.headerCategoryData(categorySlug, subCategorySlug, categoryId, subCategoryId)}/>

            {/******************* Single category informations *******************/}
            <div className="category-page">
              <section id="c_intro" className="section cat-intro" style={{ backgroundImage: `url(${process.env.REACT_APP_FILES_DESTINATION+image})` }}>
                <div className="is-hidden">
                    {checkSubCat &&( 
                    this.state.pageName = this.state.categoryName + " › " + this.state.subCategoryName
                    )}
                    {!checkSubCat &&( 
                        this.state.pageName = this.state.categoryName
                    )}
                </div> 
                <div className={"overlay " + color}></div>
                <div className="container">  
                    <div className="title-box">
                        <span className="icon-category"  style={{ backgroundImage: `url(${process.env.REACT_APP_FILES_DESTINATION+image})` }}></span>
                        <h1 className="title">{name} 
                        {checkSubCat && subCategorySlug !== "null" && ( 
                        <span className="subtitle"> {this.state.subCategoryName }</span>
                        )}
                        </h1>  
                    </div>
                    {/*   
                    <div className="share-box">
                        <p>Podijeli na: </p>
                        <a href="/"><span className="facebook"></span></a>
                        <a href="/"><span className="twitter"></span></a>
                        <a href="/"><span className="email"></span></a>
                    </div>
                    */}
                </div>{/* CONTAINER END */}  
            </section>{/* CATEGORY INTRO END */}

            {/******************* Wide banner component ******************
            <WideBanner />*/}

            <section id="c_body" className="section cat-body">
            {/******************* Loader box *******************/}
                 {this.state.loading && (<LoaderBox /> )}

                <div className="container">
                    <div className="columns margin-0">
                    
                    {/******************* Button fot toggling filters and subcategories on mobile version *******************/}
                    <p className="is-mobile filters-button"  onClick={this.toggleMobileFiltersCats.bind(this)}><span className="icon"></span>Sortirajte prema osobinama</p>
                        
                        {/******************* Left area with subcategories and filters *******************/}
                        <div className={"column is-3 area-left " + this.state.showMobileFiltersCats}>
                            
                            {/******************* Subcategories *******************/}
                            <ul className="subcategories">
                            <li>
                            <Link to={`/${categoryUrl}/${categorySlug}`} onClick={this.changeCategoryDataMain.bind(this,categoryId, categorySlug)} className="parent">{categoryName}</Link></li> 
                                {this.state.subCategories.map (subCategory => (
                                <li>
                                {subCategory.slug === subCategorySlug && (
                                    <Link to={`/${categoryUrl}/${categorySlug}/${subCategory.slug}`} onClick={this.changeCategoryData.bind(this,categorySlug, subCategory.slug, subCategory.id, subCategory.name)} className={"single active"}>{subCategory.name}</Link> 
                                )} 
                                {subCategory.slug != subCategorySlug && (
                                    <Link to={`/${categoryUrl}/${categorySlug}/${subCategory.slug}`} className={"single"} onClick={this.changeCategoryData.bind(this,categorySlug, subCategory.slug, subCategory.id, subCategory.name)}>{subCategory.name}</Link>
                                )}
                                </li>    
                                ))}
                            </ul>
                             
                           {this.state.productFilters.map((filterGroup) => { 
                                return(
                                    /******************* Filters *******************/
                                    <div className="filter-box">
                                        <h4 className="filter-title">{filterGroup.groupName}</h4>
                                        {this.state.showFilterBoxClass === filterGroup.groupName && (
                                            <div className="is-hidden">{this.state.displayedFilterClass = slugify(filterGroup.groupName)}</div>
                                        )}
                                        {this.state.showFilterBoxClass !== filterGroup.groupName && (
                                            <div className="is-hidden">{this.state.displayedFilterClass = ''}</div>
                                        )}
                                    <div className={"inner "+  this.state.displayedFilterClass}>

                                    {/******************* Filters list *******************/}
                                    {filterGroup.filters.map((filter) => {  
                                        return( 
                                            <>
                                            {filter.quantity !== 0 && (
                                                <div className={"single-line " + filter.status}>
                                                <p onClick={this.addFilters.bind(this, filter.value, filter.label)}>
                                                {filter.label} ({filter.quantity})
                                                
                                                </p>
                                                {filter.status === 'active' && (
                                                    <span className="remove" onClick={this.removeFilters.bind(this, filter.value)}></span>
                                                )}
                                                </div>
                                            )}
                                            </>
                                            
                                            )
                                        })}

                                        {/******************* Empty filters *******************/}
                                        {filterGroup.isEmpty && (
                                                <div className="single-line">
                                                <p className="no-filters-found">Nema opcija za prikazati</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            })} 

                        </div>{/* AREA LEFT END */}
                       
                       {/******************* Right area with products, sorting options and pagination *******************/}
                        <div className="column is-9 area-right">
                            {this.state.loadingSubCategory && (
                             <div className="loader-box loader-box-subcategory">
                                 <span className="loader"></span>
                             </div>
                             )}
                            {/******************* Sorting options (not used) and total products number *******************/}
                            <div className="options-box">
                           {/* <div className="sort-by">
                            <select id="sortBy" name="sortBy" value={this.state.sortBy} onChange={this.handleSortChange}>
                            {console.log(this.state.sortBy)}
                                <option value="">Sortirajte proizvode</option>
                                <option value="price">Cijena - Najmanja</option>
                            </select>
                            </div>*/}
                            <div className="sort-products">
                                <div className="inner">
                                    <div className="button-area">
                                    <p className="button default open-filter sort" onClick={this.openSortList.bind(this)}>Opcije sortiranja</p>
                                    </div>
                                    <div className={"sort-filters-list "+this.state.showSortList}>
                                  
                                        {sortingOptions.map(sortingOption => (
                                            <>
                                                {sortingOption.slug === this.state.currentSortingOption && (
                                                     <span className="single-option active" onClick={this.sortProducts.bind(this,sortingOption.slug)}>{sortingOption.name}</span>
                                                )} 
                                                {sortingOption.slug != this.state.currentSortingOption && (
                                                  <span className="single-option" onClick={this.sortProducts.bind(this,sortingOption.slug)}>{sortingOption.name}</span>
                                                )}
                                            </> 
                                        ))}
                                     </div>
                                </div>
                            </div>

                            <p className="total-products">Ukupan broj prikazanih proizvoda: 
                                <b>
                                {subCategorySlug !== undefined && this.state.subCategoryProducts.length}
                                {subCategorySlug === undefined && this.state.categoryProducts.length}
                                </b>
                            </p>
                        </div>

                        {/******************* Added filters list *******************/}
                        {this.state.addedFilters.length > 0 && (
                            <div className="filters-list">
                                <h6>Odabrali ste: </h6>
                                {this.state.addedFilters.map(filter => 
                                    <p>{filter.label}<span className="remove" onClick={this.removeFilters.bind(this, filter.value)}></span></p>
                                    )}
                            </div>
                        )}

                        {/******************* Show products when subcategory is not selected *******************/}
                        {!checkSubCat  &&( 
                            <div className="columns inner products-slider is-multiline">

                                  {/******************* Product popup *******************/}
                             {this.state.showPopupClass === 'show' && (
                               <ProductPopup
                                title={this.state.popupTitle}
                                slug={this.state.popupSlug}
                                category={this.state.popupCategory}
                                price={this.state.popupPrice}
                                oldPrice={this.state.popupOldPrice}
                                image={this.state.popupImage}
                                productId={this.state.productId}
                                popupQuantity={this.state.popupQuantity}
                                prAvailable={this.state.prAvailable}
                                showClass={this.state.showPopupClass}
                                hidePopupBox = {this.hidePopupBox.bind(this)} />
                             )}
                             
                        {this.state.pageOfProducts.map(product =>
                            <div className="column is-3 single">

                           
                          
                          {/******************* Call product component *******************/}
                            <Product product={product} 
                            key={product.key} 
                            togglePopupBox = {togglePopupBox.bind(this)} 
                            productDataForPopup={productDataForPopup.bind(this)}
                            singleProductData={(productSlug) => this.singleProductData(productSlug)}
                            />
                            </div>
                            )}
                              <div className="pagination-wrapper pagination-bottom">
                                <Pagination items={this.state.categoryProducts} onChangePage={this.onChangePage} pageName={this.state.pageName} pageSize={16} /> 
                            </div> 
                            {this.state.categoryProducts.length === 0 && (
                                <p className="no-products"><span className="icon"></span>U ovoj kategoriji trenutno nemamo nijedan proizvod.</p>
                            )}
                            </div>                              
                        )}
                      
                      {/******************* Show products when subcategory is selected *******************/}
                         {checkSubCat  &&(  
                            <div className="columns inner products-slider is-multiline">

                             {/******************* Product popup *******************/}
                             {this.state.showPopupClass === 'show' && (
                             <ProductPopup
                                title={this.state.popupTitle}
                                slug={this.state.popupSlug}
                                category={this.state.popupCategory}
                                price={this.state.popupPrice}
                                oldPrice={this.state.popupOldPrice}
                                image={this.state.popupImage}
                                productId={this.state.productId}
                                popupQuantity={this.state.popupQuantity}
                                prAvailable={this.state.prAvailable}
                                showClass={this.state.showPopupClass}
                                hidePopupBox = {this.hidePopupBox.bind(this)} />
                             )}

                            {this.state.pageOfProducts.map(product =>
                            <div className="column is-3 single">

                            {/******************* Call product component *******************/}
                            <Product product={product} 
                            key={product.key} 
                            togglePopupBox = {togglePopupBox.bind(this)} 
                            productDataForPopup={productDataForPopup.bind(this)}
                            singleProductData={(productSlug) => this.singleProductData(productSlug)}
                            />
                            </div>
                            )}

                            {this.state.pageOfProducts.length === 0 && (
                                <p className="no-products"><span className="icon"></span>U ovoj podkategoriji trenutno nemamo nijedan proizvod.</p>
                            )}
                            <div className="pagination-wrapper pagination-bottom">
                                <Pagination items={this.state.subCategoryProducts} onChangePage={this.onChangePage} pageName={this.state.pageName} pageSize={16}/> 
                            </div> 
                            </div>
                         )}
                        </div>{/* AREA RIGHT END */}
                    </div>{/* COLUMNS END */}
                </div>{/* CATEGORY END */}
            </section>{/* SECTION CATEGORY BODY END */}
            </div>
            <Footer headerCategoryData={(categorySlug) => this.headerCategoryData(categorySlug)}/>
            </div>
            </>
        );
        }
        else{
            return(
                <p>Nema proizvoda za prikazati</p>
            );
        }
    }
}

export default Category