/*
My account page
Display informations for account
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'
import {siteTitle} from '../../../functions/config'
import LoaderBox from '../../helpers/LoaderBox';
import jwt_decode from 'jwt-decode'
import Logout from './Logout'
var pageTitle = "Moj račun";
class MyAccount extends React.Component{
    constructor () {

        super()
        this.unsubscribe = null;
        
        this.state = {
            userId: '',
            userName: '',
            userSurname: '',
            loading:false,
        }
      }

handleLogout(){
}

//Function: Collect data from database

componentDidMount() {
    const token = localStorage.getItem('usertoken');
    const decoded = jwt_decode(token)
    if(token){
        this.setState({
            userName: decoded.name,
            userSurname: decoded.surname,
          })
    } 
  }
    render(){
        window.scrollTo(0,0); 
        return(
            <ShopWrapper>
              {/*  <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
              </Helmet>*/}
                <section id="p_about_us" className="section my-acount-page user-page">
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={pageTitle} />

                         {/******************* Loader box *******************/}
                        {this.state.loading && (<LoaderBox /> )}

                        {/******************* Content *******************/}
                        <div className="content">
                            <div className="columns is-multiline">
                                <div className="column is-12 user-menu">
                                    <ul>
                                        <li><Link to="/moj-racun" className="current">Naslovnica</Link></li>
                                        <li><Link to="/moj-racun/narudzbe">Moje narudžbe</Link></li>
                                        <li><Link to="/moj-racun/korisnicki-podaci">Moji podaci</Link></li>
                                       {/* <li><Link to="/moj-racun/bodovi-vjernosti">Bodovi vjernosti</Link></li>*/}
                                        <Logout />
                                    </ul>
                                </div>

                                <div className="column main">
                                    <span className="welcome-image"></span>
                                    <div className="welcome-text">
                                        <h4>Dobro došli, <b>{this.state.userName} {this.state.userSurname}</b></h4>
                                        <p>Sa vašeg računa možete pratiti vaše narudžbe, upravljati vašim privatnim informacijama, bodovima vijernosti i još mnogo toga.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}
            </ShopWrapper>
        )
    }
}

export default MyAccount