import axios from 'axios'

export const register = newUser => {
  return axios
    .post('api/users/register', {
      name: newUser.name,
      surname: newUser.surname,
      email: newUser.email,
      phone: newUser.phone,
      address: newUser.address,
      postalCode: newUser.postalCode,
      city: newUser.city,
      totalPoints: newUser.totalPoints,
      newsletter: newUser.newsletter,
      couponName: newUser.couponName,
      couponExist: newUser.couponExist,
      couponValue: newUser.couponValue,
      password: newUser.password,
      isAdmin: newUser.isAdmin,
    })
    .then(response => {
      console.log('Registered')
    })
}

export const login = user => {
    return axios
      .post(process.env.REACT_APP_SERVER_PROXY_URL+'api/users/login', {
        email: user.email,
        password: user.password,
      })
      .then(response => {
       if(!response.data.error){
        localStorage.setItem('usertoken', response.data);
        console.log(response)
        return response.data
       }
      })
      .catch(err => {
        console.log(err)
      })
  }
  
/*  export const getProfile = user => {
    return axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL+'api/users/profile', {
        headers: { Authorization: ` ${this.getToken()}` }
      })
      .then(response => {
        console.log(response)
        return response.data
      })
      .catch(err => {
        console.log(err)
      })
  }
*/
  export const addBrand = newBrand => {
    return axios
      .post(process.env.REACT_APP_SERVER_PROXY_URL+'api/brands/add-brand', {
        name: newBrand.name,
        slug: newBrand.slug,
        link: newBrand.link,
        image: newBrand.image,
        metaDesc: newBrand.metaDesc,
        discount: newBrand.discount,
      })
      .then(response => {
      })
  }

  export const getBrands = () => {
    return axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL+'api/brands', {
      })
      .then(response => {
        console.log(response)
        return response.data
      })
      .catch(err => {
        console.log(err)
      })
  }

  export const upload = (addImage, config) => {
    return   axios.post( process.env.REACT_APP_SERVER_PROXY_URL+"api/upload",addImage,config)
      .then((response) => {
      }).catch((error) => {
  });
  }

  export const addSlider = newSlider => {
    return axios
      .post(process.env.REACT_APP_SERVER_PROXY_URL+'api/sliders/add-slider', {
        name: newSlider.name,
        link: newSlider.link,
        image: newSlider.image,
        imageM: newSlider.imageM,
        position: newSlider.position,
        published: newSlider.published,
      })
      .then(response => {
      })
  }

  export const addPage = newPage => {
    return axios
      .post(process.env.REACT_APP_SERVER_PROXY_URL+'api/pages/add-page', {
        title: newPage.title,
        slug: newPage.slug,
        image: newPage.image,
        facebookImage: newPage.facebookImage,
        description: newPage.description,
        products: newPage.products,
        status: newPage.status,
        metaDesc: newPage.metaDesc,
      })
      .then(response => {
      })
  }

  export const addPost = newPost => {
    return axios
      .post(process.env.REACT_APP_SERVER_PROXY_URL+'api/posts/add-post', {
        title: newPost.title,
        description: newPost.description,
        link: newPost.link,
        image: newPost.image,
        published: newPost.published,
      })
      .then(response => {
      })
  }

  export const addMarkup = newMarkup => {
    return axios
      .post(process.env.REACT_APP_SERVER_PROXY_URL+'api/markups/add-markup', {
        name: newMarkup.name,
        value: newMarkup.value,
      })
      .then(response => {
      })
  }


  export const addFilterCategory = newFilterCategory => {
    return axios
      .post(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters/add-filter-category', {
        name: newFilterCategory.name,
      })
      .then(response => {
      })
  }

  export const addCategory = newCategory => {
    return axios
      .post(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/add-category', {
        name: newCategory.name,
        slug: newCategory.slug,
        parentCategory: newCategory.parentCategory,
        showInMenu: newCategory.showInMenu,
        color: newCategory.color,
        filtersCategories: newCategory.filtersCategories,
        image: newCategory.image,
        categoryOrder: newCategory.categoryOrder
      })
      .then(response => {
      })
  }

  export const addProduct = newProduct => {
    return axios
      .post(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/add-product', {
        title: newProduct.title,
        seoTitle: newProduct.seoTitle,
        slug: newProduct.slug,
        description: newProduct.description,
        seoDescription: newProduct.seoDescription,
        shortDescription: newProduct.shortDescription,
        vele_price: newProduct.vele_price,
        price: newProduct.price,
        markup: newProduct.markup,
        priceAction: newProduct.priceAction,
        actionPercent: newProduct.actionPercent,
        available: newProduct.available,
        brand: newProduct.brand,
        category: newProduct.category,
        subCategory: newProduct.subCategory,
        categories: newProduct.categories,
        subcategories: newProduct.subcategories,
        deliverer: newProduct.deliverer,
        delivererBarcode: newProduct.delivererBarcode,
        delivererProductKey: newProduct.delivererProductKey,
        deliveryTime: newProduct.deliveryTime,
        localProductKey: newProduct.localProductKey,
        productAction: newProduct.productAction,
        productOffer: newProduct.productOffer,
        productType: newProduct.productType,
        productVariations: newProduct.productVariations,
        productVariety: newProduct.productVariety,
        status: newProduct.status,
        filters: newProduct.filters,
        displayOnHomepage: newProduct.displayOnHomepage,
        image: newProduct.image,
        image_thumb: newProduct.image_thumb,
        sliderImage1: newProduct.sliderImage1,
        sliderImage2: newProduct.sliderImage2,
        sliderImage3: newProduct.sliderImage3,
        sliderImage4: newProduct.sliderImage4,
        facebookImage: newProduct.facebookImage,
        bestSeller: newProduct.bestSeller,
      })
      .then(response => {
      })
  }

  export const addRecension = newRecension => {
    return axios
      .post(process.env.REACT_APP_SERVER_PROXY_URL+'api/recensions/add-recension', {
        productId: newRecension.productId,
        recension_name: newRecension.recension_name,
        recension_email: newRecension.recension_email,
        recension_comment: newRecension.recension_comment,
        recension_mark: newRecension.recension_mark,
        recension_date: newRecension.recension_date,
        recension_approved: newRecension.recension_approved,
        })
      .then(response => {
      })
  }

  
  export const addCart = newCart => {
    return axios
      .post(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/add-cart', {
        cartId: newCart.cartId,
        products: newCart.products,
        totalPrice: newCart.totalPrice,
        totalPoints: newCart.totalPoints,
        })
      .then(response => {
      })
  }

  export const addOrder = newOrder => {
    return axios
      .post(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders/add-order', {

        generatedOrderId: newOrder.generatedOrderId,
        orderName: newOrder.orderName,
        name: newOrder.name,
        surname: newOrder.surname,
        email: newOrder.email,
        phone: newOrder.phone,
        address: newOrder.address,
        notes: newOrder.notes,
        postalCode: newOrder.postalCode,
        city: newOrder.city,
        country: newOrder.country,
        shippingMethod: newOrder.shippingMethod,
        totalPriceNoShipping: newOrder.totalPriceNoShipping,
        shipping: newOrder.shipping,
        totalPrice: newOrder.totalPrice,
        totalPoints: newOrder.totalPoints,
        charge: newOrder.charge,
        cartId: newOrder.cartId,
        products:newOrder.products,
        userOrderId: newOrder.userOrderId,
        readedByAdmin:newOrder.readedByAdmin,
        couponName: newOrder.couponName,
        couponValue: newOrder.couponValue,
        totalPriceNoShippingFormatted: newOrder.totalPriceNoShippingFormatted,
        shippingFormatted: newOrder.shippingFormatted,
        totalPriceFormatted: newOrder.totalPriceFormatted,
        })
      .then(response => {
      })
  }