/*
Contact page
Display contact form, and contact informations about shop
*/
import React from 'react'
import { Link } from 'react-router-dom'
import ShopWrapper from '../ShopWrapper'
import {Helmet} from "react-helmet";
import { siteTitle, timeoutDelay } from '../../../functions/config'
import axios from 'axios';

var pageTitle = "Pošaljite nam poruku"
class Contact extends React.Component{

    constructor(props) {
        super(props);
        this.unsubscribe = null;

        this.state = {
            showStickerClass: '',
            name: '',
            surname: '',
            email: '',
            company: '',
            message: '',
            date: '',
            showThankYouMessage: false,
            contactEmailId: '',
        }    
    }


//Function: handle contact states
handleChange = (e) => {     
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
}

//Function: Submit contact data and email to database
onSubmit = (e) => {
    e.preventDefault();
    this.state.date = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
    const {name,surname, email, company, message, date } = this.state;

    axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/contact-form', {
        name,
        surname,
        email,
        company,
        message,
        date
    })
    .then((response) => {
    this.setState({
        name: '',
        surname: '',
        email: '',
        company: '',
        message: '',
        date: ''
    });

    this.setState({
        showThankYouMessage: true,
    })
    })
    .catch((error) => {
    this.setState({
        showStickerClass: 'show',
    })
    setTimeout(function(){
        this.setState({
            showStickerClass: ''
        })
    }.bind(this),timeoutDelay);
    this.setState({
    stickerMessage: 'Došlo je do problema prilikom slanja vašeg upita: ' + error,
    stickerType: 'danger',
    })
    });
}

componentDidMount = () => {
    window.scrollTo(0,0)
  }

    render(){
        const {name, surname, email, company, message} = this.state;
        return(
            <ShopWrapper pageName="contact">
                <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
              </Helmet>
                {/******************* Contact top *******************/}
                <section id="contact-top" className="section contact top">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-5 is-left">
                                <h4 className="title">Kontaktirajte nas</h4>
                                <div className="social-box">
                                    <a href="https://www.facebook.com/Zdravoba-1248724705303553/" target="_blank" className="icon facebook" area-label="facebook"></a>
                                    <a href="/" className="icon instagram" area-label="instagram"></a>
                                </div>
                            </div>
                            <div className="column is-7 is-right">
                                <div className="inner">
                                    <span className="icon"></span>
                                    <div className="text">
                                    <p><span>Predložite, pišite, sugerišite...</span></p>
                                    <p>Ovo je pravo mjesto za Vaše želje. Biti će nam drago da nas informišete o Vašim zahtjevima i idejama.</p>
                                  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/******************* Contact main *******************/}
                <section id="contact-main" className="section contact main">
                    <div className="container">
                    {this.state.showThankYouMessage && (
                        <div className="inner thank-you-content">
                        <h1 className="title">Hvala vam.</h1>
                        <h4 className="subtitle">Vaša poruka je zaprimljena. Potruditi ćemo se da vam odgovorimo što je prije moguće.
                        Za to vrijeme, posjetite našu online trgovinu klikom na dugme ispod.</h4>
                        <div className="button-area">
                            <Link to="/" className="continue">Nazad u trgovinu <span className="icon"></span></Link>
                        </div>
                        </div>
                    )}
                    {!this.state.showThankYouMessage && (
                        <div className="columns">
                            <div className="column is-5 is-left">
                                <h2 className="title">Nazovite nas!</h2>
                                <p>Naši saradnici Vam stoje na raspolaganju radnim danima od 09:00 do 17:00 sati.</p>
                                <div className="social-box">
                                    <span className="icon phone"></span>
                                    <span className="icon viber"></span>
                                    <span className="icon whatsapp"></span>
                                </div>
                            </div>

                            <div className="column is-7 is-right">
                            {/******************* Contact form *******************/}
                                <form onSubmit={this.onSubmit} className="default-form">
                                    <fieldset>
                                        <div className="columns is-multiline">
                                            <label className="column is-6">
                                                <input type="text" id ="name" name="name" placeholder="Ime" value={name} onChange={ this.handleChange } required />
                                            </label>

                                            <label className="column is-6">
                                                <input type="text" id ="surname" name="surname" placeholder="Prezime" value={surname} onChange={ this.handleChange } required />
                                            </label>

                                            <label className="column is-6">
                                                <input type="text" id ="email" name="email" placeholder="Email adresa" value={email} onChange={ this.handleChange } required />
                                            </label>

                                            <label className="column is-6">
                                                <input type="text" id ="company" name="company" placeholder="Kompanija" value={company} onChange={ this.handleChange } />
                                            </label>
                                            <p className="subtitle">Pišite nam</p>
                                            <label className="column is-12">
                                                <textarea id ="message" name="message" placeholder="Poruka" value={message} onChange={ this.handleChange } required></textarea>
                                            </label>
                                            <div className="button-area">
                                                <button type="submit" className="button default"><span className="icon"></span>Pošaljite poruku</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                        )}
                    </div>
                </section> 
            </ShopWrapper>
        );
    }
}

export default Contact