import React from 'react'
import { Link } from 'react-router-dom'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import {Helmet} from "react-helmet";
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { upload } from '../../helpers/UserFunctions'

var slugify = require('slugify')

class EditCategory extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;
        
        this.state = {
            categories: [],
            allCategories: [],
            filtersCategories: [],
            allFiltersCategories: [],
            checkedFiltersCategories: [],
            showPopupClass: '',
            name: '',
            id: '',
            image: null,
            slug: '',
            parentCategory: '',
            showInMenu: '',
            color: '',
            imageFile: null,
            currentCategory: '',
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
            catFiltersCategories: [],
            categoryOrder: '',
        }   

        this.onChangeImage = this.onChangeImage.bind(this);
        this.resetImage = this.resetImage.bind(this);
    }

onChangeImage(event) {
    this.setState({
        imageChanged: true,
        image: URL.createObjectURL(event.target.files[0]),
        imageFile: event.target.files[0]
    });
}

resetImage(event) {
    event.preventDefault();
    this.setState({ image: null });
}
   

getCategoryById() {
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/edit-category/' + this.props.match.params.slug)
    .then((response) => {
    this.setState({
    name: response.data.name,
    slug: response.data.slug,
    parentCategory: response.data.parentCategory,
    showInMenu: response.data.showInMenu,
    color: response.data.color,
    image: response.data.image,
    categoryOrder: response.data.categoryOrder,
    });
    if(response.data.parentCategory === null && response.data.filtersCategories !== undefined){
        this.setState({
            catFiltersCategories: JSON.parse(response.data.filtersCategories),
        })
    }
    this.getFiltersCategoriesList();

    })
    .catch((error) => {
    console.log(error);
    })
}


handleChange = (e) => {  
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);   
        }
    
handleChangeFiltersCategories = (e) =>  {
    this.state.catFiltersCategories = this.state.catFiltersCategories;
        var allFiltersCategories = this.state.allFiltersCategories;
        if(e.target.checked === true){
            this.state.catFiltersCategories.push( parseInt(e.target.value) )
        }
        else{
            for(var i in this.state.catFiltersCategories){
                if(this.state.catFiltersCategories[i] == e.target.value){
                    this.state.catFiltersCategories.splice(i,1);
                }
            }
        }
        allFiltersCategories.forEach(filtersCategory => {
           if (filtersCategory.filter_id == e.target.value){
            filtersCategory.isChecked =  e.target.checked
           }          
        }) 
        this.state.catFiltersCategories = this.state.catFiltersCategories
      this.setState({
        allFiltersCategories: allFiltersCategories,
      })
 
      }

//ISTAKNUTA SLIKA
onSubmit = (e) => {
    e.preventDefault();
    const {name, slug, parentCategory, showInMenu, color, catFiltersCategories, image, categoryOrder} = this.state;
    var newImage;
    var imageFile = this.state.imageFile;
    if(imageFile !== null){
        newImage = imageFile.name
    }
    else{
        newImage = image;
    }
    axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/update-category/' + this.props.match.params.slug, {
    name: name,
    slug: slug,
    parentCategory: parentCategory,
    showInMenu: showInMenu,
    color: color,
    categoryOrder,
    filtersCategories: JSON.stringify(catFiltersCategories),
    image: newImage,
    })
    .then((response) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Uspješno ste uredili kategoriju "<b>' + this.state.name + '"</b>',
        stickerType: 'success',
    })
    
    this.props.history.push('/administracija/kategorije');
    })
    .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Došlo je do problema prilikom uređivanja kategorije: ' + error,
        stickerType: 'danger',
    })
    });

    const addImage = new FormData();
    addImage.append(
    'image',this.state.imageFile);        
    const config = {       
    headers: {'content-type': 'multipart/form-data'}
    };

    upload(addImage, config) .then((response) => {
        }).catch((error) => {
    });
    
}

getFiltersCategoriesList() {  
    var allFiltersCategoriesArray = [];
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters')
.then((response) => {

    response.data.forEach((doc) => {
        const { filter_id, name } = doc;
        allFiltersCategoriesArray.push({
          filter_id,
          isChecked: false,
          name,
        });          
      });
      this.setState({
            allFiltersCategories: allFiltersCategoriesArray,
        })
        let allFiltersCategories = this.state.allFiltersCategories
        let catFiltersCategories = [];
        catFiltersCategories = this.state.catFiltersCategories
        let allFiltersCategoriesId = []
        allFiltersCategories.forEach(allFiltersCategory => {
            allFiltersCategoriesId.push(allFiltersCategory.filter_id);
        })

        var filtersCategoriesDiff = allFiltersCategoriesId.filter( function( el ) {
            return catFiltersCategories.indexOf( el ) < 0;
          });

          var filtersCategoriesDiffDeleted = catFiltersCategories.filter( function( el ) {
            return allFiltersCategoriesId.indexOf( el ) < 0;
          });

          allFiltersCategories.forEach(allFiltersCategory => {
            allFiltersCategory.isChecked = true
          })
          filtersCategoriesDiff.forEach(filtersCategoryDiff => {
            allFiltersCategories.forEach(allFiltersCategory => {
                if(allFiltersCategory.filter_id === filtersCategoryDiff){
                    allFiltersCategory.isChecked = false
                }
            })
        })

        this.setState({
            allFiltersCategories: allFiltersCategories,
         /*   catFiltersCategories: catFiltersCategories */
        })
})
.catch((error) => {
    console.log(error);
})
}


componentDidMount = () => {
    this.getCategoryById();
}

    render(){
        const { id, name, slug,color, parentCategory, showInMenu, image, imageChanged, categoryOrder} = this.state;
        return(
            <AdminWrapper>
            <section id="categories" className="section default-section content-page-section add-category default-form">
              {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> {name}  &rsaquo; Uredi kategoriju  &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="content">
                        <div className="page-header">
                           <h2 className="page-title">Uređivanje kategorije</h2>
                        </div>
                        <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <Link to="/administracija/kategorije">Proizvodi</Link>
                            <span>/</span>
                            <span>Uređivanje kategorije</span>
                        </div>
                        <div className="columns">
                            <div className="column is-8">
                                <form name="add-category" onSubmit={this.onSubmit} className="form">
                                 <fieldset className="">
                                    <label htmlFor="name" className="column is-12 title">
                                        Ime kategorije
                                        <input type="text" id="name" name="name" placeholder="Puno ime kategorije"
                                        value={name} onChange={ this.handleChange} required/>
                                    </label> 

                                    <label htmlFor="slug" className="column is-12 slug">
                                    <span>Generisani URL</span>
                                        <input type="text" id="slug" name="slug" placeholder="Slug kategorije"
                                        value={slug} onChange={ this.handleChange} required/>
                                    </label> 
                                
                                    <label htmlFor="parentCategory" className="column is-12">
                                        Glavna kategorija
                                        <select id="parentCategory" name="parentCategory"  value={parentCategory} onChange={this.handleChange} >
                                        <option value="">Izaberi</option>
                                            {this.state.allCategories.map(category =>
                                             category.parentCategory === null && (
                                            <option value={category.category_id}>{category.name}</option>
                                             )
                                            )}
                                        </select>
                                    </label>
                                                            
                                        <label htmlFor="showInMenu" className="column is-12">
                                        Prikazati kategoriju u glavnom meniju?
                                            <select id="showInMenu" name="showInMenu" value={showInMenu} onChange={ this.handleChange } required>
                                                <option value="Null">Izaberi</option>
                                                <option value="0">Ne</option>
                                                <option value="1">Da</option>
                                            </select>   
                                        </label>

                                        <label htmlFor="filtersCategories" className="column is-12 filters-categories">
                                        Izaberi kategorije filterova
                                        <div className="cat-filters-list columns is-multiline">
                                        {this.state.allFiltersCategories.map((filtersCategory) => {
                                        return (
                                        <div className="single-line column is-6">
                                        <input key={filtersCategory.filter_id} 
                                        onClick={this.handleChangeFiltersCategories} 
                                        type="checkbox" 
                                        value={filtersCategory.filter_id} 
                                        checked={filtersCategory.isChecked}
                                        /> 
                                        {filtersCategory.name}
                                        </div>
                                        )
                                        })}
                                        </div>
                                        </label>

                                        
                                        <label htmlFor="color" className="column is-12">
                                    Boja kategorije
                                        <select id="color" className="select-color" name="color" value={color} onChange={ this.handleChange }>
                                            <option value="">Izaberi</option>
                                            <option value="zdravlje">Zdravlje</option>
                                            <option value="sport-i-rekreacija">Sport i rekreacija</option>
                                            <option value="aparati-i-pomagala">Aparati i pomagala</option>
                                            <option value="drogerija">Drogerija</option>
                                            <option value="ljepota-i-njega">Ljepota i njega</option>
                                        </select>   
                                    </label>

                                    <label htmlFor="slug" className="column is-12">
                                        <span>Pozicija kategorije</span>
                                        <input type="text" id="categoryOrder" name="categoryOrder" placeholder="Pozicija kategorije"
                                        value={categoryOrder} onChange={ this.handleChange}/>
                                    </label> 

                                    <div id="upload_button" className="">
                                    <label htmlFor="image" className="label-image">
                                        Istaknuta slika
                                    <input type="file" id="image" name="image" onChange={ this.onChangeImage } />
                                    <span className="add-image">Dodaj istaknutu sliku <FontAwesomeIcon icon="image" /></span>
                                    </label>
                                    <div className="image-preview">
                                        {image && !imageChanged && <img src={process.env.REACT_APP_FILES_DESTINATION + image} alt="" />}
                                        {image && imageChanged && <img src={image} alt="" />}
                                        {image && (                                 
                                                <button className="remove-button" onClick={this.resetImage}><i className="icon"></i></button>
                                                )}
                                    </div>
                                       
                                    </div>       
                                    <button type="submit" className="button default add default-no-icon"><span className="item">Spremi izmjene <FontAwesomeIcon icon="pencil-alt" /></span></button>

                            </fieldset>
                        </form>
                            </div>
                            
                         
                        </div>
                        
                    </div>
                </div>
            </section>
            </AdminWrapper>
        )
    }
}

export default EditCategory