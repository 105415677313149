import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import jwt_decode from 'jwt-decode'

export const PrivateRouteUser = ({ component: Component,authenticated, ...rest}) => (
    <Route
    {...rest}
    render={props =>
        localStorage.getItem('usertoken') && jwt_decode(localStorage.getItem('usertoken')).isAdmin === 0 ? (
        <Component {...props} />
    ) : (
        <Redirect to={{
            pathname: "/prijava",
            state: {from: props.location}
        }}
        />
    )}
    />
)