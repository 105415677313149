/*
Newsletter component for homepage
Display Newsletter box on homepage
*/
import React from 'react'

class Newsletter extends React.Component{
    render(){
        return(
            <section id="s_newsletter" className="section newsletter">
                <div className="container">
                {/******************* Newsletter content *******************/}
                    <span className="icon"></span>
                    <h1 className="title">Prijavite se za newsletter</h1>
                    <h4 className="subtitle is-right">Budite prvi koji će biti informisani o najboljim ponudama i novim proizvodima.</h4>
                    <div className="search-bar is-right">
                        <div className="inner">
                        <label>  
                            <input type="text" placeholder="Upišite svoju email adresu" />
                        </label>  
                            <button type="submit"></button>
                        </div>
                    </div>
                </div>{/* CONTAINER END */}
            </section>/*SECTION NEWSLETTER END */
        );
    }
}

export default Newsletter