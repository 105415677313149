import React from 'react'
import { Link } from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import {Helmet} from "react-helmet";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { addPage, upload } from '../../helpers/UserFunctions'
import Reactable from 'reactable';
import no_image from '../../../static/no_image.png'

var Table = Reactable.Table,
    Thead = Reactable.Thead,
    Th = Reactable.Th;

var slugify = require('slugify')
class AddPage extends React.Component{
    constructor () {
        super()

        this.unsubscribe = null;
        this.state = {
            showPopupClass: '',
            id: '',
            products: [],
            allProducts: [],
            title: '',
            slug: '',
            description: '',
            status: '',
            metaDesc: '',
            image: null,
            imageFile: null,
            facebookImage: null,
            facebookImageFile: null,
            addedPageName: '',
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
        }   

        this.onChangeImage = this.onChangeImage.bind(this);
        this.resetImage = this.resetImage.bind(this);

        this.onChangeFacebookImage = this.onChangeFacebookImage.bind(this);
        this.resetFacebookImage = this.resetFacebookImage.bind(this);

        this.addToList = this.addToList.bind(this);
    }


handleChange = (e) => {    
    this.state.slug = slugify(this.state.title, {
        replacement: '-',
        remove: null,
        lower: true
      });    
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
}


//ISTAKNUTA SLIKA
onChangeImage(event) {
    this.setState({
        imageFile:event.target.files[0],
        image: URL.createObjectURL(event.target.files[0]),
    });

}
    
resetImage(event) {
    event.preventDefault();
    this.setState({ image: null });
}

//ISTAKNUTA SLIKA
onChangeFacebookImage(event) {
    this.setState({
        facebookImageFile:event.target.files[0],
        facebookImage: URL.createObjectURL(event.target.files[0]),
    });

}
    
resetFacebookImage(event) {
    event.preventDefault();
    this.setState({ facebookImage: null });
}

getProducts(){
    var allProducts = [];
    //Collect products from selected category
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/administration')
    .then((response) => {
    response.data.forEach((doc) => {
        const { product_id, title, slug, image_thumb } = doc;
        allProducts.push({
                _id: doc._id,
                id: product_id,
                title,
                slug,
                image: image_thumb,
            })
        })
        this.setState({
            allProducts,
        })
    }).catch((error) => {
        console.log(error);
    })
}


onSubmit = (e) => {
    e.preventDefault();
    var image="";
    var {imageFile, facebookImageFile} = this.state;
    const {id, title, slug, description, status, metaDesc, products } = this.state;

    if(imageFile === null){
        imageFile = new Object();
        imageFile.name = "empty";
    }

    if(facebookImageFile === null){
        facebookImageFile = new Object();
        facebookImageFile.name = "empty";
    }
   
    const newPage = {
        title,
        slug,
        status,
        metaDesc,
        products: JSON.stringify(products),
        description,
        image: imageFile.name,
        facebookImage: facebookImageFile.name,
        }
    
        addPage(newPage).then(res => {
            this.setState({
                addedPageName: title,
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: '',
                })
                this.props.history.push("/administracija/stranice")
            }.bind(this),timeoutDelay);
        
            this.setState({
                stickerMessage: 'Uspješno ste dodali stranicu <b>"' + this.state.addedPageName + '"</b>',
                stickerType: 'success',
              id: '',
            title: '',
            slug: '',
            description: '',
            image: '',
            facebookImage: '',
            products: [],
            status: '',
            metaDesc: '',
            });
              })
              .catch((error) => {
                this.setState({
                    showStickerClass: 'show',
                })
                setTimeout(function(){
                    this.setState({
                        showStickerClass: ''
                    })
               }.bind(this),timeoutDelay);
               this.setState({
                stickerMessage: 'Došlo je do problema prilikom dodavanja stranice: ' + error,
                stickerType: 'danger',
            })
            });
    if(imageFile !== null){
        const addImage = new FormData();
        addImage.append(
        'image',this.state.imageFile);        
        const config = {       
        headers: {'content-type': 'multipart/form-data'}
        };
    
        upload(addImage, config) .then((response) => {
            }).catch((error) => {
        });
    }
    
    if(facebookImageFile !== null){
        const addFacebookImage = new FormData();
        addFacebookImage.append(
        'image',this.state.facebookImageFile);        
        const config = {       
        headers: {'content-type': 'multipart/form-data'}
        };
    
        upload(addFacebookImage, config) .then((response) => {
            }).catch((error) => {
        });
    } 
}

addToList(id, title, image, slug){
    console.log(slug)

    var products = this.state.products;
    var product = new Object();
    product.id = id;
    product.title = title;
    product.image = image;
    product.slug = slug;
    if(products.length > 0){
        if (products.some(e => e.id === id)) {
            products.splice(products.indexOf(products.some(e => e.id === id)),1)
        }
        else{
            products.push(product);
        }

    }
    else{
        products.push(product);
    }
    this.setState({
        products
    })
   
}

productOptions(key,title, image,slug, prAdded){
    return(
        <div className={"options-wrapper"}>
            <div className="inner">
                {prAdded  && (
                    <span onClick={this.addToList.bind(this, key, title, image, slug)} className="button green" title="Isključi best seler opciju">Ukloni iz liste</span>
               )}
               {!prAdded  && (
                    <span onClick={this.addToList.bind(this, key, title, image, slug)} className="button red" title="Uključi best seler opciju">Dodaj u listu</span>
               )}
            </div>
        </div>
    )
}

productImage(image){
    if(image === "empty" || image === undefined || image === null){
        return(
            <img src={no_image} />
        )
    }
    else{
        return(
            <img src={process.env.REACT_APP_FILES_DESTINATION+image} />
        )
    }    
}

componentDidMount = () => {
    this.getProducts();
}


    render(){
      var description;
        const { id, title, slug, image, status, metaDesc, allProducts, products, facebookImage } = this.state;
        var sortableProducts = [], sortableProductsAdded = [];
        var prAdded = false;

        allProducts.map(product =>
            {   
                prAdded = false;
                if(products.some(e => e.id === product.id)){
                    prAdded = true;
                }
                sortableProducts.push({
                    id: product.id,
                    image: this.productImage(product.image), 
                    title: product.title, 
                    options: this.productOptions(product.id, product.title, product.image,product.slug, prAdded)
                })
            }
        )

        products.map(product =>
            {   
                sortableProductsAdded.push({
                    id: product.id,
                    image: this.productImage(product.image), 
                    title: product.title, 
                    options: this.productOptions(product.id, product.title, product.image,product.slug, true)
                })
            }
        )

        return(
            <AdminWrapper>
            <section id="addPage" className="section default-section content-page-section add-page default-form">
              {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> Dodaj stranicu  &rsaquo; Zdravo.ba </title>
                </Helmet>
               <div className="is-hidden"><canvas ref="canvas" width={640} height={425} /> </div>

                    <div className="content">
                        <div className="page-header">
                           <h2 className="page-title">Dodaj novu stranicu</h2>
                        </div>
                        <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <Link to="/administracija/stranice">Stranice</Link>
                            <span>/</span>
                            <span>Dodaj novu stranicu</span>
                        </div>
                        <form name="add-page" onSubmit={this.onSubmit} className="form">
                            <fieldset className="">
                                <div className="columns is-multiline main">
                                    <input type="hidden" id="id" name="id"
                                        value={id} onChange={ this.handleChange} required/>

                                   

                                    <label htmlFor="title" className="column is-12 title">
                                        Ime stranice
                                        <input type="text" id="title" name="title" placeholder="Puno ime stranice koje će biti prikazano"
                                        value={title} onChange={ this.handleChange} required/>
                                    </label>
                                    <label htmlFor="slug" className="column is-12 slug">
                                    <span>Generisani url</span>
                                        <input type="text" id="slug" name="slug" placeholder=""
                                        value={slug} onChange={ this.handleChange} required/>
                                    </label>
                                    <label htmlFor="metaDesc" className="column is-12">
                                        Meta deskripcija stranice
                                        <input type="text" id="metaDesc" name="metaDesc" placeholder="Meta deskripcija stranice"
                                        value={metaDesc} onChange={ this.handleChange} required/>
                                    </label>

                                    <label htmlFor="description" className="column is-12 description">
                                        Detaljni Opis Stranice                            
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            description=""
                                            onInit={ editor => {
                                              
                                            } }
                                            onChange={ ( event, editor ) => {
                                                this.setState({
                                                    description: editor.getData(),
                                                })
                                                console.log( { event, editor, description } );
                                            } }
                                            onBlur={ editor => {
                                                console.log( 'Blur.', editor );
                                            } }
                                            onFocus={ editor => {
                                                console.log( 'Focus.', editor );
                                            } }
                                        />
                                    </label>
                                    <span className="is-hidden">
                                        {description = this.state.description}
                                    </span>

                                  
                                </div>
                                <span className="divider-vertical"></span>
                                <div className="sidebar">
                                                <div>
                                        <label htmlFor="status" className="">
                                            Status stranice
                                            <select id="status" name="status" value={status} onChange={ this.handleChange } required>
                                                <option value="null">Izaberi</option>
                                                <option value="Objavljeno">Objavljeno</option>
                                                <option value="U pripremi">U pripremi</option>
                                                <option value="Neobjavljeno">Neobjavljeno</option>
                                            </select>   
                                        </label>
                                       
                                    <div className="button-area">
                                        <button type="submit" className="button default add"><span className="item">Dodaj stranicu <FontAwesomeIcon icon="plus-circle" /></span></button>
                                    </div>

                                    <div id="upload_button" className="">
                                 
                                    <label htmlFor="image" className="label-image">
                                        Istaknuta slika
                                    <input type="file" id="image" name="image" onChange={ this.onChangeImage }/>
                                    <span className="add-image">Dodaj istaknutu sliku <FontAwesomeIcon icon="image" /></span>
                                    </label>
                                    <div className="image-preview">
                                        {image && <img src={image} alt="" />}
                                        {image && (                                 
                                                <button className="remove-button" onClick={this.resetImage}><i className="icon"></i></button>
                                                )}
                                    </div>                                     
                                    </div>

                                    <div id="upload_button" className="">
                                 
                                 <label htmlFor="facebookImage" className="label-image">
                                     Facebook slika
                                 <input type="file" id="facebookImage" name="image" onChange={ this.onChangeFacebookImage }/>
                                 <span className="add-image">Dodaj facebook sliku <FontAwesomeIcon icon="image" /></span>
                                 </label>
                                 <div className="image-preview">
                                     {facebookImage && <img src={facebookImage} alt="" />}
                                     {facebookImage && (                                 
                                             <button className="remove-button" onClick={this.resetFacebookImage}><i className="icon"></i></button>
                                             )}
                                 </div>                                     
                                 </div>
                                    </div>
                                </div>

                                <div className="columns is-multiline">
                               <div className="column is-12">
                                  <div className="page-header">
                                      <h2 className="page-title">Trenutno odabrani proizvodi</h2>
                                  </div>
                                   <Table className="default-table"
                                    filterable={['title']}
                                    noDataText="Nema pronađenih proizvoda."
                                    itemsPerPage={12}
                                    pageButtonLimit={5}
                                    currentPage={this.state.currentPage}
                                    sortable={[
                                        'id',
                                        'title',
                                    ]}
                                    previousPageLabel="Nazad"
                                    nextPageLabel="Naprijed"
                                    data={sortableProductsAdded} 
                                    defaultSort={{column: 'id', direction: 'desc'}}
                                    >
                                        <Thead>
                                            <Th column="id">ID</Th>
                                            <Th column="image">Slika</Th>
                                            <Th column="title">Naziv</Th>
                                            <Th column="options">Opcije</Th>
                                        </Thead> 
                                    </Table>
                                   </div>

                                   <div className="column is-12">
                                  <div className="page-header">
                                      <h2 className="page-title">Dodaj nove proizvode</h2>
                                  </div>
                                   <Table className="default-table"
                                    filterable={['title']}
                                    noDataText="Nema pronađenih proizvoda."
                                    itemsPerPage={12}
                                    pageButtonLimit={5}
                                    currentPage={this.state.currentPage}
                                    sortable={[
                                        'id',
                                        'title',
                                    ]}
                                    previousPageLabel="Nazad"
                                    nextPageLabel="Naprijed"
                                    data={sortableProducts} 
                                    defaultSort={{column: 'id', direction: 'desc'}}
                                    >
                                        <Thead>
                                            <Th column="id">ID</Th>
                                            <Th column="image">Slika</Th>
                                            <Th column="title">Naziv</Th>
                                            <Th column="options">Opcije</Th>
                                        </Thead> 
                                    </Table>
                                   </div>
                               </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </section>
            </AdminWrapper>
        )
    }
}

export default AddPage