import React from 'react'
class Footer extends React.Component{
    render(){
        return(
          <div id="footer" className="section footer admin-footer">
            <div className="container is-fluid">
                <p className="text">Zdravo.ba | Administracija. &copy; Copyright - {new Date().getFullYear()}</p>
            </div>
          </div>
        );
    }
}

export default Footer