/*
Product Component
Display single product in product slider for homepage, list products on category page etc...
Contain product informations(image, title, price, action price), add to cart button and view more button
*/
import React from 'react';
import { Link } from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import {calculatePrice} from '../../../functions/calculatePrice'
import { productUrl } from '../../../functions/pageUrls'
import axios from 'axios';
import { addCart } from '../../helpers/UserFunctions'
import { withGlobalState } from 'react-globally'
import LazyLoad from 'react-lazyload';

var randomString = require('random-string');
var cartId = randomString({length: 60});
class Product extends React.Component {
    constructor () {
        super()
        this.state = {
          CartPopupHidden: true,
          showOldPrice: false,
          weekOffer: '',
          publicIp: '',
          key: 'empty',
          productCategoryName: '',
          productCategorySlug: '',
          productCategoryColor: '',
          productBrandName: '',
          productBrandLogo: '',
          productBrandFullName: '',
        }

      }

//Function: add product to cart
addToCart(id, title, category,subCategory, slug, price, oldPrice, image_thumb, productAction, productOffer, deliverer, brand, categorySlug, brandSlug, points) {
      var products = [];
      var newProduct;
      var totalPrice = 0;
      var totalPoints = 0;
      newProduct = new Object();
      newProduct.id = id;
      newProduct.title = title;
      newProduct.category = category;
      newProduct.subCategory = subCategory;
      newProduct.slug = slug;
      newProduct.price = price;
      newProduct.oldPrice = oldPrice;
      newProduct.quantity = 1;
      newProduct.image = image_thumb;
      newProduct.productAction = productAction;
      newProduct.productOffer = productOffer;
      newProduct.deliverer = deliverer;
      newProduct.brand = brand;
      newProduct.categorySlug = categorySlug;
      newProduct.brandSlug = brandSlug;
      newProduct.points = points;

      this.props.setGlobalState({
        changeHeaderCart: true,
      })
   

        this.setState({
          CartPopupHidden: false
      })

      if(!localStorage.cartId){
          localStorage.cartId = cartId;
          totalPrice = parseFloat(newProduct.price);
          totalPoints = newProduct.points;


          products.push(newProduct ); 
          products = JSON.stringify(products); 
          
          const newCart = {
            cartId,
            products,
            totalPrice,
            totalPoints,
            }
        addCart(newCart).then(res => {
        }).then((response) => {
            })
            .catch((error) => {
                console.log("problem ", error);
            });
      }
      else{
          cartId = localStorage.cartId;
          axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/get-cart/' + cartId)
          .then((response) => {
          products = JSON.parse(response.data.products);
          totalPrice = response.data.totalPrice;
          totalPoints = response.data.totalPoints;
          
          totalPrice = parseFloat(totalPrice)+parseFloat(newProduct.price);
          totalPoints = totalPoints + newProduct.points;
          for (var i in products) {
              if(products[i].id == newProduct.id)
              {
                  products[i].quantity = products[i].quantity+1;
                  products = JSON.stringify(products);  
                      axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/update-cart/' + cartId, {
                        cartId,
                        products,
                        totalPrice,
                        totalPoints,
                        })
                        .then((response) => {
                    })
                    .catch((error) => {
                        console.log("problem ", error);
                    });
                  return;
              }
          }  
          products.push(newProduct ); 
          products = JSON.stringify(products);  
              axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/update-cart/' + cartId, {
                cartId,
                products,
                totalPrice,
                totalPoints,
                })
                .then((response) => {
            })
            .catch((error) => {
                console.log("problem ", error);
            });
       
          });
  }               
}

getSettings(){
  //Get settings data
axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/settings/get-settings')
.then((response) => {
  this.setState({
      weekOffer: response.data.weekOffer,
  });
  })
  .catch((error) => {
  console.log(error);
  })
}

getCategoryInfo(){
//Ger product category informations
axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/single-category-id/'+this.props.product.category)
.then((response) => { 
    this.setState({
      productCategoryName: response.data.name,
      productCategoryColor: response.data.color,
      productCategorySlug: response.data.slug,
    })
}).catch((error) => {
    console.log(error);
})
}

getBrandInfo(){
  //Get product brand informations
  axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/brands/single-brand-id/'+this.props.product.brand)
  .then((response) => {
    console.log(this.props.product.brand, response.data.name)
    this.setState({
      productBrandName: response.data.slug,
      productBrandLogo: response.data.image,
      productBrandFullName: response.data.name,
    })  
  });
}

getMarkupInfo(){
  //Get product brand informations
  axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/markups/single-markup-id/'+this.props.product.markup)
  .then((response) => {
    this.setState({
      productMarkup: 1 + (response.data.value/100),
    })  
  });
}
 
componentDidMount = () => {
  this.getSettings();
  this.getMarkupInfo();
}

render(){
    var	togglePopupBox = this.props.togglePopupBox;
    var productDataForPopup = this.props.productDataForPopup;
    const { product } = this.props;
    var {weekOffer, productActionPrice, productMarkup } = this.state;
    var productPricing = calculatePrice(product.vele_price, product.productOffer, weekOffer, product.productAction, product.actionPercent, productActionPrice, productMarkup, product.price, product.brandDiscount);
    var points = productPricing.onePercentPrice;
        return(
        <div key={product.id} className="inner">
         {/******************* Product overlay with cta button view more *******************/}
        <div className="open-product-overlay">
            <div className="button-area">
            <Link to={`/${productUrl}/${product.productCategorySlug}/${product.productBrandName}/${product.slug}`} className="button default" onClick={() => {this.props.singleProductData(product.slug)}}>Pregledaj proizvod</Link>
            </div>
        </div>
        <Link to={`/${productUrl}/${product.productCategorySlug}/${product.productBrandName}/${product.slug}`} className="image" onClick={() => {this.props.singleProductData(product.slug)}}  area-label="Product image">
          {productPricing.flagAction && productPricing.percent_discount > 0 && (
          <div className="action-label">
            <span className="percent-discount">-{productPricing.percent_discount}%</span>
            <span className="save-calculator">Uštedi* <b>{formatMoney(productPricing.save_money)}</b></span>
          </div>
          )}  
          {productPricing.flagAction &&  (productPricing.percent_discount === "0" || productPricing.percent_discount === "0 " || productPricing.percent_discount === "") && (
          <div className="action-label gratis">
            <span className="percent-discount">+ GRATIS</span>
          </div>
          )}
          <LazyLoad height={230} once="true">
         <img src={process.env.REACT_APP_FILES_DESTINATION+product.image_thumb} alt={product.purpose} />
         </LazyLoad>
         {/*{(this.state.productBrandLogo !== "" || this.state.productBrandLogo !== "null" ) && this.state.productBrandName !== 'ostalo' && (
         <div className="brand-logo"><img src={process.env.REACT_APP_FILES_DESTINATION+this.state.productBrandLogo} /></div>
         )} */}
        </Link>

         {/******************* Product title *******************/}
         <p className="brand-name">
            {(product.productBrandName !== "ostalo") && (
        product.productBrandFullName
        )}
        </p>
        
        <h1 className="title">
        <Link to={`/${productUrl}/${product.productCategorySlug}/${product.productBrandName}/${product.slug}`}  onClick={() => this.props.singleProductData(product.slug)}>{product.title}</Link>
        </h1>
     
        {/******************* Product add to cart button and prices *******************/}
        <div className="product-foot">
            <div className="price-box">
                <h2 className="price"> {formatMoney(productPricing.price)}</h2>
                <h6 className="old-price"> {productPricing.flagAction &&  productPricing.percent_discount > 0 && 
                   formatMoney(productPricing.oldPrice)
                }
               
                </h6>
              </div>
              {product.productVariety !== 1 && (
              <button onClick={() =>  
                this.addToCart(product.key, product.title, product.productCategorySlug,product.subCategory, product.slug, productPricing.price, productPricing.oldPrice,
                   product.image_thumb, product.productAction, product.productOffer,product.deliverer,product.brand,product.productCategorySlug,product.productBrandName,points,
                togglePopupBox('show'), 
                productDataForPopup(product.title,product.slug, product.productCategorySlug,productPricing.price, productPricing.oldPrice,product.image_thumb, product.key, 1, product.available))}
                className="add-to-cart-button" area-label="Add to cart"></button>
              )}
              {product.productVariety === 1 && (
              <Link to={`/${productUrl}/${product.productCategorySlug}/${product.productBrandName}/${product.slug}`} className="add-to-cart-button" onClick={() => this.props.singleProductData(product.slug)}  area-label="Add product to cart">
              <span className="variation-alert">Posjetite stranicu proizvoda kako bi izvršili dodavanje u korpu.</span>
              </Link>
              )}           
        </div>
    </div>/* SINGLE PRODUCT END */
        )
    }
}
export default withGlobalState(Product)