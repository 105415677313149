/*
Shop Wrapper component
Display start and end for each component
*/
import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Popup from './homepage/Popup'

class ShopWrapper extends React.Component{
    constructor(props) {
        super(props);
        this.handleLoad = this.handleLoad.bind(this);
     }

componentDidMount(){
    window.addEventListener('load', this.handleLoad);
}
componentWillUnmount() { 
    window.removeEventListener('load', this.handleLoad)  
  }
handleLoad() {
    console.log("page loaded")

    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/5cb03c36d6e05b735b423133/default';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s1.setAttribute('rel','preconnect');
    s0.parentNode.insertBefore(s1,s0);
    })();
   }

//Function: pass data about category from header
headerCategoryData(categorySlug){} 

    render(){

        return(
            <>
            {/*<Popup />*/}
            <div className={"shop " + this.props.pageName + " " + this.props.subPageName}>
            {/******************* Call header *******************/}
            <Header headerCategoryData={(categorySlug) => this.headerCategoryData(categorySlug)}/>
            {/******************* Call body of the component *******************/}
            {this.props.children}
            {/******************* Call footer *******************/}
            <Footer headerCategoryData={(categorySlug) => this.headerCategoryData(categorySlug)}/> 
            </div>
            </>
        )
    }
}

export default ShopWrapper