/*
Product Popup Component
Display popup with informations about added product to cart
*/
import React from 'react'
import formatMoney from '../../../functions/formatMoney'
import { Link } from 'react-router-dom'
import LoaderBox from '../../helpers/LoaderBox';
import { cartUrl, productUrl } from '../../../functions/pageUrls'
import axios from 'axios';

class ProductPopup extends React.Component{
    constructor () {
        super()
        this.state = {
            title: '',
            slug: '',
            image: null,
            price: '',
            category: '',
            priceAction: '',
            productAction: '',
            productOffer: '',
            weekOffer: '',
            trigerProductAction: false,
            loading:false,
            categorySlug: '',
        }   
    }

getSettings(){
    //Get settings data
  axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/settings/get-settings')
  .then((response) => {
    this.setState({
        weekOffer: response.data.weekOffer,
    });
    })
    .catch((error) => {
    console.log(error);
    })
}

getSingleProduct(){
  //Collect data about added product using props product ID
  axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/get-product-id/' + this.props.productId)
  .then((response) => {
    const product = response.data;
    this.setState({
      key: product._id,
      category: product.category,
      title: product.title,
      slug: product.slug,
      brand: product.brand,
      image: product.image_thumb,
      price: product.price,
      priceAction: product.priceAction,
      productOffer: product.productOffer,
      productAction: product.productAction,
    });
  }).catch((error) => {
    console.log(error);
  })
}

componentDidMount = () => {
  this.getSingleProduct();
  this.getSettings();
}
  
    render(){
        var	hidePopupBox = this.props.hidePopupBox;
   const { title, price, oldPrice } = this.props;
   const {brand, slug,image,category, productAction, productOffer, categorySlug, popupQuantity, prAvailable} = this.props;

  //Calculate action and standard price
    var actionPrice = 0, normalPrice = price, weekOffer;
     if(productOffer === 1){
      this.state.trigerProductAction = true;
        weekOffer = this.state.weekOffer;
        actionPrice = price;
        normalPrice = oldPrice;
      }

      else if(productAction === 1)
      {
        this.state.trigerProductAction = true;
        actionPrice = price;
        normalPrice = oldPrice;
      }

        return(
            <div className={"popup popup-success " + this.props.showClass}>

            {/******************* Loader box *******************/}
            {this.state.loading && (<LoaderBox /> )}
          
                <p className="popup-header">Uspješno ste dodali proizvod u korpu.</p> 
                <div className="popup-content">

                {/******************* Single product *******************/}
                    <div className="single-row">
                        <div className="image">
                            <Link to={`/${productUrl}/${category}/${brand}/${slug}`} area-label="Product image">
                            <img src={process.env.REACT_APP_FILES_DESTINATION+image} alt="Product" />
                            </Link>
                        </div>

                        <div className="info">
                            <Link to={`/${productUrl}/${categorySlug}/${brand}/${slug}`} className="title">{title}</Link>
                            {this.state.trigerProductAction && (
                              <div class="price-box">
                              <p className="price">{formatMoney(actionPrice)}</p>
                              <p className="old-price">{formatMoney(normalPrice)}</p>
                              </div>
                            )}
                            
                            {!this.state.trigerProductAction && (
                              <>
                              <p className="price">{formatMoney(normalPrice)} <span className="quantity">Količina: {popupQuantity}</span></p>
                              </>
                            )}
                             {prAvailable === 1 && (
                        <p className="product-stock yes">Proizvod je odmah isporučiv.</p>
                    )}
                    {prAvailable === 0 && (
                        <p className="product-stock no">Proizvod nije odmah isporučiv.</p>
                    )}
                        </div>
                    </div>
                   
                    {/******************* Buttons *******************/}
                    <div className="button-area">
                        <button className="button continue-shop" onClick={() => hidePopupBox('')}>Nastavite kupovinu</button>
                        <Link to={"/"+cartUrl} className="button checkout">Korpa</Link>
                    </div>
                </div> 
            </div>
        )
    }
}

export default ProductPopup