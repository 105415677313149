import React from 'react'
import { Link } from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import {Helmet} from "react-helmet";
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { upload } from '../../helpers/UserFunctions'

var slugify = require('slugify')
class Settings extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;
        
        this.state = {
            weekOffer: '',
            key: '',
            productsInCategory: '',
            freeShipping: '',
            shipping: '',
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
            loading:false,
            pdv: '',
            mobileBannerImg: null,
            mbImageFile: null,
            mbImageChanged: false,
            mobileBannerLink: '',
        }
        
        this.onChangeImage = this.onChangeImage.bind(this);
        this.resetImage = this.resetImage.bind(this);
    }


    //ISTAKNUTA SLIKA
onChangeImage(event) {
    this.setState({
        mbImageChanged: true,
        mobileBannerImg: URL.createObjectURL(event.target.files[0]),
        mbImageFile: event.target.files[0]
    });
}

resetImage(event) {
    event.preventDefault();
    this.setState({ mobileBannerImg: null });
}


getSettings() {
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/settings/get-settings')
    .then((response) => {
        var {weekOffer, productsInCategory, shipping, freeShipping, pdv, mobileBannerImg, mobileBannerLink} = response.data;
    this.setState({
        weekOffer,
        productsInCategory,
        shipping,
        freeShipping,
        pdv,
        mobileBannerLink,
        mobileBannerImg
    });
    })
    .catch((error) => {
    console.log(error);
    })
}

handleChange = (e) => {     
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
        }
    
componentDidMount = () => {
    this.getSettings();
}

    onSubmit = (e) => {
        e.preventDefault();
        const {weekOffer, productsInCategory, shipping, freeShipping, pdv, mobileBannerImg, mobileBannerLink } = this.state;
        var newImage;
        var mbImageFile = this.state.mbImageFile;
        if(mbImageFile !== null){
            newImage = mbImageFile.name
        }
        else{
            newImage = mobileBannerImg;
        }
        axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/settings/update-settings', {
            weekOffer,
            productsInCategory,
            shipping,
            freeShipping,
            mobileBannerImg: newImage,
            mobileBannerLink,
            pdv,
            })
            .then((response) => {
                this.setState({
                    showStickerClass: 'show',
                })
                setTimeout(function(){
                    this.setState({
                        showStickerClass: ''
                    })
                }.bind(this),timeoutDelay);
                this.setState({
                stickerMessage: 'Uspješno ste uredili postavke.',
                stickerType: 'success',
            })
            
            this.props.history.push('/administracija/postavke');
            })
            .catch((error) => {
                this.setState({
                    showStickerClass: 'show',
                })
                setTimeout(function(){
                    this.setState({
                        showStickerClass: ''
                    })
                }.bind(this),timeoutDelay);
                this.setState({
                stickerMessage: 'Došlo je do problema prilikom uređivanja postavki: ' + error,
                stickerType: 'danger',
            })
        });

        const addImage = new FormData();
    addImage.append(
    'image',this.state.mbImageFile);        
    const config = {       
    headers: {'content-type': 'multipart/form-data'}
    };

    upload(addImage, config) .then((response) => {
        }).catch((error) => {
    });
}


    render(){
        const { weekOffer, productsInCategory, shipping, freeShipping, pdv, mobileBannerImg, mobileBannerLink, mbImageChanged} = this.state;
        return(
            <AdminWrapper>
            <section id="settings" className="section default-section content-page-section settings default-form">
            {this.state.loading && (
                <div className="loader-box">
                    <span className="loader"></span>
                </div>
            )}

              {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> Postavke  &rsaquo; Administracija  &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="content">
                        <div className="page-header">
                           <h2 className="page-title">Postavke trgovine</h2>
                        </div>
                        <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <span>Postavke trgovine</span>
                        </div>
                        <div className="columns">
                            <div className="column is-8">
                                <form name="settings" onSubmit={this.onSubmit} className="form">
                                 <fieldset className="">
                                    <label htmlFor="weekOffer" className="column is-12 title">
                                        Sedmična ponuda
                                        <input type="text" id="weekOffer" name="weekOffer" placeholder="Procenat sedmične ponude"
                                        value={weekOffer} onChange={ this.handleChange}/>
                                    </label>   
                                    <label htmlFor="productsInCategory" className="column is-12 title">
                                        Broj proizvoda na jednoj stranici u kategoriji
                                        <input type="text" id="productsInCategory" name="productsInCategory" placeholder="Broj proizvoda u kategoriji"
                                        value={productsInCategory} onChange={ this.handleChange}/>
                                    </label>  
                                    <label htmlFor="shipping" className="column is-12 title">
                                        Vrijednost dostave
                                        <input type="text" id="shipping" name="shipping" placeholder="Vrijednost dostave"
                                        value={shipping} onChange={ this.handleChange}/>
                                         <span className="real-price">{formatMoney(shipping)}</span>
                                    </label>    

                                     <label htmlFor="shipping" className="column is-12 title">
                                        Cijena neophodna za besplatnu dostavu
                                        <input type="text" id="freeShipping" name="freeShipping" placeholder="Vrijednost dostave"
                                        value={freeShipping} onChange={ this.handleChange}/>
                                         <span className="real-price">{formatMoney(freeShipping)}</span>
                                    </label> 

                                    <label htmlFor="pdv" className="column is-12 title">
                                        Visina PDV-a
                                        <input type="text" id="pdv" name="pdv" placeholder="PDV"
                                        value={pdv} onChange={ this.handleChange}/>
                                    </label> 

                                    <label htmlFor="mobileBannerLink" className="column is-12 title">
                                        Mobilni intro banner link
                                        <input type="text" id="mobileBannerLink" name="mobileBannerLink" placeholder=""
                                        value={mobileBannerLink} onChange={ this.handleChange}/>
                                    </label> 

                                    <div id="upload_button" className="">
                                    <label htmlFor="mobileBannerImg" className="label-image">
                                        Istaknuta slika
                                    <input type="file" id="mobileBannerImg" name="image" onChange={ this.onChangeImage } />
                                    <span className="add-image">Dodaj sliku</span>
                                    </label>
                                    <div className="image-preview">
                                        {mobileBannerImg && !mbImageChanged && <img src={process.env.REACT_APP_FILES_DESTINATION + mobileBannerImg} alt="" />}
                                        {mobileBannerImg && mbImageChanged && <img src={mobileBannerImg} alt="" />}
                                        {mobileBannerImg && (                                 
                                                <button className="remove-button" onClick={this.resetImage}><i className="icon"></i></button>
                                                )}
                                    </div>
                                       
                                    </div>

                                    <button type="submit" className="button default add default-no-icon"><span className="item">Spremi izmjene <FontAwesomeIcon icon="plus-circle" /></span></button>

                            </fieldset>
                        </form>
                            </div>
                            
                         
                        </div>
                        
                    </div>
                </div>
            </section>
            </AdminWrapper>
        )
    }
}

export default Settings