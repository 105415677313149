/*
About Us page
Display static page with informations about us
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import {siteTitle, timeoutDelay} from '../../../functions/config'
import {Link} from 'react-router-dom'
import { loginUrl, registerUrl } from '../../../functions/pageUrls';
import { withRouter } from "react-router";
import { register } from '../../helpers/UserFunctions'

var pageTitle = "Registracija";

const INITIAL_STATE = {
    
  };

class SignUp extends React.Component{
    constructor() {
        super()
        this.state = {
            name: '',
            surname: '',
            email: '',
            passwordOne: '',
            passwordTwo: '',
            error: null,
            id:1,
        }
    
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
      }

onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
}

onSubmit(e) {
e.preventDefault();

const userCreatedAt = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
const {id, name, surname, email, phone, address, postalCode,
    city, passwordOne } = this.state;
const newUser = {
    name,
    surname,
    email,
    phone,
    address,
    postalCode,
    city,
    totalPoints: 0,
    newsletter: 0,
    couponName: "",
    couponExist: 0,
    couponValue: "",
    password: passwordOne,
    isAdmin: 0,
    }

    register(newUser).then(res => {
        this.setState({
            name: '',
            surname: '',
            email: '',
            phone: '',
            address: '',
            postalCode: '',
            city: '',
        });
    
        this.props.history.push("/")
        })
        .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Došlo je do problema prilikom izrade vašeg računa: ' + error,
        stickerType: 'danger',
        })
        });

}

    render(){
        window.scrollTo(0,0); 

        const {
            name, surname, email, phone, address, postalCode, city, passwordOne, passwordTwo, error,
          } = this.state;

          const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            name === '' ||
            surname === '';
        return(
            <ShopWrapper>
             {/*   <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
             </Helmet>*/}
                <section id="p_signin" className="section default-form signin-page signup-page">
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={pageTitle} />

                        {/******************* Content *******************/}
                        <div className="content">
                            <p>Otvorite vaš <b>zdravo.ba</b> račun, te ostvarite pravo na bodove vijernosti prilikom naručivanja proizvoda.</p>
                            <form name="login" method="POST" onSubmit={this.onSubmit} className="login-form">
                                <fieldset>
                                    <div className="columns is-multiline">
                                        <label htmlFor="name" className="name column is-6">Vaše ime
                                            <input type="text" name="name" id="name" placeholder="Vaše ime" onChange={this.onChange}/>
                                        </label>

                                        <label htmlFor="surname" className="surname column is-6">Vaše prezime
                                            <input type="text" name="surname" id="surname" placeholder="Vaše prezime" onChange={this.onChange}/>
                                        </label>

                                        <label htmlFor="email" className="email column is-6">E-mail adresa
                                            <input type="email" name="email" id="email" placeholder="E-mail adresa" onChange={this.onChange}/>
                                        </label>
                                        
                                        <label htmlFor="phone" className="phone column is-6">Broj telefona
                                            <input type="text" name="phone" id="phone" placeholder="Broj telefona" onChange={this.onChange}/>
                                        </label>

                                        <label htmlFor="address" className="address column is-6">Adresa stanovanja
                                            <input type="text" name="address" id="address" placeholder="Adresa stanovanja" onChange={this.onChange}/>
                                        </label>

                                        <label htmlFor="postalCode" className="postalCode column is-6">ZIP kod
                                            <input type="text" name="postalCode" id="postalCode" placeholder="ZIP kod" onChange={this.onChange}/>
                                        </label>

                                        <label htmlFor="city" className="city column is-6">Grad
                                            <input type="text" name="city" id="city" placeholder="Grad" onChange={this.onChange}/>
                                        </label>
                                        <span className="break"></span>

                                        <label htmlFor="passwordOne" className="password column is-6" >Lozinka
                                            <input type="password" name="passwordOne" id="passwordOne"placeholder="Lozinka" onChange={this.onChange}/>
                                        </label>
                                        <label htmlFor="passwordTwo" className="password column is-6" >Potvrdite lozinku
                                            <input type="password" name="passwordTwo" id="passwordTwo"placeholder="Potvrdite lozinku" onChange={this.onChange}/>
                                        </label>
                                    </div>
                                        <div className="button-area">
                                            <button disabled={isInvalid} type="submit" className="button default is-right"><span className="item">Kreirajte račun</span></button>
                                        </div>
                                </fieldset>
                            </form>

                            <div className="divider">
                                <p>ili</p>
                            </div>

                            <p className="register-text">Ukoliko već posjedujete račun, možete da se prijavite klikom na dugme ispod.</p>
                            <div className="button-area">
                                <Link to={"/"+loginUrl} className="register-button">Prijavite se</Link>
                            </div>

                        </div>
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}
            </ShopWrapper>
        )
    }
}

export default withRouter(SignUp)