import React from 'react'
import { Link } from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import {Helmet} from "react-helmet";
import ReactToPrint from 'react-to-print';
import { productUrl } from '../../../functions/pageUrls'
import axios from 'axios';

var productsInCart = [];
class OrderComponent extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            key: '',
            orderBigId: this.props.orderBigId,
            orderId: '',
            name: '',
            surname: '',
            email: '',
            address: '',
            phone: '',
            postalCode: '',
            city: '',
            country: '',
            charge: '',
            totalPriceNoShipping: '',
            shipping: '',
            totalPrice:'',
            orderCreatedAt: '',
            cartId: '',
            couponValue: '',
            couponName: '',
            shippingMethod: '',
        }    
    } 

getCartData(){
  
    }

    componentDidMount = () => {
        var productsInner = []; 
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders/single-order-id/' + this.props.orderBigId)
        .then((response) => {
            const order = response.data;
            productsInner = JSON.parse(order.products);
            Object.keys(productsInner).forEach(function(id) {
              productsInCart.push(productsInner[id]);
              });
            this.setState({
              _id: order._id,
              orderId: order.orderId,
              orderName: order.orderName,
              name: order.name,
              surname: order.surname,
              email: order.email,
              address: order.address,
              phone: order.phone,
              postalCode: order.postalCode,
              city: order.city,
              country: order.country,
              charge: order.charge,
              totalPriceNoShipping: order.totalPriceNoShipping,
              shipping: order.shipping,
              totalPrice: order.totalPrice,
              orderCreatedAt: order.orderCreatedAt,
              cartId: order.cartId,
              couponName: order.couponName,
              couponValue: order.couponValue,
              shippingMethod: order.shippingMethod,
            });
            var products = [];
            const {_id, cartId, orderId,orderName, orderCreatedAt, name, surname, email, phone, address, postalCode, city, country, charge, totalPrice, shipping,totalPriceNoShipping } = this.state;                 
              productsInCart.map(product => {
                  axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/single-category-id/'+product.category)
                    .then((response) => {  
                      product.category = response.data.slug;       
                  });
              })
    
              productsInCart.map(product => {
                axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/brands/single-brand-id/'+product.brand)
                  .then((response) => {
                      product.brand = response.data.slug;       
                  });
              })
    
              products = JSON.stringify(productsInCart);
    
              axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders/update-order-read-by-admin/' + _id, {
                readedByAdmin: 1,
            })
        
            axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/cart/delete-cart/' + cartId)
            .then(() => {
                this.setState({
              })
              }).catch((error) => {
                  console.log("problem", error);
              });
        })
        .catch((error) => {
        console.log(error);
        })
    
        }
          
    render(){
        const {orderName, orderCreatedAt, name, surname, email, phone, address, postalCode, city, country, charge, 
        totalPrice, shipping,totalPriceNoShipping, couponName, couponValue, shippingMethod } = this.state;
        if(this.state.orderBigId){
        return(
            <div className="shop">
                <section id="p_finished_order" className="section finished-order order-default">
                    <div className="container">
                    <div className="columns is-multiline content">
                    <div className="order-number">Broj narudžbe: <span>{orderName}</span></div>
                    <Helmet>
                    <title> { `Vaša narudžba - ${ orderName }`}  &rsaquo;  Zdravo.ba </title>
                    </Helmet>
                    <div className="column is-12 client-info">
                        <h1 className="part-title">Podaci o naručiocu</h1>
                        <div className="single-row">
                            <h4 className="name">Ime</h4><h4 className="answer">{name}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Prezime</h4><h4 className="answer">{surname}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">E-mail</h4><h4 className="answer">{email}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Telefon</h4><h4 className="answer">{phone}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Adresa</h4><h4 className="answer">{address}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">ZIP kod</h4><h4 className="answer">{postalCode}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Grad</h4><h4 className="answer">{city}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Država</h4><h4 className="answer">{country}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Tip dostave</h4><h4 className="answer">{shippingMethod}</h4>
                        </div>
                    </div>

                    <div className="column is-12 payment-info">
                        <h1 className="part-title">Podaci o plaćanju</h1>
                        <div className="single-row">
                            <h4 className="name">Način plaćanja</h4><h4 className="answer">{charge}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Cijena bez dostave</h4><h4 className="answer">{formatMoney(totalPriceNoShipping)}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Cijena dostave</h4><h4 className="answer">{formatMoney(shipping)}</h4>
                        </div>
                      {/*  {(couponName !== "" && couponName !== undefined ) && (
                        <div className="single-row">
                            <h4 className="name">Kupon<b>({couponName}</b></h4><h4 className="answer">{formatMoney(couponValue)}</h4>
                        </div>
                        )}
                      */}
                        <div className="single-row total">
                            <h4 className="name">Ukupno</h4><h4 className="answer">{formatMoney(totalPrice)}</h4>
                        </div>
                    </div>

                <div className="column is-12 order-items">
                    <h1 className="part-title">Detalji narudžbe</h1>
                   
                    <div>
          <div className="columns table-header">
            <div className="column is-2"><p>Slika</p></div>
            <div className="column is-4"><p>Naziv proizvoda</p></div>
            <div className="column is-2"><p>Količina</p></div>
            <div className="column is-2"><p>Veleprodaja</p></div>
            <div className="column is-2"><p>Ukupna cijena</p></div>
          </div>
            {productsInCart.map(product => 
            <div className="columns single-row margin-0">
            <div className="column is-2 image">
           
            <img src={process.env.REACT_APP_FILES_DESTINATION+product.image} />
            </div>
            <div className="column is-4 product-info">
           
                <h1 className="title">{product.title}</h1>
                {(product.productAction !== 1 && product.productOffer !== 1) && (
                    <p className="single-price">Cijena: <span>{formatMoney(product.price)}</span></p>
                    )}
                        {(product.productAction === 1 || product.productOffer === 1) && (
                    <p className="single-price">Cijena: <span>{formatMoney(product.price)}</span>
                   {(Number(product.oldPrice)-product.price !== 0) && (   <span class="old-price">{formatMoney(product.oldPrice)}</span>)}
                    </p>
                )}
                {product.productVariety === 1 && (
                    <p className="variation">{product.productVariationType}: <span>{product.productVariationName}</span></p>
                )}
            </div>
            <div className="column is-2 quantity">
                <p className="">{product.quantity}</p>
            </div>
            <div className="column is-2 deliverer">
            {product.deliverer === 1 && (<p className="">Delta Medical</p>)}
            {product.deliverer === 2 && (<p className="">Hercegovina Lijek</p>)}
            {product.deliverer === 3 && (<p className="">Ovnak</p>)}
            {product.deliverer === 4 && (<p className="">Pharma Maac</p>)}
            {product.deliverer === 5 && (<p className="">Greenwells</p>)}
            {product.deliverer === 6 && (<p className="">Elite Nutrition</p>)}
            {product.deliverer === 7 && (<p className="">Almaderm</p>)}
                
            </div>
            <div className="column is-3 price">
                <h4>{formatMoney(product.price*product.quantity)}</h4>
            </div>
        </div>/* COLUMNS SINGLE ROW END */
            )}
        </div>

                </div>
                    </div>
                    </div>{/* CONTAINER ENDS */}
                </section>{/* SECTION CHECKOUT END */}
                </div>
        );
            }
            else{
                return(
                    <p>nema nista ovdje</p>
                );
            }
    }
}
class GeneratedOrderAdmin extends React.Component{
    render() {
        return (
          <>
       
         {/*   <ReactToPrint
              trigger={() => <a href="#" className="button default print-button">Printaj račun</a>}
              content={() => this.componentRef}
            />
         */}
            <OrderComponent orderBigId={this.props.match.params.id} ref={el => (this.componentRef = el)}/>
          </>
        );
      }
}

export default GeneratedOrderAdmin