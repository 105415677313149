/*
Privacy Policy page
Display text for privacy policy, cookies, etc...
Static page
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import { homeUrl } from '../../../functions/pageUrls';
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet";
import {siteTitle} from '../../../functions/config'

var pageTitle = "Privatnost i zaštita podataka";
class PrivacyPolicy extends React.Component{

    componentDidMount() {
        }
        
    render(){
        window.scrollTo(0,0); 
        return(
            <ShopWrapper>
                <section id="p_privacy_policy" className="section static-page privacy-policy-page">
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={pageTitle} />
                        <Helmet>
                            <title>{pageTitle} &rsaquo; {siteTitle}</title>
                       </Helmet>
{/******************* Content of the page *******************/}
<div className="content">
    <h4>Uopšteno</h4>
    <p>PA "VALOR", vlasnik online trgovine obrađuje podatke svojih kupaca i korisnika usluga te posjetitelja online trgovine
        <Link to={homeUrl}>www.zdravo.ba</Link>, 
        a koje su namijenjene internetskoj prodaji proizvoda u skladu sa važećim propisima kojima se regulira zaštita ličnih podataka. 
        Na online trgovini <Link to={homeUrl}>www.zdravo.ba</Link> prikupljamo informacije koje identifikuju posjetioca (korisnika/kupca) ove online trgovine pri vršenju 
        određenih aktivnosti kao što su naručivanje proizvoda, ispunjavanje anketa, slanje komentara i sl. Prilikom obavljanja navedenih radnji 
        posjetilac ima obavezu unošenja svoji ličnih podataka (ime, prezime, adresa, kontakt telefon, e-mail adresa i dr). Unošenje ličnih 
        podataka je odluka posjetioca koji ukoliko ne unese tražene podatke za navedene servise neće biti u mogućnosti da ih koristi.</p>
        <p>Online trgovina www.zdravo.ba u svojim bazama podataka ne prikuplja i ne čuva informacije vezane za platne kartice posjetilaca i 
            korisnika usluga koje se pružaju putem navedenog servisa. </p>
     <br />   
    <h4>Opšte informacije o vlasniku online trgovine www.zdravo.ba</h4>
    <p>Vlasnik online trgovine <Link to={homeUrl}>www.zdravo.ba</Link> je PA "VALOR" sa sjedištem u Sarajevu u ulici M. Tita 11, Bosna i Hercegovina. </p>
    <p>ID broj firme je: <b>4302738260008</b></p>
    <p>Email: <b>info@www.zdravo.ba</b> </p>
    <p>Telefon: <b>+38761939348</b></p>
    <p>PA "VALOR" poduzima mjere kako bi obrada osobnih zadataka ispitanika bila zakonita, poštena i transparentna.</p>
    <br /> 
    <h4>Izjava o privatnosti i zaštiti podataka</h4>
    <p>Izjava o privatnosti i zaštiti podataka odnosi se na povjerljivost podataka koji se deponuju, prikupljaju i smještaju u baze prilikom korištenja online trgovine
    <Link to={homeUrl}>www.zdravo.ba</Link> sa ciljem obavljanja online kupovine proizvoda na pomenutoj online trgovini.</p>
    <p>Svi prikupljeni podaci biće upotrebljeni samo sa ciljem realizacije procesa kupovine, dostave naručenih proizvoda na predviđene adrese, 
        eventualne promotivne aktivnosti kao i poboljšanja naših usluga.</p>
    <p>Ovom Izjavom Vas želimo obavijestiti o:</p>
    <ul>
        <li>1. načinu prikupljanja Vaših ličnih podataka</li>
        <li>2. vrsti podataka koje prikupljamo</li>
        <li>3. načinu upotrebe prikupljenih podataka</li>
        <li>4. periodu u kojem se čuvaju prikupljeni podaci</li>
        <li>5. zaštiti prikupljenih podataka</li>
        <li>6. pristupu drugih stranaka prikupljenim podacima</li>
        <li>7. vrsti kolačića koji se koriste na online trgovini</li>
    </ul>
    <p>Zbog specifilčnosti digitalnog poslovanja pozivamo sve posjetioce online trgovine <Link to={homeUrl}>www.zdravo.ba</Link> da pažljivo pročitaju izjavu o povjerljivosti kako bi na ispravan 
        način razumjeli koje informacije treba da deponuju i u koju svrhu će deponovani podaci biti iskorišćeni.</p>
    <p>Pristupanjem ili upotrebom naše online trgovine, kupac ili posjetilac potvrđuje da je pročitao, razumio i da se slaže sa svim uslovima povjerljivosti podataka 
        i načinom korištenja online trgovine <Link to={homeUrl}>www.zdravo.ba</Link> .</p>
    <p>Ukoliko se kupac ili posjetilac ne slaže sa ovom izjavom o povjerljivosti podataka upućujemo ga da na online trgovini <Link to={homeUrl}>www.zdravo.ba</Link> ne 
        ostavlja i ne deponuje nikave lične podatke i da wonline trgovinu koristi u eventualne informativne svrhe.</p>
        <br /> 
    <h4>1. Način prikupljanja Vaših ličnih podataka</h4>
    <p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> prikuplja Vaše lične podatke pri obavljanju radnji koje prethode kupnji odnosno sklapanju ugovora što je ujedno i pravna 
        osnova obrade Vaših ličnih podataka. Prilikom narudžbe proizvoda nužno je da upišite tražene podatke kako bi mogli završiti narudžbu te dobiti broj 
        narudžbe koji služi kao pozivni broj za direktnu bankovnu transakciju na naš račun. Nakon što ste je iznos narudžbe uplaćen, a sredstva vidljiva na 
        našem računu, isporučujemo naručenene proizvode, u slučaju da se radi o uplati bankovnim putem a ne pouzećem.</p>
        <p>Prilikom kupnje proizvoda imate mogućnost kreiranja svog profila u kojemu su vidljivi svi Vaši lični podaci koji su nužni kako bi izvršili proces 
            kupoprodaje, a putem kojega imate uvid u sve Vaše prošle kupovine.</p>
        <p>Vaši lični podaci bivaju prikupljani također i prilikom isunjavanja kontaktnog formulara kada postavljate upite vezane za prodaju naših proizvoda ili u neku drugu svrhu.</p>
        <p>Internetsku adresu posjetitelja (IP adresa) prikupljamo prilikom korištenja naše internetske stranice.</p>
        <br /> 
    <h4>2. Koji lični podaci bivaju prikupljani?</h4>
    <p>Podaci koje prikupljamo kako bi Vam isporučili proizvod su: ime, prezime, adresa, poštanski broj, telefon i email adresa.</p>
    <p>Osobni podaci koje prikupljamo kada nam postavljate upite su: ime, prezime, telefon, email adresa.</p>
    <p>Internetska (IP adresa) koristi se isključivo za izradu statističkih izvještaja koja se obavlja putem alata Google Analytics.</p>
    <br /> 
    <h4>3. Kako i za što upotrebljavamo vaše podatke</h4>
    <p>Vaše podatke koje ste nam dali na ranije opisani način upotrebljavamo isključivo u cilju isporuke naručenih proizvoda, čime se ispunjava obaveza iz kupoprodajnog odnosa, 
        odnosno kako bi odgovorili na Vaše upite koje ste nam postavili.</p>
    <p>Vaše lične podatke također možemo koristiti u svrhu izvršenja zakonskih ili pravnih obveza kojima podliježemo. (npr. računovodstvene aktivnosti)</p>
    <br /> 
    <h4>4. Koliko dugo čuvamo vaše lične podatke?</h4>
    <p>Period u kojem pohranjujemo i čuvamo Vaše lične podatke ovisi o tome o kojoj se vrsti podataka rada, o svrsi za koju su isti prikupljeni te o zakonskim i pravnim obavezama
         kojima online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> podliježe.</p>
    <p>S obzirom se između online trgovine www.zdravo.ba kao prodavača i Vas kao kupca, zasniva pravni odnos kupoprodaje, izdaje se račun na kojem se nalaze i Vaši lični podaci. 
        Vaši lični podaci se pohranjuju u aktuelnom zakonom o računovodstvu određenom roku čuvanja računa.</p>
    <p>Ukoliko ste naručili neki proizvod, Vaša narudžba važi 8 dana i/ili do poziva našeg agenta koji od Vas traži potvrdu narudžbe, nakon čega se 
        podaci o Vašoj narudžbi brišu.</p>
    <p>Osobni podaci koji su prikupljeni u svrhu zaprimanja upita od Vas kao korisnika brišu se odmah nakon što se ispunila svrha odnosno nakon što 
        je odgovoreno na Vaš upit.</p>
        <br /> 
    <h4>5. Način na koji štitimo Vaše lične podatke</h4>
    <p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> poduzela je potrebne tehničke mjere kako bi zaštitila Vaše osobne podatke od neovlaštenog pristupa, upotrebe ili odavanja. 
        Za pohranu Vaših osobnih podataka koristimo savremeni informacijski sustav koji podliježe stalnim tehničkim unaprjeđenjima u skladu sa tehnološkim razvojem.</p>
    <p>Također su uspostavljene i organizacijske mjere kako bi pristup podacima imale samo ovlaštene osobe koje sudjeluju u cjelokupnom procesu kupoprodaje od narudžbe proizvoda do 
        isporuke proizvoda te koje imaju obvezu čuvanja povjerljivosti osobnih podataka kupaca/korisnika usluga.</p>
        <br /> 
    <h4>6. Da li i s kime dijelimo vaše lične podatke?</h4>
    <p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> , lične podatke svojih kupaca/korisnika usluga dijeli sa drugima u samo određenim slučajevima:</p>
    <ul>
        <li>1. Ukoliko postoji zakonska/pravna obaveza da se dostave određeni podatci nadležnom tijelu/instituciji.</li>
        <li>2. Kako bismo ispunili svoju obavezu isporuke proizvoda svojim kupcima, dostavljamo osobne podatke o kupcima ugovornom 
            partneru preko kojega proizvod dostavlja kupcu na adresu. Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> je dužna sa ugovornim partnerom putem 
            kojeg se dostavljaju proizvodi koji su predmet kupoprodaje, imati sklopljen Ugovor o obradi osobnih podataka, a koji uzima u 
            obzir sve potrebne tehničke i organizacijske mjere te podliježe obvezi čuvanja povjerljivosti osobnih podataka kupaca. 
            Prilikom isporuke proizvoda, dostavljač je dužan utvrditi identitet osobe kojoj se proizvod dostavlja te naplatiti 
            iznos ukoliko je ugovoreno plaćanje prilikom preuzimanja proizvoda.</li>
    </ul>
    <br /> 
    <h4>7. Koje kolačiće koristimo</h4>
    <p>Kolačić (eng. Cookie) je informacija pohranjena na kompjuter ili mobilni uređaj posjetitelja web stranice.</p>
    <p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> koristi kolačiće isključivo kako bi Vama kao posjetitelju web stranice 
    omogućili kvalitetnije korisničko iskustvo.</p>
    <p>Postoji više vrsta kolačića, a razlikuju se ovisno o tome koliko traju ili čiji su.</p>
    <p>Ovisno o trajanju razlikujemo: trajne (persistent cookies) i privremene (session cookies) kolačiće. Ovisno o tome čiji su, razlikujemo:
         kolačiće prve strane (first party cookies) i kolačiće treće strane (third party cookies).</p>
    <p>Trajni kolačići su oni koji na računalu ostaju nakon zatvaranja web browser-a, a datum isteka im je daleko u budućnosti te na web 
        browser-u ostaju dok taj rok ne istekne ili dok ih Vi ne obrišite. Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> koristi ovu vrstu kolačića kako 
        bi korisnik ostao prijavljen na web stranici što Vama kao korisniku olakšava korištenje naše online trgovine jer ne morate svaki 
        puta upisivati korisničko ime/email adresu i zaporku.</p>
    <p>Privremeni kolačići su oni koji se automatski brišu kada se zatvori web browser što znači da ostaju pohranjeni samo tokom posjete online trgovini. 
        Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> ovu vrstu kolačića koristi na način da Vi kao korisnik imate uvid u stanje košarice prilikom
         narudžbe proizvoda.</p>
    <p>Kolačići prve strane su kolačići koji dolaze sa naše web stranice koju posjećujete, a online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> koristi 
    gore navedene trajne i privremene kolačiće.</p>
    <p>Kolačići treće strane su kolačići koji dolaze sa drugih web mjesta koje posjetitelj pregleda, a služe praćenju interneta u marketinške svrhe. 
        Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> koristi servis za mjerenje posjećenosti web stranice „Google Analytics“
         (pravila o kolačićima možete potražiti na stranici 
        „Google Analytics“), a kojim se omogućava dobivanje statističkih podataka o posjećenosti stranice i načinu upotrebe stranice.</p> 
    <p>Ukoliko se ne slažete sa upotrebom kolačića, kolačiće možete isključiti u postavkama preglednika koji koristite. Više informacija o načinu kojim 
        se upravlja kolačićima potražite na stranici web browser-a koji koristite ili na stranici <a target="_blank" href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org</a>.
Obzirom da online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> koristi kolačiće isključivo kako bi Vam pružila kvalitetnije korisničko iskustvo, imajte na umu da 
ćete isključivanjem ili sprječavanjem kolačića onemogućiti kvalitetnije funkcioniranje web browser-a.</p>
<br /> 
 <h4>8. Vaša prava kao korisnika online trgovine www.zdravo.ba</h4>
 <p>U odnosu na sve Vaše podatke koje smo pohranili i koje obrađujemo, u svakome trenutku možete zatražiti informaciju o obradi Vaših podataka te ostvariti slijedeća prava:</p>
 <ul>
     <li>1. Pravo na pristup podacima koji se na Vas odnose (u svakom trenutku ih možete pregledati prilikom prijave sa Vašim korisničkim imenom na Vaš profil) </li>
     <li>2. Pravo na ispravak ili dopunu Vaših ličnih podataka kako bi Vaši podaci uvijek bili tačni i aktuelni (npr. promjena adrese za dostavu proizvoda; prilikom 
         pregleda Vašeg profila možete promijenite svoje podatke te ih tako držatiaktuelnim)</li>
    <li>3. Brisanje ličnih podataka (npr. brisanje Vašeg profila)</li>
    <li>4. Pravo na ograničenje obrade ličnih podataka (npr. ukoliko osporavate tačnost podataka, u razdoblju dok ne provjerimo njihovu tačnost)</li>
    <li>5. Pravo na prenosivost podataka (ako je primjenjivo)</li>
    <li>6. Pravo uskrate privole za obradu ličnih podataka u marketinške svrhe</li>
 </ul>
 <p>Svoja prava možete ostvariti u pisanom obliku na adresu sjedišta Bosna i Hercegovina, Grad Sarajevo, ulica M. Tita 11 ili na eemail-adresu info@zdravo.ba.
Vaš zahtjevi biti će riješeni u najkraćem mogućem roku u skladu sa zakonskim propisima koji reguliraju predmetnu materiju , 
a najkasnije u roku mjesec dana od dana zaprimanja zahtjeva.</p>
<p>Za ostvarivanje Vaših prava nužno je da možemo nedvojbeno utvrditi Vaš identitet kako ne bi došlo do neautorizirane obrade ličnih podataka.</p>
<p>Također, ukoliko smatrate da je povrijeđeno neko od Vaših, gore navedenih prava, možete podnijeti prigovor nadležnim službama za zaštitu osobnih podataka.</p>
<br /> 
<h4>Kontakt</h4>
<p>Sve dodatne informacije vezane za zaštitu ličnih podataka možete dobiti upitom na email adresu <a href="mailto:info@zdravo.ba">info@zdravo.ba</a> .</p>
<br /> 
<h4>OSTALO</h4>
<br /> 
<h4>Upotreba podataka posjetilaca</h4>
<p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> neće prodavati, iznajmljivati, dijeliti ili otkrivati privatne podatke posjetilaca trećim stranama osim u slučaju 
    primljenog sudskog naloga u slučaju istrage eventualnih protivzakonitih aktivnosti.  Jedini izuzetak u ovom pravilu je korištenje podataka potrebnih 
    za organizaciju slanja i isporuke kupljenih proizvoda, kada će dio podataka podjeliti sa ovlaštenom firmom koja se bavi dostavom pošiljaka na 
    predviđene adrese.</p>
    <br /> 
<h4>Postupanje prema maloljetnicima</h4>
<p>Maloljetnici mlađi od 18 godina godina ne smiju koristiti online trgovinu <Link to={homeUrl}>www.zdravo.ba</Link> i ukoliko se ovakve narudžbe identifikuju neće 
biti realizovane.</p>
<br /> 
<h4>Promjene u izjavi o povjerljivosti podataka</h4>
<p>Ovu izjavu o povjerljivosti podataka onlina trgovina <Link to={homeUrl}>www.zdravo.ba</Link> može izmijeniti u bilo kom trenutku objavljivanjem izmjenjenog teksta izjave o 
    povjerljivosti podataka u dijelu “Izjava o povjerljivosti podataka”. Izmjena izjave o povjerljivosti podataka stupa na snagu odmah nakon objave na 
    online trgovini <Link to={homeUrl}>www.zdravo.ba</Link> . Svaki Kupac sam je odgovoran i dužan periodično pregledati ovu stranicu i proučiti bilo kakve promjene u Izjavi o 
    povjerljivosti podataka. Nastavak upotrebe ove online trgovine od strane posjetilaca nakon stupanja na snagu takvih izmjena, podrazumijeva da posjetilac 
    potvrđuje i prihvata sve uslove tako izmijenjene Izjave o povjerljivosti podataka.</p>
    <br /> 
    <h4>Linkovi i veze na druge web stranice</h4>
    <p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> može sadržati linkove na druge web stranice. Ako posjetilac odluči iskoristiti taj link te pređe na drugu web stranicu, 
        online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> ne odgovara za zaštitu privatnosti posjetiočevih podataka na toj web stranici.</p>
        <br /> 
<h4>Nadležnost</h4>
<p>Posjetilac koristi web stranicu web shop <Link to={homeUrl}>www.zdravo.ba</Link> na vlastitu inicijativu i rizik, i odgovornost za poštivanje lokalnih zakonskih odredbi i pravila je isključivo 
    na posjetiocu. Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> ne garantuje da je upotreba ove web stranice u skladu sa zakonskim normama ili dostupna za upotrebu u bilo kojoj stranoj 
    zemlji ili pod bilo kojom drugom nadležnošću osim zemlje u kojoj je njeno sjedište a to je Bosna i Hercegovina.</p>

<p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> zadržava pravo da ograniči pristup bilo kojoj nadležnosti, području ili državi po svom sopstvenom nahođenju.</p>
<br /> 
<h4>Plaćanje</h4>
<p>Plaćanje na online trgovini <Link to={homeUrl}>www.zdravo.ba</Link> je osigurano SSL-certifikatom! Osim plaćanja pouzećem koristimo PayPal servis za Internet 
naplatu, koji koristi najsavremenije tehnologije zaštite od zloupotrebe..
Razmjena osjetljivih podataka između sustava koji obavlja online plaćanje i autorizacijskih centara kartičnih kuća obavlja se korištenjem virtualne 
privatne mreže (VPN) koja je potpuno zastićena od neautoriziranog pristupa. Osjetljivi podaci o Vašoj kreditnoj kartici dostavljaju se online izravno 
autorizacijskom centru.</p>
<p>Da je sigurna komunikacija izmedu Vašeg računala i poslužitelja uspostavljena možete znati po tome što će se na dnu Vašeg Internet preglednika pojaviti 
    ikonica u obliku katanca. Ako strelicu miša dovedete iznad lokota, pojavit će se natpis "SSL Secured" te razina enkripcije koja se koristi.</p>
<p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> se obavezuje pružati zaštitu osobnim podacima kupaca, na način da prikuplja samo nužne, osnovne podatke o kupcima/korisnicima 
    koji su nužni za ispunjenje naših obveza - prvenstveno za obrađivanje narudžbi i odgovaranje na Vaše upite; Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> informira kupce 
    o načinu korištenja prikupljenih podataka, redovno daje korisnicima online trgovine mogućnost izbora o upotrebi njihovih podataka, uključujući mogućnost 
    odluke žele li ili ne da se njihovo ime ukloni s lista koje se koriste za marketinške kampanje. Svi se podaci o korisnicima strogo čuvaju i dostupni su 
    samo djelatnicima kojima su ti podaci nužni za obavljanje posla. Svi uposlenici online trgovine <Link to={homeUrl}>www.zdravo.ba</Link> dužni su poštovati načela zaštite privatnosti.</p>
</div>
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}
            </ShopWrapper>
        )
    }
}

export default PrivacyPolicy