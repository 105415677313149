import React from "react";
import { Link } from "react-router-dom";
import formatMoney from "../../../functions/formatMoney";
import Sticker from "../Sticker";
import AdminWrapper from "../AdminWrapper";
import { Helmet } from "react-helmet";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { siteTitle, timeoutDelay } from "../../../functions/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { addProduct, upload } from "../../helpers/UserFunctions";

var imageFile_thumb = null;
/* Utility function to convert a canvas to a BLOB */
var dataURLToBlob = function (dataURL) {
  var BASE64_MARKER = ";base64,";
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    var parts = dataURL.split(",");
    var contentType = parts[0].split(":")[1];
    var raw = parts[1];
    return new Blob([raw], { type: contentType });
  }

  var parts = dataURL.split(BASE64_MARKER);
  var contentType = parts[0].split(":")[1];
  var raw = window.atob(parts[1]);
  var rawLength = raw.length;
  var uInt8Array = new Uint8Array(rawLength);
  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  return new Blob([uInt8Array], { type: contentType });
};
/* End Utility function to convert a canvas to a BLOB      */

var slugify = require("slugify");
var toggleSEOSidebar,
  filters = [];
var defaultMarkupValue = 1;
var date = new Date();
date = date.getDay() + date.getDate() + date.getFullYear();

class AddProduct extends React.Component {
  constructor() {
    super();

    this.unsubscribe = null;

    this.state = {
      categories: [],
      subCategories: [],
      filtersCategories: [],
      showPopupClass: "",
      productAction: "0",
      productOffer: "0",
      displayOnHomepage: "0",
      id: "",
      title: "",
      slug: "",
      description: "",
      shortDescription: "",
      image: null,
      vele_price: "",
      price: "",
      priceAction: "",
      actionPercent: "0",
      category: "",
      subCategory: "",
      brand: "",
      sliderImage1: null,
      sliderImage2: null,
      sliderImage3: null,
      sliderImage4: null,
      facebookImage: null,
      seoTitle: "",
      purpose: "",
      seoDescription: "",
      deliveryTime: "",
      productId: "null",
      showSEOSidebarClass: "",
      imageFile: null,
      imageFile_thumb: null,
      sliderImage1File: null,
      sliderImage2File: null,
      sliderImage3File: null,
      sliderImage4File: null,
      facebookImageFile: null,
      addedProductName: "",
      showStickerClass: "",
      stickerMessage: "",
      stickerType: "",
      status: "",
      allMarkups: [],
      catFiltersCategories: [],
      productFilters: [],
      addedProductFilters: [],
      deliverer: "",
      allBrands: [],
      delivererBarcode: "",
      delivererProductKey: "",
      productVariety: "0",
      productVariations: [],
      addedProductCategories: [],
      addedProductSubcategories: [],
      localProductKey:
        "GWS" +
        (Math.floor(Math.random() * 100) + 10) +
        date +
        Math.random().toString(36).substring(7).toUpperCase(),
      productType: "",
      variationId: Math.floor(Math.random() * 1000) + 100,
      variationName: "",
      variationPrice: "",
      variationType: "",
      available: "",
      pdv: "",
      currentMarkupValue: defaultMarkupValue,
      currentMarkupName: "",
      markup: "",
      allProductCategories: [],
    };

    this.onChangeImage = this.onChangeImage.bind(this);
    this.resetImage = this.resetImage.bind(this);

    this.onChangeSlider1 = this.onChangeSlider1.bind(this);
    this.resetSlider1 = this.resetSlider1.bind(this);

    this.onChangeSlider2 = this.onChangeSlider2.bind(this);
    this.resetSlider2 = this.resetSlider2.bind(this);

    this.onChangeSlider3 = this.onChangeSlider3.bind(this);
    this.resetSlider3 = this.resetSlider3.bind(this);

    this.onChangeSlider4 = this.onChangeSlider4.bind(this);
    this.resetSlider4 = this.resetSlider4.bind(this);

    this.onChangeFacebookImage = this.onChangeFacebookImage.bind(this);
    this.resetFacebookImage = this.resetFacebookImage.bind(this);

    toggleSEOSidebar = this.toggleSEOSidebar.bind(this);
    this.saveVariation = this.saveVariation.bind(this);
    this.deleteVariation = this.deleteVariation.bind(this);
  }

  getThumbnail(original, scale) {
    var canvas = document.createElement("canvas");

    canvas.width = original.width * scale;
    canvas.height = original.height * scale;

    canvas
      .getContext("2d")
      .drawImage(original, 0, 0, canvas.width, canvas.height);

    return canvas;
  }

  toggleSEOSidebar() {
    if (this.state.showSEOSidebarClass === "") {
      this.setState({
        showSEOSidebarClass: "show",
      });
      window.scrollTo(0, 0);
    } else {
      this.setState({
        showSEOSidebarClass: "",
      });
    }
  }

  handleChangeFilters = (e) => {
    var newFilter;
    var productFilters = this.state.productFilters;

    if (this.state.addedProductFilters.length !== 0) {
      this.state.addedProductFilters = JSON.parse(
        this.state.addedProductFilters
      );
    } else {
      this.state.addedProductFilters = [];
    }

    var allValue = e.target.value.split(":");
    if (e.target.checked === true) {
      newFilter = new Object();
      newFilter.filter_id = allValue[0];
      newFilter.cat_id = allValue[1];
      this.state.addedProductFilters.push(newFilter);
    } else {
      for (var i in this.state.addedProductFilters) {
        if (this.state.addedProductFilters[i].filter_id == allValue[0]) {
          this.state.addedProductFilters.splice(i, 1);
        }
      }
    }

    productFilters.map((filterGroup) => {
      filterGroup.filters.map((filter) => {
        if (filter.id == allValue[0]) {
          filter.isChecked = e.target.checked;
        }
      });
    });
    this.state.addedProductFilters = JSON.stringify(
      this.state.addedProductFilters
    );
    this.setState({
      productFilters: productFilters,
    });
  };

  handleChangeMainCategoriesArray = (e) => {
    console.log(this.state.addedProductCategories);
    var allProductCategories = this.state.allProductCategories;
    if (e.target.checked === true) {
      this.state.addedProductCategories.push(parseInt(e.target.value));
    } else {
      for (var i in this.state.addedProductCategories) {
        if (this.state.addedProductCategories[i] == e.target.value) {
          this.state.addedProductCategories.splice(i, 1);
        }
      }
    }
    allProductCategories.forEach((productCategory) => {
      if (productCategory.category_id == e.target.value) {
        productCategory.isChecked = e.target.checked;
      }
    });
    this.setState({
      allProductCategories,
    });
  };

  handleChangeSubcategoriesArray = (e) => {
    var allProductCategories = this.state.allProductCategories;
    if (e.target.checked === true) {
      this.state.addedProductSubcategories.push(parseInt(e.target.value));
    } else {
      for (var i in this.state.addedProductSubcategories) {
        if (this.state.addedProductSubcategories[i] == e.target.value) {
          this.state.addedProductSubcategories.splice(i, 1);
        }
      }
    }
    allProductCategories.forEach((productCategory) => {
      if (productCategory.category_id == e.target.value) {
        productCategory.isChecked = e.target.checked;
      }
    });

    this.setState({
      allProductCategories,
    });
  };

  handleChange = (e) => {
    this.state.seoTitle = this.state.title;
    this.state.seoDescription = this.state.shortDescription.substr(0, 120);
    this.state.slug = slugify(this.state.title, {
      replacement: "-",
      remove: null,
      lower: true,
    });
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  handleChangeMainPrice = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ product: state });
    var { price } = this.state;
    price = Math.trunc(
      this.state.vele_price * this.state.currentMarkupValue * this.state.pdv
    );

    var lastDigit = price.toString().substr(-1);
    price = price.toString().slice(0, -1) + "0";

    price = price.toString().slice(0, -1) + "0";

    if (lastDigit >= 0 && lastDigit < 3) {
      lastDigit = 0;
    } else if (lastDigit >= 3 && lastDigit < 8) {
      lastDigit = 5;
    } else if (lastDigit == 8 || lastDigit == 9) {
      lastDigit = 10;
    }

    price = parseInt(price) + lastDigit;
    this.setState({
      price,
    });
  };

  handleChangeActionPrice = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ product: state });
    var { priceAction } = this.state;
    priceAction = Math.trunc(
      this.state.price - (this.state.price * this.state.actionPercent) / 100
    );

    var lastDigit = priceAction.toString().substr(-1);
    priceAction = priceAction.toString().slice(0, -1) + "0";

    priceAction = priceAction.toString().slice(0, -1) + "0";

    if (lastDigit >= 0 && lastDigit < 3) {
      lastDigit = 0;
    } else if (lastDigit >= 3 && lastDigit < 8) {
      lastDigit = 5;
    } else if (lastDigit == 8 || lastDigit == 9) {
      lastDigit = 10;
    }

    priceAction = parseInt(priceAction) + lastDigit;
    this.setState({
      priceAction,
    });
  };

  handleChangeMarkup = (e) => {
    var allData = e.target.value;
    var markup = allData.split("_")[0];
    var currentMarkupValue = parseInt(allData.split("_")[1]);
    currentMarkupValue = defaultMarkupValue + currentMarkupValue / 100;
    var currentMarkupName = allData.split("_")[2];
    this.setState({
      markup,
      currentMarkupName,
      currentMarkupValue,
    });
  };

  handleChangeCategory = (e) => {
    var newFiltersGroup,
      productFilters = [];
    const subCategoriesArray = [];

    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    axios
      .get(
        process.env.REACT_APP_SERVER_PROXY_URL +
          "api/categories/subcategories/" +
          parseInt(this.state.category)
      )
      .then((response) => {
        this.setState({
          subCategories: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        process.env.REACT_APP_SERVER_PROXY_URL +
          "api/categories/single-category-id/" +
          parseInt(this.state.category)
      )
      .then((response) => {
        const { filtersCategories } = response.data;
        this.state.catFiltersCategories = JSON.parse(filtersCategories);
        this.state.catFiltersCategories.map((catFiltersCategory) => {
          axios
            .get(
              process.env.REACT_APP_SERVER_PROXY_URL +
                "api/filters/product-filters/" +
                catFiltersCategory
            )
            .then((response) => {
              if (response.data !== null) {
                const { filter_id, name, filters } = response.data;
                newFiltersGroup = new Object();
                newFiltersGroup.groupName = name;
                newFiltersGroup.id = filter_id;
                newFiltersGroup.filters = JSON.parse(filters);
                productFilters.push(newFiltersGroup);
                this.setState({
                  productFilters: productFilters,
                });
              }
            });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChangeSeo = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  saveVariation(id, type, name, price) {
    var newVariation;
    newVariation = new Object();
    newVariation.id = id;
    newVariation.type = type;
    newVariation.name = name;
    newVariation.price = price;
    this.state.productVariations.push(newVariation);
    JSON.stringify(this.state.productVariations);

    this.setState({
      variationId: Math.floor(Math.random() * 1000) + 100,
      variationType: "",
      variationName: "",
      variationPrice: "",
    });
  }

  deleteVariation(id) {
    var productVariations = this.state.productVariations;
    for (var i in productVariations) {
      if (productVariations[i].id == id) {
        productVariations.splice(i, 1);
      }
    }
    this.setState({
      productVariations: productVariations,
    });
    JSON.stringify(this.state.productVariations);
  }

  //ISTAKNUTA SLIKA
  onChangeImage(event) {
    var file = event.target.files[0];
    var file_thumb;
    var file_thumb_name = "thumb_" + file.name;
    this.setState({
      image: URL.createObjectURL(event.target.files[0]),
      imageFile: event.target.files[0],
    });

    const canvas = this.refs.canvas;
    var ctx = canvas.getContext("2d");

    // Ensure it's an image
    if (file.type.match(/image.*/)) {
      var reader = new FileReader();
      reader.onload = function (readerEvent) {
        var image = new Image();
        image.onload = function (imageEvent) {
          var width = 186,
            height = 230;
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(image, 0, 0, width, height);
          var dataUrl = canvas.toDataURL("image/jpeg");
          var resizedImage = dataURLToBlob(dataUrl);

          fetch(dataUrl)
            .then((res) => res.blob())
            .then((blob) => {
              file_thumb = new File([blob], file_thumb_name, blob);
              imageFile_thumb = file_thumb;
              console.log("file", imageFile_thumb);
            });
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    }

    if (this.state.imageFile !== null) {
      setTimeout(
        function () {
          const addImage = new FormData();
          const addImage_thumb = new FormData();
          addImage.append("image", this.state.imageFile);
          const config = {
            headers: { "content-type": "multipart/form-data" },
          };

          addImage_thumb.append("image", this.state.imageFile_thumb);
          const config_thumb = {
            headers: { "content-type": "multipart/form-data" },
          };

          upload(addImage, config)
            .then((response) => {})
            .catch((error) => {});
          upload(addImage_thumb, config_thumb)
            .then((response) => {})
            .catch((error) => {});
        }.bind(this),
        1500
      );
    }
  }

  resetImage(event) {
    event.preventDefault();
    this.setState({ image: null });
  }

  //PRVA SLIKA U SLIDERU
  onChangeSlider1(event) {
    this.setState({
      sliderImage1: URL.createObjectURL(event.target.files[0]),
      sliderImage1File: event.target.files[0],
    });

    if (this.state.sliderImage1File !== null) {
      const addImage_slider_1 = new FormData();
      addImage_slider_1.append("image", this.state.sliderImage1File);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_slider_1, config)
        .then((response) => {})
        .catch((error) => {});
    }
  }

  resetSlider1(event) {
    event.preventDefault();
    this.setState({ sliderImage1: null });
  }

  //DRUGA SLIKA U SLIDERU
  onChangeSlider2(event) {
    this.setState({
      sliderImage2: URL.createObjectURL(event.target.files[0]),
      sliderImage2File: event.target.files[0],
    });
    if (this.state.sliderImage2File !== null) {
      const addImage_slider_2 = new FormData();
      addImage_slider_2.append("image", this.state.sliderImage2File);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_slider_2, config)
        .then((response) => {})
        .catch((error) => {});
    }
  }

  resetSlider2(event) {
    event.preventDefault();
    this.setState({ sliderImage2: null });
  }

  //TRECA SLIKA U SLIDERU

  onChangeSlider3(event) {
    this.setState({
      sliderImage3: URL.createObjectURL(event.target.files[0]),
      sliderImage3File: event.target.files[0],
    });

    if (this.state.sliderImage3File !== null) {
      const addImage_slider_3 = new FormData();
      addImage_slider_3.append("image", this.state.sliderImage3File);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_slider_3, config)
        .then((response) => {})
        .catch((error) => {});
    }
  }

  resetSlider3(event) {
    event.preventDefault();
    this.setState({ sliderImage3: null });
  }

  //CETVRTA SLIKA U SLIDERU

  onChangeSlider4(event) {
    this.setState({
      sliderImage4: URL.createObjectURL(event.target.files[0]),
      sliderImage4File: event.target.files[0],
    });

    if (this.state.sliderImage4File !== null) {
      const addImage_slider_4 = new FormData();
      addImage_slider_4.append("image", this.state.sliderImage4File);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_slider_4, config)
        .then((response) => {})
        .catch((error) => {});
    }
  }

  resetSlider4(event) {
    event.preventDefault();
    this.setState({ sliderImage4: null });
  }

  //FACEBOOK SLIKA

  onChangeFacebookImage(event) {
    this.setState({
      facebookImage: URL.createObjectURL(event.target.files[0]),
      facebookImageFile: event.target.files[0],
    });

    if (this.state.facebookImageFile !== null) {
      const addImage_facebook_image = new FormData();
      addImage_facebook_image.append("image", this.state.facebookImageFile);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_facebook_image, config)
        .then((response) => {})
        .catch((error) => {});
    }
  }

  resetFacebookImage(event) {
    event.preventDefault();
    this.setState({ facebookImage: null });
  }

  getParentCategoryList() {
    axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL + "api/categories/parent")
      .then((response) => {
        this.setState({
          categories: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getBrandList() {
    axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL + "api/brands")
      .then((response) => {
        this.setState({
          allBrands: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Collect settings data
  getSettings() {
    axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL + "api/settings/get-settings")
      .then((response) => {
        var { pdv } = response.data;
        this.setState({
          pdv: 1 + pdv / 100,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getMarkupList() {
    axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL + "api/markups")
      .then((response) => {
        this.setState({
          allMarkups: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getProductCategoriesList() {
    var allProductCategoriesArray = [];
    axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL + "api/categories")
      .then((response) => {
        response.data.forEach((doc) => {
          const { category_id, name, parentCategory } = doc;
          allProductCategoriesArray.push({
            category_id,
            isChecked: false,
            name,
            parentCategory,
          });
        });

        this.setState({
          allProductCategories: allProductCategoriesArray,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount = () => {
    this.getMarkupList();
    this.getParentCategoryList();
    this.getBrandList();
    this.getSettings();
    this.getProductCategoriesList();
  };

  onSubmit = (e) => {
    e.preventDefault();
    var image = "",
      image_thumb = "",
      sliderImage1 = "",
      sliderImage2 = "",
      sliderImage3 = "",
      sliderImage4 = "",
      facebookImage = "";
    var {
      imageFile,
      sliderImage1File,
      sliderImage2File,
      markup,
      sliderImage3File,
      sliderImage4File,
      facebookImageFile,
    } = this.state;
    const {
      id,
      title,
      slug,
      description,
      shortDescription,
      vele_price,
      price,
      priceAction,
      actionPercent,
      category,
      subCategory,
      brand,
      purpose,
      seoTitle,
      seoDescription,
      addedProductCategories,
      addedProductSubcategories,
      deliveryTime,
      productAction,
      productOffer,
      displayOnHomepage,
      status,
      deliverer,
      delivererBarcode,
      delivererProductKey,
      localProductKey,
      productType,
      productVariety,
      productVariations,
      available,
      productCreatedAt,
    } = this.state;

    if (imageFile === null) {
      imageFile = new Object();
      imageFile.name = "empty";
      imageFile_thumb.name = "empty";
    }
    if (sliderImage1File === null) {
      sliderImage1File = new Object();
      sliderImage1File.name = "empty";
    }
    if (sliderImage2File === null) {
      sliderImage2File = new Object();
      sliderImage2File.name = "empty";
    }
    if (sliderImage3File === null) {
      sliderImage3File = new Object();
      sliderImage3File.name = "empty";
    }
    if (sliderImage4File === null) {
      sliderImage4File = new Object();
      sliderImage4File.name = "empty";
    }
    if (facebookImageFile === null) {
      facebookImageFile = new Object();
      facebookImageFile.name = "empty";
    }
    const newProduct = {
      title: title,
      seoTitle: seoTitle,
      slug: slug,
      description: description,
      seoDescription: seoDescription,
      purpose,
      shortDescription: shortDescription,
      vele_price: vele_price,
      price: price,
      markup: parseInt(markup),
      priceAction: priceAction,
      actionPercent: actionPercent,
      available: parseInt(available),
      brand: parseInt(brand),
      category: parseInt(category),
      subCategory: parseInt(subCategory),
      categories: addedProductCategories,
      subcategories: addedProductSubcategories,
      deliverer: parseInt(deliverer),
      delivererBarcode: delivererBarcode,
      delivererProductKey: delivererProductKey,
      deliveryTime: deliveryTime,
      localProductKey: localProductKey,
      productAction: parseInt(productAction),
      productOffer: parseInt(productOffer),
      displayOnHomepage: parseInt(displayOnHomepage),
      productType: parseInt(productType),
      productVariations: JSON.stringify(productVariations),
      productVariety: parseInt(productVariety),
      status: status,
      filters: this.state.addedProductFilters,
      image: imageFile.name,
      image_thumb: imageFile_thumb.name,
      sliderImage1: sliderImage1File.name,
      sliderImage2: sliderImage2File.name,
      sliderImage3: sliderImage3File.name,
      sliderImage4: sliderImage4File.name,
      facebookImage: facebookImageFile.name,
    };

    addProduct(newProduct)
      .then((res) => {
        this.setState({
          addedProductName: title,
          showStickerClass: "show",
        });
        setTimeout(
          function () {
            this.setState({
              showStickerClass: "",
            });
            this.props.history.push("/administracija/proizvodi");
          }.bind(this),
          timeoutDelay
        );

        this.setState({
          stickerMessage:
            'Uspješno ste dodali proizvod <b>"' +
            this.state.addedProductName +
            '"</b>',
          stickerType: "success",
          id: "",
          title: "",
          slug: "",
          description: "",
          shortDescription: "",
          image: "",
          vele_price: "",
          price: "",
          markup: "",
          priceAction: "",
          actionPercent: "",
          category: "",
          subCategory: "",
          brand: "",
          sliderImage1: "",
          sliderImage2: "",
          sliderImage3: "",
          sliderImage4: "",
          facebookImage: "",
          seoTitle: "",
          purpose: "",
          seoDescription: "",
          deliveryTime: "",
          productAction: "",
          productOffer: "",
          displayOnHomepage: "",
          status: "",
          deliverer: "",
          delivererBarcode: "",
          delivererProductKey: "",
          localProductKey: "",
          productType: "",
          productVariety: "",
          available: "",
          productVariations: [],
          productCreatedAt: "",
        });
      })
      .catch((error) => {
        this.setState({
          showStickerClass: "show",
        });
        setTimeout(
          function () {
            this.setState({
              showStickerClass: "",
            });
          }.bind(this),
          timeoutDelay
        );
        this.setState({
          stickerMessage:
            "Došlo je do problema prilikom dodavanja proizvoda: " + error,
          stickerType: "danger",
        });
      });

    if (imageFile !== null) {
      setTimeout(
        function () {
          const addImage = new FormData();
          const addImage_thumb = new FormData();
          addImage.append("image", this.state.imageFile);
          const config = {
            headers: { "content-type": "multipart/form-data" },
          };

          addImage_thumb.append("image", imageFile_thumb);
          const config_thumb = {
            headers: { "content-type": "multipart/form-data" },
          };

          upload(addImage, config)
            .then((response) => {})
            .catch((error) => {});
          upload(addImage_thumb, config_thumb)
            .then((response) => {})
            .catch((error) => {});
        }.bind(this),
        1500
      );
    }

    if (sliderImage1File !== null) {
      const addImage_slider_1 = new FormData();
      addImage_slider_1.append("image", this.state.sliderImage1File);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_slider_1, config)
        .then((response) => {})
        .catch((error) => {});
    }
    if (sliderImage2File !== null) {
      const addImage_slider_2 = new FormData();
      addImage_slider_2.append("image", this.state.sliderImage2File);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_slider_2, config)
        .then((response) => {})
        .catch((error) => {});
    }
    if (sliderImage3File !== null) {
      const addImage_slider_3 = new FormData();
      addImage_slider_3.append("image", this.state.sliderImage3File);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_slider_3, config)
        .then((response) => {})
        .catch((error) => {});
    }
    if (sliderImage4File !== null) {
      const addImage_slider_4 = new FormData();
      addImage_slider_4.append("image", this.state.sliderImage4File);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_slider_4, config)
        .then((response) => {})
        .catch((error) => {});
    }
    if (facebookImageFile !== null) {
      const addImage_facebook_image = new FormData();
      addImage_facebook_image.append("image", this.state.facebookImageFile);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_facebook_image, config)
        .then((response) => {})
        .catch((error) => {});
    }
  };

  romanize(num) {
    var lookup = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      },
      roman = "",
      i;
    for (i in lookup) {
      while (num >= lookup[i]) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  }

  render() {
    var shortDescription, description;
    const {
      id,
      title,
      slug,
      image,
      price,
      priceAction,
      actionPercent,
      category,
      subCategory,
      brand,
      sliderImage1,
      sliderImage2,
      sliderImage3,
      sliderImage4,
      facebookImage,
      seoTitle,
      purpose,
      seoDescription,
      markup,
      deliveryTime,
      productAction,
      productOffer,
      displayOnHomepage,
      status,
      deliverer,
      delivererBarcode,
      delivererProductKey,
      productType,
      productVariations,
      vele_price,
      variationId,
      variationName,
      variationPrice,
      variationType,
      available,
    } = this.state;
    var productVariety = parseInt(this.state.productVariety);
    return (
      <AdminWrapper>
        <section
          id="addProduct"
          className="section default-section content-page-section add-product default-form"
        >
          {this.state.showStickerClass === "show" && (
            <Sticker
              stickerType={this.state.stickerType}
              stickerMessage={this.state.stickerMessage}
            />
          )}
          <div className="container">
            <Helmet>
              <title> Dodaj proizvod &rsaquo; Zdravo.ba </title>
            </Helmet>
            <div className="is-hidden">
              <canvas ref="canvas" width={640} height={425} />{" "}
            </div>

            <div className="content">
              <div className="page-header">
                <h2 className="page-title">Dodaj novi proizvod</h2>
              </div>
              <div className="breadcrumbs">
                <Link to="/administracija">Administracija</Link>
                <span>/</span>
                <Link to="/administracija/proizvodi">Proizvodi</Link>
                <span>/</span>
                <span>Dodaj novi proizvod</span>
              </div>
              <form
                name="add-product"
                onSubmit={this.onSubmit}
                className="form"
              >
                <fieldset className="">
                  <div className="columns is-multiline main">
                    <input
                      type="hidden"
                      id="id"
                      name="id"
                      value={id}
                      onChange={this.handleChange}
                      required
                    />

                    <label htmlFor="title" className="column is-12 title">
                      Ime proizvoda
                      <input
                        type="text"
                        id="title"
                        name="title"
                        placeholder="Puno ime proizvoda koje će biti prikazano"
                        value={title}
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                    <label htmlFor="slug" className="column is-12 slug">
                      <span>Generisani url</span>
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        placeholder=""
                        value={slug}
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                    <label htmlFor="purpose" className="column is-12">
                      <span>Namjena proizvoda</span>
                      <input
                        type="text"
                        id="purpose"
                        name="purpose"
                        placeholder=""
                        value={purpose}
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                    <label
                      htmlFor="shortDescription"
                      className="column is-12 short-description"
                    >
                      Kratki opis Proizvoda
                      <CKEditor
                        editor={ClassicEditor}
                        shortDescription=""
                        onInit={(editor) => {}}
                        onChange={(event, editor) => {
                          this.setState({
                            shortDescription: editor.getData(),
                          });
                          console.log(shortDescription);
                        }}
                        onBlur={(editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                    </label>

                    <label
                      htmlFor="description"
                      className="column is-12 description"
                    >
                      Detaljni Opis Proizvoda
                      <CKEditor
                        editor={ClassicEditor}
                        description=""
                        onInit={(editor) => {}}
                        onChange={(event, editor) => {
                          this.setState({
                            description: editor.getData(),
                          });
                          console.log({ event, editor, description });
                        }}
                        onBlur={(editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                    </label>
                    <span className="is-hidden">
                      {(shortDescription = this.state.shortDescription)}
                      {(description = this.state.description)}
                    </span>
                    {/*  <label htmlFor="markup" className="column is-6">
                                        Vrijednost marže
                                        <select id="markup" name="markup" value={markup} onChange={this.handleChangeMarkup} required>
                                            <option value="null">Izaberi vrijednost marže</option>
                                            {this.state.allMarkups.map(markup =>
                                            <option value={markup.markup_id+"_"+markup.value+"_"+markup.name}>{markup.name} ({markup.value}%)</option>
                                            )}
                                        </select>
                                        <span>{this.state.currentMarkupName}</span>
                                            </label> */}
                    {/*   <label htmlFor="vele_price" className="column is-12 price">
                                        Veleprodajna cijena proizvoda(1KM = 100)
                                        <input type="text" id="vele_price" name="vele_price" placeholder="Veleprodajna cijena proizvoda"
                                        value={vele_price} onChange={ this.handleChangeMainPrice } required/>
                                        <span className="real-price">{formatMoney(vele_price)}</span>
                                            </label> */}

                    <label htmlFor="price" className="column is-4 price">
                      Cijena Proizvoda(1KM = 100)
                      <input
                        type="text"
                        id="price"
                        name="price"
                        placeholder="Stvarna cijena proizvoda"
                        value={price}
                        onChange={this.handleChange}
                        required
                      />
                      <span className="real-price">{formatMoney(price)}</span>
                    </label>

                    <label
                      htmlFor="actionPercent"
                      className="column is-4 price"
                    >
                      Popust(unijeti samo broj, bez znaka %)
                      <input
                        type="text"
                        id="actionPercent"
                        name="actionPercent"
                        placeholder="Procenat popusta na akciji"
                        value={actionPercent}
                        onChange={this.handleChangeActionPrice}
                      />
                    </label>

                    <label
                      htmlFor="priceAction"
                      className="column is-4 price not-editable"
                    >
                      Akcijska cijena Proizvoda
                      <input
                        type="text"
                        id="priceAction"
                        name="priceAction"
                        placeholder="Cijena proizvoda na akciji"
                        value={priceAction}
                        onChange={this.handleChange}
                        disabled
                      />
                    </label>

                    <label htmlFor="category" className="column is-6">
                      Glavna kategorija(za prikaz kategorije u URL-u proizvoda)
                      <select
                        id="category"
                        name="category"
                        onChange={this.handleChangeCategory}
                        required
                      >
                        <option value="" disabled selected>
                          Kategorija
                        </option>
                        {this.state.categories.map((category) => (
                          <option value={category.category_id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </label>

                    {/*    <label htmlFor="subCategory" className="column is-6">
                                        Podkategorija
                                        <select id="subCategory" name="subCategory" onChange={ this.handleChange } >
                                            <option value="null"></option>
                                            {this.state.subCategories.map(subCategory =>
                                            <option value={subCategory.category_id}>{subCategory.name}</option>
                                            )}
                                        </select>
                                    </label>
                                */}

                    <label htmlFor="deliverer" className="column is-6">
                      Dostavljač
                      <select
                        id="deliverer"
                        name="deliverer"
                        value={deliverer}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled selected>
                          Izaberi dostavljača
                        </option>
                        {this.state.allMarkups.map((markup) => (
                          <option value={markup.markup_id}>
                            {markup.name}
                          </option>
                        ))}
                      </select>
                    </label>

                    <label htmlFor="brand" className="column is-6">
                      Proizvođač
                      <select
                        id="brand"
                        name="brand"
                        onChange={this.handleChange}
                        required
                      >
                        <option value="" disabled selected>
                          Izaberi proizvođača
                        </option>
                        {this.state.allBrands.map((brand) => (
                          <option value={brand.brand_id}>{brand.name}</option>
                        ))}
                      </select>
                    </label>

                    <label htmlFor="deliveryTime" className="column is-6">
                      Rok isporuke
                      <select
                        id="deliveryTime"
                        name="deliveryTime"
                        value={deliveryTime}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled selected>
                          Izaberi vrijeme isporuke
                        </option>
                        <option value="1 dan">1 dan</option>
                        <option value="2 dana">2 dana</option>
                        <option value="3 dana">3 dana</option>
                        <option value="4 dana">4 dana</option>
                      </select>
                    </label>

                    <label htmlFor="productVariety" className="column is-6">
                      Varijabilni proizvod
                      <select
                        id="productVariety"
                        name="productVariety"
                        value={productVariety}
                        onChange={this.handleChange}
                      >
                        <option value="0">Ne</option>
                        <option value="1">Da</option>
                      </select>
                    </label>
                    {productVariety === 1 && (
                      <div className="column is-12 variation-box">
                        <div className="columns form">
                          <input
                            type="hidden"
                            name="variationId"
                            value={variationId}
                            placeholder=""
                            onChange={this.handleChange}
                          />
                          <label
                            htmlFor="variationType"
                            className="column is-3"
                          >
                            <select
                              id="variationType"
                              name="variationType"
                              value={variationType}
                              onChange={this.handleChange}
                            >
                              <option value="" disabled selected>
                                Tip varijacije
                              </option>
                              <option value="Boja">Boja</option>
                              <option value="Količina">Količina</option>
                              <option value="Aroma">Aroma</option>
                              <option value="Sastav">Sastav</option>
                              <option value="Vremenski period">
                                Vremenski period
                              </option>
                              <option value="Tip pojasa">Tip pojasa</option>
                              <option value="Tip">Tip</option>
                              <option value="Vrsta">Vrsta</option>
                            </select>
                          </label>
                          <label
                            htmlFor="variationName"
                            className="column is-3"
                          >
                            <input
                              type="text"
                              name="variationName"
                              value={variationName}
                              placeholder="Ime varijcije"
                              onChange={this.handleChange}
                            />
                          </label>
                          <label
                            htmlFor="variationPrice"
                            className="column is-3"
                          >
                            <input
                              type="text"
                              name="variationPrice"
                              value={variationPrice}
                              placeholder="Cijena"
                              onChange={this.handleChange}
                            />
                          </label>
                          <span
                            className="button default"
                            onClick={this.saveVariation.bind(
                              this,
                              variationId,
                              variationType,
                              variationName,
                              variationPrice
                            )}
                          >
                            Dodaj <FontAwesomeIcon icon="plus-circle" />
                          </span>
                        </div>
                        {this.state.productVariations.length > 0 && (
                          <table>
                            <thead>
                              <tr>
                                <th>Tip</th>
                                <th>Ime</th>
                                <th>Cijena</th>
                                <th>Opcije</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.productVariations.map(
                                (productVariation) => {
                                  return (
                                    <tr>
                                      <td>{productVariation.type}</td>
                                      <td>{productVariation.name}</td>
                                      <td>
                                        {formatMoney(productVariation.price)}
                                      </td>
                                      <td>
                                        <span
                                          className=""
                                          onClick={this.deleteVariation.bind(
                                            this,
                                            productVariation.id
                                          )}
                                        >
                                          <FontAwesomeIcon icon="trash-alt" />
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="columns is-multiline top">
                    <label htmlFor="available" className="column is-6">
                      Proizvod na stanju
                      <select
                        id="available"
                        name="available"
                        value={available}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled selected>
                          Izaberi
                        </option>
                        <option value="1">Da</option>
                        <option value="0">Ne</option>
                      </select>
                    </label>
                    <label htmlFor="localProductKey" className="column is-6">
                      Šifra proizvoda(Naša)
                      <input
                        type="text"
                        id="localProductKey"
                        name="localProductKey"
                        placeholder="Šifra proizvoda"
                        value={this.state.localProductKey}
                        onChange={this.handleChange}
                        required
                      />
                    </label>

                    <label
                      htmlFor="delivererProductKey"
                      className="column is-6"
                    >
                      Šifra proizvoda(Dobavljač)
                      <input
                        type="text"
                        id="delivererProductKey"
                        name="delivererProductKey"
                        placeholder="Bar kod proizvoda"
                        value={delivererProductKey}
                        onChange={this.handleChange}
                      />
                    </label>

                    <label htmlFor="delivererBarcode" className="column is-6">
                      Bar kod proizvoda(Dobavljač)
                      <input
                        type="text"
                        id="delivererBarcode"
                        name="delivererBarcode"
                        placeholder="Šifra proizvoda"
                        value={delivererBarcode}
                        onChange={this.handleChange}
                      />
                    </label>

                    <label htmlFor="productType" className="column is-6">
                      Tip proizvoda
                      <select
                        id="productType"
                        name="productType"
                        value={productType}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled selected>
                          Izaberi tip proizvoda
                        </option>
                        <option value="1">Dodatak prehrani</option>
                        <option value="2">Kozmetika</option>
                        <option value="3">Ishrana</option>
                        <option value="4">Drogerija</option>
                        <option value="5">Aparati i pomagala</option>
                      </select>
                    </label>
                  </div>
                  <span className="divider-vertical"></span>
                  <div className="sidebar">
                    <div>
                      <label htmlFor="status" className="">
                        Status proizvoda
                        <select
                          id="status"
                          name="status"
                          value={status}
                          onChange={this.handleChange}
                          required
                        >
                          <option value="" disabled selected>
                            Izaberi
                          </option>
                          <option value="Objavljeno">Objavljeno</option>
                          <option value="U pripremi">U pripremi</option>
                          <option value="Neobjavljeno">Neobjavljeno</option>
                        </select>
                      </label>
                      <label htmlFor="displayOnHomepage" className="">
                        Prikazati proizvod na početnoj stranici?
                        <select
                          id="displayOnHomepage"
                          name="displayOnHomepage"
                          value={displayOnHomepage}
                          onChange={this.handleChange}
                        >
                          <option value="" disabled selected>
                            Izaberi
                          </option>
                          <option value="0">Ne</option>
                          <option value="1">Da</option>
                        </select>
                      </label>
                      <label htmlFor="productAction" className="">
                        Proizvod na akciji?
                        <select
                          id="productAction"
                          name="productAction"
                          value={productAction}
                          onChange={this.handleChange}
                        >
                          <option value="" disabled selected>
                            Izaberi
                          </option>
                          <option value="0">Ne</option>
                          <option value="1">Da</option>
                        </select>
                      </label>
                      <label htmlFor="productOffer" className="">
                        Proizvod u ponudi sedmice?
                        <select
                          id="productOffer"
                          name="productOffer"
                          value={productOffer}
                          onChange={this.handleChange}
                        >
                          <option value="" disabled selected>
                            Izaberi
                          </option>
                          <option value="0">Ne</option>
                          <option value="1">Da</option>
                        </select>
                      </label>
                      <div className="button-area">
                        <button type="submit" className="button default add">
                          <span className="item">
                            Dodaj proizvod{" "}
                            <FontAwesomeIcon icon="plus-circle" />
                          </span>
                        </button>
                      </div>

                      {this.state.productFilters.length > 0 && (
                        <label
                          htmlFor="filters"
                          className="column is-12 filters-categories"
                        >
                          Atributi proizvoda
                          <div className="wrapper">
                            {this.state.productFilters.map((filterGroup) => {
                              return (
                                <div className="inner-box">
                                  <h6 className="filter-category-name">
                                    {filterGroup.groupName}
                                  </h6>
                                  {filterGroup.filters.map((filter) => {
                                    return (
                                      <div className="single-line">
                                        <input
                                          key={filter.id}
                                          name="filter_id"
                                          onClick={this.handleChangeFilters}
                                          type="checkbox"
                                          value={
                                            filter.id + ":" + filterGroup.id
                                          }
                                          checked={filter.isChecked}
                                        />

                                        {filter.name}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </label>
                      )}

                      <label
                        htmlFor="filters"
                        className="column is-12 product-categories filters-categories"
                      >
                        Kategorije proizvoda
                        <div className="wrapper">
                          {this.state.allProductCategories.map(
                            (singleCategory) =>
                              singleCategory.parentCategory === null && (
                                <div className="inner-box">
                                  <div className="single-line">
                                    <input
                                      key={singleCategory.id}
                                      name="filter_id"
                                      onClick={
                                        this.handleChangeMainCategoriesArray
                                      }
                                      type="checkbox"
                                      value={singleCategory.category_id}
                                      checked={singleCategory.isChecked}
                                    />
                                    {singleCategory.name}
                                  </div>

                                  {this.state.allProductCategories.map(
                                    (singleSubCategory) => (
                                      <>
                                        {singleSubCategory.parentCategory ===
                                          singleCategory.category_id && (
                                          <div className="single-line subcategory">
                                            <input
                                              key={singleCategory.id}
                                              name="filter_id"
                                              onClick={
                                                this
                                                  .handleChangeSubcategoriesArray
                                              }
                                              type="checkbox"
                                              value={
                                                singleSubCategory.category_id
                                              }
                                              checked={
                                                singleSubCategory.isChecked
                                              }
                                            />
                                            {singleSubCategory.name}
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                </div>
                              )
                          )}
                        </div>
                      </label>

                      <div id="upload_button" className="">
                        <label htmlFor="image" className="label-image">
                          Istaknuta slika
                          <input
                            type="file"
                            id="image"
                            name="image"
                            onChange={this.onChangeImage}
                            required
                          />
                          <span className="add-image">
                            Dodaj istaknutu sliku{" "}
                            <FontAwesomeIcon icon="image" />
                          </span>
                        </label>
                        <div className="image-preview">
                          {image && <img src={image} alt="" />}
                          {image && (
                            <button
                              className="remove-button"
                              onClick={this.resetImage}
                            >
                              <i className="icon"></i>
                            </button>
                          )}
                        </div>
                      </div>

                      <div id="upload_slider" className="">
                        <div className="single-row">
                          <label htmlFor="sliderImage1">
                            <input
                              type="file"
                              id="sliderImage1"
                              name="image"
                              onChange={this.onChangeSlider1}
                            />
                            <span className="add-image">
                              Dodaj sliku u slider
                            </span>
                          </label>
                          <div className="image-preview">
                            {sliderImage1 && <img src={sliderImage1} alt="" />}
                            {sliderImage1 && (
                              <button
                                className="remove-button"
                                onClick={this.resetSlider1}
                              >
                                <i className="icon"></i>
                              </button>
                            )}
                          </div>
                        </div>

                        <div className="single-row">
                          <label htmlFor="sliderImage2">
                            <input
                              type="file"
                              id="sliderImage2"
                              name="image"
                              onChange={this.onChangeSlider2}
                            />
                            <span className="add-image">
                              Dodaj sliku u slider
                            </span>
                          </label>
                          <div className="image-preview">
                            {sliderImage2 && <img src={sliderImage2} alt="" />}
                            {sliderImage2 && (
                              <button
                                className="remove-button"
                                onClick={this.resetSlider2}
                              >
                                <i className="icon"></i>
                              </button>
                            )}
                          </div>
                        </div>

                        <div className="single-row">
                          <label htmlFor="sliderImage3">
                            <input
                              type="file"
                              id="sliderImage3"
                              name="image"
                              onChange={this.onChangeSlider3}
                            />
                            <span className="add-image">
                              Dodaj sliku u slider
                            </span>
                          </label>
                          <div className="image-preview">
                            {sliderImage3 && <img src={sliderImage3} alt="" />}
                            {sliderImage3 && (
                              <button
                                className="remove-button"
                                onClick={this.resetSlider3}
                              >
                                <i className="icon"></i>
                              </button>
                            )}
                          </div>
                        </div>

                        <div className="single-row">
                          <label htmlFor="sliderImage4">
                            <input
                              type="file"
                              id="sliderImage4"
                              name="image"
                              onChange={this.onChangeSlider4}
                            />
                            <span className="add-image">
                              Dodaj sliku u slider
                            </span>
                          </label>
                          <div className="image-preview">
                            {sliderImage4 && <img src={sliderImage4} alt="" />}
                            {sliderImage4 && (
                              <button
                                className="remove-button"
                                onClick={this.resetSlider4}
                              >
                                <i className="icon"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div id="upload_button" className="">
                        <label htmlFor="facebookImage" className="label-image">
                          Facebook slika
                          <input
                            type="file"
                            id="facebookImage"
                            name="image"
                            onChange={this.onChangeFacebookImage}
                          />
                          <span className="add-image">
                            Dodaj facebook sliku{" "}
                            <FontAwesomeIcon icon="image" />
                          </span>
                        </label>
                        <div className="image-preview">
                          {facebookImage && <img src={facebookImage} alt="" />}
                          {facebookImage && (
                            <button
                              className="remove-button"
                              onClick={this.resetFacebookImage}
                            >
                              <i className="icon"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    <span
                      className="meta-button"
                      onClick={this.toggleSEOSidebar.bind(this)}
                    >
                      <span className="inner">
                        <h4>Meta informacije</h4>
                        <p>Dodaci za SEO</p>
                      </span>
                      <span className="icon"></span>
                    </span>
                  </div>

                  <div
                    className={
                      "sidebar seo-sidebar " + this.state.showSEOSidebarClass
                    }
                  >
                    <div className="header">
                      <h4>SEO informacije</h4>
                      <span
                        className="icon"
                        onClick={this.toggleSEOSidebar.bind(this)}
                      ></span>
                    </div>

                    <label htmlFor="seoTitle" className="">
                      SEO naziv proizvoda
                      <input
                        type="text"
                        id="seoTitle"
                        name="seoTitle"
                        placeholder="Naziv proizvoda za SEO"
                        value={seoTitle}
                        onChange={this.handleChangeSeo}
                      />
                    </label>

                    <label htmlFor="seoDescription" className="">
                      Meta deskripcija
                      <textarea
                        id="seoDescription"
                        name="seoDescription"
                        placeholder="Meta deskripcija za proizvod"
                        value={seoDescription}
                        onChange={this.handleChangeSeo}
                      ></textarea>
                    </label>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </section>
      </AdminWrapper>
    );
  }
}

export default AddProduct;
