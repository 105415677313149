import React from 'react'
import { Link } from 'react-router-dom'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import Pagination from '../Pagination';
import {Helmet} from "react-helmet";
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addMarkup, upload } from '../../helpers/UserFunctions'
import axios from 'axios';

var slugify = require('slugify')
var togglePopupDelete;

class Markups extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;
        
        this.state = {
            allMarkups: [],
            pageOfMarkups: [],
            showPopupDeleteClass: '',
            id: '',
            name: '',
            value: '',
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
            singleMarkupKey: '',
            singleMarkupName: '',
            addedMarkupName: '',
            test: '',
            loading:false,
        }   

        togglePopupDelete = this.togglePopupDelete.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
    }

handleChange = (e) => { 
    this.state.slug = slugify(this.state.name, {
        replacement: '-',
        remove: null,
        lower: true
      });    
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);    
      }


togglePopupDelete (id, name) {
    if (this.state.showPopupDeleteClass === '') {
        this.setState({
            showPopupDeleteClass: 'show',
            singleMarkupKey: id,
            singleMarkupName: name,
        })
    }
    else{
        this.setState({
            showPopupDeleteClass: ''
        })
    }     
  }

deleteMarkup(id){
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/markups/delete-markup/' + id)
    .then(() => {
        this.getMarkupList();
        this.setState({
            showPopupDeleteClass: '',
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Uspješno ste izbrisali maržu "<b>' + this.state.singleMarkupName + '"</b>',
        stickerType: 'success',
        })
    })
    .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Došlo je do problema prilikom brisanja marže: ' + error,
        stickerType: 'danger',
    })
    });
}

onChangePage(pageOfMarkups) {
    // update state with new page of items
    this.setState({ 
        pageOfMarkups: pageOfMarkups 
    });
}

onSubmit = (e) => {
    e.preventDefault();
    const {name, value} = this.state;
const newMarkup = {
    name: name,
    value: value,
    }

    addMarkup(newMarkup).then(res => {
            this.getMarkupList();
            this.setState({
                addedMarkupName: this.state.name,
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
            }.bind(this),timeoutDelay);
        
            this.setState({
                stickerMessage: 'Uspješno ste dodali maržu <b>"' + this.state.addedMarkupName + '"</b>',
                stickerType: 'success',
                name: '',
                value: '',
            });
        
            this.props.history.push("/administracija/marze")
          })
          .catch((error) => {
            this.setState({
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
           }.bind(this),timeoutDelay);
           this.setState({
            stickerMessage: 'Došlo je do problema prilikom dodavanja marže.',
            stickerType: 'danger',
            })
        });
   
}

getMarkupList() {  
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/markups')
.then((response) => {
this.setState({
allMarkups: response.data
});
})
.catch((error) => {
console.log(error);
})
}

componentDidMount = () => {
    this.getMarkupList();
}

    render(){
        const { id, name, value} = this.state;
        return(
            <AdminWrapper>
            <section id="markups" className="section default-section add-markup content-page-section default-form">
            {this.state.loading && (
                <div className="loader-box">
                    <span className="loader"></span>
                </div>
            )}

            <div className={"popup-wrapper " + this.state.showPopupDeleteClass}>
                    <div className="overlay-back"></div>
                    <div className="popup-box popup-delete">
                        <h6 className="text">Jesi li siguran da želiš izbrisati maržu <br /><b>"{this.state.singleMarkupName}"</b>?</h6>
                        <div className="button-area">
                            <button className="button confirm" onClick={this.deleteMarkup.bind(this, this.state.singleMarkupKey)}>Želim <FontAwesomeIcon icon="trash-alt" /></button>
                            <button className="button decline" onClick={this.togglePopupDelete.bind(this)}>Ne, hvala <FontAwesomeIcon icon="times-circle" /></button>
                        </div>
                    </div>
                </div>
            {this.state.test}
             {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> Stranica 1  &rsaquo; Marže  &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="content">
                        <div className="page-header">
                           <h2 className="page-title">Nova marža</h2>
                        </div>
                        <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>   
                            <span>Marže</span>
                        </div>
                        <div className="columns">
                            <div className="column is-5">
                                <form name="add-markup" onSubmit={this.onSubmit} className="form">
                                 <fieldset className="">
                                    <label htmlFor="name" className="column is-12 title">
                                        Ime marže
                                        <input type="text" id="name" name="name" placeholder="Puno ime marže"
                                        value={name} onChange={ this.handleChange} required/>
                                    </label>

                                    <label htmlFor="value" className="column is-12">
                                        Vrijednost marže
                                        <input type="text" id="value" name="value" placeholder="Vrijednost marže"
                                        value={value} onChange={ this.handleChange} required/>
                                    </label>                          
                                    <button type="submit" className="button default add default-no-icon"><span className="item">Dodaj maržu <FontAwesomeIcon icon="plus-circle" /></span></button>

                            </fieldset>
                        </form>
                            </div>
                            
                            <div className="column is-7 markup-list default-list is-right">
                            {this.state.pageOfMarkups.map(singleMarkup =>
                               <div className="single-row">
                                    <h1 className="title">{singleMarkup.name} ({singleMarkup.value})</h1>
                                    <div className="options is-right">
                                        <Link to={`/administracija/uredjivanje-marze/${singleMarkup._id}`} className="link"><FontAwesomeIcon icon="edit" /></Link>
                                        <button onClick={this.togglePopupDelete.bind(this, singleMarkup._id, singleMarkup.name)}  className="link"><FontAwesomeIcon icon="trash-alt" /></button>
                                    </div>  
                               </div>  
                            )}
                              <div className="pagination-wrapper pagination-bottom">
                              <Pagination items={this.state.allMarkups} onChangePage={this.onChangePage} pageName="Markupi" /> 
                            </div> 
                               
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            </AdminWrapper>
        )
    }
}

export default Markups