import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-globally'

const initialState = {
    changeHeaderCart: false,
    singleProductSlug: "",
    changeProductSlug: false,
    changeSearchTerm: false,
    pdv: 1.17,
  }

ReactDOM.render(
<Provider globalState={initialState}>

    <App />
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
