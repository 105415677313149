/*
About Us page
Display static page with informations about us
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import {siteTitle} from '../../../functions/config'
import {Link} from 'react-router-dom'
import logo from '../../../../src/static/zdravo-logo-white.png'

var pageTitle = "Održavanje";
class PageMaintance extends React.Component{

    componentDidMount() {
    }

    render(){
        window.scrollTo(0,0); 
        return(
            <div>
               <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
               </Helmet>
                <section id="p_maintance" className="section page-maintance">
                    <div className="container">
                        <img src={logo} />
                        <span className="big-title">Posjetite nas uskoro.</span>
                        <span className="small-title">Poštovani, online shop <b>Zdravo.ba</b> je u fazi održavanje do 20.09.2019 godine(Petak).<br />
                        Hvala vam na razumijevanju. <br />Vaš <b>Zdravo.ba</b> tim.</span>
                     
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}
            </div>
        )
    }
}

export default PageMaintance