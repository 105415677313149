function generateMainPrice(vele_price, currentMarkup, pdv, fullPrice){
  //var price = Math.trunc((vele_price*currentMarkup)*pdv);
  var price = fullPrice;
  var lastDigit = price.toString().substr(-1);
  price = price.toString().slice(0, -1) + '0';
  if(lastDigit >= 0 && lastDigit < 3){
      lastDigit = 0;
  }
  else if(lastDigit >=3 && lastDigit < 8){
      lastDigit = 5;
  }
  else if(lastDigit == 8 || lastDigit == 9){
      lastDigit = 10;
  }

  price = parseInt(price)+lastDigit;
 return price
}


//Function: Calculate action price, format price, round and remove third decimal point
function calculateActionPrice(number){
    if(!Number.isInteger(number)){
      number = Math.round(number);
    }
  
    if(number%10 !== 0 && number%10 >= 5){
    number = number + (10 - number%10);
    }
    else if(number%10 !==0 && number%10 < 5){
      number = number - number%10;
    }
  
  return number;
  }
  

  function calculateOnePercentPrice(number){
    if(!Number.isInteger(number)){
      number = Math.floor(number);
    }
  number = Math.floor(number/5)
  return number;
  }

  
function generateActionPrice(price, percent){
  var percentDiff = (100 - percent)/100;
 
  //var priceAction = Math.trunc(parseInt(price) + (price*percent/100));
  var priceAction = Math.trunc(price/percentDiff);
  var lastDigit = priceAction.toString().substr(-1);
  priceAction = priceAction.toString().slice(0, -1) + '0';
  
  priceAction = priceAction.toString().slice(0, -1) + '0';
 
  if(lastDigit >= 0 && lastDigit < 3){
      lastDigit = 0;
  }
  else if(lastDigit >=3 && lastDigit < 8){
      lastDigit = 5;
  }
  else if(lastDigit == 8 || lastDigit == 9){
      lastDigit = 10;
  }

  priceAction = parseInt(priceAction)+lastDigit;
  return priceAction
}


//Function: calculating price, action price, percent discount, save money...
export function calculatePrice(price, productOffer, weekOffer, productAction, actionPercent, productActionPrice, markupValue, fullPrice, brandDiscount) {
  //price = generateMainPrice(price, markupValue, 1.17);
  price = fullPrice;
  var oldPrice = 0, weekOffer, save_money = 0, percent_discount = 0, flagAction = false, onePercentPrice = 0, brandDiscountCalculated = 0;
  if(productOffer === 1){
      flagAction = true;
      percent_discount = actionPercent;
      //percent_discount = weekOffer;
      oldPrice = generateActionPrice(price, actionPercent);
      save_money = calculateActionPrice(oldPrice - price);
    }

    else if(productAction === 1)
    {
      percent_discount = actionPercent;
      flagAction = true;
      oldPrice = generateActionPrice(oldPrice, actionPercent);
      save_money = calculateActionPrice(oldPrice - price);
    }
    else if(brandDiscount > 0 && brandDiscount !== undefined && brandDiscount !== null)
    {
      flagAction = true;
      percent_discount = parseInt(brandDiscount)+2;
      oldPrice = generateActionPrice(price, parseInt(brandDiscount)+2);
      save_money = calculateActionPrice(oldPrice - price);
    }
  return {
      price:price,
      flagAction: flagAction,
      oldPrice: calculateActionPrice(oldPrice),
      percent_discount: percent_discount,
      save_money: save_money,
      onePercentPrice: calculateOnePercentPrice(price/100),  
  }
};