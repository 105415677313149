/*
My points page
Display informations about points
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'
import Sticker from '../../helpers/Sticker'
import {siteTitle, timeoutDelay} from '../../../functions/config'
import LoaderBox from '../../helpers/LoaderBox';
import formatMoney from '../../../functions/formatMoney'
import Logout from './Logout'
import jwt_decode from 'jwt-decode'
import axios from 'axios'

var randomString = require('random-string'), curUserId = "";
var pageTitle = "Bodovi vijernosti";
class MyPoints extends React.Component{
    constructor () {

        super()
        this.unsubscribe = null;
        
        this.state = {
            id: '',
            showStickerClass: '',
            name: '',
            surname: '',
            email: '',
            phone: '',
            address: '',
            postalCode: '',
            city: '',
            userCreatedAt: '',
            newsletter: 0,
            loading:false,
            couponValue: '',
            couponName: '',
            errorCoupon: false,
            totalPoints: '',
            couponMoney: '',
            couponCreated: false,
        }
        this.removeCoupon = this.removeCoupon.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
      }

//Function: Collect data from database
getUserData(){
    axios
    .get(process.env.REACT_APP_SERVER_PROXY_URL+'api/users/profile/'+curUserId, {
    })
    .then(response => {
      const {name, surname, address, city, email, phone, postalCode, newsletter, userCreatedAt, couponName, 
        couponValue, couponExist, totalPoints } = response.data;
     this.setState({
      name,
      surname,
      email,
      couponName,
      couponValue,
      couponExist,
      totalPoints,
      couponMoney: parseFloat(couponValue)*10,
     })
     if(couponExist === 1){
        this.setState({
            couponCreated:true
        })
    }
    })
    .catch(err => {
      console.log(err)
    })
}

//Function: handle recension form data
handleChange(e) {     
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
    this.state.couponMoney = e.target.value *10;
}

//Function: submit recension
onSubmit(e) {
    e.preventDefault();
    const {totalPoints, couponValue, id, name, surname, email, phone, address, postalCode, city, userCreatedAt} = this.state;
    var couponName = this.state.couponName;
    if(couponValue > totalPoints){  
        this.setState({
            errorCoupon: true
        })
    }
    else{
        couponName = randomString({length: 7}).toUpperCase();
        this.setState({
            couponName,
            errorCoupon: false,
        })

        
    axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/users/profile-update-points/' + curUserId, {
        couponName,
        couponValue,
        couponExist: 1,
        totalPoints: totalPoints - couponValue,
    }).then((response) => {
        this.getUserData();
        this.setState({
            showStickerClass: 'show',
            couponCreated: true,
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Čestitamo, uspješno ste kreirali vaš kupon.',
        stickerType: 'success',
        }) 
    })
    .catch((error) => {
    this.setState({
        showStickerClass: 'show',
    })
    setTimeout(function(){
        this.setState({
            showStickerClass: ''
        })
    }.bind(this),timeoutDelay);
    this.setState({
    stickerMessage: 'Došlo je do problema prilikom izrade vašeg kupona: ' + error,
    stickerType: 'danger',
    })
});

    }

}


removeCoupon(){

    axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/users/profile-update-points/' + curUserId, {
        couponName: "",
        couponValue: "",
        couponExist: 0,
        totalPoints: this.state.totalPoints + this.state.couponMoney/10,
    }).then((docRef) => {
        this.getUserData();
        this.setState({
            showStickerClass: 'show',
            couponCreated: false,
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Uspješno ste poništili vaš kupon.',
        stickerType: 'success',
        }) 
    })
    .catch((error) => {
    this.setState({
        showStickerClass: 'show',
    })
    setTimeout(function(){
        this.setState({
            showStickerClass: ''
        })
    }.bind(this),timeoutDelay);
    this.setState({
    stickerMessage: 'Došlo je do problema prilikom izrade vašeg kupona: ' + error,
    stickerType: 'danger',
    })
});

}

componentDidMount = () => { 
    const token = localStorage.getItem('usertoken');
    const decoded = jwt_decode(token)
    if(token){
        curUserId = decoded._id
    } 
    this.getUserData();
}
    render(){

        const {couponName, couponValue} = this.state;
        return(
            <ShopWrapper>
              {/*  <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
              </Helmet>*/}
                <section id="p_about_us" className="section my-points-page user-page">
                     {this.state.showStickerClass=== 'show' && (  
                        <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                    )}
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={pageTitle} />
                        
                         {/******************* Loader box *******************/}
                         {this.state.loading && (<LoaderBox /> )}

                        {/******************* Content *******************/}
                        <div className="content">
                            <div className="columns is-multiline">
                                <div className="column is-12 user-menu">
                                    <ul>
                                        <li><Link to="/moj-racun">Naslovnica</Link></li>
                                        <li><Link to="/moj-racun/narudzbe">Moje narudžbe</Link></li>
                                        <li><Link to="/moj-racun/korisnicki-podaci">Moji podaci</Link></li>
                                      {/*  <li><Link to="/moj-racun/bodovi-vjernosti" className="current">Bodovi vjernosti</Link></li> */}
                                        <Logout />
                                    </ul>
                                </div>

                                <div className="column main">
                                    <div className="total-points">
                                        <h4 className="subtitle">Ukupan broj vaših bodova</h4>
                                        <h1>{this.state.totalPoints}</h1>
                                    </div>

                                    <div className="create-coupon">
                                        <h4 className="subtitle">Kreirajte vaš kupon</h4>

                                        <div className="inner">
                                        {!this.state.couponCreated && ( <form name="create-coupon" onSubmit={this.onSubmit} className="form default-form">
                                                <fieldset>
                                                    <div className="columns is-multiline form">
                                                        <label htmlFor="couponValue" className="column is-6">Unesite broj bodova koje želite pretvoriti u kupon
                                                            <input type="number" id="couponValue" name="couponValue" placeholder=""
                                                            value={couponValue} onChange={ this.handleChange } required />
                                                        </label>
                                                        <div className="button-area column is-6">
                                                        <button type="submit" className="button default"><span className="icon"></span>Kreirajte kupon</button>
                                                        {/*  <PaypalExpressBtn client={client} currency={'USD'} total={1.00} /> */}
                                                    </div>
                                                    </div>
                                                    {this.state.errorCoupon && (
                                                        <p className="error-coupon">Molimo vas da unesete vrijednost koja je manja ili jednaka vašem ukupnom broju bodova.</p>
                                                    )}
                                                    {this.state.couponValue !== '' && (
                                                        <p className="coupon-money">Vrijednost vašeg kupona će iznositi <b>{formatMoney(this.state.couponMoney)}</b>.</p>
                                                    )}
                                                   
                                                </fieldset>
                                            </form>  )}
                                            {this.state.couponCreated && (
                                                <div className="coupon-name">
                                                    <p>Vaš kupon je generisan. Možete ga koristiti već u sljedećoj narudžbi. Vrijednost vašeg kupona je <b>{formatMoney(this.state.couponMoney)}</b>.</p>
                                                     <span className="name">
                                                        {this.state.couponName}
                                                    </span>
                                                    <div className="button-area">
                                                        <span className="remove-coupon button default" onClick={this.removeCoupon.bind(this)}>Poništite kupon</span>
                                                    </div>
                                                </div>  
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}
            </ShopWrapper>
        )
    }
}

export default MyPoints