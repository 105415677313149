export function calcNormalPrice(price){
    price = price.toString().split('.')[0];
  var lastDigit = price.toString().slice(-1);
  price = price.toString().slice(0, -1) + '0';
  

  if(lastDigit >= 0 && lastDigit < 3){
      lastDigit = 0;
  }
  else if(lastDigit >=3 && lastDigit < 8){
      lastDigit = 5;
  }
  else if(lastDigit == 8 || lastDigit == 9){
      lastDigit = 10;
  }

  price = parseInt(price)+lastDigit;
  return price
}