import React from 'react'
import {Link} from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import {Helmet} from "react-helmet";
import Reactable from 'reactable';
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { productUrl } from '../../../functions/pageUrls'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { months}  from '../../helpers/months'

var Table = Reactable.Table,
    Thead = Reactable.Thead,
    Th = Reactable.Th;

var togglePopupDelete;
class Products extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            products: [],
            totalNumberProducts: [],
            publishedProducts: [],
            draftProducts: [],
            unpublishedProducts: [],
            filteredProducts: [],
            currentPage: 0,
            showPopupDeleteClass: '',
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
            singleProductKey: '',
            singleProductName: '',
            productsType: this.props.match.params.type,
            loading:false,
            categoryId: '',
            category: '',
        };

        togglePopupDelete = this.togglePopupDelete.bind(this);
        this.addToBestSellers = this.addToBestSellers.bind(this);
      }
    
    

togglePopupDelete (id, name) {
if (this.state.showPopupDeleteClass === '') {
    this.setState({
        showPopupDeleteClass: 'show',
        singleProductKey: id,
        singleProductName: name,
    })
}
else{
    this.setState({
        showPopupDeleteClass: ''
    })
}     
}

addToBestSellers(id, value, name){
    axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/update-best-seller/' + id, {
        bestSeller: value,
        })
        .then((response) => {
            this.setState({
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
            }.bind(this),timeoutDelay);
            this.setState({
            stickerMessage: 'Uspješno ste uredili proizvod "<b>' + name + '"</b>',
            stickerType: 'success',
        })
        this.componentDidMount()
        
        })
        .catch((error) => {
            this.setState({
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
            }.bind(this),timeoutDelay);
            this.setState({
            stickerMessage: 'Došlo je do problema prilikom uređivanja proizvoda: ' + error,
            stickerType: 'danger',
        })
        });
}


deleteProduct(id){
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/delete-product/' + id)
    .then(() => {
        this.getProductsList();
        this.setState({
            showPopupDeleteClass: '',
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Uspješno ste izbrisali proizvod "<b>' + this.state.singleProductName + '"</b>',
        stickerType: 'success',
        })

        var productsInner = []; 
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts')
        .then((response) => {
            response.data.forEach((doc) => {
            const { cartId, products, totalPrice } = doc;
            productsInner.push({
                key: doc.id,
                doc, 
                cartId,
                products: JSON.parse(products),
                totalPrice,
                });
                var totalPriceNew = 0;
                for(var i in productsInner){
                    for(var j in productsInner[i].products){
                        if(productsInner[i].products[j].id === id){
                            totalPriceNew = parseFloat(productsInner[i].totalPrice) - parseFloat(productsInner[i].products[j].price);
                            productsInner[i].products.splice(j,1);
                            axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/update-cart/' + productsInner[i].cartId, {
                                cartId: productsInner[i].cartId,
                                products: JSON.stringify(productsInner[i].products),
                                totalPrice: totalPriceNew,
                              }).then((docRef) => {
                              })
                              .catch((error) => {
                                  console.log("problem ", error);
                              });
                        }
                    }
                }   
        });
    });
    })
    .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Došlo je do problema prilikom brisanja proizvoda: ' + error,
        stickerType: 'danger',
    })
    });
    
    }

productID(id){
    return(
        <p>{id}</p>
    )
}

productImage(image){
    return(
        <img src={process.env.REACT_APP_FILES_DESTINATION+image} />
    )
    
}

productTitle(title){
    return(
        <p>{title}</p>
    )
}

productPrice(price){
    return(
        <p>{formatMoney(price)}</p>
    )
}

productOptions(key, slug, category,brand, title, status, bestSeller, name){
    var statusVar;
    if(status === "Objavljeno") statusVar = "published";
    else if(status === "U pripremi") statusVar = "draft";
    else if(status === "Neobjavljeno") statusVar = "unpublished";
    return(
        <div className={"options-wrapper"}>
            <div className="inner">
                <Link to={`/${productUrl}/${category}/${brand}/${slug}`} className="link "><FontAwesomeIcon icon="eye" /></Link>
                <Link to={`/administracija/uredjivanje-proizvoda/${slug}`} className="link "><FontAwesomeIcon icon="edit" /></Link>
                <button onClick={this.togglePopupDelete.bind(this, key, title)} className="link "><FontAwesomeIcon icon="trash-alt" /></button>
               {bestSeller === 1 && (
                    <button onClick={this.addToBestSellers.bind(this, key, 0, name)} className="button green" title="Isključi best seler opciju">Best Seller</button>
               )}
               {bestSeller !== 1 && (
                    <button onClick={this.addToBestSellers.bind(this, key, 1, name)} className="button red" title="Uključi best seler opciju">Best Seller</button>
               )}
            </div>
        </div>
    )
}

getProductsList() {  
    const products = [],totalNumberProducts = [], publishedProducts = [], draftProducts = [], unpublishedProducts = [];
    var productsType = null, newProduct, getApi;

    
     //Collect products from selected category
     axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/administration/best-sellers')
    .then((response) => {
    response.data.forEach((doc) => {
        const { product_id, title,brand, category, price, image_thumb, status, slug, productCreatedAt, bestSeller } = doc;

            products.push({
                _id: doc._id,
                id: product_id,
                slug,
                title ,
                price ,
                bestSeller,
                image:image_thumb,
                productCreatedAt: productCreatedAt.split('T')[0],
                status,
                category: doc.categoryData[0].slug,
                categoryName: doc.categoryData[0].name,
                brand: doc.brandData[0].slug,
                brandName: doc.brandData[0].name,
            })
        })
        this.setState({
            products: products,
            filteredProducts: products,
            totalNumberProducts: products,
        })

    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/administration/count/published')
    .then((response) => {
        response.data.forEach((doc) => {
            publishedProducts.push({
            key: doc._id,
            });
            });
            this.setState({
            publishedProducts,
        });
    });
    }).catch((error) => {
        console.log(error);
    })

    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/administration/count/draft')
    .then((response) => {
        response.data.forEach((doc) => {
            draftProducts.push({
            key: doc._id,
            });
            });
            this.setState({
            draftProducts,
        });
        });

        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/administration/count/unpublished')
        .then((response) => {
            response.data.forEach((doc) => {
            unpublishedProducts.push({
            key: doc._id,
            });
            });
            this.setState({
            unpublishedProducts,
        });
        });
}

componentDidMount = () => {
    this.getProductsList();
}

    render(){
        console.log(this.state.products)
        var sortableProducts = [];
        
          {this.state.products.map(product =>
            {
                var unformatedDate = product.productCreatedAt.split('-');
                var formatedMonth ,formatedDate;
                months.forEach(month => {
                    if(month.value == unformatedDate[1])  {
                        formatedMonth = month.name;
                    }
                });
                formatedDate = unformatedDate[2] + " " + formatedMonth + ", " + unformatedDate[0];
                sortableProducts.push({
                    id: product.id,
                    image: this.productImage(product.image), 
                    title: product.title, 
                    category: product.categoryName,
                    price: formatMoney(product.price),
                    status: product.status, 
                    date: formatedDate, 
                    options: this.productOptions(product._id, product.slug, product.category,product.brand, product.title, product.status, product.bestSeller, product.title)
                })
            }
           
        )}
        return(
            <AdminWrapper>
            <section id="products" className="section products content-page-section default-table products-table">
            {this.state.loading && (
                <div className="loader-box">
                    <span className="loader"></span>
                </div>
            )}
             <div className={"popup-wrapper " + this.state.showPopupDeleteClass}>
                    <div className="overlay-back"></div>
                    <div className="popup-box popup-delete">
                        <h6 className="text">Jesi li siguran da želiš izbrisati proizvod <br /><b>"{this.state.singleProductName}"</b>?</h6>
                        <div className="button-area">
                            <button className="button confirm" onClick={this.deleteProduct.bind(this, this.state.singleProductKey)}>Želim <FontAwesomeIcon icon="trash-alt" /></button>
                            <button className="button decline" onClick={this.togglePopupDelete.bind(this)}>Ne, hvala <FontAwesomeIcon icon="times-circle" /></button>
                        </div>
                    </div>
                </div>
                {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> Administracija  &rsaquo; Proizvodi  &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="page-header">
                    {this.state.productsType === "akcija" && (
                        <h2 className="page-title">Akcijski proizvodi</h2>
                    )}
                    {this.state.productsType === "ponuda-sedmice" && (
                        <h2 className="page-title">Proizvodi u ponudi sedmice</h2>
                    )}
                    {this.state.productsType !== "akcija" && this.state.productsType !== "ponuda-sedmice" && (
                        <h2 className="page-title">Svi proizvodi</h2>
                    )}
                      
                        <Link to="/administracija/novi-proizvod" className="button default default-no-icon is-right"><span className="item">Dodaj proizvod <FontAwesomeIcon icon="plus" /></span></Link>
                    </div>
                  
                    <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <span>Proizvodi</span>
                        </div>

                    <div className="meta-info">
                        <p className="products-number default-text">Svi proizvodi <b>({this.state.totalNumberProducts.length})</b></p>
                        <p className="products-number default-text">Objavljeno <b>({this.state.publishedProducts.length})</b></p>
                        <p className="products-number default-text">U pripremi <b>({this.state.draftProducts.length})</b></p>
                        <p className="products-number default-text">Neobjavljeno <b>({this.state.unpublishedProducts.length})</b></p>
                    </div>
                    
                    <Table className="default-table"
                    filterable={['title', 'category', 'price']}
                    noDataText="Nema pronađenih proizvoda."
                    itemsPerPage={12}
                    pageButtonLimit={5}
                    currentPage={this.state.currentPage}
                    sortable={[
                        'id',
                        'title',
                        'category',
                        'price',
                        'status',
                    ]}
                    previousPageLabel="Nazad"
                    nextPageLabel="Naprijed"
                    data={sortableProducts} 
                    defaultSort={{column: 'id', direction: 'desc'}}
                    >
                    <Thead>
                        <Th column="id">ID</Th>
                        <Th column="image">Slika</Th>
                        <Th column="title">Naziv</Th>
                        <Th column="category">Kategorija</Th>
                        <Th column="price">Cijena</Th>
                        <Th column="status">Status</Th>
                        <Th column="date">Datum objave</Th>
                        <Th column="options">Opcije</Th>
                    </Thead> 
                    </Table>
                </div>
            </section>
            </AdminWrapper>
        );
    }

}

export default Products