import React from 'react'
import { Link } from 'react-router-dom'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import Pagination from '../Pagination';
import {Helmet} from "react-helmet";
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { addFilterCategory, upload } from '../../helpers/UserFunctions'

var togglePopupDelete;

//Komponenta Filteri: Prikazuje listu svih trenutnih filtera, sa opcijama uređivanja, pregledanja i brisanja.
//Sadrži formu za dodavanje novih filtera

class FiltersCategories extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;
        this.state = {
            filtersCategories: [],
            pageOfCategories: [],
            id: '',
            showPopupDeleteClass: '',
            name: '',
            slug: '',
            filters: [],
            singleFiltersCategoryKey: '',
            singleFiltersCategoryName: '',
            addedFiltersCategoryName: '',
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
        }   

        togglePopupDelete = this.togglePopupDelete.bind(this);
    }

//Spremanje podataka sa inputa u state
handleChange = (e) => {   
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);  
}


//Prikaz popupa za brisanje kategorije
togglePopupDelete (id, name) {
    if (this.state.showPopupDeleteClass === '') {
        this.setState({
            showPopupDeleteClass: 'show',
            singleFiltersCategoryKey: id,
            singleFiltersCategoryName: name,
        })
    }
    else{
        this.setState({
            showPopupDeleteClass: ''
        })
    }     
  }

//Metoda za brisanje kategorije
deleteFiltersCategory(id){
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters/delete-filter-category/' + id)
    .then(() => {
        this.getSFiltersCategoriesList();
        this.setState({
            showPopupDeleteClass: '',
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Uspješno ste izbrisali grupu filtera "<b>' + this.state.singleFiltersCategoryName + '"</b>',
        stickerType: 'success',
        })
    })
    .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Došlo je do problema prilikom brisanja grupe filtera: ' + error,
        stickerType: 'danger',
    })
    });
}

//Metoda potrebna za paginaciju, prelaz između stranica
onChangePage(pageOfCategories) {
    this.setState({ 
        pageOfCategories: pageOfCategories 
    });
}

//Spremanje podataka u kolekciju
onSubmit = (e) => {
    e.preventDefault();
    const {name} = this.state;

const newFilterCategory = {
    name: name,
    }

    addFilterCategory(newFilterCategory).then(res => {
            this.getFiltersCategoriesList();
            this.setState({
                addedFiltersCategoryName: this.state.name,
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
            }.bind(this),timeoutDelay);
        
            this.setState({
                stickerMessage: 'Uspješno ste dodali grupu filtera <b>"' + this.state.addedFiltersCategoryName + '"</b>',
                stickerType: 'success',
                name: '',
            });
        
            this.props.history.push("/administracija/filteri")
          })
          .catch((error) => {
            this.setState({
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
           }.bind(this),timeoutDelay);
           this.setState({
            stickerMessage: 'Došlo je do problema prilikom dodavanja grupe filtera.',
            stickerType: 'danger',
            })
        });
   }

getFiltersCategoriesList() {  
axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters')
.then((response) => {
    this.setState({
        filtersCategories: response.data
    });
})
.catch((error) => {
    console.log(error);
})
}

componentDidMount = () => {
    this.getFiltersCategoriesList();
}

    render(){
        const { name} = this.state;
        return(
            <AdminWrapper>
            <section id="categories" className="section default-section content-page-section add-category default-form">
                <div className={"popup-wrapper " + this.state.showPopupDeleteClass}>
                    <div className="overlay-back"></div>
                    <div className="popup-box popup-delete">
                        <h6 className="text">Jesi li siguran da želiš izbrisati kategoriju filtera<br /><b>"{this.state.singleFiltersCategoryName}"</b>?</h6>
                        <div className="button-area">
                            <button className="button confirm" onClick={this.deleteFiltersCategory.bind(this, this.state.singleFiltersCategoryKey)}>Želim <FontAwesomeIcon icon="trash-alt" /></button>
                            <button className="button decline" onClick={this.togglePopupDelete.bind(this)}>Ne, hvala <FontAwesomeIcon icon="times-circle" /></button>
                        </div>
                    </div>
                </div>
                {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
              
                <div className="container">
                <Helmet>
                    <title>Filteri &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="content">
                        <div className="page-header">
                           <h2 className="page-title">Nova kategorija filtera</h2>
                        </div>
                        <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <span>Nova kategorija filtera</span>
                        </div>
                        <div className="columns">
                            <div className="column is-5">
                                <form name="add-category" onSubmit={this.onSubmit} className="form">
                                 <fieldset className="">
                                    <label htmlFor="name" className="column is-12 title">
                                        Ime kategorije filtera
                                        <input type="text" id="name" name="name" placeholder="Puno ime kategorije"
                                        value={name} onChange={ this.handleChange} required/>
                                    </label> 

                                    <button type="submit" className="button default add default-no-icon"><span className="item">Dodaj kategoriju filtera <FontAwesomeIcon icon="plus-circle" /></span></button>

                            </fieldset>
                        </form>
                            </div>
                            <div className="column is-6 default-list is-right">
                            <div className="inner-box">
                             {this.state.filtersCategories.map(singleFilterCategory =>
                               <div className="single-row">
                                    <h1 className="title">{singleFilterCategory.name}</h1>
                                    <div className="options is-right">
                                        <Link to={`/administracija/uredjivanje-filtera/${singleFilterCategory._id}`} className="link"><FontAwesomeIcon icon="edit" /></Link>
                                        <button onClick={this.togglePopupDelete.bind(this, singleFilterCategory._id, singleFilterCategory.name)} className="link"><FontAwesomeIcon icon="trash-alt" /></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            </AdminWrapper>
        )
    }
}

export default FiltersCategories