/*
FAQ page
Display questions and answers from customers
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import {siteTitle} from '../../../functions/config'
import { homeUrl, registerUrl, termsOfUseUrl, privacyPolicyUrl, contactUrl } from '../../../functions/pageUrls';
import { Link } from 'react-router-dom'
import axios from 'axios';

var pageTitle = "Brendovi";
class BrandsList extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;
        
        this.state = {
           brands: [],
           allBrands: [],
           no_results: false,
           selectedLetter: '',
           isLetter: false,
        }
        this.onChangeSortLetter = this.onChangeSortLetter.bind(this);
      }
    
getBrandsList() {  
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/brands')
    .then((response) => {
            this.setState({
        brands: response.data,
        allBrands: response.data,
        });
    })
    .catch((error) => {
        console.log(error);
    })
}

onChangeSortLetter(letter){
    var brands = this.state.allBrands;
    var brandsLetter = [];

    for(var i in brands){
        if(brands[i].name.charAt(0) === letter){
            brandsLetter.push(brands[i]);
        }
    }
   if(brandsLetter.length === 0){
    this.setState({
        no_results: true,   
    }) 
    }
    else{
        this.setState({
            no_results: false,
        }) 
    }
    this.setState({
        brands: brandsLetter,
        selectedLetter: letter,
        isLetter: true,
    })  
}

removeLetter(){
    this.setState({
        isLetter: false,
        brands: this.state.allBrands,
        selectedLetter: ''
    })
}

componentDidMount() {
    this.getBrandsList();
}
        
    render(){
        var alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        window.scrollTo(0,0); 
        return(
            <ShopWrapper>
               <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
               </Helmet>
                <section id="p_brends" className="section static-page brends-page">
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={pageTitle} />

                        {/******************* Content *******************/}
                        <div className="alphabet">
                            <div className="inner">
                                {alphabet.map(letter => (
                                    <>
                                    {this.state.selectedLetter === letter && (
                                        <span className="current" onClick={this.onChangeSortLetter.bind(this, letter)}>{letter}</span>
                                    )}
                                    {this.state.selectedLetter !== letter && (
                                        <span onClick={this.onChangeSortLetter.bind(this, letter)}>{letter}</span>
                                    )}
                                    </>
                                      
                                ))}                                    
                            </div>
                        </div>
                        {this.state.isLetter && (
                            <div className="selected-letter">
                                <span className="big-text">{this.state.selectedLetter}<i className="remove-letter" onClick={this.removeLetter.bind(this)}></i></span>
                            </div>
                         )}
                        <div className="columns is-multiline">
                            {this.state.brands.map(brand => (
                                <div className="column is-1 single-brand">
                                    <Link to={`/brend/${brand.slug}`}>
                                    <img src={process.env.REACT_APP_FILES_DESTINATION+brand.image} /> 
                                    </Link>                            
                                </div>
                            ))}
                        </div>
                    </div>{/* CONTAINER END */}
                </section>{/*BRANDS SECTION END */}
            </ShopWrapper>
        )
    }
}

export default BrandsList