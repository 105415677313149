import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import jwt_decode from 'jwt-decode'

var authenticated = false;
if(localStorage.getItem('usertoken')){
    const token = localStorage.getItem('usertoken');
    const decoded = jwt_decode(token)
    if(decoded.isAdmin === 1){
        authenticated = true;
    }
  }

export const LoginRouteAdmin = ({ component: Component, ...rest}) => (
    <>
    <Route
    {...rest}
    render={props =>
        localStorage.getItem('usertoken') && jwt_decode(localStorage.getItem('usertoken')).isAdmin === 1 ? (
        <Redirect to={{
            pathname: "/administracija",
            state: {from: props.location}
        }}
        />
        
    ) : (
        <Component {...props} />

    )}
    />
    </>
)
