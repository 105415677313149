import React from 'react'
import { Link } from 'react-router-dom'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import {Helmet} from "react-helmet";
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { upload } from '../../helpers/UserFunctions'
import { isBuffer } from 'util';

var slugify = require('slugify')

class EditBrand extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;
        
        this.state = {
            showPopupClass: '',
            id: '',
            slug: '',
            name: '',
            image: null,
            link: '',
            discount: '',
            metaDesc: '',
            imageFile: null,
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
            imageChanged: false,
        }   

        this.onChangeImage = this.onChangeImage.bind(this);
        this.resetImage = this.resetImage.bind(this);
    }

handleChange = (e) => {     
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
        }
    
componentDidMount = () => {
    this.getBrandById();
}


getBrandById() {
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/brands/edit-brand/' + this.props.match.params.slug)
    .then((response) => {
    this.setState({
    name: response.data.name,
    link: response.data.link,
    discount: response.data.discount,
    metaDesc: response.data.metaDesc,
    slug: response.data.slug,
    image: response.data.image,
    });
    })
    .catch((error) => {
    console.log(error);
    })
}

//ISTAKNUTA SLIKA
onChangeImage(event) {
    this.setState({
        imageChanged: true,
        image: URL.createObjectURL(event.target.files[0]),
        imageFile: event.target.files[0]
    });
}

resetImage(event) {
    event.preventDefault();
    this.setState({ image: null });
}

onSubmit = (e) => {
    e.preventDefault();
    const { name, link, slug, image,discount, metaDesc } = this.state;
    var newImage;
    var imageFile = this.state.imageFile;
    if(imageFile !== null){
        newImage = imageFile.name
    }
    else{
        newImage = image;
    }
    axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/brands/update-brand/' + this.props.match.params.slug, {
    name: name,
    link: link,
    discount,
    metaDesc,
    slug: slug,
    image: newImage,
    })
    .then((response) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Uspješno ste uredili brend "<b>' + this.state.name + '"</b>',
        stickerType: 'success',
    })
    
    this.props.history.push('/administracija/brendovi');
    })
    .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Došlo je do problema prilikom uređivanja brenda: ' + error,
        stickerType: 'danger',
    })
    });

    const addImage = new FormData();
    addImage.append(
    'image',this.state.imageFile);        
    const config = {       
    headers: {'content-type': 'multipart/form-data'}
    };

    upload(addImage, config) .then((response) => {
        }).catch((error) => {
    });
    
}

    render(){
        const { id,slug, name, link,discount, metaDesc, image, imageChanged} = this.state;
        console.log(image);
        return(
            <AdminWrapper>
            <section id="brands" className="section default-section content-page-section add-brand default-form">
               {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> {name}  &rsaquo; Uređivanje brenda  &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="content">
                        <div className="page-header">
                            <h2 className="page-title">Uređivanje brenda</h2>
                        </div>
                        <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <Link to="/administracija/brendovi">Brendovi</Link>
                            <span>/</span>
                            <span>Uređivanje brenda</span>
                        </div>
                        <div className="columns">
                            <div className="column is-8">
                                <form name="edit-brand" onSubmit={this.onSubmit} className="form">
                                 <fieldset className="">
                                    <label htmlFor="name" className="column is-12 title">
                                        Ime brenda
                                        <input type="text" id="name" name="name" placeholder="Puno ime brenda"
                                        value={name} onChange={ this.handleChange} required/>
                                    </label> 
                                
                                    <label htmlFor="slug" className="column is-12 slug">
                                        <span>Generisani url</span>
                                        <input type="text" id="slug" name="slug" placeholder="Slug brenda"
                                        value={slug} onChange={ this.handleChange} required/>
                                    </label>

                                    <label htmlFor="metaDesc" className="column is-12">
                                        Meta opis brenda
                                        <input type="text" id="metaDesc" name="metaDesc" placeholder="Meta opis brenda"
                                        value={metaDesc} onChange={ this.handleChange} required/>
                                    </label>

                                    <label htmlFor="link" className="column is-12">
                                        Web stranica brenda
                                        <input type="text" id="link" name="link" placeholder="Web stranica brenda"
                                        value={link} onChange={ this.handleChange} required/>
                                    </label>

                                    <label htmlFor="discount" className="column is-12">
                                        Popust proizvođača
                                        <input type="text" id="discount" name="discount" placeholder="Popust od proizvođača na ukupnu cijenu proizvoda"
                                        value={discount} onChange={ this.handleChange} required/>
                                    </label>

                                    <div id="upload_button" className="">
                                    <label htmlFor="image" className="label-image">
                                        Istaknuta slika
                                    <input type="file" ref="upload" id="image" name="image" onChange={ this.onChangeImage }  />
                                    <span className="add-image">Dodaj istaknutu sliku</span>
                                    </label>
                                    <div className="image-preview">
                                        {image && !imageChanged && <img src={process.env.REACT_APP_FILES_DESTINATION + image} alt="" />}
                                        {image && imageChanged && <img src={image} alt="" />}
                                        {image &&  (                                 
                                        <button className="remove-button" onClick={this.resetImage}><i className="icon"></i></button>
                                        )}
                                    </div>
                                       
                                    </div>       
                                    <button type="submit" className="button default add default-no-icon"><span className="item">Spremi izmjene <FontAwesomeIcon icon="pencil-alt" /></span></button>

                            </fieldset>
                        </form>
                            </div>
                            
                         
                        </div>
                        
                    </div>
                </div>
            </section>
            </AdminWrapper>
        )
    }
}

export default EditBrand