/*
Pagination component
Display pagination on category page, actions, weekoffer etc...
*/
import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import { siteTitle } from '../../functions/config';

const propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number
}

const defaultProps = {
    initialPage: 1,
  //  pageSize: 1
}

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pager: {} };
        this.setState({
            currentPage: 1,
        })
    }

componentWillMount() {
    if (this.props.items && this.props.items.length) {
        this.setPage(this.props.initialPage);
    } 
}

componentDidUpdate(prevProps, prevState) {
    if (this.props.items !== prevProps.items) {
        this.setPage(this.props.initialPage);
    }
}

setPage(page) {
    var { items, pageSize } = this.props;
    console.log(pageSize)
    var pager = this.state.pager;

    if (page < 1 || page > pager.totalPages) {
        return;
    }

    // get new pager object for specified page
    pager = this.getPager(items.length, page, pageSize);

    // get new page of items from items array
    var pageOfProducts = items.slice(pager.startIndex, pager.endIndex + 1);

    // update state
    this.setState({ pager: pager });

    // call change page function in parent component
    this.props.onChangePage(pageOfProducts);
    window.scrollTo(0, 0);
}

getPager(totalItems, currentPage, pageSize) {
// default to first page
currentPage = currentPage || 1;

// default page size is 10
pageSize = pageSize || 5;

// calculate total pages
var totalPages = Math.ceil(totalItems / pageSize);

var startPage, endPage;
if (totalPages <= 5) {
    // less than 10 total pages so show all
    startPage = 1;
    endPage = totalPages;
} else {
    // more than 10 total pages so calculate start and end pages
    if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
    } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 3;
        endPage = totalPages;
    } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
    }
}

// calculate start and end item indexes
var startIndex = (currentPage - 1) * pageSize;
var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

// create an array of pages to ng-repeat in the pager control
var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

// return object with all pager properties required by the view
return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages
};
}

    render() {
        var pager = this.state.pager;

        if (!pager.pages || pager.pages.length <= 1) {
            // don't display pager if there is only 1 page
            return null;
        }
        return (
            <ul className="pagination pagination-box">
              <Helmet>
                <title>{ `${ this.props.pageName }`}  &rsaquo;  {siteTitle}  - { `Stranica ${ pager.currentPage }`} </title>
            </Helmet>
                <li className={pager.currentPage === 1 ? 'disabled' : ''}>
                    <a onClick={() => this.setPage(1)} className="text">Prva</a>
                </li>
                <li className={pager.currentPage === 1 ? 'disabled' : ''}>
                    <a onClick={() => this.setPage(pager.currentPage - 1)} className="text">Nazad</a>
                </li>
                {pager.pages.map((page, index) =>
                    <li key={index} className={pager.currentPage === page ? 'active' : ''}>
                        <a onClick={() => this.setPage(page)}>{page}</a>
                    </li>
                )}
                <li className={pager.currentPage === pager.totalPages ? 'disabled' : ''}>
                    <a onClick={() => this.setPage(pager.currentPage + 1)} className="text">Naprijed</a>
                </li>
                <li className={pager.currentPage === pager.totalPages ? 'disabled' : ''}>
                    <a onClick={() => this.setPage(pager.totalPages)} className="text">Zadnja</a>
                </li>
            </ul>
        );
    }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;