/*
Payment options page
Display text about payment options
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import {siteTitle} from '../../../functions/config'

var pageTitle = "Načini plaćanja";
class PaymentOptions extends React.Component{

    componentDidMount() {
        }
        
    render(){
        window.scrollTo(0,0); 
        return(
            <ShopWrapper>
                <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
               </Helmet>
                <section id="p_payment_options" className="section static-page payment-options-page">
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={pageTitle} />

                        {/******************* Content *******************/}
                        <div className="content">
                            <p>Plaćanje možete izvršiti putema pouzeća što podrazumjeva da ćete pošiljku platiti dostavljaču prilikom preuzimanja. Dostavljač će na 
                                Vašu adresu doći s pošiljkom i prilikom isporuke zatražiti da mu predate iznos za kojeg je pošiljatelj tražio da se naplati pouzećem. 
                                Primaocima pošiljaka ne izračunavaju se nikakvi dodatni troškovi. Plaćanje pouzećem je moguće isključivo gotovinom.</p>

                            <p>Proizvode možete podignuti osobno na našim GRATIS PICK-UP lokacijama i time izbjeći troškove dostave:</p>
                            <ul>
                                <li><b>“Apoteka Valor”</b> Rogačići broj 1, Sarajevo, Ilidža 71215, radno vrijeme Pon - Sri; 08.00h - 18.00h, Cet; 10.00h - 20.00h, Pet; 08.00 - 18.00h, Sub; 08.00h - 14.00h</li>
                                <li><b>1. "WINK SPORT"</b> D.O.O.-Prodavnica br. 17.- adresa: Koševo 3, Opština Centar, Sarajevo, tel: 033/216-204, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00</li>
                                <li><b>2. "WINK SPORT"</b> D.O.O.-Prodavnica br. 7.- adresa: Trg međunarodnog prijateljstva 1.Opština Novi grad, Sarajevo,  tel: 033/465-622, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00 (Alipašino polje)</li>
                            </ul>
                        </div>
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}
            </ShopWrapper>
        )
    }
}

export default PaymentOptions