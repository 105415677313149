/*
Home page
Display Intro slider, action products, week offer products, three selected category products
*/
import React from 'react'
import '../../../styles/shop/index.scss';
import CategoryList from './CategoryList'
import Intro from './Intro'
import IntroMobile from './IntroMobile'
import Offer from './Offer'
import Newsletter from './Newsletter'
import ShopWrapper from '../ShopWrapper'
import Posts from './Posts'
import {Helmet} from "react-helmet";
import { homeCategory1, homeCategory2, homeCategory3, siteTitle } from '../../../functions/config'

var pageTitle = "Početna";
class Homepage extends React.Component{
    constructor(props) {
        super(props);
    }

//Function: get header data and pass to the next component
headerCategoryData(categorySlug){} 

trigerHeaderCart(triger){
}
componentDidMount(){
    window.scrollTo(0,0); 
}

    render(){
        return(
            <ShopWrapper>
                <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
               </Helmet>
                <Intro />
                <IntroMobile />
                <Offer />
                <CategoryList categoryDisplayed={homeCategory1}  />
                <CategoryList categoryDisplayed={homeCategory2}  />
                <CategoryList categoryDisplayed={homeCategory3} trigerHeaderCart={(triger) => this.trigerHeaderCart(triger)} />
                <Newsletter />
                <Posts />
           </ShopWrapper>

        )
    }
}

export default Homepage