/*
Genereated order page
Display customer order with personal informations, payment method and ordered products
*/
import React from 'react'
import { Link } from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import {Helmet} from "react-helmet";
import ReactToPrint from 'react-to-print';
import { siteTitle } from '../../../functions/config';
import {productUrl} from '../../../functions/pageUrls'
import axios from 'axios';

var productsInCart = [];
var pageTitle = "Vaša narudžba";
class OrderComponent extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            orderId: this.props.orderBigId,
            name: '',
            surname: '',
            email: '',
            address: '',
            phone: '',
            postalCode: '',
            city: '',
            country: '',
            charge: '',
            totalPriceNoShipping: '',
            shipping: '',
            totalPrice:'',
            orderCreatedAt: '',
            cartId: '',
            couponName: '',
            couponValue: '',
            charge:'',
            shippingMethod: '',
        }    
    } 

getCartData = (querySnapshot) => { }

//Function: collecting order data
componentDidMount() {
    var productsInner = [];
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/orders/single-order-id/' + this.props.orderBigId)
    .then((response) => {
            const order = response.data;
            productsInner = JSON.parse(order.products);
            Object.keys(productsInner).forEach(function(id) {
            productsInCart.push(productsInner[id]);
            });
            this.setState({
            key: order._id,
            orderName: order.orderName,
            name: order.name,
            surname: order.surname,
            email: order.email,
            address: order.address,
            phone: order.phone,
            postalCode: order.postalCode,
            city: order.city,
            country: order.country,
            charge: order.charge,
            totalPriceNoShipping: order.totalPriceNoShipping,
            shipping: order.shipping,
            totalPrice: order.totalPrice,
            orderCreatedAt: order.orderCreatedAt,
            cartId: order.cartId,
            couponName: order.couponName,
            couponValue: order.couponValue,
            shippingMethod: order.shippingMethod,
            });

            productsInCart.map(product => {
                axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories/single-category-id/'+product.category)
                .then((response) => {
                    product.category = response.data.slug;       
                });
            })

            productsInCart.map(product => {
                axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/brands/single-brand-id/'+product.brand)
                .then((response) => {
                    product.brand = response.data.slug;       
                });
            })

            var cartId = this.state.cartId;
            axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/cart/delete-cart/' + cartId)
            .then(() => {
                this.setState({
                })
                }).catch((error) => {
                    console.log("problem", error);
                });
        
    });        
}

render(){
    const {orderName, orderCreatedAt, name, surname, email, phone, address, postalCode, city, country, 
        charge, totalPrice, shipping,totalPriceNoShipping, couponName, couponValue, shippingMethod } = this.state;

    if(this.state.orderId){
        return(
            <div className="shop">
             <Helmet>
                <title>{pageTitle} &rsaquo; {siteTitle}</title>
             </Helmet>
                <section id="p_finished_order" className="section finished-order order-default">
                    <div className="container">
                    <div className="columns is-multiline content">

                    {/******************* Order number *******************/}
                    <div className="order-number">Broj narudžbe: <span>{orderName}</span></div>
                    
                    {/******************* Customer personal informations *******************/}
                    <div className="column is-12 client-info">
                        <h1 className="part-title">Podaci o naručiocu</h1>
                        <div className="single-row">
                            <h4 className="name">Ime</h4><h4 className="answer">{name}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Prezime</h4><h4 className="answer">{surname}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">E-mail</h4><h4 className="answer">{email}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Telefon</h4><h4 className="answer">{phone}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Adresa</h4><h4 className="answer">{address}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">ZIP kod</h4><h4 className="answer">{postalCode}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Grad</h4><h4 className="answer">{city}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Država</h4><h4 className="answer">{country}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Tip dostave</h4><h4 className="answer">{shippingMethod}</h4>
                        </div>
                    </div>

                    {/******************* Payment informations *******************/}
                    <div className="column is-12 payment-info">
                       
                        <h1 className="part-title">Podaci o plaćanju</h1>
                        <div className="single-row">
                            <h4 className="name">Način plaćanja</h4><h4 className="answer">{charge}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Cijena bez dostave</h4><h4 className="answer">{formatMoney(totalPriceNoShipping)}</h4>
                        </div>
                        <div className="single-row">
                            <h4 className="name">Cijena dostave</h4><h4 className="answer">{formatMoney(shipping)}</h4>
                        </div>
                       {/* {(couponName !== "" && couponName !== undefined ) && (
                        <div className="single-row">
                            <h4 className="name">Kupon<b>({couponName})</b></h4><h4 className="answer">{formatMoney(couponValue)}</h4>
                        </div>
                        )}
                       */}
                        <div className="single-row total">
                            <h4 className="name">Ukupno</h4><h4 className="answer">{formatMoney(totalPrice)}</h4>
                        </div>
                    </div>

                {/******************* Ordered products *******************/}
                <div className="column is-12 order-items">
                    <h1 className="part-title">Detalji narudžbe</h1>
                    <div>
                    <div className="columns table-header">
                        <div className="column is-2"><p>Slika</p></div>
                        <div className="column is-5"><p>Naziv proizvoda</p></div>
                        <div className="column is-2"><p>Količina</p></div>
                        <div className="column is-3"><p>Ukupna cijena</p></div>
                    </div>
                        {productsInCart.map(product => 
                        /******************* Single product row *******************/
                        <div className="columns single-row margin-0">
                        <div className="column is-2 image">
                        
                        <img src={process.env.REACT_APP_FILES_DESTINATION+product.image} alt="Product" />
                        </div>
                        <div className="column is-5 product-info">
                        
                            <h1 className="title">{product.title}</h1>
                            {(product.productAction !== 1 && product.productOffer !== 1) && (
                                <p className="single-price">Cijena: <span>{formatMoney(product.price)}</span></p>
                                )}
                                    {(product.productAction === 1 || product.productOffer === 1) && (
                                <p className="single-price">Cijena: <span>{formatMoney(product.price)}</span>
                                {(Number(product.oldPrice)-product.price !== 0) && (  <span class="old-price">{formatMoney(product.oldPrice)}</span>)}
                                </p>
                            )}
                            {product.productVariety === 1 && (
                                <p className="variation">{product.productVariationType}: <span>{product.productVariationName}</span></p>
                            )}
                        </div>
                        <div className="column is-2 quantity">
                            <p className="">{product.quantity}</p>
                        </div>
                        <div className="column is-3 price">
                            <h4>{formatMoney(product.price*product.quantity)}</h4>
                        </div>
                    </div>/* COLUMNS SINGLE ROW END */
                        )}
                    </div>
                </div>
            </div>
        </div>{/* CONTAINER ENDS */}
    </section>{/* SECTION CHECKOUT END */}
</div>
    );
}
else{
    return(
        <p>Narudžba nije generisana.</p>
    );
}
}
}

{/******************* Main class *******************/}
class GeneratedOrder extends React.Component{
   render(){
       return(
        <>
            {/******************* Component for print order *******************/}
          {/*  <ReactToPrint
            trigger={() => <a href="#" className="button default print-button">Isprintajte račun</a>}
            content={() => this.componentRef}
            />
          */}
            <OrderComponent orderBigId={this.props.match.params.id} ref={el => (this.componentRef = el)}/>
        </>
       )
   }
}

export default GeneratedOrder