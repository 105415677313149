/*
Action component for homepage
Display products on base action on homepage
*/
import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { withGlobalState } from 'react-globally'
import ReactHtmlParser from 'react-html-parser';
import MainTitle from '../../helpers/MainTitle'
import LazyLoad from 'react-lazyload';
var pageTitle= "Korisne informacije od stručnjaka";
class Posts extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            posts: [],
        }
    }

getPostsList() {  
  const postsArray = [];
  
      axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/posts')
      .then((response) => {
      response.data.forEach((doc) => {
          if(doc.published === 1){
            const { title, description, link, image, published } = doc;
            postsArray.push({
            key: doc._id,
            title,
            description,
            link,
            image,
            published
            });
          }
        
      });
      this.setState({
          posts: postsArray,
      })
  }).catch((error) => {
      console.log(error);
  })
}
  
componentDidMount = () => {
  this.getPostsList();
}

    render(){
     
        return(
            <>
          {this.state.posts.length > 0 && (
            <section id="s_posts" className="section posts-homepage">
                <div className="container">

                    {/******************* Main title *******************/}
                    <MainTitle title={pageTitle} />

                    <div className="columns">
                    {/******************* Product popup *******************/}

                                {/******************* Products slider *******************/}
                                {this.state.posts.map(post =>
                                    <div className="column is-4 single">
                                        <div className="inner">
                                             <figure>
                                                 <a href={post.link} target="_blank">
                                                    <LazyLoad height={250} once="true">
                                                        <img src={process.env.REACT_APP_FILES_DESTINATION + post.image} />
                                                     </LazyLoad>
                                                 </a>
                                             </figure>
                                             <div className="content">
                                                <h4 className="title"><a href={post.link} target="_blank">{post.title}</a></h4>
                                                {ReactHtmlParser(post.description)}
                                                <div className="button-area">
                                                    <a href={post.link} target="_blank" className="button default">Pročitajte više</a>
                                                </div>
                                             </div>
                                        </div>
                                    </div>
                                    )}               
                    </div>{/* COLUMNS END */}
                </div>{/* CONTAINER END */}
            </section>/* SECTION ACTION END */
          )}
          </>
        );
    }
}

export default withGlobalState(Posts)