import React from "react";
import { Link } from "react-router-dom";
import formatMoney from "../../../functions/formatMoney";
import Sticker from "../Sticker";
import AdminWrapper from "../AdminWrapper";
import { Helmet } from "react-helmet";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { siteTitle, timeoutDelay } from "../../../functions/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { upload } from "../../helpers/UserFunctions";

var imageFile_thumb = null;

var slugify = require("slugify");
var toggleSEOSidebar;
var exist = false;
var BreakException = {};
var defaultMarkupValue = 1;
class EditProduct extends React.Component {
  constructor() {
    super();

    this.unsubscribe = null;

    this.state = {
      categories: [],
      subCategories: [],
      showPopupClass: "",
      productAction: "0",
      productOffer: "0",
      displayOnHomepage: "0",
      status: "",
      key: "",
      id: "",
      title: "",
      slug: "",
      description: "",
      shortDescription: "",
      image: null,
      vele_price: "",
      price: "",
      priceAction: "",
      actionPercent: "0",
      category: "",
      subCategory: "",
      brand: "",
      markup: "",
      sliderImage1: null,
      sliderImage2: null,
      sliderImage3: null,
      sliderImage4: null,
      facebookImage: null,
      seoTitle: "",
      seoDescription: "",
      deliveryTime: "",
      productId: "null",
      showSEOSidebarClass: "",
      imageFile: null,
      sliderImage1File: null,
      sliderImage2File: null,
      sliderImage3File: null,
      sliderImage4File: null,
      facebookImageFile: null,
      currentCategory: "",
      currentSubCategory: "",
      showStickerClass: "",
      stickerMessage: "",
      stickerType: "",
      filters: [],
      cartsProducts: "",
      catFiltersCategories: [],
      productFilters: [],
      deliverer: "",
      delivererBarcode: "-",
      delivererProductKey: "-",
      localProductKey: "",
      productType: "",
      addedProductFilters: [],
      addedProductCategories: [],
      addedProductSubcategories: [],
      allBrands: [],
      allMarkups: [],
      productVariety: "0",
      productVariations: [],
      variationId: Math.floor(Math.random() * 1000) + 100,
      variationName: "",
      variationPrice: "",
      variationType: "",
      available: "",
      usedFilters: [],
      productCreatedAt: "",
      imageChanged: false,
      sliderImage1Changed: false,
      sliderImage2Changed: false,
      sliderImage3Changed: false,
      sliderImage3Changed: false,
      facebookImageChanged: false,
      currentMarkupValue: defaultMarkupValue,
      currentMarkupName: "",
      pdv: "",
      allProductCategories: [],
      purpose: "",
    };

    this.onChangeImage = this.onChangeImage.bind(this);
    this.resetImage = this.resetImage.bind(this);

    this.onChangeSlider1 = this.onChangeSlider1.bind(this);
    this.resetSlider1 = this.resetSlider1.bind(this);

    this.onChangeSlider2 = this.onChangeSlider2.bind(this);
    this.resetSlider2 = this.resetSlider2.bind(this);

    this.onChangeSlider3 = this.onChangeSlider3.bind(this);
    this.resetSlider3 = this.resetSlider3.bind(this);

    this.onChangeSlider4 = this.onChangeSlider4.bind(this);
    this.resetSlider4 = this.resetSlider4.bind(this);

    this.onChangeFacebookImage = this.onChangeFacebookImage.bind(this);
    this.resetFacebookImage = this.resetFacebookImage.bind(this);

    toggleSEOSidebar = this.toggleSEOSidebar.bind(this);
    this.saveVariation = this.saveVariation.bind(this);
    this.deleteVariation = this.deleteVariation.bind(this);
  }

  saveVariation(id, type, name, price) {
    var newVariation;
    newVariation = new Object();
    newVariation.id = id;
    newVariation.type = type;
    newVariation.name = name;
    newVariation.price = price;
    this.state.productVariations.push(newVariation);

    this.setState({
      variationId: Math.floor(Math.random() * 1000) + 100,
      variationType: "",
      variationName: "",
      variationPrice: "",
    });
  }

  deleteVariation(id) {
    var productVariations = this.state.productVariations;
    for (var i in productVariations) {
      if (productVariations[i].id == id) {
        productVariations.splice(i, 1);
      }
    }
    this.setState({
      productVariations: productVariations,
    });
  }

  toggleSEOSidebar() {
    if (this.state.showSEOSidebarClass === "") {
      this.setState({
        showSEOSidebarClass: "show",
      });
      window.scrollTo(0, 0);
    } else {
      this.setState({
        showSEOSidebarClass: "",
      });
    }
  }

  handleChangeMainCategoriesArray = (e) => {
    console.log(this.state.addedProductCategories);
    var allProductCategories = this.state.allProductCategories;
    if (e.target.checked === true) {
      this.state.addedProductCategories.push(parseInt(e.target.value));
    } else {
      for (var i in this.state.addedProductCategories) {
        if (this.state.addedProductCategories[i] == e.target.value) {
          this.state.addedProductCategories.splice(i, 1);
        }
      }
    }
    allProductCategories.forEach((productCategory) => {
      if (productCategory.category_id == e.target.value) {
        productCategory.isChecked = e.target.checked;
      }
    });

    this.setState({
      allProductCategories,
    });
  };

  handleChangeSubcategoriesArray = (e) => {
    var allProductCategories = this.state.allProductCategories;
    if (e.target.checked === true) {
      this.state.addedProductSubcategories.push(parseInt(e.target.value));
    } else {
      for (var i in this.state.addedProductSubcategories) {
        if (this.state.addedProductSubcategories[i] == e.target.value) {
          this.state.addedProductSubcategories.splice(i, 1);
        }
      }
    }
    allProductCategories.forEach((productCategory) => {
      if (productCategory.category_id == e.target.value) {
        productCategory.isChecked = e.target.checked;
      }
    });

    this.setState({
      allProductCategories,
    });
  };

  handleChangeFilters = (e) => {
    var newFilter;
    var productFilters = this.state.productFilters;
    if (this.state.addedProductFilters !== undefined) {
      this.state.addedProductFilters = JSON.parse(
        this.state.addedProductFilters
      );
    } else {
      this.state.addedProductFilters = [];
    }

    var allValue = e.target.value.split(":");
    if (e.target.checked === true) {
      newFilter = new Object();
      newFilter.filter_id = allValue[0];
      newFilter.cat_id = allValue[1];
      this.state.addedProductFilters.push(newFilter);
    } else {
      for (var i in this.state.addedProductFilters) {
        if (this.state.addedProductFilters[i].filter_id == allValue[0]) {
          this.state.addedProductFilters.splice(i, 1);
        }
      }
    }

    productFilters.map((filterGroup) => {
      filterGroup.filters.map((filter) => {
        if (filter.id == allValue[0]) {
          filter.isChecked = e.target.checked;
        }
      });
    });
    this.state.addedProductFilters = JSON.stringify(
      this.state.addedProductFilters
    );
    this.setState({
      productFilters: productFilters,
    });
  };

  handleChangeSlug = (e) => {
    this.state.seoTitle = this.state.title;
    this.state.slug = slugify(this.state.title, {
      replacement: "-",
      remove: null,
      lower: true,
    });
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ product: state });
  };

  handleChange = (e) => {
    this.state.seoTitle = this.state.title;
    this.state.seoDescription = this.state.shortDescription.substr(0, 120);

    // this.state.productCreatedAt = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();

    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ product: state });
  };

  handleChangeMarkup = (e) => {
    var allData = e.target.value;
    var markup = allData.split("_")[0];
    var currentMarkupValue = parseInt(allData.split("_")[1]);
    var currentMarkupName = allData.split("_")[2];
    currentMarkupValue = defaultMarkupValue + currentMarkupValue / 100;
    this.setState({
      markup,
      currentMarkupValue,
      currentMarkupName,
    });
  };

  handleChangeMainPrice = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ product: state });
    var { price } = this.state;
    price = Math.trunc(
      this.state.vele_price * this.state.currentMarkupValue * this.state.pdv
    );

    var lastDigit = price.toString().substr(-1);
    price = price.toString().slice(0, -1) + "0";

    price = price.toString().slice(0, -1) + "0";

    if (lastDigit >= 0 && lastDigit < 3) {
      lastDigit = 0;
    } else if (lastDigit >= 3 && lastDigit < 8) {
      lastDigit = 5;
    } else if (lastDigit == 8 || lastDigit == 9) {
      lastDigit = 10;
    }

    price = parseInt(price) + lastDigit;
    this.setState({
      price,
    });
  };

  handleChangeActionPrice = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ product: state });
    var { priceAction } = this.state;
    priceAction = Math.trunc(
      this.state.price - (this.state.price * this.state.actionPercent) / 100
    );

    var lastDigit = priceAction.toString().substr(-1);
    priceAction = priceAction.toString().slice(0, -1) + "0";

    priceAction = priceAction.toString().slice(0, -1) + "0";

    if (lastDigit >= 0 && lastDigit < 3) {
      lastDigit = 0;
    } else if (lastDigit >= 3 && lastDigit < 8) {
      lastDigit = 5;
    } else if (lastDigit == 8 || lastDigit == 9) {
      lastDigit = 10;
    }

    priceAction = parseInt(priceAction) + lastDigit;
    this.setState({
      priceAction,
    });
  };

  handleChangeCategory = (e) => {
    var newFiltersGroup,
      productFilters = [];
    const subCategoriesArray = [];

    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    axios
      .get(
        process.env.REACT_APP_SERVER_PROXY_URL +
          "api/categories/subcategories/" +
          parseInt(this.state.category)
      )
      .then((response) => {
        this.setState({
          subCategories: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        process.env.REACT_APP_SERVER_PROXY_URL +
          "api/categories/single-category-id/" +
          parseInt(this.state.category)
      )
      .then((response) => {
        const { filtersCategories } = response.data;
        this.state.catFiltersCategories = JSON.parse(filtersCategories);
        this.state.catFiltersCategories.map((catFiltersCategory) => {
          axios
            .get(
              process.env.REACT_APP_SERVER_PROXY_URL +
                "api/filters/product-filters/" +
                catFiltersCategory
            )
            .then((response) => {
              if (response.data !== null) {
                const { filter_id, name, filters } = response.data;
                newFiltersGroup = new Object();
                newFiltersGroup.groupName = name;
                newFiltersGroup.id = filter_id;
                newFiltersGroup.filters = JSON.parse(filters);
                productFilters.push(newFiltersGroup);
                this.setState({
                  productFilters: productFilters,
                });
              }
            });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChangeSeo = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ product: state });
  };

  //ISTAKNUTA SLIKA
  onChangeImage(event) {
    var file = event.target.files[0];
    var file_thumb;
    var file_thumb_name = "thumb_" + file.name;

    this.setState({
      imageChanged: true,
      image: URL.createObjectURL(event.target.files[0]),
      imageFile: event.target.files[0],
    });

    const canvas = this.refs.canvas;
    var ctx = canvas.getContext("2d");

    // Ensure it's an image
    if (file.type.match(/image.*/)) {
      var reader = new FileReader();
      reader.onload = function (readerEvent) {
        var image = new Image();
        image.onload = function (imageEvent) {
          var width = 186,
            height = 230;
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(image, 0, 0, width, height);
          var dataUrl = canvas.toDataURL("image/jpeg");
          // var resizedImage = dataURLToBlob(dataUrl);

          fetch(dataUrl)
            .then((res) => res.blob())
            .then((blob) => {
              file_thumb = new File([blob], file_thumb_name, blob);
              imageFile_thumb = file_thumb;
            });
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  resetImage(event) {
    event.preventDefault();
    this.setState({ image: null });
  }

  //PRVA SLIKA U SLIDERU
  onChangeSlider1(event) {
    this.setState({
      sliderImage1Changed: true,
      sliderImage1: URL.createObjectURL(event.target.files[0]),
      sliderImage1File: event.target.files[0],
    });
  }

  resetSlider1(event) {
    event.preventDefault();
    this.setState({ sliderImage1: null });
  }

  //DRUGA SLIKA U SLIDERU
  onChangeSlider2(event) {
    this.setState({
      sliderImage2Changed: true,
      sliderImage2: URL.createObjectURL(event.target.files[0]),
      sliderImage2File: event.target.files[0],
    });
  }

  resetSlider2(event) {
    event.preventDefault();
    this.setState({ sliderImage2: null });
  }

  //TRECA SLIKA U SLIDERU

  onChangeSlider3(event) {
    this.setState({
      sliderImage3Changed: true,
      sliderImage3: URL.createObjectURL(event.target.files[0]),
      sliderImage3File: event.target.files[0],
    });
  }

  resetSlider3(event) {
    event.preventDefault();
    this.setState({ sliderImage3: null });
  }

  //CETVRTA SLIKA U SLIDERU

  onChangeSlider4(event) {
    this.setState({
      sliderImage4Changed: true,
      sliderImage4: URL.createObjectURL(event.target.files[0]),
      sliderImage4File: event.target.files[0],
    });
  }

  resetSlider4(event) {
    event.preventDefault();
    this.setState({ sliderImage4: null });
  }

  onChangeFacebookImage(event) {
    this.setState({
      facebookImageChanged: true,
      facebookImage: URL.createObjectURL(event.target.files[0]),
      facebookImageFile: event.target.files[0],
    });
  }

  resetSlider4(event) {
    event.preventDefault();
    this.setState({ sliderImage4: null });
  }

  resetFacebookImage(event) {
    event.preventDefault();
    this.setState({ facebookImage: null });
  }

  getProductById() {
    var newFiltersGroup,
      productFilters = this.state.productFilters;

    axios
      .get(
        process.env.REACT_APP_SERVER_PROXY_URL +
          "api/products/edit-product/" +
          this.props.match.params.slug
      )
      .then((response) => {
        const {
          title,
          seoTitle,
          purpose,
          slug,
          description,
          seoDescription,
          shortDescription,
          price,
          vele_price,
          priceAction,
          actionPercent,
          available,
          brand,
          markup,
          category,
          subCategory,
          deliverer,
          delivererBarcode,
          delivererProductKey,
          deliveryTime,
          localProductKey,
          productAction,
          productOffer,
          displayOnHomepage,
          productType,
          productVariations,
          productVariety,
          status,
          filters,
          image,
          image_thumb,
          sliderImage1,
          sliderImage2,
          sliderImage3,
          sliderImage4,
          facebookImage,
          productCreatedAt,
          fitlers,
        } = response.data[0];
        this.setState({
          title,
          seoTitle,
          purpose,
          slug,
          description,
          seoDescription,
          shortDescription,
          vele_price,
          price,
          priceAction,
          actionPercent,
          available,
          brand,
          markup,
          category,
          subCategory,
          deliverer,
          delivererBarcode,
          delivererProductKey,
          deliveryTime,
          addedProductFilters: filters,
          localProductKey,
          productAction,
          productOffer,
          displayOnHomepage,
          productType,
          status,
          filters,
          image,
          image_thumb,
          sliderImage1,
          sliderImage2,
          sliderImage3,
          sliderImage4,
          facebookImage,
          productCreatedAt,
        });

        if (productVariety) {
          this.setState({
            productVariety: productVariety,
          });
        }
        if (productVariety === 1) {
          this.setState({
            productVariations: JSON.parse(productVariations),
          });
        }
        if (response.data[0].categories !== undefined) {
          this.setState({
            addedProductCategories: response.data[0].categories,
          });
        }
        if (response.data[0].subcategories !== undefined) {
          this.setState({
            addedProductSubcategories: response.data[0].subcategories,
          });
        }

        this.getProductCategoriesList();

        axios
          .get(
            process.env.REACT_APP_SERVER_PROXY_URL +
              "api/markups/single-markup-id/" +
              markup
          )
          .then((response) => {
            this.setState({
              currentMarkupValue: 1 + response.data.value / 100,
              currentMarkupName: response.data.name,
            });
          });

        axios
          .get(
            process.env.REACT_APP_SERVER_PROXY_URL +
              "api/categories/single-category-id/" +
              category
          )
          .then((response) => {
            this.setState({
              currentCategory: response.data.name,
              catFiltersCategories: JSON.parse(response.data.filtersCategories),
            });

            this.state.catFiltersCategories.map((catFiltersCategory) => {
              axios
                .get(
                  process.env.REACT_APP_SERVER_PROXY_URL +
                    "api/filters/product-filters/" +
                    catFiltersCategory
                )
                .then((response) => {
                  if (response.data !== null) {
                    const { filter_id, name, filters } = response.data;
                    newFiltersGroup = new Object();
                    newFiltersGroup.groupName = name;
                    newFiltersGroup.id = filter_id;
                    newFiltersGroup.filters = JSON.parse(filters);
                    newFiltersGroup.filters.map((filter) => {
                      filter.isChecked = false;
                    });
                    productFilters.push(newFiltersGroup);
                  }
                  var addedProductFilters;
                  if (
                    this.state.addedProductFilters !== undefined &&
                    this.state.addedProductFilters.length > 0
                  ) {
                    addedProductFilters = JSON.parse(
                      this.state.addedProductFilters
                    );
                  } else {
                    addedProductFilters = [];
                  }

                  productFilters.map((filterGroup) => {
                    filterGroup.filters.map((filter) => {
                      for (var k in addedProductFilters) {
                        if (filter.id === addedProductFilters[k].filter_id) {
                          filter.isChecked = true;
                        }
                      }
                    });
                  });
                  this.setState({
                    productFilters: productFilters,
                  });
                });
            });
          })
          .catch((error) => {
            console.log(error);
          });

        axios
          .get(
            process.env.REACT_APP_SERVER_PROXY_URL +
              "api/categories/single-category-id/" +
              subCategory
          )
          .then((response) => {
            this.setState({
              currentSubCategory: response.data.name,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getParentCategoryList() {
    axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL + "api/categories/parent")
      .then((response) => {
        this.setState({
          categories: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getProductCategoriesList() {
    var allProductCategoriesArray = [];
    axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL + "api/categories")
      .then((response) => {
        response.data.forEach((doc) => {
          const { category_id, name, parentCategory } = doc;
          allProductCategoriesArray.push({
            category_id,
            isChecked: false,
            name,
            parentCategory,
          });
        });
        this.setState({
          allProductCategories: allProductCategoriesArray,
        });
        let allProductCategories = this.state.allProductCategories;
        let addedProductCategories = [],
          addedProductSubcategories = [];
        addedProductCategories = this.state.addedProductCategories;
        addedProductSubcategories = this.state.addedProductSubcategories;

        let allProductCategoriesId = [],
          allProductSubcategoriesId = [];
        allProductCategories.forEach((allProductCategory) => {
          if (allProductCategory.parentCategory === null) {
            allProductCategoriesId.push(allProductCategory.category_id);
          } else {
            allProductSubcategoriesId.push(allProductCategory.category_id);
          }
        });

        var productCategoriesDiff = allProductCategoriesId.filter(function (
          el
        ) {
          return addedProductCategories.indexOf(el) < 0;
        });

        var productSubcategoriesDiff = allProductSubcategoriesId.filter(
          function (el) {
            return addedProductSubcategories.indexOf(el) < 0;
          }
        );

        allProductCategories.forEach((allProductCategory) => {
          allProductCategory.isChecked = true;
        });

        productCategoriesDiff.forEach((productCategoryDiff) => {
          allProductCategories.forEach((allProductCategory) => {
            if (allProductCategory.category_id === productCategoryDiff) {
              allProductCategory.isChecked = false;
            }
          });
        });

        productSubcategoriesDiff.forEach((productSubcategoryDiff) => {
          allProductCategories.forEach((allProductCategory) => {
            if (allProductCategory.category_id === productSubcategoryDiff) {
              allProductCategory.isChecked = false;
            }
          });
        });

        this.setState({
          allProductCategories: allProductCategories,
          /*   catFiltersCategories: catFiltersCategories */
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getBrandList() {
    axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL + "api/brands")
      .then((response) => {
        this.setState({
          allBrands: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getMarkupList() {
    axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL + "api/markups")
      .then((response) => {
        this.setState({
          allMarkups: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Collect settings data
  getSettings() {
    axios
      .get(process.env.REACT_APP_SERVER_PROXY_URL + "api/settings/get-settings")
      .then((response) => {
        var { pdv } = response.data;
        this.setState({
          pdv: 1 + pdv / 100,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount = () => {
    this.getSettings();
    this.getProductById();
    this.getParentCategoryList();
    this.getBrandList();
    this.getMarkupList();
  };

  onSubmit = (e) => {
    e.preventDefault();
    var addedProductFilters = JSON.parse(this.state.addedProductFilters);

    const {
      id,
      title,
      slug,
      description,
      shortDescription,
      price,
      vele_price,
      image,
      image_thumb,
      sliderImage1,
      sliderImage2,
      sliderImage3,
      sliderImage4,
      facebookImage,
      priceAction,
      actionPercent,
      category,
      subCategory,
      addedProductCategories,
      addedProductSubcategories,
      brand,
      markup,
      seoTitle,
      purpose,
      seoDescription,
      deliveryTime,
      productAction,
      productOffer,
      displayOnHomepage,
      status,
      addedFilters,
      deliverer,
      delivererBarcode,
      delivererProductKey,
      localProductKey,
      productType,
      productVariety,
      productVariations,
      available,
      productCreatedAt,
    } = this.state;

    var newImage,
      newImage_thumb,
      newSliderImage1,
      newSliderImage2,
      newSliderImage3,
      newSliderImage4,
      newFacebookImage;
    var {
      imageFile,
      sliderImage1File,
      sliderImage2File,
      sliderImage3File,
      sliderImage4File,
      facebookImageFile,
    } = this.state;
    if (imageFile !== null) {
      newImage = imageFile.name;
      newImage_thumb = imageFile_thumb.name;
    } else {
      newImage = image;
      newImage_thumb = image_thumb;
    }

    if (sliderImage1File !== null) {
      newSliderImage1 = sliderImage1File.name;
    } else {
      newSliderImage1 = sliderImage1;
    }

    if (sliderImage2File !== null) {
      newSliderImage2 = sliderImage2File.name;
    } else {
      newSliderImage2 = sliderImage2;
    }

    if (sliderImage3File !== null) {
      newSliderImage3 = sliderImage3File.name;
    } else {
      newSliderImage3 = sliderImage3;
    }

    if (sliderImage4File !== null) {
      newSliderImage4 = sliderImage4File.name;
    } else {
      newSliderImage4 = sliderImage4;
    }

    if (facebookImageFile !== null) {
      newFacebookImage = facebookImageFile.name;
    } else {
      newFacebookImage = facebookImage;
    }

    var usedFilters = this.state.usedFilters;
    let addedProductFiltersIds = [];
    addedProductFilters.forEach((addedProductFilter) => {
      addedProductFiltersIds.push(addedProductFilter.filter_id);

      axios
        .get(
          process.env.REACT_APP_SERVER_PROXY_URL +
            "api/filters/product-filters/" +
            parseInt(addedProductFilter.cat_id)
        )
        .then((response) => {
          var key = response.data._id;
          var { filter_id, name, filters } = response.data;
          var filters = JSON.parse(response.data.filters);
          let filtersIds = [];
          filters.forEach((filter) => {
            filtersIds.push(filter.id);
          });

          var intersectRes = addedProductFiltersIds.filter((value) =>
            filtersIds.includes(value)
          );

          filters.forEach((filter) => {
            if (intersectRes.includes(filter.id)) {
              filter.isUsed = 1;
              filter.quantity = filter.quantity + 1;
            } else {
              if (filter.isUsed !== 1) filter.isUsed = 0;
            }
          });

          filters = JSON.stringify(filters);
          axios
            .post(
              process.env.REACT_APP_SERVER_PROXY_URL +
                "api/filters/update-filter/" +
                key,
              {
                filters: filters,
              }
            )
            .then((response) => {})
            .catch((error) => {
              console.log(error);
            });
        });
    });

    axios
      .post(
        process.env.REACT_APP_SERVER_PROXY_URL +
          "api/products/update-product/" +
          this.props.match.params.slug,
        {
          title,
          slug,
          description,
          shortDescription,
          vele_price,
          price,
          priceAction,
          actionPercent,
          category: parseInt(category),
          subCategory: parseInt(subCategory),
          categories: addedProductCategories,
          subcategories: addedProductSubcategories,
          brand: parseInt(brand),
          markup: parseInt(markup),
          image: newImage,
          image_thumb: newImage_thumb,
          sliderImage1: newSliderImage1,
          sliderImage2: newSliderImage2,
          sliderImage3: newSliderImage3,
          sliderImage4: newSliderImage4,
          facebookImage: newFacebookImage,
          seoTitle,
          purpose,
          seoDescription,
          deliveryTime,
          productAction: parseInt(productAction),
          productOffer: parseInt(productOffer),
          displayOnHomepage: parseInt(displayOnHomepage),
          status,
          deliverer: parseInt(deliverer),
          delivererBarcode,
          delivererProductKey,
          localProductKey,
          productType: parseInt(productType),
          productVariety: parseInt(productVariety),
          productVariations: JSON.stringify(productVariations),
          filters: this.state.addedProductFilters,
          available: parseInt(available),
        }
      )
      .then((response) => {
        this.setState({
          showStickerClass: "show",
        });
        setTimeout(
          function () {
            this.setState({
              showStickerClass: "",
            });
          }.bind(this),
          timeoutDelay
        );
        this.setState({
          stickerMessage:
            'Uspješno ste uredili proizvod "<b>' + this.state.title + '"</b>',
          stickerType: "success",
        });

        var productsInner = [];
        axios
          .get(process.env.REACT_APP_SERVER_PROXY_URL + "api/carts")
          .then((response) => {
            response.data.forEach((doc) => {
              const { cartId, products, totalPrice } = doc;
              productsInner.push({
                key: doc.id,
                doc,
                cartId,
                products: JSON.parse(products),
                totalPrice,
              });
              var totalPriceNew = 0;
              for (var i in productsInner) {
                for (var j in productsInner[i].products) {
                  if (productsInner[i].products[j].id === this.state.key) {
                    if (
                      productsInner[i].products[j].productAction !==
                        productAction ||
                      productsInner[i].products[j].productOffer !== productOffer
                    ) {
                      totalPriceNew =
                        parseFloat(productsInner[i].totalPrice) -
                        parseFloat(productsInner[i].products[j].price);
                      productsInner[i].products.splice(j, 1);
                    }
                    axios
                      .post(
                        process.env.REACT_APP_SERVER_PROXY_URL +
                          "api/carts/update-cart/" +
                          productsInner[i].cartId,
                        {
                          cartId: productsInner[i].cartId,
                          products: JSON.stringify(productsInner[i].products),
                          totalPrice: totalPriceNew,
                        }
                      )
                      .then((docRef) => {})
                      .catch((error) => {
                        console.log("problem ", error);
                      });
                  }
                }
              }
            });
          });
      })
      .catch((error) => {
        this.setState({
          showStickerClass: "show",
        });
        setTimeout(
          function () {
            this.setState({
              showStickerClass: "",
            });
          }.bind(this),
          timeoutDelay
        );
        this.setState({
          stickerMessage:
            "Došlo je do problema prilikom uređivanja proizvoda: " + error,
          stickerType: "danger",
        });
      });

    if (imageFile !== null) {
      setTimeout(
        function () {
          const addImage = new FormData();
          const addImage_thumb = new FormData();
          addImage.append("image", this.state.imageFile);
          const config = {
            headers: { "content-type": "multipart/form-data" },
          };

          addImage_thumb.append("image", imageFile_thumb);
          const config_thumb = {
            headers: { "content-type": "multipart/form-data" },
          };

          upload(addImage, config)
            .then((response) => {})
            .catch((error) => {});
          upload(addImage_thumb, config_thumb)
            .then((response) => {})
            .catch((error) => {});
        }.bind(this),
        1500
      );
    }

    if (sliderImage1File !== null) {
      const addImage_slider_1 = new FormData();
      addImage_slider_1.append("image", this.state.sliderImage1File);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_slider_1, config)
        .then((response) => {})
        .catch((error) => {});
    }
    if (sliderImage2File !== null) {
      const addImage_slider_2 = new FormData();
      addImage_slider_2.append("image", this.state.sliderImage2File);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_slider_2, config)
        .then((response) => {})
        .catch((error) => {});
    }
    if (sliderImage3File !== null) {
      const addImage_slider_3 = new FormData();
      addImage_slider_3.append("image", this.state.sliderImage3File);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_slider_3, config)
        .then((response) => {})
        .catch((error) => {});
    }
    if (sliderImage4File !== null) {
      const addImage_slider_4 = new FormData();
      addImage_slider_4.append("image", this.state.sliderImage4File);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_slider_4, config)
        .then((response) => {})
        .catch((error) => {});
    }

    if (facebookImageFile !== null) {
      const addImage_facebook_image = new FormData();
      addImage_facebook_image.append("image", this.state.facebookImageFile);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      upload(addImage_facebook_image, config)
        .then((response) => {})
        .catch((error) => {});
    }
  };

  render() {
    var { description, shortDescription } = this.state;
    const {
      title,
      slug,
      image,
      price,
      priceAction,
      actionPercent,
      category,
      subCategory,
      brand,
      markup,
      sliderImage1,
      sliderImage2,
      sliderImage3,
      sliderImage4,
      facebookImage,
      seoTitle,
      purpose,
      seoDescription,
      deliveryTime,
      productAction,
      productOffer,
      displayOnHomepage,
      status,
      deliverer,
      delivererBarcode,
      delivererProductKey,
      productType,
      localProductKey,
      productVariations,
      variationId,
      variationName,
      variationPrice,
      variationType,
      available,
      imageChanged,
      sliderImage1Changed,
      sliderImage2Changed,
      sliderImage3Changed,
      sliderImage4Changed,
      facebookImageChanged,
      vele_price,
    } = this.state;
    var productVariety = parseInt(this.state.productVariety);

    return (
      <AdminWrapper>
        <section
          id="addProduct"
          className="section default-section add-product content-page-section default-form"
        >
          {this.state.showStickerClass === "show" && (
            <Sticker
              stickerType={this.state.stickerType}
              stickerMessage={this.state.stickerMessage}
            />
          )}
          <div className="container">
            <Helmet>
              <title>
                {" "}
                {title} &rsaquo; Uredi proizvod &rsaquo; Zdravo.ba{" "}
              </title>
            </Helmet>
            <div className="is-hidden">
              <canvas ref="canvas" width={640} height={425} />
            </div>
            <div className="content">
              <div className="page-header">
                <h2 className="page-title">Uređivanje proizvoda</h2>
              </div>
              <div className="breadcrumbs">
                <Link to="/administracija">Administracija</Link>
                <span>/</span>
                <Link to="/administracija/proizvodi">Proizvodi</Link>
                <span>/</span>
                <span>Uređivanje proizvoda</span>
              </div>
              <form
                name="add-product"
                onSubmit={this.onSubmit}
                className="form"
              >
                <fieldset className="">
                  <div className="columns is-multiline main">
                    <label htmlFor="title" className="column is-12 title">
                      Ime proizvoda
                      <input
                        type="text"
                        id="title"
                        name="title"
                        placeholder="Puno ime proizvoda koje će biti prikazano"
                        value={title}
                        onChange={this.handleChangeSlug}
                        required
                      />
                    </label>
                    <label htmlFor="slug" className="column is-12 slug">
                      <span>Generisani url</span>
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        placeholder="Slug proizvoda"
                        value={slug}
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                    <label htmlFor="purpose" className="column is-12">
                      <span>Namjena proizvoda</span>
                      <input
                        type="text"
                        id="purpose"
                        name="purpose"
                        placeholder="Namjena proizvoda"
                        value={purpose}
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                    <label
                      htmlFor="description"
                      className="column is-12 short-description"
                    >
                      Kratki opis Proizvoda
                      <CKEditor
                        editor={ClassicEditor}
                        data={shortDescription}
                        onInit={(editor) => {}}
                        onChange={(event, editor) => {
                          this.setState({
                            shortDescription: editor.getData(),
                          });
                        }}
                        onBlur={(editor) => {}}
                        onFocus={(editor) => {}}
                      />
                    </label>

                    <label
                      htmlFor="description"
                      className="column is-12 description"
                    >
                      Detaljni Opis Proizvoda
                      <CKEditor
                        editor={ClassicEditor}
                        data={description}
                        onInit={(editor) => {}}
                        onChange={(event, editor) => {
                          this.setState({
                            description: editor.getData(),
                          });
                        }}
                        onBlur={(editor) => {}}
                        onFocus={(editor) => {}}
                      />
                    </label>
                    <span className="is-hidden">
                      {(shortDescription = this.state.shortDescription)}
                      {(description = this.state.description)}
                    </span>
                    {/*    <label htmlFor="markup" className="column is-6">
                                        Vrijednost marže
                                        <select id="markup" name="markup" value={markup} onChange={this.handleChangeMarkup} required>
                                            <option value="null">Izaberi vrijednost marže</option>
                                            {this.state.allMarkups.map(markup =>
                                            <option value={markup.markup_id+"_"+markup.value+"_"+markup.name}>{markup.name} ({markup.value}%)</option>
                                            )}
                                        </select>
                                        <span>{this.state.currentMarkupName}</span>
                                            </label> */}
                    {/*     <label htmlFor="vele_price" className="column is-12 price">
                                        Veleprodajna cijena proizvoda(1KM = 100)
                                        <input type="text" id="vele_price" name="vele_price" placeholder="Veleprodajna cijena proizvoda"
                                        value={vele_price} onChange={ this.handleChangeMainPrice } required/>
                                        <span className="real-price">{formatMoney(vele_price)}</span>
                                            </label> */}
                    <label htmlFor="price" className="column is-4 price">
                      Cijena Proizvoda(1KM = 100)
                      <input
                        type="text"
                        id="price"
                        name="price"
                        placeholder="Stvarna cijena proizvoda"
                        value={price}
                        onChange={this.handleChange}
                        required
                      />
                      <span className="real-price">{formatMoney(price)}</span>
                    </label>

                    <label
                      htmlFor="actionPercent"
                      className="column is-4 price"
                    >
                      Popust(unijeti samo broj, bez znaka %)
                      <input
                        type="text"
                        id="actionPercent"
                        name="actionPercent"
                        placeholder="Procenat popusta na akciji"
                        value={actionPercent}
                        onChange={this.handleChangeActionPrice}
                      />
                    </label>

                    <label htmlFor="priceAction" className="column is-4 price">
                      Akcijska cijena Proizvoda
                      <input
                        type="text"
                        id="priceAction"
                        name="priceAction"
                        placeholder="Cijena proizvoda na akciji"
                        value={priceAction}
                        onChange={this.handleChange}
                        disabled
                      />
                      <span className="real-price">
                        {formatMoney(priceAction)}
                      </span>
                    </label>

                    <label htmlFor="category" className="column is-6">
                      Glavna kategorija(za prikaz kategorije u URL-u proizvoda)
                      <select
                        id="category"
                        name="category"
                        onChange={this.handleChangeCategory}
                        required
                      >
                        <option value={category}>
                          {this.state.currentCategory}
                        </option>
                        {this.state.categories.map((category) => (
                          <option value={category.category_id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </label>

                    {/*  <label htmlFor="subCategory" className="column is-6">
                                        Podkategorija
                                        <select id="subCategory" name="subCategory" onChange={ this.handleChange } >
                                            <option value={subCategory}>{this.state.currentSubCategory}</option>
                                            {this.state.subCategories.map(subCategory =>
                                            <option value={subCategory.category_id}>{subCategory.name}</option>
                                            )}
                                        </select>
                                    </label>
                                */}

                    <label htmlFor="deliverer" className="column is-6">
                      Dostavljač
                      <select
                        id="deliverer"
                        name="deliverer"
                        value={deliverer}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled>
                          Izaberi dostavljača
                        </option>
                        {this.state.allMarkups.map((markup) => (
                          <option value={markup.markup_id}>
                            {markup.name}
                          </option>
                        ))}
                      </select>
                    </label>

                    <label htmlFor="brand" className="column is-6">
                      Proizvođač
                      <select
                        id="brand"
                        name="brand"
                        value={brand}
                        onChange={this.handleChange}
                        required
                      >
                        <option value="" disabled>
                          Izaberi proizvođača
                        </option>
                        {this.state.allBrands.map((brand) => (
                          <option value={brand.brand_id}>{brand.name}</option>
                        ))}
                      </select>
                    </label>

                    <label htmlFor="deliveryTime" className="column is-6">
                      Rok isporuke
                      <select
                        id="deliveryTime"
                        name="deliveryTime"
                        value={deliveryTime}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled>
                          Izaberi vrijeme isporuke
                        </option>
                        <option value="1 dan">1 dan</option>
                        <option value="2 dana">2 dana</option>
                        <option value="3 dana">3 dana</option>
                        <option value="4 dana">4 dana</option>
                      </select>
                    </label>

                    <label htmlFor="productVariety" className="column is-6">
                      Varijabilni proizvod
                      <select
                        id="productVariety"
                        name="productVariety"
                        value={productVariety}
                        onChange={this.handleChange}
                      >
                        <option value="0">Ne</option>
                        <option value="1">Da</option>
                      </select>
                    </label>
                    {productVariety === 1 && (
                      <div className="column is-12 variation-box">
                        <div className="columns form">
                          <input
                            type="hidden"
                            name="variationId"
                            value={variationId}
                            placeholder=""
                            onChange={this.handleChange}
                          />
                          <label
                            htmlFor="variationType"
                            className="column is-3"
                          >
                            <select
                              id="variationType"
                              name="variationType"
                              value={variationType}
                              onChange={this.handleChange}
                            >
                              <option value="" disabled>
                                Tip varijacije
                              </option>
                              <option value="Boja">Boja</option>
                              <option value="Količina">Količina</option>
                              <option value="Aroma">Aroma</option>
                              <option value="Vremenski period">
                                Vremenski period
                              </option>
                              <option value="Sastav">Sastav</option>
                              <option value="Tip pojasa">Tip pojasa</option>
                              <option value="Tip">Tip</option>
                              <option value="Vrsta">Vrsta</option>
                            </select>
                          </label>
                          <label
                            htmlFor="variationName"
                            className="column is-3"
                          >
                            <input
                              type="text"
                              name="variationName"
                              value={variationName}
                              placeholder="Ime varijcije"
                              onChange={this.handleChange}
                            />
                          </label>
                          <label
                            htmlFor="variationPrice"
                            className="column is-3"
                          >
                            <input
                              type="text"
                              name="variationPrice"
                              value={variationPrice}
                              placeholder="Cijena"
                              onChange={this.handleChange}
                            />
                          </label>
                          <span
                            className="button default"
                            onClick={this.saveVariation.bind(
                              this,
                              variationId,
                              variationType,
                              variationName,
                              variationPrice
                            )}
                          >
                            Dodaj <FontAwesomeIcon icon="plus-circle" />
                          </span>
                        </div>
                        {this.state.productVariations.length > 0 && (
                          <table>
                            <thead>
                              <tr>
                                <th>Tip</th>
                                <th>Ime</th>
                                <th>Cijena</th>
                                <th>Opcije</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.productVariations.map(
                                (productVariation) => {
                                  return (
                                    <tr>
                                      <td>{productVariation.type}</td>
                                      <td>{productVariation.name}</td>
                                      <td>
                                        {formatMoney(productVariation.price)}
                                      </td>
                                      <td>
                                        <span
                                          className=""
                                          onClick={this.deleteVariation.bind(
                                            this,
                                            productVariation.id
                                          )}
                                        >
                                          <FontAwesomeIcon icon="trash-alt" />
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="columns is-multiline top">
                    <label htmlFor="available" className="column is-6">
                      Proizvod na stanju
                      <select
                        id="available"
                        name="available"
                        value={available}
                        onChange={this.handleChange}
                      >
                        <option value="1">Da</option>
                        <option value="0">Ne</option>
                      </select>
                    </label>
                    <label htmlFor="localProductKey" className="column is-6">
                      Šifra proizvoda(Naša)
                      <input
                        type="text"
                        id="localProductKey"
                        name="localProductKey"
                        placeholder="Šifra proizvoda"
                        value={localProductKey}
                        onChange={this.handleChange}
                        required
                      />
                    </label>

                    <label
                      htmlFor="delivererProductKey"
                      className="column is-6"
                    >
                      Šifra proizvoda(Dobavljač)
                      <input
                        type="text"
                        id="delivererProductKey"
                        name="delivererProductKey"
                        placeholder="Bar kod proizvoda"
                        value={delivererProductKey}
                        onChange={this.handleChange}
                      />
                    </label>

                    <label htmlFor="delivererBarcode" className="column is-6">
                      Bar kod proizvoda(Dobavljač)
                      <input
                        type="text"
                        id="delivererBarcode"
                        name="delivererBarcode"
                        placeholder="Bar kod proizvoda"
                        value={delivererBarcode}
                        onChange={this.handleChange}
                      />
                    </label>

                    <label htmlFor="productType" className="column is-6">
                      Tip proizvoda
                      <select
                        id="productType"
                        name="productType"
                        value={productType}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled>
                          Izaberi tip proizvoda
                        </option>
                        <option value="1">Dodatak prehrani</option>
                        <option value="2">Kozmetika</option>
                        <option value="3">Ishrana</option>
                        <option value="4">Drogerija</option>
                        <option value="5">Aparati i pomagala</option>
                      </select>
                    </label>
                  </div>

                  <div className="sidebar">
                    <label htmlFor="status" className="">
                      Status proizvoda
                      <select
                        id="status"
                        name="status"
                        value={status}
                        onChange={this.handleChange}
                        required
                      >
                        <option value="" disabled>
                          Izaberi
                        </option>
                        <option value="Objavljeno">Objavljeno</option>
                        <option value="U pripremi">U pripremi</option>
                        <option value="Neobjavljeno">Neobjavljeno</option>
                      </select>
                    </label>
                    <label htmlFor="displayOnHomepage" className="">
                      Prikazati proizvod na početnoj stranici?
                      <select
                        id="displayOnHomepage"
                        name="displayOnHomepage"
                        value={displayOnHomepage}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled>
                          Izaberi
                        </option>
                        <option value="0">Ne</option>
                        <option value="1">Da</option>
                      </select>
                    </label>
                    <label htmlFor="productAction" className="">
                      Proizvod na akciji?
                      <select
                        id="productAction"
                        name="productAction"
                        value={productAction}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled>
                          Izaberi
                        </option>
                        <option value="0">Ne</option>
                        <option value="1">Da</option>
                      </select>
                    </label>
                    <label htmlFor="productOffer" className="">
                      Proizvod u ponudi sedmice?
                      <select
                        id="productOffer"
                        name="productOffer"
                        value={productOffer}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled>
                          Izaberi
                        </option>
                        <option value="0">Ne</option>
                        <option value="1">Da</option>
                      </select>
                    </label>

                    {category !== "" && (
                      <label
                        htmlFor="filters"
                        className="column is-12 filters-categories"
                      >
                        Atributi proizvoda
                        <div className="wrapper">
                          {this.state.productFilters.map((filterGroup) => {
                            return (
                              <div className="inner-box">
                                <h6 className="filter-category-name">
                                  {filterGroup.groupName}
                                </h6>
                                {filterGroup.filters.map((filter) => {
                                  return (
                                    <div className="single-line">
                                      <input
                                        key={filter.id}
                                        name="filter_id"
                                        onClick={this.handleChangeFilters}
                                        type="checkbox"
                                        value={filter.id + ":" + filterGroup.id}
                                        checked={filter.isChecked}
                                      />

                                      {filter.name}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </div>
                      </label>
                    )}

                    <label
                      htmlFor="filters"
                      className="column is-12 product-categories filters-categories"
                    >
                      Kategorije proizvoda
                      <div className="wrapper">
                        {this.state.allProductCategories.map(
                          (singleCategory) =>
                            singleCategory.parentCategory === null && (
                              <div className="inner-box">
                                <div className="single-line">
                                  <input
                                    key={singleCategory.id}
                                    name="filter_id"
                                    onClick={
                                      this.handleChangeMainCategoriesArray
                                    }
                                    type="checkbox"
                                    value={singleCategory.category_id}
                                    checked={singleCategory.isChecked}
                                  />
                                  {singleCategory.name}
                                </div>

                                {this.state.allProductCategories.map(
                                  (singleSubCategory) => (
                                    <>
                                      {singleSubCategory.parentCategory ===
                                        singleCategory.category_id && (
                                        <div className="single-line subcategory">
                                          <input
                                            key={singleCategory.id}
                                            name="filter_id"
                                            onClick={
                                              this
                                                .handleChangeSubcategoriesArray
                                            }
                                            type="checkbox"
                                            value={
                                              singleSubCategory.category_id
                                            }
                                            checked={
                                              singleSubCategory.isChecked
                                            }
                                          />
                                          {singleSubCategory.name}
                                        </div>
                                      )}
                                    </>
                                  )
                                )}
                              </div>
                            )
                        )}
                      </div>
                    </label>
                    <div className="button-area">
                      <button type="submit" className="button default add">
                        <span className="item">
                          Spremi izmjene <FontAwesomeIcon icon="pencil-alt" />
                        </span>
                      </button>
                    </div>

                    {this.state.imageFile !== null && this.state.imageFile.name}
                    <div id="upload_button" className="">
                      <label htmlFor="image" className="label-image">
                        Istaknuta slika
                        <input
                          type="file"
                          id="image"
                          name="image"
                          onChange={this.onChangeImage}
                        />
                        <span className="add-image">
                          Dodaj istaknutu sliku <FontAwesomeIcon icon="image" />
                        </span>
                      </label>
                      <div className="image-preview">
                        {image && !imageChanged && (
                          <img
                            src={
                              process.env.REACT_APP_FILES_DESTINATION + image
                            }
                            alt=""
                          />
                        )}
                        {image && imageChanged && <img src={image} alt="" />}
                        {image !== "empty" && (
                          <button
                            className="remove-button"
                            onClick={this.resetImage}
                          >
                            <i className="icon"></i>
                          </button>
                        )}
                      </div>
                    </div>

                    <div id="upload_slider" className="">
                      <div className="single-row">
                        <label htmlFor="sliderImage1">
                          <input
                            type="file"
                            id="sliderImage1"
                            name="sliderImage1"
                            onChange={this.onChangeSlider1}
                          />
                          <span className="add-image">
                            Dodaj sliku u slider
                          </span>
                        </label>
                        <div className="image-preview">
                          {sliderImage1 && !sliderImage1Changed && (
                            <img
                              src={
                                process.env.REACT_APP_FILES_DESTINATION +
                                sliderImage1
                              }
                              alt=""
                            />
                          )}
                          {sliderImage1 && sliderImage1Changed && (
                            <img src={sliderImage1} alt="" />
                          )}
                          {sliderImage1 !== "empty" && (
                            <button
                              className="remove-button"
                              onClick={this.resetSlider1}
                            >
                              <i className="icon"></i>
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="single-row">
                        <label htmlFor="sliderImage2">
                          <input
                            type="file"
                            id="sliderImage2"
                            name="sliderImage2"
                            onChange={this.onChangeSlider2}
                          />
                          <span className="add-image">
                            Dodaj sliku u slider
                          </span>
                        </label>
                        <div className="image-preview">
                          {sliderImage2 && !sliderImage2Changed && (
                            <img
                              src={
                                process.env.REACT_APP_FILES_DESTINATION +
                                sliderImage2
                              }
                              alt=""
                            />
                          )}
                          {sliderImage2 && sliderImage2Changed && (
                            <img src={sliderImage2} alt="" />
                          )}
                          {sliderImage2 !== "empty" && (
                            <button
                              className="remove-button"
                              onClick={this.resetSlider2}
                            >
                              <i className="icon"></i>
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="single-row">
                        <label htmlFor="sliderImage3">
                          <input
                            type="file"
                            id="sliderImage3"
                            name="sliderImage3"
                            onChange={this.onChangeSlider3}
                          />
                          <span className="add-image">
                            Dodaj sliku u slider
                          </span>
                        </label>
                        <div className="image-preview">
                          {sliderImage3 && !sliderImage3Changed && (
                            <img
                              src={
                                process.env.REACT_APP_FILES_DESTINATION +
                                sliderImage3
                              }
                              alt=""
                            />
                          )}
                          {sliderImage3 && sliderImage3Changed && (
                            <img src={sliderImage3} alt="" />
                          )}
                          {sliderImage3 !== "empty" && (
                            <button
                              className="remove-button"
                              onClick={this.resetSlider3}
                            >
                              <i className="icon"></i>
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="single-row">
                        <label htmlFor="sliderImage4">
                          <input
                            type="file"
                            id="sliderImage4"
                            name="sliderImage4"
                            onChange={this.onChangeSlider4}
                          />
                          <span className="add-image">
                            Dodaj sliku u slider
                          </span>
                        </label>
                        <div className="image-preview">
                          {sliderImage4 && !sliderImage4Changed && (
                            <img
                              src={
                                process.env.REACT_APP_FILES_DESTINATION +
                                sliderImage4
                              }
                              alt=""
                            />
                          )}
                          {sliderImage4 && sliderImage4Changed && (
                            <img src={sliderImage4} alt="" />
                          )}
                          {sliderImage4 !== "empty" && (
                            <button
                              className="remove-button"
                              onClick={this.resetSlider4}
                            >
                              <i className="icon"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    {this.state.facebookImageFile !== null &&
                      this.state.facebookImageFile.name}
                    <div id="upload_button" className="">
                      <label htmlFor="facebookImage" className="label-image">
                        Facebook slika
                        <input
                          type="file"
                          id="facebookImage"
                          name="image"
                          onChange={this.onChangeFacebookImage}
                        />
                        <span className="add-image">
                          Dodaj facebook sliku <FontAwesomeIcon icon="image" />
                        </span>
                      </label>
                      <div className="image-preview">
                        {facebookImage && !facebookImageChanged && (
                          <img
                            src={
                              process.env.REACT_APP_FILES_DESTINATION +
                              facebookImage
                            }
                            alt=""
                          />
                        )}
                        {facebookImage && facebookImageChanged && (
                          <img src={facebookImage} alt="" />
                        )}
                        {facebookImage !== "empty" && (
                          <button
                            className="remove-button"
                            onClick={this.resetFacebookImage}
                          >
                            <i className="icon"></i>
                          </button>
                        )}
                      </div>
                    </div>

                    <h4 className="label">SEO</h4>
                    <span
                      className="meta-button"
                      onClick={this.toggleSEOSidebar.bind(this)}
                    >
                      <span className="inner">
                        <h4>Meta informacije</h4>
                        <p>Dodaci za SEO</p>
                      </span>
                      <span className="icon"></span>
                    </span>
                  </div>

                  <div
                    className={
                      "sidebar seo-sidebar " + this.state.showSEOSidebarClass
                    }
                  >
                    <div className="header">
                      <h4>SEO informacije</h4>
                      <span
                        className="icon"
                        onClick={this.toggleSEOSidebar.bind(this)}
                      ></span>
                    </div>

                    <label htmlFor="seoTitle" className="">
                      SEO naziv proizvoda
                      <input
                        type="text"
                        id="seoTitle"
                        name="seoTitle"
                        placeholder="Naziv proizvoda za SEO"
                        value={seoTitle}
                        onChange={this.handleChangeSeo}
                      />
                    </label>

                    <label htmlFor="seoDescription" className="">
                      Meta deskripcija
                      <textarea
                        id="seoDescription"
                        name="seoDescription"
                        placeholder="Meta deskripcija za proizvod"
                        value={seoDescription}
                        onChange={this.handleChangeSeo}
                      ></textarea>
                    </label>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </section>
      </AdminWrapper>
    );
  }
}

export default EditProduct;
