import React from 'react'
import { Link } from 'react-router-dom'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import {Helmet} from "react-helmet";
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { upload } from '../../helpers/UserFunctions'

var slugify = require('slugify')

class EditMarkup extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;
        
        this.state = {
            showPopupClass: '',
            id: '',
            name: '',
            value: '',
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
        }   

    }

handleChange = (e) => {     
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
        }
    
componentDidMount = () => {
    this.getMarkupById();
}

getMarkupById() {
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/markups/edit-markup/' + this.props.match.params.id)
    .then((response) => {
    this.setState({
    name: response.data.name,
    value: response.data.value,
    });
    })
    .catch((error) => {
    console.log(error);
    })
}

onSubmit = (e) => {
    e.preventDefault();
    const { name, value } = this.state;
    axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/markups/update-markup/' + this.props.match.params.id, {
    name: name,
    value: value,
    })
    .then((response) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Uspješno ste uredili maržu "<b>' + this.state.name + '"</b>',
        stickerType: 'success',
    })
    
    this.props.history.push('/administracija/marze');
    })
    .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Došlo je do problema prilikom uređivanja marže: ' + error,
        stickerType: 'danger',
    })
    });
}        

    render(){
        const { id, name, value} = this.state;
        return(
            <AdminWrapper>
            <section id="markups" className="section default-section content-page-section add-markup default-form">
               {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> {name}  &rsaquo; Uređivanje marže  &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="content">
                        <div className="page-header">
                            <h2 className="page-title">Uređivanje marže</h2>
                        </div>
                        <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <Link to="/administracija/baneri">Marže</Link>
                            <span>/</span>
                            <span>Uređivanje marže</span>
                        </div>
                        <div className="columns">
                            <div className="column is-8">
                                <form name="edit-markup" onSubmit={this.onSubmit} className="form">
                                 <fieldset className="">
                                    <label htmlFor="name" className="column is-12 title">
                                        Ime marže
                                        <input type="text" id="name" name="name" placeholder="Ime marže"
                                        value={name} onChange={ this.handleChange} required/>
                                    </label> 
                                
                                    <label htmlFor="value" className="column is-12">
                                        Vrijednost marže
                                        <input type="text" id="value" name="value" placeholder="Vrijednost marže"
                                        value={value} onChange={ this.handleChange} required/>
                                    </label>
                                    <button type="submit" className="button default add default-no-icon"><span className="item">Spremi izmjene <FontAwesomeIcon icon="pencil-alt" /></span></button>

                            </fieldset>
                        </form>
                            </div>
                            
                         
                        </div>
                        
                    </div>
                </div>
            </section>
            </AdminWrapper>
        )
    }
}

export default EditMarkup