/*/
Function: Format price, add KM
*/

export default function(amount) {
    const options = {
      style: 'currency',
      currency: 'BAM',
      minimumFractionDigits: 2,
    };
    // if its a whole, dollar amount, leave off the .00
    var price = amount/100, priceBeforePoint, priceAfterPoint, displayPrice;
    const formatter = new Intl.NumberFormat('ba-BA', options);
    var priceWithBAM = formatter.format(amount / 100);
    var priceWithKM = priceWithBAM.slice(3) + 'KM';

    priceBeforePoint = (price.toString().split(".")[0]); ///before
    priceAfterPoint = (price.toString().split(".")[1]); ///after

    if (priceAfterPoint === 0 || priceAfterPoint === undefined ){
      priceAfterPoint = "00"
    }
    else if( priceAfterPoint.length === 1){
      priceAfterPoint = priceAfterPoint + "0"
    }

    displayPrice = priceBeforePoint + "," + priceAfterPoint + "KM";

  
    return displayPrice
  }
  