import React from 'react'
import { Link } from 'react-router-dom'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import Pagination from '../Pagination';
import {Helmet} from "react-helmet";
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addPost, upload } from '../../helpers/UserFunctions'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';

var togglePopupDelete;

class Posts extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;
        
        this.state = {
            allPosts: [],
            pageOfPosts: [],
            showPopupDeleteClass: '',
            id: '',
            title: '',
            link: '',
            image: null,
            published: '',
            imageFile: null,
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
            singlePostKey: '',
            singlePostName: '',
            addedPostName: '',
            test: '',
            description: '',
            loading:false,
        }   

        togglePopupDelete = this.togglePopupDelete.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.onChangeImage = this.onChangeImage.bind(this);
        this.resetImage = this.resetImage.bind(this);
    }

handleChange = (e) => {  
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);    
      }

    
onChangeImage = (event) => {
    this.setState({
      imageFile:event.target.files[0],
      image: URL.createObjectURL(event.target.files[0]),
  });
}

resetImage(event) {
    event.preventDefault();
    this.setState({ image: null });
}

togglePopupDelete (id, name) {
    if (this.state.showPopupDeleteClass === '') {
        this.setState({
            showPopupDeleteClass: 'show',
            singlePostKey: id,
            singlePostName: name,
        })
    }
    else{
        this.setState({
            showPopupDeleteClass: ''
        })
    }     
  }

deletePost(id){
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/posts/delete-post/' + id)
    .then(() => {
        this.getPostList();
        this.setState({
            showPopupDeleteClass: '',
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Uspješno ste izbrisali članak "<b>' + this.state.singlePostName + '"</b>',
        stickerType: 'success',
        })
    })
    .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Došlo je do problema prilikom brisanja posta: ' + error,
        stickerType: 'danger',
    })
    });
}

onChangePage(pageOfPosts) {
    // update state with new page of items
    this.setState({ 
        pageOfPosts: pageOfPosts 
    });
}

onSubmit = (e) => {
    e.preventDefault();
    const {title,description, link, published} = this.state;
    var imageFile = this.state.imageFile;
if(imageFile === null){
    imageFile = new Object();
    imageFile.name = "empty"
}
const newPost = {
    title,
    description,
    link,
    published: parseInt(published),
    image: imageFile.name
    }

    addPost(newPost).then(res => {
            this.getPostList();
            this.setState({
                addedPostName: this.state.title,
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
            }.bind(this),timeoutDelay);
        
            this.setState({
                stickerMessage: 'Uspješno ste dodali članak <b>"' + this.state.addedPostName + '"</b>',
                stickerType: 'success',
                title: '',
                description: '',
                link: '',
                image: '',
                published: '',
            });
        
            this.props.history.push("/administracija/clanci")
          })
          .catch((error) => {
            this.setState({
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
           }.bind(this),timeoutDelay);
           this.setState({
            stickerMessage: 'Došlo je do problema prilikom dodavanja članka.',
            stickerType: 'danger',
            })
        });
   
    if(imageFile !== null){
        const addImage = new FormData();
        addImage.append(
        'image',this.state.imageFile);        
        const config = {       
        headers: {'content-type': 'multipart/form-data'}
        };
    
        upload(addImage, config) .then((response) => {
            }).catch((error) => {
        });
    }
}

getPostList() {  
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/posts')
.then((response) => {
this.setState({
allPosts: response.data
});
})
.catch((error) => {
console.log(error);
})
}

componentDidMount = () => {
    this.getPostList();
}

    render(){
        var description = this.state.description;
        const { id, title, link, published, image} = this.state;
        return(
            <AdminWrapper>
            <section id="posts" className="section default-section add-post content-page-section default-form">
            {this.state.loading && (
                <div className="loader-box">
                    <span className="loader"></span>
                </div>
            )}

            <div className={"popup-wrapper " + this.state.showPopupDeleteClass}>
                    <div className="overlay-back"></div>
                    <div className="popup-box popup-delete">
                        <h6 className="text">Jesi li siguran da želiš izbrisati post <br /><b>"{this.state.singlePostName}"</b>?</h6>
                        <div className="button-area">
                            <button className="button confirm" onClick={this.deletePost.bind(this, this.state.singlePostKey)}>Želim <FontAwesomeIcon icon="trash-alt" /></button>
                            <button className="button decline" onClick={this.togglePopupDelete.bind(this)}>Ne, hvala <FontAwesomeIcon icon="times-circle" /></button>
                        </div>
                    </div>
                </div>
            {this.state.test}
             {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> Stranica 1  &rsaquo; Posti  &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="content">
                        <div className="page-header">
                           <h2 className="page-title">Novi članak</h2>
                        </div>
                        <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>   
                            <span>članci</span>
                        </div>
                        <div className="columns">
                            <div className="column is-5">
                                <form name="add-post" onSubmit={this.onSubmit} className="form">
                                 <fieldset className="">
                                    <label htmlFor="title" className="column is-12 title">
                                        Ime posta
                                        <input type="text" id="title" name="title" placeholder="Puno ime članka"
                                        value={title} onChange={ this.handleChange} required/>
                                    </label>

                                    <label htmlFor="link" className="column is-12">
                                        Link posta
                                        <input type="text" id="link" name="link" placeholder="Link koji će se otvarati putem članka"
                                        value={link} onChange={ this.handleChange} required/>
                                    </label>                          
                                    <label htmlFor="published" className="column is-12">
                                    Prikazati post na početnoj strani?
                                        <select id="published" name="published" value={published} onChange={ this.handleChange }>
                                            <option value="">Izaberi</option>
                                            <option value="1">Da</option>
                                            <option value="0">Ne</option>
                                        </select>   
                                    </label>
                                    <label htmlFor="description" className="column is-12 description">
                                        Skraćeni tekst članka                            
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            description=""
                                            onInit={ editor => {
                                              
                                            } }
                                            onChange={ ( event, editor ) => {
                                                this.setState({
                                                    description: editor.getData(),
                                                })
                                                console.log( { event, editor, description } );
                                            } }
                                            onBlur={ editor => {
                                                console.log( 'Blur.', editor );
                                            } }
                                            onFocus={ editor => {
                                                console.log( 'Focus.', editor );
                                            } }
                                        />
                                    </label>
                                    <span className="is-hidden">
                                        {description = this.state.description}
                                    </span>
                                    <div id="upload_button" className="">
                                    <label htmlFor="image" className="label-image">
                                        Slika posta
                                    <input type="file" id="image" name="image" onChange={ this.onChangeImage }/>
                                    <span className="add-image">Dodaj sliku <FontAwesomeIcon icon="image" /></span>
                                    </label>
                                    {image &&  (<div className="image-preview">
                                        <img src={image} alt="" />
                                        {image && (                                 
                                                <button className="remove-button" onClick={this.resetImage}><i className="icon"></i></button>
                                                )}
                                    </div>
                                    )}
                                    </div>       
                                    <button type="submit" className="button default add default-no-icon"><span className="item">Dodaj članak <FontAwesomeIcon icon="plus-circle" /></span></button>

                            </fieldset>
                        </form>
                            </div>
                            
                            <div className="column is-7 post-list default-list is-right">
                            {this.state.pageOfPosts.map(singlePost =>
                               <div className="single-row">
                                    <div className="image">
                                        <img src={process.env.REACT_APP_FILES_DESTINATION + singlePost.image} />
                                    </div>
                                    <h1 className="title">{singlePost.title}</h1>
                                    <div className="options is-right">
                                        <Link to={`/administracija/uredjivanje-clanka/${singlePost._id}`} className="link"><FontAwesomeIcon icon="edit" /></Link>
                                        <button onClick={this.togglePopupDelete.bind(this, singlePost._id, singlePost.title)}  className="link"><FontAwesomeIcon icon="trash-alt" /></button>
                                    </div>  
                               </div>  
                            )}
                              <div className="pagination-wrapper pagination-bottom">
                              <Pagination items={this.state.allPosts} onChangePage={this.onChangePage} pageName="Posti" /> 
                            </div> 
                               
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            </AdminWrapper>
        )
    }
}

export default Posts