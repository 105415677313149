import React from 'react'
import { Link } from 'react-router-dom'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import {Helmet} from "react-helmet";
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';

var filtersInCategory = [];
var randomString = require('random-string');

var togglePopupDelete, toggleEditBox;

class EditFiltersCategory extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;
        
        this.state = {
            filtersCategories: [],
            showPopupClass: '',
            showPopupDeleteClass: '',
            showEditFilterBoxClass: '',
            name: '',
            id: '',
            filters: [],
            currentFiltersCategory: '',
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
            filter: '',
            editedFilter: '',
            filterToRemoveId: '',
            filterToRemoveTitle: '',
            loading:false,
            filtersInCategory: [],

        }   
        togglePopupDelete = this.togglePopupDelete.bind(this);
        toggleEditBox = this.toggleEditBox.bind(this);
    }

handleChange = (e) => {     
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
}

//Prikaz popupa za brisanje kategorije
togglePopupDelete (id, name) {
    if (this.state.showPopupDeleteClass === '') {
        this.setState({
            showPopupDeleteClass: 'show',
            filterToRemoveTitle: name,
            filterToRemoveId: id,
        })
    }
    else{
        this.setState({
            showPopupDeleteClass: ''
        })
    }     
    }

toggleEditBox (id, name) {
    if (this.state.showEditFilterBoxClass === '') {
        this.setState({
            showEditFilterBoxClass: 'show',
            filterToRemoveTitle: name,
            filterToRemoveId: id,
            editedFilter: name,
        })
    }
    else{
        this.setState({
            showEditFilterBoxClass: ''
        })
    }     
    }

onSubmit = (e) => {
    e.preventDefault();
    const { name } = this.state;
    axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters/update-filter-category/' + this.props.match.params.id, {
    name: name,
    })
    .then((response) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Uspješno ste uredili kategoriju filtera "<b>' + this.state.name + '"</b>',
        stickerType: 'success',
    })
    
    this.props.history.push('/administracija/filteri');
    })
    .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Došlo je do problema prilikom uređivanja kategorije filtera: ' + error,
        stickerType: 'danger',
    })
    });
}

onSubmitAddFilter = (e) => {
    e.preventDefault();
    var filters = [];
    var newFilter;
    const {filter } = this.state;
    newFilter = new Object();
    newFilter.name = filter;
    newFilter.id = randomString({length: 10});
        
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters/edit-filter-category/' + this.props.match.params.id)
        .then((response) => {
            if(response.data.filters){
                filters = JSON.parse(response.data.filters);
            }
           
        filters.push(newFilter ); 
        filters = JSON.stringify(filters);  

       
        axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters/update-filter/' + this.props.match.params.id, {
            filters: filters,
            })
            .then((response) => {
                filtersInCategory = [];
                filters = JSON.parse(filters);
                Object.keys(filters).forEach(function(id) {
                    filtersInCategory.push(filters[id]);
                });

                this.setState({
                showStickerClass: 'show',
                filtersInCategory,
                })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
            }.bind(this),timeoutDelay);
            this.setState({
                filter: '',
            stickerMessage: 'Uspješno ste dodali filter "<b>' + newFilter.name + '"</b>',
            stickerType: 'success',
            })

        })
        .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
    
            showStickerClass: ''
            })
        }.bind(this),timeoutDelay);
        this.setState({
        stickerMessage: 'Došlo je do problema prilikom dodavanja filtera: ' + error,
        stickerType: 'danger',
        })
    });

        })
        .catch((error) => {
            console.log(error);
        })
     
}

    editFilter(id){
        var filters = [];
        filtersInCategory = this.state.filtersInCategory;
        for (var i in filtersInCategory) {
            if(filtersInCategory[i].id == id)
            {
              
                filtersInCategory[i].name = this.state.editedFilter;
            }
        }

        filters = JSON.stringify(filtersInCategory); 
        axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters/update-filter/' + this.props.match.params.id, {
            filters: filters,
            })
            .then((response) => {
                filtersInCategory = [];
                filters = JSON.parse(filters);
                Object.keys(filters).forEach(function(id) {
                    filtersInCategory.push(filters[id]);
                });
                  this.setState({
                    showEditFilterBoxClass: '',
                    filtersInCategory,
                    editedFilter: '',
                      showStickerClass: 'show',
                  })
                  setTimeout(function(){
                    this.setState({
                        showStickerClass: ''
                    })
               }.bind(this),timeoutDelay);
               this.setState({
               
                stickerMessage: 'Filter "<b>' + this.state.filterToRemoveTitle + '"</b> je uspješno uređen.',
                stickerType: 'success',
            })
        })
        .catch((error) => {
            this.setState({
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
           }.bind(this),timeoutDelay);
           this.setState({
            stickerMessage: 'Došlo je do problema prilikom uređivanja filtera: ' + error,
            stickerType: 'danger',
        })
    });
    }

    deleteFilter(id){
        var filters = [];
        filtersInCategory = this.state.filtersInCategory;
        for (var i in filtersInCategory) {
            if(filtersInCategory[i].id == id)
            {
                filtersInCategory.splice(i,1);
            }
        }

        filters = JSON.stringify(filtersInCategory); 
        axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters/update-filter/' + this.props.match.params.id, {
            filters: filters,
            })
            .then((response) => {
                filtersInCategory = [];
                filters = JSON.parse(filters);
                Object.keys(filters).forEach(function(id) {
                    filtersInCategory.push(filters[id]);
                });
                  this.setState({
                      showPopupDeleteClass: '',
                      showStickerClass: 'show',
                      filtersInCategory,
                  })
                  setTimeout(function(){
                    this.setState({
                        showStickerClass: ''
                    })
               }.bind(this),timeoutDelay);
               this.setState({
                stickerMessage: 'Filter "<b>' + this.state.filterToRemoveTitle + '"</b> je uspješno izbrisan.',
                stickerType: 'success',
            })
        })
        .catch((error) => {
            this.setState({
                showStickerClass: 'show',
            })
            setTimeout(function(){
                this.setState({
                    showStickerClass: ''
                })
           }.bind(this),timeoutDelay);
           this.setState({
            stickerMessage: 'Došlo je do problema prilikom brisanja filtera: ' + error,
            stickerType: 'danger',
        })
    });
}
    
getFiltersCategoryById() {
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters/edit-filter-category/' + this.props.match.params.id)
    .then((response) => {
    this.setState({
    name: response.data.name,
    });
    if(response.data.filters){
        this.setState({
            filtersInCategory: JSON.parse(response.data.filters),
        });   
    }
    })
    .catch((error) => {
    console.log(error);
    })
}


componentDidMount = () => {
    this.getFiltersCategoryById();
}

    render(){
        const name = this.state.name;
        return(
            <AdminWrapper>
            <section id="categories" className="section default-section content-page-section add-category default-form">
            {this.state.loading && (
                <div className="loader-box">
                    <span className="loader"></span>
                </div>
            )}

              {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> {name}  &rsaquo; Uredi kategoriju filterova  &rsaquo; Zdravo.ba </title>
                </Helmet>
                        <div className={"popup-wrapper " + this.state.showPopupDeleteClass}>
                            <div className="overlay-back"></div>
                            <div className="popup-box popup-delete">
                                <h6 className="text">Jeste li sigurni da želite izbrisati filter<br /><b>"{this.state.filterToRemoveTitle}"</b>?</h6>
                                <div className="button-area">
                                    <button className="button confirm"  onClick={() => this.deleteFilter(this.state.filterToRemoveId)}>Želim <FontAwesomeIcon icon="trash-alt" /></button>
                                    <button className="button decline" onClick={this.togglePopupDelete.bind(this)}>Ne, hvala <FontAwesomeIcon icon="times-circle" /></button>
                                </div>
                            </div>
                        </div>

                        <div className={"popup-wrapper " + this.state.showEditFilterBoxClass}>
                            <div className="overlay-back"></div>
                            <div className="popup-box popup-delete popup-edit-filter">
                                <h6 className="text">Uredi filter <b>"{this.state.filterToRemoveTitle}"</b></h6>
                                {this.state.filter}
                                <label htmlFor="name" className="column is-12 title">
                                    Ime filtera
                                <input type="text" id="editedFilter" name="editedFilter" placeholder="Ime filtera"
                                        value={this.state.editedFilter} onChange={ this.handleChange}/>
                                </label>
                                    <div className="button-area">
                                    <button className="button confirm" onClick={() => this.editFilter(this.state.filterToRemoveId)}>Uredi <FontAwesomeIcon icon="edit" /></button>
                                    <button className="button decline" onClick={this.toggleEditBox.bind(this)}>Zatvori <FontAwesomeIcon icon="times-circle" /></button>
                                </div>

                            
                            </div>
                        </div>
                    <div className="content">
                        <div className="page-header">
                           <h2 className="page-title">Uređivanje filtera</h2>
                        </div>
                        <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <Link to="/administracija/filteri">Filteri</Link>
                            <span>/</span>
                            <span>Uređivanje filtera</span>
                        </div>
                        <div className="columns">
                            <div className="column is-5">
                                <form name="add-category" onSubmit={this.onSubmit} className="form">
                                 <fieldset className="">
                                    <label htmlFor="name" className="column is-12 title">
                                        Ime kategorije filtera
                                        <input type="text" id="name" name="name" placeholder="Puno ime kategorije"
                                        value={name} onChange={ this.handleChange} required/>
                                    </label> 

                                    <button type="submit" className="button default add default-no-icon"><span className="item">Spremi izmjene <FontAwesomeIcon icon="pencil-alt" /></span></button>

                            </fieldset>
                        </form>
                            </div>
                            
                            <div className="column is-6 default-list filters-list is-right">
                            <form name="add-category" onSubmit={this.onSubmitAddFilter} className="form add-filter-form">
                                 <fieldset className="">
                                    <label htmlFor="name" className="column is-12">
                                        Dodaj novi filter
                                        <input type="text" id="filter" name="filter" placeholder="Ime filtera"
                                        value={this.state.filter} onChange={ this.handleChange} required/>
                                    </label> 

                                    <button type="submit" className="button default add default-no-icon"><span className="item">Dodaj novi filter <FontAwesomeIcon icon="plus-circle" /></span></button>

                            </fieldset>
                         </form>
                         <div className="inner-box">
                            {this.state.filtersInCategory.map(singleFilter =>
                               <div className="single-row">
                                    <h1 className="title">{singleFilter.name}</h1>
                                    <div className="options is-right">
                                        <button onClick={this.toggleEditBox.bind(this, singleFilter.id, singleFilter.name)} className="link"><FontAwesomeIcon icon="edit" /></button>
                                        <button onClick={this.togglePopupDelete.bind(this, singleFilter.id, singleFilter.name)} className="link"><FontAwesomeIcon icon="trash-alt" /></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            </div>
                         
                        </div>
                        
                    </div>
                </div>
            </section>
            </AdminWrapper>
        );
    }
}

export default EditFiltersCategory