/*
About Us page
Display static page with informations about us
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import {siteTitle} from '../../../functions/config'
import {Link} from 'react-router-dom'
import { loginUrl, registerUrl } from '../../../functions/pageUrls';
import { login } from '../../helpers/UserFunctions'

var pageTitle = "Prijava";
class SignIn extends React.Component{
    constructor() {
        super()
        this.state = {
          email: '',
          password: '',
          errors: {}
        }
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
      }
  
      onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
      }

      onSubmit(e) {
        e.preventDefault();
        const { email, password } = this.state;
        
    const user = {
        email,
        password,
      }
  
      login(user).then(res => {
        if (res) {
          this.props.history.push(`/`)
        }
      })
      .catch(err => {
        console.log(err)
      })
      };

      componentDidMount() { 
      }
      
    render(){
        const { email, password, error } = this.state;
        return(
            <ShopWrapper>
              {/*  <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
              </Helmet>*/}
                <section id="p_signin" className="section default-form signin-page">
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={pageTitle} />

                        {/******************* Content *******************/}
                        <div className="content">
                            <p>Prijavite se na vaš <b>zdravo.ba</b> račun.</p>
                            <form name="login" method="POST" onSubmit={this.onSubmit} className="login-form">
                                <fieldset className="columns">
                                    <label htmlFor="email" className="email column is-12">E-mail adresa
                                        <input type="email" name="email" id="email" onChange={this.onChange} placeholder="E-mail adresa"/>
                                    </label>
                                    <label htmlFor="password" className="password column is-12" >Lozinka
                                        <input type="password" name="password" onChange={this.onChange} id="password"placeholder="Lozinka"/>
                                    </label>

                                    <div className="button-area">
                                        <Link to="/administracija/" className="forgot-password">Zaboravili ste lozinku?</Link>
                                        <button type="submit" className="button default is-right"><span className="item">Prijava</span></button>
                                    </div>
                                    <p className="register-text">Ukoliko još uvijek nemate otvoren vaš korisnički račun, možete to uraditi klikom na dugme ispod.</p>
                                    <div className="button-area">
                                        <Link to={"/"+registerUrl} className="register-button">Kreirajte račun</Link>
                                    </div>
                                   
                                </fieldset>
                            </form>

                         {/*   <div className="divider">
                                <p>ili</p>
                            </div>

                            <div className="social-login">
                                <a href="" className="google"><span className="icon"><i></i></span><span className="item">Prijavite se putem Google-a</span></a>
                                <a href="" className="facebook"><span className="icon"><i></i></span><span className="item">Prijavite se putem Facebook-a</span></a>
                            </div>

                        */}

                        </div>
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}
            </ShopWrapper>
        )
    }
}

export default SignIn