import React from 'react'
import {Link} from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import Sticker from '../Sticker'
import AdminWrapper from '../AdminWrapper';
import {Helmet} from "react-helmet";
import Reactable from 'reactable';
import { siteTitle, timeoutDelay } from '../../../functions/config'
import { userUrl } from '../../../functions/pageUrls'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { months}  from '../../helpers/months'
import no_image from '../../../static/no_image.png'
var Table = Reactable.Table,
    Thead = Reactable.Thead,
    Th = Reactable.Th;

var togglePopupDelete;
class Users extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            users: [],
            totalNumberUsers: [],
            filteredUsers: [],
            currentPage: 0,
            showPopupDeleteClass: '',
            showStickerClass: '',
            stickerMessage: '',
            stickerType: '',
            singleUserKey: '',
            singleUserName: '',
            loading:false,
        };

        togglePopupDelete = this.togglePopupDelete.bind(this);
      }
    
    

togglePopupDelete (id, name, surname) {
if (this.state.showPopupDeleteClass === '') {
    this.setState({
        showPopupDeleteClass: 'show',
        singleUserKey: id,
        singleUserName: name + " "+surname,
    })
}
else{
    this.setState({
        showPopupDeleteClass: ''
    })
}     
}

deleteUser(id){
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/users/delete-user/' + id)
    .then(() => {
        this.getUsersList();
        this.setState({
            showPopupDeleteClass: '',
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Uspješno ste izbrisali korisnika "<b>' + this.state.singleUserName + '"</b>',
        stickerType: 'success',
        })
    })
    .catch((error) => {
        this.setState({
            showStickerClass: 'show',
        })
        setTimeout(function(){
            this.setState({
                showStickerClass: ''
            })
       }.bind(this),timeoutDelay);
       this.setState({
        stickerMessage: 'Došlo je do problema prilikom brisanja korisnika: ' + error,
        stickerType: 'danger',
    })
    });
    
    }


userOptions(key, name, surname){
    return(
        <div className={"options-wrapper"}>
            <div className="inner">
                <button onClick={this.togglePopupDelete.bind(this, key, name, surname)} className="button red">Izbriši</button>
            </div>
        </div>
    )
}

getUsersList() {  
    const users = [],totalNumberUsers = [], publishedUsers = [], draftUsers = [], unpublishedUsers = [];
    var usersType = null, newUser, getApi;

    
     //Collect users from selected category
     getApi = axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/users')
    .then((response) => {
    response.data.forEach((doc) => {
        const { user_id, name, surname, email, phone, city, totalPoints, userCreatedAt, address, postalCode } = doc;

            users.push({
                _id: doc._id,
                id: user_id,
                name,
                surname,
                email,
                phone,
                address,
                postalCode,
                city,
                userCreatedAt: userCreatedAt.split('T')[0],
                totalPoints
            })
        })
        this.setState({
            users: users,
            filteredUsers: users,
            totalNumberUsers: users,
        })

    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/users')
    .then((response) => {
        response.data.forEach((doc) => {
            publishedUsers.push({
            key: doc._id,
            });
            });
            this.setState({
            publishedUsers,
        });
    });
    }).catch((error) => {
        console.log(error);
    })
}

componentDidMount = () => {
    this.getUsersList();
}

    render(){
        var sortableUsers = [];
        
          {this.state.users.map(user =>
            {
                var unformatedDate = user.userCreatedAt.split('-');
                var formatedMonth ,formatedDate;
                months.forEach(month => {
                    if(month.value == unformatedDate[1])  {
                        formatedMonth = month.name;
                    }
                });
                formatedDate = unformatedDate[2] + " " + formatedMonth + ", " + unformatedDate[0];
                sortableUsers.push({
                    id: user.id,
                    name: user.name+" "+user.surname, 
                    email: user.email, 
                    phone: user.phone,
                    address: user.address + ", "+user.postalCode+", "+ user.city,
                    totalPoints: user.totalPoints, 
                    date: formatedDate, 
                    options: this.userOptions(user._id, user.name, user.surname)
                })
            }
           
        )}
        return(
            <AdminWrapper>
            <section id="users" className="section users content-page-section default-table users-table">
            {this.state.loading && (
                <div className="loader-box">
                    <span className="loader"></span>
                </div>
            )}
             <div className={"popup-wrapper " + this.state.showPopupDeleteClass}>
                    <div className="overlay-back"></div>
                    <div className="popup-box popup-delete">
                        <h6 className="text">Jesi li siguran da želiš izbrisati korisnika <br /><b>"{this.state.singleUserName}"</b>?</h6>
                        <div className="button-area">
                            <button className="button confirm" onClick={this.deleteUser.bind(this, this.state.singleUserKey)}>Želim <FontAwesomeIcon icon="trash-alt" /></button>
                            <button className="button decline" onClick={this.togglePopupDelete.bind(this)}>Ne, hvala <FontAwesomeIcon icon="times-circle" /></button>
                        </div>
                    </div>
                </div>
                {this.state.showStickerClass=== 'show' && (  
                <Sticker stickerType={this.state.stickerType} stickerMessage={this.state.stickerMessage} />
                )}
                <div className="container">
                <Helmet>
                    <title> Administracija  &rsaquo; Korisnici  &rsaquo; Zdravo.ba </title>
                </Helmet>
                    <div className="page-header">
                    <h2 className="page-title">Svi korisnici</h2>
                    </div>
                  
                    <div className="breadcrumbs">
                            <Link to="/administracija">Administracija</Link>
                            <span>/</span>
                            <span>Korisnici</span>
                        </div>

                    <div className="meta-info">
                        <p className="users-number default-text">Svi korisnici <b>({this.state.totalNumberUsers.length})</b></p>
                    </div>
                    
                    <Table className="default-table"
                    filterable={['name','email', 'phone',  'address', 'date', 'totalPoints']}
                    noDataText="Nema pronađenih korisnika."
                    itemsPerPage={12}
                    pageButtonLimit={5}
                    currentPage={this.state.currentPage}
                    sortable={[
                        'id',
                        'totalPoints',
                        'name',
                        'date',
                    ]}
                    previousPageLabel="Nazad"
                    nextPageLabel="Naprijed"
                    data={sortableUsers} 
                    defaultSort={{column: 'id', direction: 'desc'}}
                    >
                    <Thead>
                        <Th column="id">ID</Th>
                        <Th column="name">Ime i prezime</Th>
                        <Th column="email">Email</Th>
                        <Th column="phone">Telefon</Th>
                        <Th column="address">Adresa</Th>
                        <Th column="totalPoints">Broj bodova</Th>
                        <Th column="date">Datum registracije</Th>
                        <Th column="options">Opcije</Th>
                    </Thead> 
                    </Table>
                </div>
            </section>
            </AdminWrapper>
        );
    }

}

export default Users