import React, { Component } from "react";
import {Link, withRouter} from 'react-router-dom'

class Logout extends Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }
  handleLogout() {
    localStorage.removeItem("usertoken");
    this.props.history.push("/prijava");
  }
  render() {
    return (
        <li><span className="link" onClick={this.handleLogout}>Odjava</span></li>
    );
  }
}
export default withRouter(Logout);