/*
Intro component for homepage
Display Intro slider with banners on right side
*/
import React from 'react'
import { Link } from 'react-router-dom'
import mobile_intro_image from '../../../static/intro_home_1.jpg'
import axios from 'axios';
import Slider from "react-slick";

class IntroMobile extends React.Component{
    constructor () {
        super()
        this.unsubscribe = null;
        
        this.state = {
            sliders: [],
            loading: false
        }
      }
getSliderList() {  
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/sliders')
    .then((response) => {
        this.setState({
            sliders: response.data
        });
    })
    .catch((error) => {
        console.log(error);
    })
}

componentDidMount = () => {
    this.getSliderList();
}
    render(){
          //Slider attributes

          const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: true,
          };

        var {mobileBannerImg, mobileBannerLink} = this.state;
        return(
        <section id="s_intro" className="section intro-mobile">
            <div className="container">
                <Slider {...settings}>
                    {this.state.sliders.map(slider => 
                            <>
                            {slider.published === 1 && (
                        <Link to={slider.link} className="image"  area-label="Slider image">
                            {slider.image && <img src={process.env.REACT_APP_FILES_DESTINATION + slider.imageM} alt="Slider" />}
                        </Link>/* AREA LEFT END */
                            )}
                        </>
                    )}
                </Slider>
            </div>{/*  CONTAINER END */}
        </section>/* INTRO SECTION END */
        );
    }
}

export default IntroMobile
